import React from 'react';
import { formatDate, getLeaveStatusInfo } from '../../../src/component/Common';
const moment = require('moment');

const ViewLeaveComponent = ({ visible, onClose, editValues }) => {
	const { leaveStatusName, leaveTextcolor } = getLeaveStatusInfo(
		editValues?.leave_status
	);
	const startDate = moment(editValues?.start_date);
	const endDate = moment(editValues?.end_date).add(1, 'days');
	const dayDiff = endDate.diff(startDate, 'days');

	return (
		<>
			<div
				className={` togglesidebar bg-[#ffffff57] fixed h-full w-full top-0 left-0 p-6 z-[99999]  ${
					visible ? 'active' : ''
				}`}
			>
				<div
					onClick={onClose}
					className="w-full bg-[#ffffff57] overlay bg-[#0f1a32] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
				/>
				<div className="dark:bg-[#12243d] bg-[#fafafa] border-gray-300 border-l w-[85%] lg:w-[50%] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]">
					<button
						className="close h-8 w-8 rounded-full absolute top-5 right-5 bg-[#fff]"
						onClick={onClose}
					>
						X
					</button>
					<h1 className="p-6 dark:text-white text-[#4b5563] font-bold text-[20px] text-left pb-[20px] border-solid border-b dark:border-[#333333] border-gray-300">
						Leave Information
					</h1>
					<div className="rounded-lg">
						<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
							<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
								<div className="info-address pb-[15px] flex">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Leave Type
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{editValues?.leave_type_name ||
												'N/A'}
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											From Date
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{formatDate(
												editValues?.start_date,
												'DD MMM YYYY'
											) || 'N/A'}
										</h4>
									</div>
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											To Date
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{formatDate(
												editValues?.end_date,
												'DD MMM YYYY'
											) || 'N/A'}
										</h4>
									</div>
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											No.Of Days
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{`${dayDiff} ${
												dayDiff > 1 ? 'days' : 'day'
											}`}
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Status
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											<div className="flex font-semibold">
												<button
													className={`inline-flex items-center gap-[5px] bg-white border ${leaveTextcolor} font-semibold text-[14px] px-3 py-1 pr-[15px] rounded-[50px] border-solid`}
												>
													{' '}
													<i
														className={`fa-regular fa-circle-dot ${leaveTextcolor}`}
													></i>{' '}
													{leaveStatusName}
												</button>
											</div>
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Approved / Rejected By
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{`${
												editValues?.approved_by
													?.first_name || ''
											} ${
												editValues?.approved_by
													?.last_name || ''
											}`}
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Reason
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											<span
												className="noti-title"
												dangerouslySetInnerHTML={{
													__html: `${editValues?.reason}`,
												}}
											></span>
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Notes
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{editValues?.additional_notes ||
												'N/A'}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewLeaveComponent;
