import React, { useEffect, useState } from 'react';
import InputComponent from '../../../component/ui-component/InputComponent';
import CKEditorComponent from '../../../component/ui-component/CKEditorComponent';
import Button from '../../../component/ui-component/Button';
import { useDispatch, useSelector } from 'react-redux';
import { vsmRecruitment } from '../../../utils/validations';
import { readFileWithExtension } from '../../../component/Common';
import SelectComponent from '../../../component/ui-component/SelectComponent';
import { getJobsList } from '../../../store/thunk/jobsThunk';
import DatePickerComponent from '../../../component/ui-component/DatePickerComponent';
import MultiSelectComponent from '../../../component/ui-component/MultiSelectComponent';
import { getUserList } from '../../../store/thunk/userThunk';
import RadioComponent from '../../../component/ui-component/RadioComponent';
import TimePickerComponent from '../../../component/ui-component/TimePickerComponent';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	notes,
	feedback,
	setValue,
	setUploadedFile,
	fileURL,
	selectedJob,
	selectedAssignedInterviewers,
	selectedInterviewStatus,
}) => {
	const dispatch = useDispatch();
	const jobsList = useSelector((state) => state.jobs.jobsList);
	const userList = useSelector((state) => state.user.userList);
	const [isMultiSelectValid, setIsMultiSelectValid] = useState(
		selectedAssignedInterviewers && selectedAssignedInterviewers.length > 0
			? true
			: false
	);
	const [error, setError] = useState(false);

	const optionsLists = [
		{ id: 'open', name: 'Open', value: true },
		{ id: 'close', name: 'Close', value: false },
	];

	useEffect(() => {
		dispatch(getJobsList());
		dispatch(getUserList());
	}, [dispatch]);

	const onChangeSelectValue = (key, selectedValue) => {
		setValue(key, selectedValue);
	};

	const handleEditorChange = (id, content) => {
		setValue(id, content);
	};
	const handleFileChange = (file) => {
		const selectedFile = file.target.files[0];
		setUploadedFile(selectedFile);
	};
	const onChangeMultiSelectValue = (key, selectedValue) => {
		let assigned_interviewers_ids = [];
		selectedValue.map((item) =>
			assigned_interviewers_ids.push(parseInt(item.value))
		);
		setValue(key, assigned_interviewers_ids);
		setIsMultiSelectValid(selectedValue.length > 0);
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (!isMultiSelectValid) {
			setError(true);
			return;
		} else {
			setError(false);
		}
		// Proceed with form submission
		handleSubmit(onSubmit)(e);
	};
	return (
		<form onSubmit={handleFormSubmit} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-6 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="dark:text-white text-[#4b5563] font-bold text-[16px] text-left pb-[10px] text-[18px]">
							Applicant Information
						</h3>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
						<InputComponent
							id="full_name"
							title="Full Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
						<InputComponent
							id="email"
							title="Email Address"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
						<InputComponent
							id="phone_number"
							title="Phone Number"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
						<InputComponent
							id="address"
							title="Address"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
						<InputComponent
							id="linkedin_profile"
							title="Linkedin Profile URL"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
						<InputComponent
							id="portfolio_url"
							title="Portfolio URL"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
						<InputComponent
							id="education"
							title="Education"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
						<InputComponent
							id="work_experience"
							title="Work Experience"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
						<InputComponent
							id="skills"
							title="Skills"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
						<InputComponent
							id="references"
							title="Reference By"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
						<InputComponent
							id="resume"
							inputType={'file'}
							title="Resume"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
							onChange={handleFileChange}
						/>
						{isUpdate && fileURL != null && (
							<div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
								{fileURL
									? readFileWithExtension(fileURL, 'resumes/')
									: 'N/A'}
							</div>
						)}
					</div>
				</div>
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-6 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="dark:text-white text-[#4b5563] font-bold text-[16px] text-left pb-[10px] text-[18px]">
							Interview Information
						</h3>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
						<SelectComponent
							selectOptionLists={jobsList}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedJob}
							title="Select Job"
							id="job"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
						<InputComponent
							id="interview_format"
							title="Interview Format"
							placeholder="[In-person/Remote (Zoom/Teams/Skype)]"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
						<DatePickerComponent
							id="interview_date"
							title="Interview Date"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
						<TimePickerComponent
							id="from_time"
							title="From Time"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
						<TimePickerComponent
							id="to_time"
							title="To Time"
							errors={errors}
							register={register}
							fieldvalidation={vsmRecruitment}
						/>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-1 gap-2 mt-2">
						<MultiSelectComponent
							selectOptionLists={userList}
							onChangeSelectValue={onChangeMultiSelectValue}
							selectedValue={selectedAssignedInterviewers}
							title="Interviewers"
							id="assigned_interviewers"
							error={error}
						/>
						<CKEditorComponent
							id="notes"
							title="Notes"
							data={notes}
							onChange={handleEditorChange}
						/>
						{isUpdate && (
							<>
								<CKEditorComponent
									id="feedback"
									title="Feedback"
									data={feedback}
									onChange={handleEditorChange}
								/>
								<RadioComponent
									id="status_interview"
									title="Interview Status"
									errors={errors}
									register={register}
									fieldvalidation={vsmRecruitment}
									optionsLists={optionsLists}
									selectedOptions={selectedInterviewStatus}
									onChangeSelectValue={onChangeSelectValue}
								/>
							</>
						)}
					</div>
				</div>
			</div>
			<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<Button
					buttonText={`${isUpdate ? 'Update' : 'Create'} Applicant`}
					linkTo="#"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default FormComponent;
