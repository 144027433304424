import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
	notificationSuccess,
	notificationFail,
} from '../slices/notificationSlice';
import { setLoading } from '../slices/dataLoadingSlice';
import { setRolesData, setFetching } from '../slices/rolesSlice';
import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';
import { vsmRoles } from '../../utils/validations';

export const getRolesList = createAsyncThunk(
	'getRolesList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			let { pageNo, pageSize, searchText, sortBy } = _request;
			let ordering = '';
			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';
				if (sortField === 'department.title') {
					sortField = 'department__title';
				}
				if (sortField !== 'active' && sortField !== 'action') {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}
			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';
			const response = await apiClient(false).get(
				`/accounts/roles/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setRolesData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addRole = createAsyncThunk(
	'addRole',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().post(
				`/accounts/roles/`,
				_request
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getRolesList());
				dispatch(notificationSuccess(vsmRoles?.addSucces));
				toast.success(vsmRoles?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const editRole = createAsyncThunk(
	'editRole',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().patch(
				`/accounts/roles/${_request.id}/`,
				_request
			);

			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getRolesList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmRoles?.updateSuccess);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeRole = createAsyncThunk(
	'removeRole',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(`/accounts/roles/${_request.id}/`);
			dispatch(setLoading(false));
			dispatch(getRolesList());
			dispatch(notificationSuccess(vsmRoles?.deleteSuccess));
			toast.success(vsmRoles?.deleteSuccess);
			// _request.callbackFunction();
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);
