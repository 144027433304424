import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	ScheduleComponent,
	ViewsDirective,
	ViewDirective,
	Inject,
	ResourcesDirective,
	ResourceDirective,
	Resize,
	TimelineMonth,
	Month,
} from '@syncfusion/ej2-react-schedule';
import { getUserList } from '../../store/thunk/userThunk';
import { getAllLeavesList } from '../../store/thunk/leavesThunk';
import UserProfile from '../../component/ui-component/UserProfile';
import { getDefaultProfile } from '../../component/Common';

const UserAwayComponent = () => {
	const dispatch = useDispatch();
	const userList = useSelector((state) => state.user.userList);
	const leavesData = useSelector((state) => state.leaves);
	const [userLeaveCount, setUserLeaveCount] = useState(0);

	useEffect(() => {
		dispatch(getUserList());
		dispatch(getAllLeavesList());
	}, [dispatch]);

	useEffect(() => {
		const newCount = leavesData.myleavesList.reduce((count, leave) => {
			if (leave?.leave_status_name === 'Approved') {
				return count + 1;
			}
			return count;
		}, 0);
		setUserLeaveCount(newCount);
	}, [leavesData.myleavesList]);

	const timelineEventTemplate = (props) => {
		return (
			<div
				className="template-wrap"
				style={{ background: props.PrimaryColor }}
			>
				<div
					className="subject"
					style={{ background: props.SecondaryColor }}
				>
					{props.Subject}
				</div>
			</div>
		);
	};
	const generateStaticEvents = (leaveLists) => {
		const data = leaveLists
			.filter((leave) => leave?.leave_status_name === 'Approved')
			.map((leave) => ({
				Id: leave?.id,
				Subject: 'Away',
				StartTime: new Date(leave?.start_date),
				EndTime: new Date(leave?.end_date),
				IsAllDay: leave?.id % 10 ? false : true,
				ResourceId: leave?.user?.id,
			}));

		return data;
	};
	const generateResourceData = (userList) => {
		const data = userList.map((user) => ({
			Id: user.id,
			Text: user.fullname,
			profile_image_url: user.profile_image_url,
			designation: user.designation,
			gender: user.gender,
		}));
		return data;
	};
	const resourceHeaderTemplate = (props) => {
		return (
			<div className="flex multi-options ml-4">
				<UserProfile
					imageUrl={props?.resourceData?.profile_image_url}
					defaultImageUrl={getDefaultProfile(
						props?.resourceData?.gender
					)}
					altName={props?.resourceData?.Text}
					className={
						'rounded-full h-[38px] w-[38px] inline-block mt-[2px] rounded-full bg-white border border-slate-300 p-0.5'
					}
				/>
				<div className="ml-3 mt-1">
					<h6 className="font-bold mb-0 text-[12px]">
						{`${props?.resourceData?.Text}`}
					</h6>
					<p className="text-[10px] text-left">
						{props?.resourceData?.designation || ''}
					</p>
				</div>
			</div>
		);
	};
	return (
		<div className="p-[20px]">
			<div className="dark:text-[#e2e8f0] text-[#54565C] text-left mb-4 text-base font-semibold">
				{`${userLeaveCount} people away this month`}
			</div>
			<div className="schedule-control-section">
				<div className="col-lg-12 control-section">
					<div className="control-wrapper">
						<ScheduleComponent
							cssClass="virtual-scrolling"
							width="100%"
							// height="750px"
							selectedDate={new Date(2024, 1, 1)}
							group={{ resources: ['Resources'] }}
							eventSettings={{
								dataSource: generateStaticEvents(
									leavesData.myleavesList
								),
							}}
							resourceHeaderTemplate={resourceHeaderTemplate}
						>
							<ResourcesDirective>
								<ResourceDirective
									field="ResourceId"
									title="Resource"
									name="Resources"
									allowMultiple={true}
									dataSource={generateResourceData(userList)}
									textField="Text"
									idField="Id"
									colorField="Color"
								/>
							</ResourcesDirective>
							<ViewsDirective>
								<ViewDirective
									option="TimelineMonth"
									isSelected={true}
									eventTemplate={timelineEventTemplate}
									allowVirtualScrolling={true}
								/>
								<ViewDirective
									option="Month"
									eventTemplate={timelineEventTemplate}
									allowVirtualScrolling={true}
								/>
							</ViewsDirective>
							<Inject services={[TimelineMonth, Month, Resize]} />
						</ScheduleComponent>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserAwayComponent;
