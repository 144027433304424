import React from 'react';
const UserProfile = ({ imageUrl, defaultImageUrl, altName, className }) => {
	const handleError = (event) => {
		event.target.src = defaultImageUrl;
	};

	return (
		<>
			<img
				src={imageUrl || defaultImageUrl}
				alt={altName}
				title={altName}
				onError={handleError}
				className={className}
			/>
		</>
	);
};
export default UserProfile;
