import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReportAnalytics } from '../../store/thunk/companyThunk';
import ReactApexChart from 'react-apexcharts';
import { getFormatMonthRange } from '../../component/Common';
const moment = require('moment');

const EmployeeMap = () => {
	const dispatch = useDispatch();
	const report_analytics = useSelector(
		(state) => state.company.reportAnalytics
	);
	const [selectedDateRange, setSelectedDateRange] = useState('');

	// monthRange Dropdown called here :
	const dateFrom = moment();
	const dateTo = moment().add(1, 'month');
	const monthRanges = getFormatMonthRange(dateFrom, dateTo);

	let dates_lists = [];
	if (report_analytics?.dates_lists) {
		dates_lists = report_analytics?.dates_lists.map((date) =>
			moment(date).format('DD MMM')
		);
	}

	const currentDate = moment();
	const oneWeekLaterDate = moment().add(6, 'days');
	const initialDateRange = `${currentDate.format(
		'YYYY-MM-DD'
	)}/${oneWeekLaterDate.format('YYYY-MM-DD')}`;

	useEffect(() => {
		setSelectedDateRange(initialDateRange);
	}, [initialDateRange]);

	const handleSelectChange = (e) => {
		setSelectedDateRange(e.target.value);
	};

	useEffect(() => {
		if (selectedDateRange) {
			const [startDate, endDate] = selectedDateRange.split('/');
			dispatch(
				getReportAnalytics({ start_date: startDate, end_date: endDate })
			);
		}
	}, [dispatch, selectedDateRange]);

	const options = {
		chart: {
			id: 'line-chart',
			type: 'line',
			toolbar: {
				show: false,
			},
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: dates_lists,
			tick: false,
		},
		yaxis: {
			max: 50,
			min: 5,
		},
		stroke: {
			curve: 'straight',
		},
		colors: ['#FF00B8', '#FFC700', '#00FFA3', '#00C2FF'],
		legend: {
			show: true,
			position: 'top',
		},
		theme: {
			mode: 'light',
		},
	};
	const series = [
		{
			name: 'New Hires',
			data: report_analytics?.user_counts,
		},
		{
			name: 'Leave',
			data: report_analytics?.leave_counts,
		},
		{
			name: 'Terminations',
			data: [20, 25, 35, 10, 29, 25, 50],
		},
	];
	const customCSS = `
		.apexcharts-legend-series {
			text-align: left !important;
		}
		.apexcharts-legend-text {
			font-size: 14px !important;
			line-height: 30px;
			color: #fff !important;
		}
		.apexcharts-pie-series path {
			stroke:transparent !important;
		}
		.apexcharts-inner .apexcharts-xaxis-tick {
			display: none;
		}
		.apexcharts-legend {
			justify-content: flex-start !important;
			padding:0px !important;
		}`;
	return (
		<div>
			<div className="flex items-center justify-between mb-[20px] h-[40px]">
				<h1 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7">
					Reporting & Analytics
				</h1>
			</div>
			<div className="relative md:p-[21.84px] p-[10px] md:mb-0 mb-[20px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] rounded-[15px]">
				<select
					className="dropdown md:absolute unset md:w-[140px] w-full z-[99] border text-[13px] dark:text-white text-[#47586f] px-2 py-1 rounded-lg border-solid border-[#384B72] right-[18px] top-[8%] bg-[transparent]"
					onChange={handleSelectChange}
					value={selectedDateRange}
				>
					{monthRanges?.map((row, index) => (
						<option
							key={index}
							value={row?.id}
							className="bg-[#17243e]"
						>
							{row?.name}
						</option>
					))}
				</select>
				<ReactApexChart
					options={options}
					series={series}
					type="line"
					height={300}
				/>
				<style>{customCSS}</style>
			</div>
		</div>
	);
};

export default EmployeeMap;
