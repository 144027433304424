import React, { useEffect, useState } from 'react';
import {
	useTable,
	usePagination,
	useSortBy,
	useResizeColumns,
} from 'react-table';
import Pagination from './Pagination';
import GlobalSearch from './GlobalSearch';
import ExportButton from '../ExportButton';

function ReactTablePagination({
	columns,
	data,
	fetchData,
	loading,
	pageCount: controlledPageCount,
	totalRecords,
	recordsPerPage,
	setRecordsPerPage,
	showContentClass = true,
	showCSVButton = true,
	csvFileName = '',
}) {
	const [globalSearchText, setGlobalSearchText] = useState('');
	const [sortBy, setSortBy] = useState([]);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: recordsPerPage,
				sortBy,
			}, // Pass our hoisted table state
			manualPagination: true, // Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: controlledPageCount,
		},
		useSortBy,
		usePagination,
		useResizeColumns
	);

	// Listen for changes in pagination and use the state to fetch our new data
	useEffect(() => {
		fetchData(pageSize, pageIndex + 1, globalSearchText, sortBy);
	}, [fetchData, pageIndex, pageSize, globalSearchText, sortBy]);

	return (
		<>
			<div
				id="recipients"
				className={`${showContentClass
					? 'mt-2 lg:mt-0 p-6 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]'
					: ''
					}`}
			>
				<div className="md:flex block items-center justify-between filter-block mb-[20px]">
					<GlobalSearch
						setGlobalSearchText={setGlobalSearchText}
						globalSearchText={globalSearchText}
					/>

					{showCSVButton && (
						<ExportButton
							data={data}
							columns={columns}
							filename={csvFileName}
						/>
					)}
				</div>
				<div className="mb-[20px] ">
					<div className="overflow-x-auto top-scroll">
						<table
							id="example"
							className="stripe hover text-white lg:w-full md:w-max w-max"
							// style={{
							// 	width: "100%",
							// 	paddingTop: "1em",
							// 	paddingBottom: "1em",
							// }}
							{...getTableProps()}
						>
							<thead className="dark:bg-[#202D47] bg-[#f2f2f2] h-[51.49px] rounded-[8px]  block">
								{headerGroups.map((headerGroup) => (
									<tr
										className=" w-full flex items-center  h-full px-2.5 py-2.5 rounded-lg justify-between"
										{...headerGroup.getHeaderGroupProps()}
									>
										{headerGroup.headers.map((column) => (
											<th
												key={column.id}
												className="text-left text-[12px] md:text-[12px] md:text-[14px] text-[#101B32] dark:text-[#fff] table-header-cell"
												{...column.getHeaderProps(
													column.getSortByToggleProps(),
													column.getResizerProps()
												)}
												onClick={() => {
													setSortBy([
														{
															id: column.id,
															desc: !column.isSortedDesc,
														},
													]);
												}}
												style={{ width: column.width }}
											>
												{column.render('Header')}
												<span className="mr-1">
													{column.isSorted ? (
														column.isSortedDesc ? (
															<i className="fas fa-arrow-down text-[13px] ml-[5px]"></i>
														) : (
															<i className="fas fa-arrow-up text-[13px] ml-[5px]"></i>
														)
													) : (
														''
													)}
												</span>
												<div
													{...column.getResizerProps()}
													className="resizer"
												/>
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody
								className="rounded-[8px] mb-[17px]  block bottom-scroll "
								{...getTableBodyProps()}
							>
								{page.length === 0 ? (
									<tr>
										<td
											colSpan={columns.length}
											className="pt-[20px] text-center text-gray-600  dark:text-[#fff] mt-[20px]"
										>
											No records found.
										</td>
									</tr>
								) : (
									page.map((row, i) => {
										prepareRow(row);
										return (
											<tr
												key={`tbr_${i}`}
												className="border-b border-solid border-[#ddd] dark:border-[#2e4062]  w-full flex items-center px-2.5 py-2.5 justify-between"
												{...row.getRowProps()}
											>
												{row.cells.map(
													(cell, cellIndex) => {
														const { column } = cell;
														return (
															<td
																style={{
																	textAlign:
																		'left',
																	width: column.width,
																}}
																key={`tb_td_${cellIndex}`}
																className="text-[12px] md:text-[12px] md:text-[14px] dark:text-white text-black"
																{...cell.getCellProps()}
															>
																{/* {cellIndex === 0 ? (
															<span>
																{`${(i + 1).toString().padStart(2, "0")}. `} {cell.render("Cell")}
															</span>
														) : (
															cell.render("Cell")
														)} */}
																{cell.render(
																	'Cell'
																)}
															</td>
														);
													}
												)}
											</tr>
										);
									})
								)}
							</tbody>
						</table>
					</div>
				</div>
				<Pagination
					totalRecords={totalRecords}
					canPreviousPage={canPreviousPage}
					gotoPage={gotoPage}
					previousPage={previousPage}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
					canNextPage={canNextPage}
					nextPage={nextPage}
					pageCount={pageCount}
					pageSize={pageSize}
					setPageSize={setPageSize}
					setRecordsPerPage={setRecordsPerPage}
				/>
			</div>
		</>
	);
}

export default ReactTablePagination;
