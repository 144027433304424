import React, { useEffect, useState } from 'react';
import InputComponent from '../../component/ui-component/InputComponent';
import Button from '../../component/ui-component/Button';
import SelectComponent from '../../component/ui-component/SelectComponent';
import DatePickerComponent from '../../component/ui-component/DatePickerComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentList, getRolesList } from '../../store/thunk/commonThunk';
import { resendVerifyEmail } from '../../store/thunk/commonThunk';
import { vsmUser } from '../../utils/validations';
import MultiSelectComponent from '../../component/ui-component/MultiSelectComponent';
import { getSkillsList } from '../../store/thunk/skillsThunk';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	setValue,
	selectedDepartment,
	selectedRoles,
	selectedGender,
	editUserData,
	selectedJobType,
	userPermissions = [],
	selectedUserSkills,
}) => {
	const genderList = [
		{
			id: 'male',
			name: 'Male',
		},
		{
			id: 'female',
			name: 'Female',
		},
	];
	const JobTypeList = [
		{
			id: '1',
			name: 'Full Time',
		},
		{
			id: '2',
			name: 'Part Time',
		},
	];
	const dispatch = useDispatch();
	const departmentList = useSelector((state) => state.common.departmentList);
	const roleList = useSelector((state) => state.common.roleList);
	const skillList = useSelector((state) => state.skills.skillsList);

	const [isMultiSelectValid, setIsMultiSelectValid] = useState(
		selectedUserSkills && selectedUserSkills.length > 0 ? true : false
	);
	const [error, setError] = useState(false);

	useEffect(() => {
		dispatch(getDepartmentList());
		// dispatch(getRolesList());
	}, [dispatch]);

	useEffect(() => {
		if (selectedDepartment && isUpdate) {
			dispatch(getRolesList({ department: selectedDepartment }));
		}
		if (selectedRoles && isUpdate) {
			dispatch(getSkillsList({ roles: selectedRoles }));
		}
	}, [dispatch, selectedDepartment, isUpdate, selectedRoles]);

	const onChangeSelectValue = (key, selectedValue) => {
		setValue(key, selectedValue);
		if (key === 'department') {
			dispatch(getRolesList({ department: selectedValue }));
		}
		if (key === 'role') {
			dispatch(getSkillsList({ roles: selectedValue }));
		}
	};

	const handleresendEmail = () => {
		dispatch(resendVerifyEmail(editUserData));
	};

	const [selectedPermissions, setSelectedPermissions] = useState(
		userPermissions || []
	);
	const handlePermissionChange = (event) => {
		const isChecked = event.target.checked;
		const model_permissions = event.target.id;
		setSelectedPermissions((prevPermissions) => {
			if (isChecked) {
				return [...prevPermissions, model_permissions];
			} else {
				return prevPermissions.filter((p) => p !== model_permissions);
			}
		});
	};

	const onChangeMultiSelectValue = (key, selectedValue) => {
		let skill_ids = [];
		selectedValue.map((item) => skill_ids.push(parseInt(item.value)));
		setValue(key, skill_ids);
		setIsMultiSelectValid(selectedValue.length > 0);
	};

	useEffect(() => {
		setValue('permissions', selectedPermissions);
	}, [selectedPermissions, setValue]);

	const moduleLists = [
		{ key: 'holidays', value: 'Holidays' },
		{ key: 'userleaves', value: 'Leaves' },
		{ key: 'client', value: 'Clients' },
		{ key: 'projects', value: 'Projects' },
		{ key: 'tasks', value: 'Tasks' },
		{ key: 'interview', value: 'Recruitment' },
		// { key: 'xpearningmaster', value: 'XP Earning Table' }
	];

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (!isMultiSelectValid) {
			setError(true);
			return;
		} else {
			setError(false);
		}
		// Proceed with form submission
		handleSubmit(onSubmit)(e);
	};

	return (
		<form onSubmit={handleFormSubmit} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
							User Information
						</h3>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
						<InputComponent
							id="first_name"
							title="First Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<InputComponent
							id="middle_name"
							title="Middle Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<InputComponent
							id="last_name"
							title="Last Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<InputComponent
							id="address"
							title="Address"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<InputComponent
							id="city"
							title="City"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<InputComponent
							id="postal_code"
							title="Postal Code"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<InputComponent
							id="email"
							title="Email"
							errors={errors}
							register={register}
							// readOnly={isUpdate ? true : false}
							fieldvalidation={vsmUser}
						/>

						<InputComponent
							id="phone_number"
							title="Phone No"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<SelectComponent
							selectOptionLists={genderList}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedGender}
							title="Select Gender"
							id="gender"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<DatePickerComponent
							id="joining_date"
							title="Joining Date"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<SelectComponent
							selectOptionLists={JobTypeList}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedJobType}
							title="Select Job Type"
							id="job_type"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<InputComponent
							id="designation"
							title="Designation"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<SelectComponent
							selectOptionLists={departmentList}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedDepartment}
							title="Select Department"
							id="department"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<SelectComponent
							selectOptionLists={roleList}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedRoles}
							title="Select Role"
							id="role"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
					</div>
					<div className="grid grid-cols-1 gap-2">
						<MultiSelectComponent
							selectOptionLists={skillList}
							onChangeSelectValue={onChangeMultiSelectValue}
							selectedValue={selectedUserSkills}
							title="Select Skills"
							id="skills"
							error={error}
						/>
					</div>
					{/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mt-6">
						<InputComponent
							id="paid_leave"
							title="Paid Leave"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<InputComponent
							id="casual_leave"
							title="Casual Leave"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<InputComponent
							id="sick_leave"
							title="Sick Leave"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
						<InputComponent
							id="other_leave"
							title="Other Leave"
							errors={errors}
							register={register}
							fieldvalidation={vsmUser}
						/>
					</div> */}
					<div className="flex flex-col">
						<div className="overflow-x-auto sm:-mx-6 lg:-mx-8 mt-4">
							<div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
								<div className="overflow-x-auto top-scroll">
									<table className="stripe hover text-white lg:w-full md:w-max w-max">
										<thead className="dark:bg-[#202D47] bg-[#f2f2f2] h-[51.49px] rounded-[8px]  block">
											<tr className="w-full flex items-center  h-full px-2.5 py-2.5 rounded-lg justify-between">
												<th
													className="text-left text-[14px] text-[#101B32] dark:text-[#fff] table-header-cell w-1/5"
													key="module_name"
												>
													Module Permission
												</th>
												<th
													className="text-left text-[14px] text-[#101B32] dark:text-[#fff] table-header-cell w-1/6"
													key="view"
												>
													Read
												</th>
												<th
													className="text-left text-[14px] text-[#101B32] dark:text-[#fff] table-header-cell w-1/6"
													key="add"
												>
													Create
												</th>
												<th
													className="text-left text-[14px] text-[#101B32] dark:text-[#fff] table-header-cell w-1/6"
													key="change"
												>
													Update
												</th>
												<th
													className="text-left text-[14px] text-[#101B32] dark:text-[#fff] table-header-cell w-1/6"
													key="delete"
												>
													Delete
												</th>
												<th
													className="text-left text-[14px] text-[#101B32] dark:text-[#fff] table-header-cell w-1/6"
													key="export"
												>
													Export CSV
												</th>
											</tr>
										</thead>
										<tbody>
											{moduleLists.map((module, i) => (
												<tr
													key={i}
													className="border-b border-solid border-[#ddd] dark:border-[#2e4062] flex items-center px-2.5 py-2.5 justify-between"
												>
													<td
														key={module}
														className="text-left text-[15px] text-[#101B32] dark:text-[#fff] w-1/5"
													>
														{module.value}
													</td>
													{[
														'view',
														'add',
														'change',
														'delete',
														'export',
													].map(
														(
															permission,
															columnIndex
														) => {
															return (
																<React.Fragment
																	key={
																		columnIndex
																	}
																>
																	<td
																		key={`${permission}_${module.key}`}
																		className="text-left text-[15px] text-[#101B32] dark:text-[#fff] w-1/6"
																	>
																		<div className="inline-flex items-center">
																			<label
																				className="relative flex items-center p-3 rounded-full cursor-pointer"
																				htmlFor="blue"
																			>
																				<input
																					type="checkbox"
																					className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none border dark:border-slate-400 border-[#4b5563] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
																					id={`${permission}_${module.key}`}
																					defaultChecked={userPermissions.includes(
																						`${permission}_${module.key}`
																					)}
																					onChange={(
																						e
																					) =>
																						handlePermissionChange(
																							e
																						)
																					}
																				/>
																				<span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						className="h-3.5 w-3.5"
																						viewBox="0 0 20 20"
																						fill="currentColor"
																						stroke="currentColor"
																						strokeWidth="1"
																					>
																						<path
																							fillRule="evenodd"
																							d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
																							clipRule="evenodd"
																						></path>
																					</svg>
																				</span>
																			</label>
																		</div>
																	</td>
																</React.Fragment>
															);
														}
													)}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="pt-[20px] p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<div className="float-left">
					<Button
						buttonText={`${isUpdate ? 'Update' : 'Create'} User`}
						linkTo="#"
						customValue="175px"
						inputType="submit"
						loading={loading}
					/>
				</div>

				{editUserData?.is_verified ||
				editUserData?.is_verified === undefined ? (
					''
				) : (
					<div className="float-right">
						<a
							href="#!"
							className="px-14 expert-btn text-sm leading-[18px] font-semibold text-white rounded-lg p-[10px] float-right"
							onClick={(e) => {
								e.preventDefault(); // Prevent the default link behavior
								handleresendEmail(); // Call your function
							}}
						>
							{loading && (
								<svg
									aria-hidden="true"
									role="status"
									className="inline w-4 h-4 mr-3 text-white animate-spin"
									viewBox="0 0 100 101"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
										fill="#E5E7EB"
									/>
									<path
										d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
										fill="currentColor"
									/>
								</svg>
							)}
							Resend Verify Email
						</a>
					</div>
				)}
			</div>
		</form>
	);
};
export default FormComponent;
