import React, { useState, useEffect } from 'react';
import Workflow from './Workflow';
import AddTaskComponent from './AddTaskComponent';
import EditTaskComponent from './EditTaskComponent';
import Dialogpopup from '../../component/ui-component/Dailogpopup';
import BreadcrumbsComponent from '../../component/ui-component/BreadcrumbsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { removeTask } from '../../store/thunk/tasksThunk';
import { getUserPermissions } from '../../store/thunk/userThunk';
import { hasPermission } from '../../component/Common';

export default function TaskManagement() {
	const dispatch = useDispatch();
	const [addDrawerVisible, setAddDrawerVisible] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);
	const authUser = useSelector((state) => state.auth);
	const user_permissions = useSelector((state) => state.user.userPermissions);

	const handleSetEditValues = (item) => {
		setEditValues(item);
		setEditDrawerVisible(true);
	};

	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
	};

	useEffect(() => {
		dispatch(getUserPermissions());
	}, [dispatch]);

	return (
		<>
			<div className="p-[20px]">
				<div className="flex items-center justify-between">
					<BreadcrumbsComponent
						showBreadCrumbs={true}
						pageTitle={'Tasks'}
					/>
					{hasPermission(
						authUser?.userDetails,
						user_permissions,
						'add_tasks'
					) && (
						<button
							className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
							onClick={() => setAddDrawerVisible(true)}
						>
							+ Create Tasks
						</button>
					)}
				</div>
				<Workflow
					handleSetEditValues={handleSetEditValues}
					handleDeleteClick={handleDeleteClick}
					userData={authUser?.userDetails}
					userPermissions={user_permissions}
				/>
			</div>
			{addDrawerVisible && (
				<AddTaskComponent
					visible={addDrawerVisible}
					onClose={() => setAddDrawerVisible(false)}
				/>
			)}

			{editDrawerVisible && editValues && (
				<EditTaskComponent
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
				/>
			)}

			{deleteDailogVisible && (
				<>
					<Dialogpopup
						onCancel={() => setDeleteDailogVisible(false)}
						deleteItem={editValues}
						removeThunkCall={removeTask}
						message="Are you sure you want to delete this task ?"
					/>
				</>
			)}
			{/* <CreateTasks /> */}
		</>
	);
}
