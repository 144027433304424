import React from 'react';

const Unauthorized = (props) => {
	return (
		<div className="flex items-center justify-center w-screen h-screen bg-indigo-600">
			<p className="text-5xl text-white md:text-7xl lg:text-9xl">
				Unauthorized 403
			</p>
		</div>
	);
};

export default Unauthorized;
