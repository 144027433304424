import React from 'react';
import { formatDate, getDefaultProfile } from '../../../src/component/Common';
import UserProfile from '../../component/ui-component/UserProfile';
import { readFileWithExtension } from '../../../src/component/Common';

const ViewEventComponent = ({
	visible,
	onClose,
	viewValues,
	handleSetEditValues,
	handleDeleteClick,
}) => {
	return (
		<>
			<div
				className={` togglesidebar bg-[#ffffff57] fixed h-full w-full top-0 left-0 p-6 z-[99999]  ${
					visible ? 'active' : ''
				}`}
			>
				<div
					onClick={onClose}
					className="w-full bg-[#ffffff57] overlay bg-[#0f1a32] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
				/>
				<div className="dark:bg-[#12243d] bg-[#fafafa] border-gray-300 border-l w-[85%] lg:w-[50%] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]">
					<button
						className="close h-8 w-8 rounded-full absolute top-5 right-5 bg-[#fff]"
						onClick={onClose}
					>
						X
					</button>
					<h1 className="p-6 dark:text-white text-[#4b5563] font-bold text-[20px] text-left pb-[20px] border-solid border-b dark:border-[#333333] border-gray-300">
						Event Information
					</h1>
					<div className="rounded-lg">
						<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
							<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
								<div className="info-address pb-[15px] flex">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Event Title
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{viewValues?.title || 'N/A'}
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Event Date
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{formatDate(
												viewValues?.start,
												'DD MMM YYYY'
											) || 'N/A'}
										</h4>
									</div>
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											From Time
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{viewValues?.from_time || 'N/A'}
										</h4>
									</div>
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											To Time
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{viewValues?.to_time || 'N/A'}
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Assigned User's
										</p>
										{viewValues?.users &&
											viewValues?.users.length > 0 &&
											viewValues?.users.map((user) => (
												<div
													key={user?.id}
													className="flex float-left mr-4 border-solid border-[#ddd] dark:border-[#2e4062] border p-1.5 px-2 rounded-lg shadow-xl hover:shadow-gray-400 dark:shadow-md dark:hover:shadow-[#0c4a6e] hover:cursor-pointer"
												>
													<UserProfile
														imageUrl={
															user?.profile_image
														}
														defaultImageUrl={getDefaultProfile(
															user?.gender
														)}
														altName={user?.fullname}
														className={
															'mr-[13px] h-[35px] w-[35px] rounded-full'
														}
													/>
													<span className="mt-[6.5px] text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
														{user?.fullname}
													</span>
												</div>
											))}
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Event Descriptions
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{viewValues?.description || 'N/A'}
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Attached Documents
										</p>
										<div className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{viewValues?.files
												? readFileWithExtension(
														viewValues?.files,
														'meeting_files/'
												  )
												: 'N/A'}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
						<button
							className="w-[100px] expert-btn text-sm leading-[18px] font-semibold text-white rounded-full p-[10px] shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)] mx-[10px]"
							onClick={() => handleSetEditValues(viewValues)}
						>
							Edit
						</button>
						<button
							className="w-[100px] expert-btn-delete text-sm leading-[18px] font-semibold text-white rounded-full p-[10px] shadow-[0_4px_9px_-4px_rgba(203,213,225,1)] hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)] mx-[10px] "
							onClick={() => handleDeleteClick(viewValues)}
						>
							Delete
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewEventComponent;
