import React, { useCallback } from 'react';
// import { getBase64 } from './utils';

const FileComponent = ({
	id,
	inputType,
	title,
	placeholder,
	errors,
	register,
	readOnly,
	fieldvalidation,
	onChange,
	existingProfileImage,
}) => {
	// const [image, setImage] = useState(existingProfileImage);
	const handleInputChange = useCallback(
		async (event) => {
			// const base64 = await getBase64(event.target.files[0]);
			// setImage(base64);
			onChange(event);
		},
		[onChange]
	);
	return (
		<div className="input-component mt-[15px] mr-[12px]">
			<div className="relative h-12 w-full min-w-[200px]">
				<label
					htmlFor="formFileMultiple"
					className="text-left dark:text-white text-[#4b5563] left-0 flex w-full mb-[10px] text-[15px] text-base"
				>
					{title}
				</label>
				<input
					id={id}
					className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary text-base"
					type="file"
					readOnly={readOnly}
					{...register(id, fieldvalidation.validation[id])}
					onChange={handleInputChange}
					autoComplete="off"
					multiple
				/>
			</div>
			{/* {inputType === "file" && image && (
        <div className="mt-[15px]">
          <img src={image} width="150px" alt="" className="rounded-full" />
        </div>
      )} */}
		</div>
	);
};
export default FileComponent;
