import React, { useEffect, useState } from 'react';
import InputComponent from '../../../component/ui-component/InputComponent';
import Button from '../../../component/ui-component/Button';
import DatePickerComponent from '../../../component/ui-component/DatePickerComponent';
import { useDispatch, useSelector } from 'react-redux';
import { vsmMeeting } from '../../../utils/validations';
import TextareaComponent from '../../../component/ui-component/TextareaComponent';
import { getUserList } from '../../../store/thunk/userThunk';
import TimePickerComponent from '../../../component/ui-component/TimePickerComponent';
import MultiSelectComponent from '../../../component/ui-component/MultiSelectComponent';
import { readFileWithExtension } from '../../../component/Common';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	setValue,
	setUploadedFile,
	selectedAssignedUsers,
	fileURL,
}) => {
	const dispatch = useDispatch();
	const userList = useSelector((state) => state.user.userList);
	const [isMultiSelectValid, setIsMultiSelectValid] = useState(
		selectedAssignedUsers && selectedAssignedUsers.length > 0 ? true : false
	);
	const [error, setError] = useState(false);

	useEffect(() => {
		dispatch(getUserList());
	}, [dispatch]);

	const handleFileChange = (file) => {
		const selectedFile = file.target.files[0];
		setUploadedFile(selectedFile);
	};

	const onChangeMultiSelectValue = (key, selectedValue) => {
		let users_ids = [];
		selectedValue.map((item) => users_ids.push(parseInt(item.value)));
		setValue(key, users_ids);
		setIsMultiSelectValid(selectedValue.length > 0);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (!isMultiSelectValid) {
			setError(true);
			return;
		} else {
			setError(false);
		}
		// Proceed with form submission
		handleSubmit(onSubmit)(e);
	};

	return (
		<form onSubmit={handleFormSubmit} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
							Meeting Information
						</h3>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
						<InputComponent
							id="title"
							title="Meeting Title"
							errors={errors}
							register={register}
							fieldvalidation={vsmMeeting}
						/>
						<DatePickerComponent
							id="meeting_date"
							title="Meeting Date"
							errors={errors}
							register={register}
							fieldvalidation={vsmMeeting}
						/>
						<TimePickerComponent
							id="from_time"
							title="From Time"
							errors={errors}
							register={register}
							fieldvalidation={vsmMeeting}
						/>
						<TimePickerComponent
							id="to_time"
							title="To Time"
							errors={errors}
							register={register}
							fieldvalidation={vsmMeeting}
						/>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-1 gap-2 mt-[18px]">
						<MultiSelectComponent
							selectOptionLists={userList}
							onChangeSelectValue={onChangeMultiSelectValue}
							selectedValue={selectedAssignedUsers}
							title="Assigned To"
							id="users"
							error={error}
						/>
						<TextareaComponent
							id="description"
							title="Meeting Description"
							errors={errors}
							register={register}
							fieldvalidation={vsmMeeting}
							customClass="mt-[30px]"
						/>
						<InputComponent
							id="file"
							inputType={'file'}
							title="Files"
							errors={errors}
							register={register}
							fieldvalidation={vsmMeeting}
							onChange={handleFileChange}
						/>
					</div>
					{isUpdate && fileURL != null && (
						<div className="grid gap-5 mt-[18px]">
							{fileURL
								? readFileWithExtension(
										fileURL,
										'meeting_files/'
								  )
								: 'N/A'}
						</div>
					)}
				</div>
			</div>
			<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<Button
					buttonText={`${isUpdate ? 'Update' : 'Create'} Meeting`}
					linkTo="#"
					customValue="190px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default FormComponent;
