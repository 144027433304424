import React, { useEffect, useState } from 'react';
import InputComponent from '../../component/ui-component/InputComponent';
import Button from '../../component/ui-component/Button';
import SelectComponent from '../../component/ui-component/SelectComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryList } from '../../store/thunk/commonThunk';
import { vsmUser } from '../../utils/validations';
import DatePickerComponent from '../../component/ui-component/DatePickerComponent';
import { getDepartmentList, getRolesList } from '../../store/thunk/commonThunk';
import CKEditorComponent from '../../component/ui-component/CKEditorComponent';
import MultiSelectComponent from '../../component/ui-component/MultiSelectComponent';
import { getSkillsList } from '../../store/thunk/skillsThunk';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	setValue,
	about_me,
	setUploadedFile,
	selectedDepartment,
	selectedRoles,
	existingProfileImage,
	selectedCountry,
	showPortion,
	selectedGender,
	selectedMaritalStatus,
	selectedUserSkills,
}) => {
	const genderList = [
		{
			id: 'male',
			name: 'Male',
		},
		{
			id: 'female',
			name: 'Female',
		},
	];
	const maritalStatus = [
		{
			id: 'single',
			name: 'Single',
		},
		{
			id: 'married',
			name: 'Married',
		},
	];
	const dispatch = useDispatch();
	const departmentList = useSelector((state) => state.common.departmentList);
	const roleList = useSelector((state) => state.common.roleList);
	const skillList = useSelector((state) => state.skills.skillsList);
	const countryList = useSelector((state) => state.common.countryList);
	const [isMultiSelectValid, setIsMultiSelectValid] = useState(
		selectedUserSkills && selectedUserSkills.length > 0 ? true : false
	);
	const [error, setError] = useState(false);

	useEffect(() => {
		dispatch(getCountryList());
		dispatch(getDepartmentList());
	}, [dispatch]);

	useEffect(() => {
		if (selectedDepartment && isUpdate) {
			dispatch(getRolesList({ department: selectedDepartment }));
		}
		if (selectedRoles && isUpdate) {
			dispatch(getSkillsList({ roles: selectedRoles }));
		}
	}, [dispatch, selectedDepartment, selectedRoles, isUpdate]);

	const handleFileChange = (file) => {
		const selectedFile = file.target.files[0];
		setUploadedFile(selectedFile);
	};

	const onChangeSelectValue = (key, selectedValue) => {
		setValue(key, selectedValue);
		if (key === 'department') {
			dispatch(getRolesList({ department: selectedValue }));
		}
		if (key === 'role') {
			dispatch(getSkillsList({ roles: selectedValue }));
		}
	};

	const onChangeMultiSelectValue = (key, selectedValue) => {
		let skill_ids = [];
		selectedValue.map((item) => skill_ids.push(parseInt(item.value)));
		setValue(key, skill_ids);
		setIsMultiSelectValid(selectedValue.length > 0);
	};

	const handleEditorChange = (id, content) => {
		setValue(id, content);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (!isMultiSelectValid) {
			setError(true);
			return;
		} else {
			setError(false);
		}
		// Proceed with form submission
		handleSubmit(onSubmit)(e);
	};

	return (
		<form onSubmit={handleFormSubmit} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded pb-8">
					{showPortion === 1 && (
						<div className="hover:cursor-pointer dark:bg-[#12243d] bg-[#fafafa] rounded py-6 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
							<div>
								<h3 className="text-left dark:text-white text-[#4b5563] font-bold mb-4">
									Profile Information
								</h3>
							</div>
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
								<InputComponent
									id="first_name"
									title="First Name"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="middle_name"
									title="Middle Name"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="last_name"
									title="Last Name"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<SelectComponent
									selectOptionLists={genderList}
									onChangeSelectValue={onChangeSelectValue}
									selectedValue={selectedGender}
									title="Select Gender"
									id="gender"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="email"
									title="Email Address"
									errors={errors}
									register={register}
									readOnly={isUpdate ? true : false}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="phone_number"
									title="Phone Number"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<SelectComponent
									selectOptionLists={departmentList}
									onChangeSelectValue={onChangeSelectValue}
									selectedValue={selectedDepartment}
									title="Select Department"
									id="department"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<SelectComponent
									selectOptionLists={roleList}
									onChangeSelectValue={onChangeSelectValue}
									selectedValue={selectedRoles}
									title="Select Role"
									id="role"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
							</div>
							<div className="grid grid-cols-1 gap-2 mb-3">
								<MultiSelectComponent
									selectOptionLists={skillList}
									onChangeSelectValue={
										onChangeMultiSelectValue
									}
									selectedValue={selectedUserSkills}
									title="Select Skills"
									id="skills"
									error={error}
								/>
							</div>
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mt-8">
								<InputComponent
									id="profile_image"
									inputType={'file'}
									title="Profile Pic"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
									onChange={handleFileChange}
									existingProfileImage={existingProfileImage}
								/>
								<SelectComponent
									selectOptionLists={countryList}
									onChangeSelectValue={onChangeSelectValue}
									selectedValue={selectedCountry}
									title="Select Country"
									id="country"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
							</div>
						</div>
					)}

					{showPortion === 2 && (
						<div className="hover:cursor-pointer dark:bg-[#12243d] bg-[#fafafa] rounded py-4 p-4 mt-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
							<div>
								<h3 className="text-left dark:text-white text-[#4b5563] font-bold mb-4">
									Basic Informations
								</h3>
							</div>
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
								<InputComponent
									id="address"
									title="Address"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="city"
									title="City"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="postal_code"
									title="Postal Code"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<DatePickerComponent
									id="dob"
									title="Date of Birth"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<DatePickerComponent
									id="joining_date"
									title="Joining Date"
									errors={errors}
									register={register}
									readOnly={isUpdate ? true : false}
									fieldvalidation={vsmUser}
								/>
								<SelectComponent
									selectOptionLists={maritalStatus}
									onChangeSelectValue={onChangeSelectValue}
									selectedValue={selectedMaritalStatus}
									title="Select Marital Status"
									id="marital_status"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="facebook_link"
									title="Facebook Link"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="instagram_link"
									title="Instagram Link"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="linkedin_link"
									title="Linkedin Link"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="twitter_link"
									title="Twitter Link"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="passport_number"
									title="Passport Number"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<DatePickerComponent
									id="passport_expiry_date"
									title="Passport Expiry Date"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>

								{/* <SelectComponent
									selectOptionLists={countryList}
									onChangeSelectValue={onChangeSelectValue}
									selectedValue={selectedCountry}
									title="Select Country"
									id="country"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/> */}
							</div>
							<div className="grid grid-cols-1 lg:grid-cols-1 gap-2 textareaComponent">
								<CKEditorComponent
									id="about"
									title="About Me"
									data={about_me}
									onChange={handleEditorChange}
								/>
							</div>
						</div>
					)}

					{showPortion === 3 && (
						<div className="hover:cursor-pointer dark:bg-[#12243d] bg-[#fafafa] rounded py-4 p-4 mt-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
							<div>
								<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
									Bank Informations
								</h3>
							</div>
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-2 py-4">
								<InputComponent
									id="bank_name"
									title="Bank Name"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="bank_account_number"
									title="Bank Account Number"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="bank_ifsc_number"
									title="Bank IFSC Code"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
								<InputComponent
									id="bank_pan_number"
									title="Bank Pan Number"
									errors={errors}
									register={register}
									fieldvalidation={vsmUser}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<Button
					buttonText={`${isUpdate ? 'Update' : 'Create'} Profile`}
					linkTo="#"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default FormComponent;
