import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	customerList: [],
};

export const customerSlice = createSlice({
	name: 'customers',
	initialState,
	reducers: {
		setCustomers: (state, action) => ({
			...state,
			customerList: action.payload,
		}),
	},
});

export const { setCustomers } = customerSlice.actions;
export default customerSlice.reducer;
