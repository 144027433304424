import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import KanhasoftLogo from '../assets/images/kanhasoft-logo.svg';
import GAMECANDarkLogo from '../assets/images/GAMECANdarkLogo.png';
import GAMECANLogo from '../assets/images/GAMECAN-logo.png';
import TabComponent from './TabComponent';
import { commonMenu } from './header/menuList';
import { setUserData } from '../store/slices/authSlice';
import { useTimer } from '../pages/Attendance/TimerContext';

const SidebarComponent = (props) => {
	const [isActive, setIsActive] = useState(false);
	const authUser = useSelector((state) => state.auth);
	const { isWidth, HandleCLick } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isRunning, handleStartPauseClick } = useTimer();

	const handleLogout = () => {
		if (isRunning) {
			handleStartPauseClick({ is_from_logout: true });
		}
		localStorage.removeItem('user_data');
		localStorage.removeItem('auth_tokens');
		localStorage.removeItem('profile_image');
		dispatch(setUserData(null));
		navigate('/login');
	};

	const shouldRenderMenuItem = (row) => {
		if (row.company && authUser?.userDetails?.is_companyadmin) {
			return true;
		} else if (row.user && !authUser?.userDetails?.is_companyadmin) {
			return true;
		}
		return false;
	};

	return (
		<>
			<div
				id="desktop-sidebar-component"
				className={`top-sidebar fixed top-[30px] left-[20px] ${
					isWidth ? 'active' : ''
				}`}
			>
				<span
					className="NavToggleSidebar h-6 w-6 bg-[#59657D] absolute rounded-full top-4 -right-2.5 cursor-pointer z-[999999]"
					onClick={HandleCLick}
				>
					<i className="fa fa-angle-left text-white"></i>
				</span>
				<div className="md:h-[92vh] h-[97vh] overflow-x-hidden overflow-y-auto sidebar-wrapper md:rounded-2xl lg:rounded-2xl rounded-none">
					<div className="text-gray-100 text-xl">
						<div className="flex items-center h-[60px] justify-center">
							{/* <div className="rounded pt-1.5">
								<img
									src={KanhasoftLogo}
									alt="KanhasoftLogo"
									className="px-2.5 py-1 z-10 rounded-lg absolute top-4 left-8 mr-8 bg-[#0c9cff] dark:bg-transparent"
								/>
							</div> */}
								<img src={GAMECANLogo} alt="GAMECANLogo" />
							
						</div>
					</div>
					<div className="sidebar top-0 bottom-0 lg:left-0 p-2 w-full overflow-y-auto text-center">
						{/* <div className="absolute top-0 left-0 w-[215.84px] h-[100%] z-[-1] sidebar-inner">
              <img src={SidebarImage} alt="SidebarImage" className="w-[100%] h-[100%]" />
            </div> */}
						<TabComponent />
						<div className="mt-10 mb-10">
							<p className="text-xs font-normal leading-4 py-[10px] px-[16px] text-left dark:text-[#fff] text-[#293650]">
								Support
							</p>
							{commonMenu.slice(0, 3).map((row, i) => {
								if (shouldRenderMenuItem(row)) {
									return (
										<NavLink
											key={row.url}
											to={row.url}
											className={isActive ? 'active' : ''}
										>
											<div
												key={i}
												className="ml-3 p-2.5 sidebar-list-item  flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white"
											>
												<img
													src={row.image}
													alt="icons"
												/>
												<span className="text-[15px] ml-3 text-gray-200 font-bold">
													{row.label}
												</span>
											</div>
										</NavLink>
									);
								}
								return null;
							})}
						</div>
						<div onClick={handleLogout}>
							{commonMenu.slice(3).map((row, i) => (
								<div
									key={row.url}
									className="ml-3 p-2.5 sidebar-list-item flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white"
								>
									<img src={row.image} alt="icons" />
									<span className="text-[15px] ml-3 text-gray-200 font-bold">
										{row.label}
									</span>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default SidebarComponent;
