import React from 'react';
import { CSVLink } from 'react-csv';

const ExportButton = ({ data, columns, filename }) => {
	const getColumnLabels = () => {
		return columns
			.filter((column) => column.accessor !== 'action')
			.map((column) => ({
				label: column.Header,
				key: column.accessor,
			}));
	};
	return (
		<CSVLink
			data={data}
			headers={getColumnLabels()}
			filename={filename}
			className="export-csv-button btn-pink text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
		>
			Export to CSV
		</CSVLink>
	);
};

export default ExportButton;
