import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

export default function UserEngagement() {
	const [colorMode, setColorMode] = useState(localStorage.getItem('theme'));

	useEffect(() => {
		setColorMode(localStorage.getItem('theme'));
	}, []);

	const options = {
		chart: {
			id: 'line-chart',
			type: 'line',
			toolbar: {
				show: false,
			},
		},
		xaxis: {
			categories: [
				'Jan 23',
				'Feb 23',
				'Mar 23',
				'Apl 23',
				'May 23',
				'Jun 23',
				'Jul 23',
			],
			labels: {
				style: {
					colors: colorMode === 'dark' ? '#FFF' : '#373d3f',
				},
			},
			tick: false,
		},

		yaxis: {
			categories: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
			],
			labels: {
				style: {
					colors: colorMode === 'dark' ? '#FFF' : '#373d3f',
				},
			},
			tickAmount: 7,
		},
		stroke: {
			width: 3,
		},
		colors: ['#00A3FF'],
		legend: {
			show: false,
		},
		theme: {
			mode: 'light',
		},
	};

	const series = [
		{
			data: [30, 40, 25, 50, 49, 60],
		},
	];
	const customCSS = `
    .apexcharts-legend-series {
      text-align: left !important;
    }
    .apexcharts-legend-text {
      font-size: 18px !important;
      line-height: 30px;
    }
    #apexchartsline-chart .apexcharts-xaxis-tick {
      display: none;
    }
  `;
	return (
		<div>
			<h2 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7 text-left pb-[25.92px]">
				User Engagement
			</h2>
			<div className="mb-[39px] p-8 mt-6 lg:mt-0 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
				<div className="flex items-center justify-between mb-5 pb-5 dark:border-[#333333] border-gray-300 border-b border-solid">
					<p className="dark:text-white text-[#4b5563] text-[19px] font-semibold leading-[25px]">
						Sara Kabir
					</p>
					<i className="fa fa-angle-down text-[#BFC6D2] text-[22px]"></i>
				</div>
				<div className="h-[252px]">
					<ReactApexChart
						options={options}
						series={series}
						type="line"
						height="100%"
					/>
				</div>
			</div>
			<style>{customCSS}</style>
		</div>
	);
}
