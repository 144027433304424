import React, { useState, useEffect } from 'react';
import { getProjectViewData } from '../../store/thunk/projectsThunk';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import BreadcrumbsComponent from '../../component/ui-component/BreadcrumbsComponent';
import doc_image from '../../assets/images/doc.png';
import EditProjectComponent from './EditProjectComponent';
import EditTaskComponent from './Tasks/EditTaskComponent';
import Dialogpopup from '../../component/ui-component/Dailogpopup';
import { removeTask } from '../../store/thunk/tasksThunk';
import { removeAttachment } from '../../store/thunk/projectsThunk';
import Workflow from './Tasks/Workflow';
import {
	getPriorityStatus,
	getStatus,
	getDefaultProfile,
} from '../../component/Common';
import UserProfile from '../../component/ui-component/UserProfile';
import { getUserPermissions } from '../../store/thunk/userThunk';
import { hasPermission, getFileExtension } from '../../component/Common';

const moment = require('moment');

export default function ViewProjectComponent() {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const project_data = useSelector((state) => state.projects.projectViewData);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [editProjectDrawerVisible, setProjectDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [deleteVisibleAttachment, setDeleteVisibleAttachment] =
		useState(false);
	const [editValues, setEditValues] = useState(null);
	const [editProjectValues, setEditProjectValues] = useState(null);
	const [editValuesAttachment, setEditValuesAttachment] = useState(null);
	const authUser = useSelector((state) => state.auth);
	const user_permissions = useSelector((state) => state.user.userPermissions);
	const handleSetEditValues = (item) => {
		setEditValues(item);
		setEditDrawerVisible(true);
	};
	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
	};
	const handleDeleteFiles = (item) => {
		const data = {
			id: id,
			attachment_id: item.id,
			file: item.file,
		};
		setEditValuesAttachment(data);
		setDeleteVisibleAttachment(true);
	};
	const handleSetProjectEditValues = (item) => {
		setEditProjectValues(item);
		setProjectDrawerVisible(true);
	};
	useEffect(() => {
		let data = { id: id };
		dispatch(getProjectViewData(data));
	}, [dispatch, id]);
	useEffect(() => {
		dispatch(getUserPermissions());
	}, [dispatch]);
	const { priorityName, priorityTextcolor } = getPriorityStatus(
		project_data?.priority
	);
	const { statusName, textcolor } = getStatus(project_data?.status);
	return (
		<>
			{project_data && (
				<>
					<div className="p-[20px]">
						<div className="float-left mt-2">
							<BreadcrumbsComponent
								showBreadCrumbs={true}
								pageTitle={'Projects'}
								showMoreOptions={true}
								pageTitles={{
									page01_link: '/projects',
									page01_title: 'Projects',
									page02_title: 'View',
								}}
							/>
						</div>
						<div className="float-right lg:mt-[0px] mt-[20px]">
							{hasPermission(
								authUser?.userDetails,
								user_permissions,
								'change_projects'
							) && (
								<button
									className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
									onClick={() =>
										handleSetProjectEditValues(project_data)
									}
								>
									<i
										className="fa fa-pencil"
										aria-hidden="true"
									></i>{' '}
									Edit Project
								</button>
							)}
							<button
								className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)] ml-4"
								onClick={() => navigate(-1)}
							>
								<i
									className="fa fa-angle-double-left"
									aria-hidden="true"
								></i>{' '}
								Back
							</button>
						</div>
					</div>
					<div className="my-[38px] mx-[20px] relative">
						<div className="grid-cols-12 md:grid lg:grid-cols-12 block gap-[30px] lg:mt-[38px] mt-[90px]">
							<div className="col-span-9">
								<div className="relative p-6 dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] rounded-[15px] lg:mb-8 mb-5 md:mb-6">
									<div className="project-title dark:text-[#BFC6D2] text-[#54565C]">
										<h2 className="text-[20px] not-italic text-left font-bold leading-7 mb-[10px]">
											{project_data?.project_name}
										</h2>
										<small className="block text-ellipsis text-left mb-2">
											<span className="text-xs">
												{project_data?.total_open_task}
											</span>{' '}
											<span className="text-muted">
												open tasks,{' '}
											</span>
											<span className="text-xs">
												{
													project_data?.total_completed_task
												}
											</span>{' '}
											<span className="text-muted">
												tasks completed
											</span>
										</small>
										<p
											className="py-2 text-left text-[14px] leading-6 dark:text-[#BFC6D2] text-[#54565C]"
											dangerouslySetInnerHTML={{
												__html: `${
													project_data?.project_descriptions >
													10
														? project_data?.project_descriptions.substring(
																0,
																10
														  ) + '...'
														: project_data?.project_descriptions
												}`,
											}}
										></p>
									</div>
								</div>
								<div className="relative overflow-hidden rounded-[15px] p-6 dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] lg:mb-8 mb-5 md:mb-6">
									<div className="user-profile relative flex mb-[12px]">
										<div className="content text-left dark:text-[#BFC6D2] text-[#54565C]">
											<h2 className="text-[20px] not-italic font-bold leading-7 mb-[10px]">
												Uploaded image files
											</h2>
										</div>
									</div>
									<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
										{project_data?.attachments &&
											project_data?.attachments.map(
												(row, index) => {
													const {
														fileName,
														extension,
													} = getFileExtension(
														row?.file
													);
													if (
														[
															'jpg',
															'jpeg',
															'png',
														].includes(extension)
													) {
														return (
															<div
																className="border-[#ddd] dark:border-[#2e4062] border"
																key={row?.id}
															>
																<div className="relative">
																	<div className="float-left p-2.5 w-full">
																		<img
																			src={
																				row?.file
																			}
																			className="h-full w-[195px] object-cover border-solid"
																			alt="userpic"
																		/>
																		<div className=" dark:text-[#BFC6D2] text-[#54565C] pt-1.5 break-all text-[13px] text-center">
																			{
																				fileName
																			}
																		</div>
																	</div>
																	{authUser
																		?.userDetails
																		?.is_companyadmin && (
																		<div
																			title="Delete"
																			onClick={() =>
																				handleDeleteFiles(
																					row
																				)
																			}
																			className="cursor-pointer flex items-center justify-center dark:bg-[#b91c1c] bg-[#fff] w-[25px] h-[25px] border border-solid dark:border-[#b91c1c] border-[#bfc6d2] absolute top-0 right-0"
																		>
																			<i
																				className="fa fa-times dark:text-[#BFC6D2] text-[#54565C] hover:text-[#dc2626]"
																				aria-hidden="true"
																			></i>
																		</div>
																	)}
																</div>
															</div>
														);
													} else {
														return null;
													}
												}
											)}
									</div>
								</div>
								<div className="relative overflow-hidden rounded-[15px] p-6 dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] lg:mb-8 mb-5 md:mb-6">
									<div className="user-profile relative flex mb-[12px]">
										<div className="content text-left dark:text-[#BFC6D2] text-[#54565C]">
											<h2 className="text-[20px] not-italic font-bold leading-7 mb-[10px]">
												Uploaded files
											</h2>
										</div>
									</div>
									{project_data?.attachments &&
										project_data?.attachments.map(
											(row, index) => {
												const { fileName, extension } =
													getFileExtension(row?.file);
												if (
													![
														'jpg',
														'jpeg',
														'png',
													].includes(extension)
												) {
													return (
														<div
															className="grid gap-5 mt-[18px]"
															key={row?.id}
														>
															<div className="flex pb-2.5 mb-2.5 dark:border-[#293650] border-gray-300 border-b border-solid justify-between">
																<div className="flex">
																	<img
																		src={
																			doc_image
																		}
																		width="40px"
																		alt="doc"
																		className="float-left mr-3"
																	/>
																	<div>
																		<h5 className="dark:text-[#BFC6D2] text-[#54565C] text-left mb-[5px] font-bold dark:hover:text-blue-600 hover:text-blue-600 mt-1.5">
																			<Link
																				to={
																					row?.file
																				}
																				target="__blank"
																			>
																				{
																					fileName
																				}
																			</Link>
																		</h5>
																	</div>
																</div>
																{authUser
																	?.userDetails
																	?.is_companyadmin && (
																	<div
																		title="Delete"
																		onClick={() =>
																			handleDeleteFiles(
																				row
																			)
																		}
																		className="cursor-pointer flex items-center justify-center dark:bg-[#b91c1c] bg-[#fff] w-[30px] h-[30px] rounded-full border border-solid dark:border-[#b91c1c] border-[#bfc6d2]"
																	>
																		<i
																			className="fa fa-times dark:text-[#BFC6D2] text-[#54565C]"
																			aria-hidden="true"
																		></i>
																	</div>
																)}
															</div>
														</div>
													);
												} else {
													return null;
												}
											}
										)}
								</div>
							</div>
							<div className="col-span-3 md:mt-0 mt-3">
								<div className="upcommingsection relative dark-dark-light-card p-6 lg:mb-8 mb-5 md:mb-6">
									<div className="content text-left dark:text-[#BFC6D2] text-[#54565C]">
										<h2 className="text-[20px] not-italic font-bold leading-7 mb-[10px]">
											Project Details
										</h2>
									</div>
									<div className="flex flex-col">
										<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
											<div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
												<div className="overflow-hidden">
													<table className="min-w-full text-left text-sm font-light">
														<tbody>
															<tr className="dark:bg-[#202D47] bg-[#f2f2f2] h-[51.49px] dark:text-[#BFC6D2] text-[#54565C]">
																<th
																	scope="col"
																	className="px-4 py-4"
																>
																	Cost:
																</th>
																<td className="px-6 py-4">
																	{`$ ${project_data?.budget}`}
																</td>
															</tr>
															<tr className="dark:text-[#BFC6D2] text-[#54565C]">
																<th className="whitespace-nowrap px-4 py-4 font-medium">
																	Created:
																</th>
																<td className="whitespace-nowrap px-6 py-4">
																	{project_data?.created_at
																		? moment(
																				project_data?.created_at,
																				'YYYY-MM-DD'
																		  ).format(
																				'DD MMM, YYYY'
																		  )
																		: ''}
																</td>
															</tr>
															<tr className="dark:bg-[#202D47] bg-[#f2f2f2] h-[51.49px] dark:text-[#BFC6D2] text-[#54565C]">
																<th className="whitespace-nowrap px-4 py-4 font-medium">
																	Deadline:
																</th>
																<td className="whitespace-nowrap px-6 py-4">
																	{project_data?.end_date
																		? moment(
																				project_data?.end_date,
																				'YYYY-MM-DD'
																		  ).format(
																				'DD MMM, YYYY'
																		  )
																		: ''}
																</td>
															</tr>
															<tr className="dark:text-[#BFC6D2] text-[#54565C]">
																<th className="whitespace-nowrap px-4 py-4 font-medium">
																	Priority:
																</th>
																<td className="whitespace-nowrap px-6 py-4">
																	<div className="flex font-semibold">
																		<button
																			className={`inline-flex items-center gap-[5px] bg-white border ${priorityTextcolor} font-semibold text-[14px] px-3 py-1 pr-[15px] rounded-[50px] border-solid`}
																		>
																			<i
																				className={`fa-regular fa-circle-dot ${priorityTextcolor}`}
																			></i>{' '}
																			{
																				priorityName
																			}
																		</button>
																	</div>
																</td>
															</tr>
															<tr className="dark:bg-[#202D47] bg-[#f2f2f2] h-[51.49px] dark:text-[#BFC6D2] text-[#54565C]">
																<th className="whitespace-nowrap px-4 py-4 font-medium">
																	status:
																</th>
																<td className="whitespace-nowrap px-6 py-4">
																	<div className="flex font-semibold">
																		<button
																			className={`inline-flex items-center gap-[5px] bg-white border ${textcolor} font-semibold text-[14px] px-3 py-1 pr-[15px] rounded-[50px] border-solid`}
																		>
																			<i
																				className={`fa-regular fa-circle-dot ${textcolor}`}
																			></i>{' '}
																			{
																				statusName
																			}
																		</button>
																	</div>
																</td>
															</tr>
															<tr>
																<td colSpan="2">
																	<p className="m-b-5 flex items-center justify-between dark:text-[#BFC6D2] text-[#54565C] mt-4">
																		Progress{' '}
																		<span className="text-[#00FFE0] float-end">
																			40%
																		</span>
																	</p>
																	<div className="progress progress-xs mb-0">
																		<div
																			className="progress-bar bg-success w-40"
																			role="progressbar"
																			data-bs-toggle="tooltip"
																			aria-label="40%"
																			data-bs-original-title="40%"
																		></div>
																	</div>
																	<div className="flex-start flex h-1.5 w-full overflow-hidden rounded-sm bg-blue-gray-50 font-sans text-xs font-medium bg-[#101a30] mt-[10px]">
																		<div
																			className="flex h-full items-baseline justify-center overflow-hidden break-all bg-[#00FFE0] dark:text-[#BFC6D2] text-[#54565C]"
																			style={{
																				width: '50%',
																			}}
																		/>
																	</div>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="upcommingsection relative dark-dark-light-card p-6 lg:mb-8 mb-5 md:mb-6">
									<div className="content text-left dark:text-[#BFC6D2] text-[#54565C]">
										<h2 className="text-[20px] not-italic font-bold leading-7 mb-[10px]">
											Assigned Leader
										</h2>
									</div>
									<div className="flex flex-col mt-2.5 border-solid border-[#ddd] dark:border-[#2e4062] border p-2 rounded-lg shadow-xl hover:shadow-gray-400 dark:shadow-md dark:hover:shadow-[#0c4a6e]">
										<Link
											to={`/users/${project_data?.team_leader?.id}`}
											target="__blank"
											title="View Profile"
										>
											<div className="flex">
												<UserProfile
													imageUrl={
														project_data
															?.team_leader
															?.profile_image_url
													}
													defaultImageUrl={getDefaultProfile(
														project_data
															?.team_leader
															?.gender
													)}
													altName={
														project_data
															?.team_leader
															?.fullname
													}
													className={
														'rounded-full h-[45px] w-[45px] inline-block mt-[2px]'
													}
												/>
												<div className="ml-3 mt-1 dark:text-[#BFC6D2] text-[#54565C] text-left">
													<h6 className="font-bold mb-0 text-[15px]">{`${project_data?.team_leader?.first_name} ${project_data?.team_leader?.last_name}`}</h6>
													<p className="text-[12px]">
														{
															project_data
																?.team_leader
																?.designation
														}
													</p>
												</div>
											</div>
										</Link>
									</div>
								</div>
								<div className="upcommingsection relative dark-dark-light-card p-6 lg:mb-8 mb-5 md:mb-6">
									<div className="content text-left dark:text-[#BFC6D2] text-[#54565C]">
										<h2 className="text-[20px] not-italic font-bold leading-7 mb-[10px]">
											Assigned Team Members
										</h2>
									</div>
									{project_data?.team_members &&
										project_data?.team_members.map(
											(trow, tindex) => {
												return (
													<div
														className="flex flex-col mt-2.5 border-solid border-[#ddd] dark:border-[#2e4062] border p-2 rounded-lg shadow-xl hover:shadow-gray-400 dark:shadow-md dark:hover:shadow-[#0c4a6e]"
														key={trow?.id}
													>
														<Link
															to={`/users/${trow?.id}`}
															target="__blank"
															title="View Profile"
														>
															<div className="flex">
																<UserProfile
																	imageUrl={
																		trow?.profile_image_url
																	}
																	defaultImageUrl={getDefaultProfile(
																		trow?.gender
																	)}
																	altName={
																		trow?.fullname
																	}
																	className={
																		'rounded-full h-[45px] w-[45px] inline-block mt-[2px]'
																	}
																/>
																<div className="ml-3 mt-1 dark:text-[#BFC6D2] text-[#54565C] text-left">
																	<h6 className="font-bold mb-0 text-[15px]">{`${trow?.first_name} ${trow?.last_name}`}</h6>
																	<p className="text-[12px]">
																		{
																			trow?.designation
																		}
																	</p>
																</div>
															</div>
														</Link>
													</div>
												);
											}
										)}
								</div>
							</div>
						</div>

						<div className="grid-cols-12 grid gap-[30px] mb-[38px]">
							<div className="col-span-12">
								<Workflow
									handleSetEditValues={handleSetEditValues}
									handleDeleteClick={handleDeleteClick}
									projectID={id}
									userData={authUser?.userDetails}
									userPermissions={user_permissions}
								/>
							</div>
						</div>
					</div>
					{editDrawerVisible && editValues && (
						<EditTaskComponent
							visible={editDrawerVisible}
							onClose={() => setEditDrawerVisible(false)}
							editValues={editValues}
						/>
					)}

					{deleteDailogVisible && (
						<>
							<Dialogpopup
								onCancel={() => setDeleteDailogVisible(false)}
								deleteItem={editValues}
								removeThunkCall={removeTask}
								message="Are you sure you want to delete this task ?"
							/>
						</>
					)}

					{deleteVisibleAttachment && (
						<>
							<Dialogpopup
								onCancel={() =>
									setDeleteVisibleAttachment(false)
								}
								deleteItem={editValuesAttachment}
								removeThunkCall={removeAttachment}
								message="Are you sure you want to delete this project attachment ?"
							/>
						</>
					)}
					{editProjectDrawerVisible && editProjectValues && (
						<EditProjectComponent
							visible={editProjectDrawerVisible}
							onClose={() => setProjectDrawerVisible(false)}
							editValues={editProjectValues}
							isFromViewPage={true}
						/>
					)}
				</>
			)}
		</>
	);
}
