import React from 'react';
import EarningMultiprogressbar from '../../pages/Earning/EarningMultiprogressbar';
import Genius from '../../assets/images/b1.png';
import Toggle from '../../assets/images/dots.png';

function Earning() {
	const ActivitiesGamecan = [
		{
			images: Genius,
			title: 'GENIUS',
			description:
				'It is a long established fact that a reader will be distracted by the readable content of a page when',
		},
		{
			images: Genius,
			title: 'PROFESSIONAL',
			description:
				'It is a long established fact that a reader will be distracted by the readable content of a page when',
		},
		{
			images: Genius,
			title: 'EXPERT',
			description:
				'It is a long established fact that a reader will be distracted by the readable content of a page when',
		},
	];

	return (
		<>
			<div className="grid-cols-12 md:grid block px-5 mb-[40px] gap-[30px] md:mt-[38px] mt-0">
				<div className="col-span-9">
					<div className="earning-filter flex justify-between md:mt-0 mt-5">
						<div className="dark-dark-light-card flex items-center dark:text-[#fff] text-[#0C1932]">
							Expert Level
						</div>
						<div className="filter h-[62.677px] w-[65.345px] flex items-center justify-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="34"
								height="34"
								viewBox="0 0 34 34"
								fill="none"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12.3006 8.959C12.3006 11.5257 10.1847 13.606 7.5722 13.606C4.96124 13.606 2.84375 11.5257 2.84375 8.959C2.84375 6.39383 4.96124 4.31348 7.5722 4.31348C10.1847 4.31348 12.3006 6.39383 12.3006 8.959ZM28.2721 6.92367C29.4149 6.92367 30.3438 7.83627 30.3438 8.959C30.3438 10.0832 29.4149 10.9958 28.2721 10.9958H19.2308C18.0865 10.9958 17.1576 10.0832 17.1576 8.959C17.1576 7.83627 18.0865 6.92367 19.2308 6.92367H28.2721ZM4.91693 22.1307H13.9583C15.1026 22.1307 16.0315 23.0433 16.0315 24.1675C16.0315 25.2902 15.1026 26.2043 13.9583 26.2043H4.91693C3.77263 26.2043 2.84375 25.2902 2.84375 24.1675C2.84375 23.0433 3.77263 22.1307 4.91693 22.1307ZM25.6153 28.7579C28.2278 28.7579 30.3438 26.6776 30.3438 24.1124C30.3438 21.5457 28.2278 19.4654 25.6153 19.4654C23.0043 19.4654 20.8869 21.5457 20.8869 24.1124C20.8869 26.6776 23.0043 28.7579 25.6153 28.7579Z"
									fill="white"
								/>
							</svg>
						</div>
					</div>

					<div className="dark-light-light-card md:my-9 md:p-[30px] p-[15px] my-3">
						<div className="overflow-x-auto top-scroll pb-[31px]">
							<div className="items flex w-[max-content]">
								<EarningMultiprogressbar
									progress="100%"
									number="5"
									name="Successfully complete a task"
								/>
								<EarningMultiprogressbar
									progress="20%"
									number="20"
									name="Complete a project milestone"
								/>
								<EarningMultiprogressbar
									progress="30%"
									number="10"
									name="Present a successful proposal"
								/>
								<EarningMultiprogressbar
									progress="40%"
									number="5"
									name="Provide constructive peer feedback"
								/>
								<EarningMultiprogressbar
									progress="50%"
									number="10"
									name="Receive positive peer feedback"
								/>
								<EarningMultiprogressbar
									progress="60%"
									number="20"
									name="Successfully complete a task"
								/>
								<EarningMultiprogressbar
									progress="70%"
									number="15"
									name="Complete a project milestone"
								/>
								<EarningMultiprogressbar
									progress="80%"
									number="7"
									name="Present a successful proposal"
								/>
								<EarningMultiprogressbar
									progress="90%"
									number="8"
									name="Provide constructive peer feedback"
								/>
								<EarningMultiprogressbar
									progress="85%"
									number="2"
									name="Receive positive peer feedback"
								/>
							</div>
						</div>
					</div>

					<div className="xp-work dark-dark-light-card text-left md:p-10 p-5">
						<h2 className="card-title">How XP Works</h2>
						<p className="dark:text-white text-[#4b5563]">
							Admins can approve XP at any time, for certain
							activities, but most XP is earned via quarterly
							performance reviews Which we will call Checkpoints.
						</p>
						<p className="dark:text-white text-[#4b5563]">
							Nullam ut diam sit amet diam vehicula tristique.
							Fusce sit amet risus dictum, consectetur tortor non,
							commodo ex. Fusce mattis, metus a commodo
							vestibulum, ipsum neque interdum dui, in ultrices
							purus nisi at felis. Integer id ex eu felis
							sollicitudin congue et laoreet odio. Mauris in
							mattis turpis. Phasellus commodo libero mi. Duis
							tristique ac lacus nec ornare. Etiam mattis ipsum
							quis feugiat sagittis. Proin vel odio a ex varius
							facilisis at eu odio. Nam non magna quis tortor
							porta pretium. Maecenas consequat vestibulum est a
							ultrices.
						</p>
						<p className="dark:text-white text-[#4b5563]">
							Fusce elementum enim non ante lacinia, nec aliquet
							nibh pulvinar. Cras et suscipit sem, nec
							sollicitudin felis.{' '}
						</p>
					</div>
				</div>
				<div className="col-span-3">
					<h2 className="card-title text-left md:mt-0 mt-[20px]">
						Top 3 XP Activities in Gamecan
					</h2>
					<div>
						{ActivitiesGamecan.map((i, index) => (
							<div
								key={index}
								className="dark-dark-light-card text-center relative mb-10 p-5"
							>
								<span className="absolute text-[#fff] top-5 right-5 cursor-pointer">
									<img
										src={Toggle}
										alt="dot"
										className="dot-toggle"
									/>
								</span>
								<img
									className="inline-block w-24 h-24"
									src={i.images}
									alt="icon"
								/>
								<h2 className="dark:text-[#fff] uppercase text-[22px] font-extrabold leading-7 pt-[6px] pb-[18px]">
									{i.title}
								</h2>
								<p className="text-center text-[13px] not-italic font-normal leading-[23px] dark:text-[#BFC6D2] text-[#4b5563]">
									{i.description}
								</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
}

export default Earning;
