import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { editProject } from '../../store/thunk/projectsThunk';

import FormComponent from './FormComponent';

const EditProjectComponent = ({
	visible,
	onClose,
	editValues,
	isFromViewPage = false,
}) => {
	const loading = useSelector((state) => state.dataLoading.loading);
	const [selectedFile, setSelectedFile] = useState([]);
	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		setValue,
	} = useForm({
		defaultValues: {
			project_name: editValues?.project_name,
			project_type: editValues?.project_type,
			client: editValues?.client?.id,
			budget: editValues?.budget,
			priority: editValues?.priority,
			start_date: editValues?.start_date,
			end_date: editValues?.end_date,
			project_descriptions: editValues?.project_descriptions,
			team_leader: editValues?.team_leader?.id,
			status: editValues?.status,
		},
	});

	const onSuccessCallback = () => {
		reset();
		onClose();
	};

	const onSubmit = (data) => {
		data.callbackFunction = onSuccessCallback;
		data.setError = setError;
		data.id = editValues?.id;
		if (selectedFile !== undefined) {
			data.attachments = selectedFile;
		}
		if (data.team_members === undefined) {
			let team_members_ids = [];
			editValues?.team_members.map((item) =>
				team_members_ids.push(parseInt(item.id))
			);
			data.team_members = team_members_ids;
		}
		data.is_from_view_page = isFromViewPage;
		dispatch(editProject(data));
	};

	return (
		<>
			<div
				className={` togglesidebar bg-[#ffffff57] fixed h-full w-full top-0 left-0 p-6 z-[99999]  ${
					visible ? 'active' : ''
				}`}
			>
				<div
					onClick={onClose}
					className="w-full bg-[#ffffff57] overlay bg-[#0f1a32] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
				/>
				<div className="dark:bg-[#12243d] bg-[#fafafa] border-gray-300 border-l w-[85%] lg:w-[50%] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]">
					<button
						className="close h-8 w-8 rounded-full absolute top-5 right-5 bg-[#fff]"
						onClick={onClose}
					>
						X
					</button>
					<h1 className="p-6 dark:text-white text-[#4b5563] font-bold text-[20px] text-left pb-[20px] border-solid border-b dark:border-[#333333] border-gray-300">
						Update Project Data
					</h1>
					<div className="rounded-lg">
						<FormComponent
							loading={loading}
							register={register}
							handleSubmit={handleSubmit}
							onSubmit={onSubmit}
							errors={errors}
							isUpdate={true}
							setValue={setValue}
							projectDescriptions={
								editValues?.project_descriptions
							}
							selectedPriority={editValues?.priority || ''}
							selectedStatus={editValues?.status || ''}
							selectedTeamLeader={
								editValues?.team_leader?.id || ''
							}
							selectedTeamMembers={editValues?.team_members || ''}
							selectedClient={editValues?.client?.id || ''}
							setSelectedFile={setSelectedFile}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditProjectComponent;
