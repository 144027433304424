import React, { useEffect, useState } from 'react';
import InputComponent from '../../component/ui-component/InputComponent';
import Button from '../../component/ui-component/Button';
import RadioComponent from '../../component/ui-component/RadioComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentList } from '../../store/thunk/commonThunk';
import MultiSelectComponent from '../../component/ui-component/MultiSelectComponent';
import { vsmXPEarning } from '../../utils/validations';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	setValue,
	selectedOptions,
	selectedAssignedDepartment,
}) => {
	const dispatch = useDispatch();
	const departmentList = useSelector((state) => state.common.departmentList);
	const [showMultipleDepartmentOptions, setShowMultipleDepartmentOptions] =
		useState(!selectedOptions || false);

	const [isMultiSelectValid, setIsMultiSelectValid] = useState(
		selectedAssignedDepartment && selectedAssignedDepartment.length > 0
			? true
			: false
	);
	const [error, setError] = useState(false);

	useEffect(() => {
		dispatch(getDepartmentList());
	}, [dispatch]);

	const optionsLists = [
		{ id: 'yes', name: 'Yes', value: true },
		{ id: 'no', name: 'No', value: false },
	];

	const onChangeSelectValue = (key, selectedValue) => {
		if (selectedValue === 'false') {
			setShowMultipleDepartmentOptions(true);
		} else {
			setShowMultipleDepartmentOptions(false);
		}
		setValue(key, selectedValue);
	};

	const onChangeMultiSelectValue = (key, selectedValue) => {
		let role_ids = [];
		selectedValue.map((item) => role_ids.push(parseInt(item.value)));
		setValue(key, role_ids);
		setIsMultiSelectValid(selectedValue.length > 0);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (!isMultiSelectValid && showMultipleDepartmentOptions) {
			setError(true);
			return;
		} else {
			setError(false);
		}
		// Proceed with form submission
		handleSubmit(onSubmit)(e);
	};

	return (
		<form onSubmit={handleFormSubmit} noValidate>
			<div className="p-[20px] infobox custom-scroll max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
							XP Earning Master Information
						</h3>
					</div>
					<div className="grid grid-cols-1 gap-2">
						<InputComponent
							id="activity_or_achievement"
							title="Activity / Achievement"
							errors={errors}
							register={register}
							fieldvalidation={vsmXPEarning}
						/>
						<InputComponent
							id="xp_earned_points"
							title="XP Earned"
							errors={errors}
							register={register}
							fieldvalidation={vsmXPEarning}
						/>
						<RadioComponent
							id="is_applicable_for_all"
							title="Applicable for all Department(s)"
							errors={errors}
							register={register}
							fieldvalidation={vsmXPEarning}
							optionsLists={optionsLists}
							selectedOptions={selectedOptions}
							onChangeSelectValue={onChangeSelectValue}
						/>
						{showMultipleDepartmentOptions && (
							<MultiSelectComponent
								selectOptionLists={departmentList}
								onChangeSelectValue={onChangeMultiSelectValue}
								selectedValue={selectedAssignedDepartment}
								title="Select Applicable Department"
								id="applicable_departments"
								error={error}
							/>
						)}
					</div>
				</div>
			</div>
			<div className="flex md:p-6 p-3 border-solid border-t dark:border-[#333333] border-gray-300 absolute w-full bottom-0">
				<Button
					buttonText={`${isUpdate ? 'Update' : 'Create'} XP Earning`}
					linkTo="#"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default FormComponent;
