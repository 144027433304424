const vsmCompany = {
	success: 'Data successfully fetched!',
	addSucces: 'Company created successfully!',
	updateSuccess: 'Company updated successfully!',
	profileUpdate: 'Profile updated successfully!',
	logoUpdate: 'Company Logo updated successfully!',
	deleteSuccess: 'Company deleted successfully!',
	update_status_success: 'User status updated successfully!',
	validation: {
		company_name: {
			required: 'Company name required!',
		},
		company_email: {
			required: 'Company email required!',
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: 'Invalid email address',
			},
		},
		company_address: {
			required: 'Company address required!',
		},
		company_country: {
			required: 'Company country required!',
		},
		company_postal_code: {
			required: 'Company postal code required!',
		},
		company_contact_no: {
			required: 'Company contact no required!',
			pattern: {
				value: /^[\d()\- ]+$/, // Regular expression to allow only digits (numbers)
				message: 'Invalid contact number. Please enter digits only!',
			},
		},
		first_name: {
			required: 'First name required!',
		},
		last_name: {
			required: 'Last name required!',
		},
		email: {
			required: 'Email address Required',
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: 'Invalid email address',
			},
		},
		phone_number: {
			required: 'Phone number required!',
			pattern: {
				value: /^[\d()\- ]+$/, // Regular expression to allow only digits (numbers)
				message: 'Invalid phone number. Please enter digits only!',
			},
		},
		company_filling_date: {
			required: 'Company filling date is required!',
		},
		company_website: {
			pattern: {
				value: /^(ftp|http|https):\/\/[^ "]+$/,
				message: 'Invalid website URL. Please enter a valid URL!',
			},
		},
	},
};

const vsmUser = {
	success: 'Data successfully fetched!',
	addSucces: 'User created successfully!',
	updateSuccess: 'User updated successfully!',
	deleteSuccess: 'User deleted successfully!',
	profileUpdate: 'Profile updated successfully!',
	userSettings: 'Settings updated successfully!',
	update_status_success: 'User status updated successfully!',
	validation: {
		first_name: {
			required: 'First name required!',
		},
		last_name: {
			required: 'Last name required!',
		},
		email: {
			required: 'Email address Required!',
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: 'Invalid email address',
			},
		},
		phone_number: {
			required: 'Phone number required!',
			pattern: {
				value: /^[\d()\- ]+$/, // Regular expression to allow only digits (numbers)
				message: 'Invalid phone number. Please enter digits only!',
			},
		},
		joining_date: {
			required: 'Joining date required!',
		},
		job_type: {
			required: 'Job type required!',
		},
		department: {
			required: 'Department name required!',
		},
		role: {
			required: 'Role name required!',
		},
		bank_name: {
			required: 'Bank name required!',
		},
		bank_account_number: {
			required: 'Bank account number required!',
		},
		bank_ifsc_number: {
			required: 'Bank IFSC code required!',
		},
		bank_pan_number: {
			required: 'Pan number required!',
		},
		country: {
			required: 'Country is required!',
		},
		marital_status: {
			required: 'Marital status is required!',
		},
		dob: {
			required: 'Birth date is required!',
		},
	},
};

const vsmDepartment = {
	success: 'Data successfully fetched!',
	addSucces: 'Created successfully!',
	updateSuccess: 'Updated successfully!',
	deleteSuccess: 'Deleted successfully!',
	validation: {
		title: {
			required: 'Department name required!',
		},
	},
};

const vsmXPEarning = {
	success: 'Data successfully fetched!',
	addSucces: 'Created successfully!',
	updateSuccess: 'Updated successfully!',
	deleteSuccess: 'Deleted successfully!',
	validation: {
		activity_or_achievement: {
			required: 'Activity/Achievement name required!',
		},
		xp_earned_points: {
			required: 'Xp earned points required!',
		},
	},
};
const vsmSkills = {
	success: 'Data successfully fetched!',
	addSucces: 'Created successfully!',
	updateSuccess: 'Updated successfully!',
	deleteSuccess: 'Deleted successfully!',
	validation: {
		name: {
			required: 'Skill name required!',
		},
	},
};

const vsmRoles = {
	success: 'Data successfully fetched!',
	addSucces: 'Created successfully!',
	updateSuccess: 'Updated successfully!',
	deleteSuccess: 'Deleted successfully!',
	validation: {
		title: {
			required: 'Role name required!',
		},
		department: {
			required: 'Deparment name required!',
		},
	},
};

const vsmTask = {
	success: 'Data successfully fetched!',
	addSucces: 'Task created successfully!',
	updateSuccess: 'Task updated successfully!',
	deleteSuccess: 'Task deleted successfully!',
	update_status_success: 'Task status updated successfully!',
	validation: {
		title: {
			required: 'Task title required!',
		},
		description: {
			required: 'Task descriptions required!',
		},
		due_date: {
			required: 'Due date required!',
		},
		assigned_to: {
			required: 'Please select user to do this task!',
		},
		priority: {
			required: 'Priority is required',
		},
	},
};

const vsmLeaves = {
	success: 'Data successfully fetched!',
	addSucces: 'Created successfully!',
	updateSuccess: 'Updated successfully!',
	deleteSuccess: 'Deleted successfully!',
	leaveStatus: 'Leave Status Updated Successfully',
	validation: {
		start_date: {
			required: 'Start date required!',
		},
		end_date: {
			required: 'End date required!',
		},
		leave_type: {
			required: 'Leave type required!',
		},
		reason: {
			required: 'Leave reason required!',
		},
		additional_notes: {
			required: 'Reason for reject is required!',
		},
	},
};

const vsmMeeting = {
	success: 'Data successfully fetched!',
	addSucces: 'Created successfully!',
	updateSuccess: 'Updated successfully!',
	deleteSuccess: 'Deleted successfully!',
	leaveStatus: 'Leave Status Updated Successfully',
	validation: {
		title: {
			required: 'Meeting title required!',
		},
		description: {
			required: 'Meeting description required!',
		},
		meeting_date: {
			required: 'Meeting date required!',
		},
		from_time: {
			required: 'Meeting from time required!',
		},
		to_time: {
			required: 'Meeting to time required!',
		},
		users: {
			required: 'Meeting assigned users required!',
		},
	},
};

const vsmProjects = {
	success: 'Data successfully fetched!',
	addSucces: 'Created successfully!',
	updateSuccess: 'Updated successfully!',
	deleteSuccess: 'Deleted successfully!',
	validation: {
		project_name: {
			required: 'Project name required!',
		},
		client: {
			required: 'Client is required!',
		},
		start_date: {
			required: 'Start date required!',
		},
		end_date: {
			required: 'End date required!',
		},
		team_leader: {
			required: 'Team leader required!',
		},
		priority: {
			required: 'Priority is required!',
		},
	},
};

const vsmClient = {
	success: 'Data successfully fetched!',
	addSucces: 'Created successfully!',
	updateSuccess: 'Updated successfully!',
	deleteSuccess: 'Deleted successfully!',
	validation: {
		first_name: {
			required: 'First name required!',
		},
		last_name: {
			required: 'Last is required!',
		},
		company_name: {
			required: 'Company name required!',
		},
		position: {
			required: 'Position required!',
		},
		phone_number: {
			required: 'Phone number is required!',
		},
		email: {
			required: 'Email address is required!',
		},
	},
};
const vsmHoliday = {
	success: 'Data successfully fetched!',
	addSucces: 'Created successfully!',
	updateSuccess: 'Updated successfully!',
	deleteSuccess: 'Deleted successfully!',
	validation: {
		title: {
			required: 'Holiday title required!',
		},
		date: {
			required: 'Holiday date required!',
		},
	},
};

const vsmUserEducation = {
	success: 'Data successfully fetched!',
	addSucces: 'Education created successfully!',
	updateSuccess: 'Education data updated successfully!',
	deleteSuccess: 'User deleted successfully!',
	profileUpdate: 'Profile updated successfully!',
	userSettings: 'Settings updated successfully!',
	update_status_success: 'User status updated successfully!',
	validation: {
		institution: {
			required: 'Institution name required!',
		},
		subject: {
			required: 'Subject required!',
		},
		starting_date: {
			required: 'Starting date required!',
		},
		complete_date: {
			required: 'Complete date required!',
		},
		degree: {
			required: 'Degree required!',
		},
		grade: {
			required: 'Grade required!',
		},
	},
};

const vsmUserExperience = {
	success: 'Data successfully fetched!',
	addSucces: 'Experience created successfully!',
	updateSuccess: 'Experiecen data updated successfully!',
	deleteSuccess: 'User deleted successfully!',
	profileUpdate: 'Profile updated successfully!',
	userSettings: 'Settings updated successfully!',
	update_status_success: 'User status updated successfully!',
	validation: {
		company_name: {
			required: 'Company name required!',
		},
		location: {
			required: 'Job location required!',
		},
		job_position: {
			required: 'Job position required!',
		},
		start_date: {
			required: 'Start date required!',
		},
	},
};

const vsmUserEmergency = {
	success: 'Data successfully fetched!',
	addSucces: 'Emergency created successfully!',
	updateSuccess: 'Emergency contact updated successfully!',
	deleteSuccess: 'User deleted successfully!',
	profileUpdate: 'Profile updated successfully!',
	userSettings: 'Settings updated successfully!',
	update_status_success: 'User status updated successfully!',
	validation: {
		name: {
			required: 'Name is required',
		},
		relationship: {
			required: 'Relationship required!',
		},
		phone1: {
			required: 'Phone no required!',
			pattern: {
				value: /^[\d()\- ]+$/, // Regular expression to allow only digits (numbers)
				message: 'Invalid phone number. Please enter digits only!',
			},
		},
	},
};

const vsmUserFamilyInfo = {
	success: 'Data successfully fetched!',
	addSucces: 'Family Info created successfully!',
	updateSuccess: 'Family Info updated successfully!',
	deleteSuccess: 'User deleted successfully!',
	profileUpdate: 'Profile updated successfully!',
	userSettings: 'Settings updated successfully!',
	update_status_success: 'User status updated successfully!',
	validation: {
		name: {
			required: 'Name is required',
		},
		relationship: {
			required: 'Relationship required!',
		},
		phone: {
			required: 'Phone No required!',
			pattern: {
				value: /^[\d()\- ]+$/, // Regular expression to allow only digits (numbers)
				message: 'Invalid phone number. Please enter digits only!',
			},
		},
		dob: {
			required: 'Date of birth required!',
		},
	},
};

const vsmCommon = {
	noRecord: 'No Records Found.',
};

const vsmJobs = {
	success: 'Data successfully fetched!',
	addSucces: 'Created successfully!',
	updateSuccess: 'Updated successfully!',
	deleteSuccess: 'Deleted successfully!',
	update_status_success: 'Status updated successfully!',
	validation: {
		title: {
			required: 'Title is required',
		},
		description: {
			required: 'Description is required!',
		},
		requirements: {
			required: 'Requirement is required!',
		},
	},
};

const vsmRecruitment = {
	success: 'Data successfully fetched!',
	addSucces: 'Created successfully!',
	updateSuccess: 'Updated successfully!',
	deleteSuccess: 'Deleted successfully!',
	orderSuccess: 'Status updated successfully',
	validation: {
		full_name: {
			required: 'Candidate full name required!',
		},
		email: {
			required: 'Candidate email required!',
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: 'Invalid email address',
			},
		},
		phone_number: {
			required: 'Candidate phone number required!',
			pattern: {
				value: /^[\d()\- ]+$/, // Regular expression to allow only digits (numbers)
				message: 'Invalid phone number. Please enter digits only!',
			},
		},
		address: {
			required: 'Candidate address required!',
		},

		// resume: {
		// 	required: 'Candidate resume required!',
		// },
		education: {
			required: 'Candidate Education detail required!',
		},
		work_experience: {
			required: 'Candidate work experience required!',
		},
		skills: {
			required: 'Candidate skills required!',
		},
		job: {
			required: 'Please select job required!',
		},
		interview_date: {
			required: 'Interview date required!',
		},
		from_time: {
			required: 'Interview time required!',
		},
		to_date: {
			required: 'Interview time required!',
		},
		interview_format: {
			required: 'Interview format required either in person or remote!',
		},
	},
};
const customValidations = (fieldname, value, fieldvalidation) => {
	const fieldKey = fieldname.split('.').pop();
	const validationRules = fieldvalidation.validation[fieldKey];

	if (validationRules) {
		if (validationRules.required && !value) {
			return validationRules.required;
		}

		if (
			validationRules.pattern &&
			!validationRules.pattern.value.test(value)
		) {
			return validationRules.pattern.message;
		}
		return undefined;
	}
	return undefined;
};

export {
	vsmCompany,
	vsmUser,
	vsmCommon,
	vsmDepartment,
	vsmSkills,
	vsmRoles,
	vsmTask,
	vsmLeaves,
	vsmMeeting,
	vsmProjects,
	vsmHoliday,
	vsmUserEducation,
	vsmUserExperience,
	vsmUserEmergency,
	vsmUserFamilyInfo,
	customValidations,
	vsmClient,
	vsmJobs,
	vsmRecruitment,
	vsmXPEarning,
};
