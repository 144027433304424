import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserTimeTrackingList } from '../../store/thunk/userThunk';
const moment = require('moment');

const Activity = () => {
	const dispatch = useDispatch();
	const activityTrack = useSelector((state) => state.user.userTimeTracking);

	useEffect(() => {
		let data = { show_all: false };
		dispatch(getUserTimeTrackingList(data));
	}, [dispatch]);
	return (
		<div className="p-7 relative dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] rounded-[15px]">
			<div className="text-left dark:text-white text-[#4b5563] text-2xl font-bold tracking-wider">
				Today Activity
			</div>
			<div className="p-[20px] h-[470px] overflow-y-auto top-scroll-activity ">
				<ol className="mt-5 border-l-2 border-neutral-300 dark:border-neutral-500">
					{activityTrack.length > 0 &&
						activityTrack.map((activity, i) => (
							<li key={i}>
								<div className="flex-start flex items-center pt-1">
									<div className="-ml-[8px] mr-3 h-[14px] w-[14px] border-2 border-orange-500 rounded-full dark:bg-[#17243E] bg-[#fff]"></div>
									<span className="dark:text-white text-[#4b5563] text-lg font-semibold ml-4 tracking-wide mb-1 mt-1.5">
										{activity?.punch_type_name}
									</span>
								</div>
								<div className="mb-4 ml-9">
									<p className="text-sm dark:text-white text-[#4b5563] tracking-wide">
										<i
											className="fa fa-clock"
											aria-hidden="true"
										></i>{' '}
										{moment(activity?.timestamp).format(
											'hh:mm:ss A'
										)}
									</p>
								</div>
							</li>
						))}
				</ol>
				{activityTrack.length === 0 && (
					<div className="dark:text-white text-[#4b5563]">
						No activity found for today
					</div>
				)}
			</div>
		</div>
	);
};
export default Activity;
