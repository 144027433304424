import React from 'react';

const RadioComponent = ({
	id,
	inputType,
	title,
	optionsLists,
	selectedOptions,
	onChangeSelectValue,
}) => {
	const handleSelectChange = (e) => {
		onChangeSelectValue(id, e.target.value);
	};
	return (
		<div
			className={`input-component my-[10px] mr-[12px] ${inputType || ''}`}
		>
			<h5 className="text-base text-left dark:text-white text-[#4b5563]">
				{title}
			</h5>
			<div className="flex gap-10">
				{optionsLists.length > 0 &&
					optionsLists.map((row, index) => (
						<div
							className="inline-flex items-center"
							key={`radio_${row.id}_${index}`}
						>
							<label
								className="relative flex items-center p-3 rounded-full cursor-pointer"
								htmlFor={`radio_${row.id}_${index}`}
							>
								<input
									name={id}
									type="radio"
									className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border-2 dark:border-slate-300 border-gray-900/10 bg-gray-900/5 p-0 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity dark:checked:border-slate-300 checked:border-gray-300 dark:checked:before:bg-slate-900 checked:before:bg-gray-900 hover:before:opacity-0"
									id={`radio_${row.id}_${index}`}
									value={row.value}
									defaultChecked={
										row.value === selectedOptions
									}
									onChange={handleSelectChange}
								/>
								<span className="absolute text-blue-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-3.5 w-3.5"
										viewBox="0 0 16 16"
										fill="currentColor"
									>
										<circle
											data-name="ellipse"
											cx="8"
											cy="8"
											r="8"
										></circle>
									</svg>
								</span>
							</label>
							<label
								className="mt-px font-light text-gray-700 cursor-pointer select-none"
								htmlFor={`radio_${row.id}_${index}`}
							>
								<p className="block font-sans text-base antialiased font-normal leading-relaxed dark:text-white text-[#4b5563]">
									{row.name}
								</p>
							</label>
						</div>
					))}
			</div>
		</div>
	);
};
export default RadioComponent;
