import React, { useState } from 'react';
import { removeClient } from '../../store/thunk/projectsThunk';
import AddClientComponent from './AddClientComponent';
import EditClientComponent from './EditClientComponent';
import ListComponent from './ListComponent';
import Dialogpopup from '../../component/ui-component/Dailogpopup';
import BreadcrumbsComponent from '../../component/ui-component/BreadcrumbsComponent';

function ClientsComponent() {
	const [addDrawerVisible, setAddDrawerVisible] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);

	const handleSetEditValues = (item) => {
		setEditValues(item);
		setEditDrawerVisible(true);
	};

	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
	};

	return (
		<>
			<div className="p-[20px]">
				<div className="flex justify-between items-center mb-5">
					<BreadcrumbsComponent
						showBreadCrumbs={true}
						pageTitle={'Clients'}
					/>
					<button
						className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 lg:py-3 py-2 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
						onClick={() => setAddDrawerVisible(true)}
					>
						+ Create Client
					</button>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 text-white text-left">
					<ListComponent
						handleSetEditValues={handleSetEditValues}
						handleDeleteClick={handleDeleteClick}
					/>
				</div>
			</div>
			{addDrawerVisible && (
				<AddClientComponent
					visible={addDrawerVisible}
					onClose={() => setAddDrawerVisible(false)}
				/>
			)}
			{editDrawerVisible && editValues && (
				<EditClientComponent
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
				/>
			)}
			{deleteDailogVisible && (
				<>
					<Dialogpopup
						onCancel={() => setDeleteDailogVisible(false)}
						deleteItem={editValues}
						removeThunkCall={removeClient}
						message="Are you sure you want to delete this client & it will delete all projects under this client ?"
					/>
				</>
			)}
		</>
	);
}
export default ClientsComponent;
