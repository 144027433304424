import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import AddEventComponent from './AddEventComponent';
import { formatDate } from '../../component/Common';
import moment from 'moment';
import {
	getAllMeetingList,
	removeMeeting,
	updateMeeting,
} from '../../store/thunk/meetingThunk';
import { getHolidayList } from '../../store/thunk/holidaysThunk';
import Dialogpopup from '../../component/ui-component/Dailogpopup';
import ViewEventComponent from './ViewEventComponent';
import EditEventComponent from './EditEventComponent';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

export default function EventsComponent() {
	const dispatch = useDispatch();
	const [selectedDate, setSelectedDate] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [viewDailogVisible, setViewDailogVisible] = useState(false);
	const [viewValues, setViewValues] = useState(null);
	const [editValues, setEditValues] = useState(null);

	const meetingsLists = useSelector((state) => state.meetings?.meetingList);
	const holidayData = useSelector((state) => state.holiday?.holidayList);

	const [meetingEvents, setMeetingEvents] = useState([]);
	const [holidayEvents, setHolidayEvents] = useState([]);

	useEffect(() => {
		dispatch(getAllMeetingList());
		dispatch(getHolidayList());
	}, [dispatch]);

	useEffect(() => {
		if (meetingsLists && meetingsLists.length > 0) {
			setMeetingEvents(
				meetingsLists.map((event) => {
					const startDateTime = `${event.meeting_date} ${event.from_time}`;
					const endDateTime = `${event.meeting_date} ${event.to_time}`;

					return {
						id: event?.id,
						title: event?.title,
						start: new Date(startDateTime),
						end: new Date(endDateTime),
						description: event?.description,
						users: event?.users,
						from_time: event?.from_time,
						to_time: event?.to_time,
						files: event?.files,
						type: 'events',
					};
				})
			);
		}

		if (holidayData && holidayData.length > 0) {
			setHolidayEvents(
				holidayData.map((event) => {
					return {
						id: event?.id,
						title: event?.title,
						start: new Date(event.date),
						end: new Date(event.date),
						type: 'holidays',
					};
				})
			);
		}
	}, [meetingsLists, holidayData]);

	const allEvents = [...meetingEvents, ...holidayEvents];

	const handleDateClick = (date) => {
		setSelectedDate(formatDate(date, 'YYYY-MM-DD'));
		setIsModalOpen(true);
	};

	const handleEventClick = (event) => {
		if (event.type === 'events') {
			setViewDailogVisible(true);
			setViewValues(event);
		}
	};

	const handleSetEditValues = (item) => {
		setViewDailogVisible(false);
		setEditDrawerVisible(true);
		setEditValues(item);
	};

	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
		setViewDailogVisible(false);
	};

	const EventComponent = ({ event }) => {
		return (
			<div>
				<div
					className={`${event.type} p-1.5 px-2 font-black tracking-wide rounded-md shadow-xl dark:shadow-[#2e4062] shadow-[#cbd5e1]`}
				>
					<strong>{event.title}</strong>
					{event.type === 'events' &&
						` (${formatDate(event.start, 'hh:mm A')} - ${formatDate(
							event.end,
							'hh:mm A'
						)})`}
				</div>
			</div>
		);
	};

	const moveEvent = useCallback(
		({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
			if (event.type === 'events') {
				const { allDay } = event;
				if (!allDay && droppedOnAllDaySlot) {
					event.allDay = true;
				}

				setMeetingEvents((prev) => {
					const existing =
						prev.find((ev) => ev.id === event.id) ?? {};
					const filtered = prev.filter((ev) => ev.id !== event.id);
					return [...filtered, { ...existing, start, end, allDay }];
				});

				let data = {};
				data.id = event?.id;
				data.meeting_date = formatDate(start, 'YYYY-MM-DD');
				data.is_from_events = true;
				dispatch(updateMeeting(data));
			}
		},
		[dispatch, setMeetingEvents]
	);

	return (
		<>
			<div className="p-[20px]">
				<div className="border dark:border-[#2e4062] p-5 shadow-lg dark:shadow-[#2e4062] shadow-[#cbd5e1] rounded">
					<div className="text-left mb-4">
						<div className="dark:text-[#fff] text-[#4b5563] mb-2 font-semibold float-left mr-3">
							<span className="holidays p-0.5 px-3.5 font-black tracking-wide text-white mx-2"></span>
							Holidays
						</div>
						<div className="dark:text-[#fff] text-[#4b5563] font-semibold">
							<span className="events p-0.5 px-3.5 font-black tracking-wide text-white mr-2"></span>
							Events / Meetings
						</div>
					</div>
					<DnDCalendar
						localizer={localizer}
						events={allEvents}
						className="events_calendar"
						startAccessor="start"
						endAccessor="end"
						onSelectSlot={({ start }) => handleDateClick(start)}
						onSelectEvent={(event) => handleEventClick(event)}
						components={{
							event: EventComponent,
						}}
						onEventDrop={moveEvent}
						selectable
					/>
				</div>
			</div>

			{isModalOpen && (
				<AddEventComponent
					visible={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					selectedDate={selectedDate}
				/>
			)}
			{editDrawerVisible && (
				<EditEventComponent
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
				/>
			)}
			{viewDailogVisible && (
				<ViewEventComponent
					visible={viewDailogVisible}
					onClose={() => setViewDailogVisible(false)}
					viewValues={viewValues}
					handleSetEditValues={handleSetEditValues}
					handleDeleteClick={handleDeleteClick}
				/>
			)}
			{deleteDailogVisible && (
				<>
					<Dialogpopup
						onCancel={() => setDeleteDailogVisible(false)}
						deleteItem={editValues}
						removeThunkCall={removeMeeting}
						message="Are you sure you want to delete this Event ?"
					/>
				</>
			)}
		</>
	);
}
