import React from 'react';
import AdminBoxLayout from './AdminBoxLayout';
import EmployeeMap from './EmployeeMap';
import TaskManagement from './Tasks/TaskManagement';
import ActivityFeed from './ActivityFeed';
import Meetings from './Meetings/Meetings';
import { useSelector } from 'react-redux';

const AdminPanel = () => {
	const authUser = useSelector((state) => state.auth);
	return (
		<>
			<div className=" p-[20px]">
				<div>
					<p className="dark:text-white text-[#4b5563] text-left text-[color:var(--dark-shade-2,#BFC6D2)] text-base leading-[21px]">
						Hello Admin,
					</p>
					<h1 className="dark:text-white text-[#4b5563] text-left text-[32px] font-semibold leading-[42px] font-family-Montserrat-bold">
						Good Morning
					</h1>
				</div>
				{authUser?.userDetails?.is_companyadmin && (
					<>
						<div className="grid">
							<AdminBoxLayout />
						</div>
						<div className="block md:grid grid-cols-2 gap-6 md:mb-10 mb-5 md:mt-10 mt-0">
							<div>
								<EmployeeMap />
							</div>
							<div>
								<TaskManagement />
							</div>
						</div>
						<div className="md:grid block grid-cols-12 gap-6 md:mt-10 mt-5">
							<div className="col-span-6">
								<ActivityFeed />
							</div>
							<div className="col-span-6">
								<Meetings />
							</div>
						</div>
					</>
				)}

				{authUser?.userDetails?.is_superuser && (
					<>
						<div className="grid">
							<AdminBoxLayout />
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default AdminPanel;
