import { Link } from 'react-router-dom';

const BreadcrumbsComponent = ({
	showBreadCrumbs = false,
	pageTitle,
	showMoreOptions = false,
	pageTitles = {},
}) => {
	return (
		<>
			{showBreadCrumbs && (
				<nav className="rounded-md">
					<ol className="list-reset flex">
						<li>
							<Link
								to="/admin-panel"
								className="font-medium text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
							>
								Dashboard
							</Link>
						</li>
						<li>
							<span className="mx-2 text-neutral-500 dark:text-neutral-400">
								/
							</span>
						</li>
						{!showMoreOptions && (
							<li className="text-neutral-500 dark:text-neutral-400">
								{pageTitle}
							</li>
						)}
						{showMoreOptions && (
							<>
								<li>
									<Link
										to={pageTitles?.page01_link}
										className="font-medium text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
									>
										{pageTitles?.page01_title}
									</Link>
								</li>
								<li>
									<span className="mx-2 text-neutral-500 dark:text-neutral-400">
										/
									</span>
								</li>
								<li className="text-neutral-500 dark:text-neutral-400">
									{pageTitles?.page02_title}
								</li>
							</>
						)}
					</ol>
				</nav>
			)}
			{!showBreadCrumbs && (
				<h2 className="dark:text-white text-black text-[22px] font-bold leading-7">
					{pageTitle}
				</h2>
			)}
		</>
	);
};
export default BreadcrumbsComponent;
