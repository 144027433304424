import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PublicRoute(props) {
	const { component } = props;
	const userAuthData = useSelector((state) => state.auth);

	let redirectPath =
		userAuthData?.userDetails?.is_superuser ||
		userAuthData?.userDetails?.is_companyadmin
			? '/admin-panel'
			: '/dashboard';

	return userAuthData?.userDetails ? (
		<Navigate to={redirectPath} />
	) : (
		component
	);
}

export default PublicRoute;
