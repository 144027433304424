import React from 'react';

import ProgressBar from '../../component/ui-component/ProgressBar';
import Xp2 from '../../assets/images/xp2.png';
import Toggle from '../../assets/images/dots.png';

function EarningMultiprogressbar(props) {
	return (
		<>
			<div className="relative mr-[37px] w-[223px] rounded-[15px] overflow-hidden pt-9 px-[15px] pb-4 dark:bg-[#17243E] bg-[#F7F7F7] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-none inline-block ">
				<span className="absolute text-[#fff] top-5 right-5 cursor-pointer">
					<img src={Toggle} alt="dot" className="dot-toggle" />
				</span>
				<div className="relative z-[99] flex items-center justify-center mb-[12px] flex-col">
					<img src={Xp2} alt="Xp2" />
					<h2 className="text-[40px] font-bold leading-[45px] mt-[15px] dark:text-[#fff] text-[#0C1932]">
						x{props.number}
					</h2>
				</div>
				<h3 className="relative dark:text-white text-[#54565C] mb-[27px] text-xs not-italic font-semibold leading-4;">
					{props.name}
				</h3>

				<div className="relative z-[99] flex items-start">
					<img src={Xp2} alt="Xp2" className="h-[24px] mt-1" />
					<div className="w-full pl-3 pt-2 height-progressbar">
						<ProgressBar
							mainColor="#1F4466"
							progress={props.progress}
							shadow1="#2E6FAA"
							shadow2="#074675"
							shadow3="rgb(52, 113, 192)"
							bgColor="#3471c0"
							bgColor2="rgb(195,213,236)"
							bgColor3="rgb(255,255,255)"
							color1="#1ffbff"
							color2="#009eef"
						/>

						<div className="relative z-[99] flex items-center justify-between py-[10px]">
							<p className="text-sm leading-[18px] font-family-OpenSans dark:text-[#fff] text-[#0C1932]">
								XP 87%
							</p>
							<span className="text-right text-sm leading-[18px] font-family-OpenSans dark:text-[#fff] text-[#0C1932]">
								100%
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default EarningMultiprogressbar;
