import React, { useEffect, useState } from 'react';
import InputComponent from '../../component/ui-component/InputComponent';
import DatePickerComponent from '../../component/ui-component/DatePickerComponent';
import Button from '../../component/ui-component/Button';
import SelectComponent from '../../component/ui-component/SelectComponent';
import MultiSelectComponent from '../../component/ui-component/MultiSelectComponent';
import CKEditorComponent from '../../component/ui-component/CKEditorComponent';
import FileComponent from '../../component/ui-component/FileComponent';
import { vsmProjects } from '../../utils/validations';
import { useDispatch, useSelector } from 'react-redux';
import { getUserList } from '../../store/thunk/userThunk';
import { getClientList } from '../../store/thunk/projectsThunk';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	setValue,
	projectDescriptions,
	selectedPriority,
	selectedClient,
	selectedStatus,
	selectedTeamLeader,
	selectedTeamMembers,
	setSelectedFile,
}) => {
	const priorityList = [
		{ id: 1, name: 'Low' },
		{ id: 2, name: 'Medium' },
		{ id: 3, name: 'High' },
	];

	const statusList = [
		{ id: 1, name: 'In Progress' },
		{ id: 2, name: 'Completed' },
		{ id: 3, name: 'On Hold' },
	];

	const dispatch = useDispatch();
	const userList = useSelector((state) => state.user.userList);
	const clientList = useSelector((state) => state.projects.clientList);
	const [isMultiSelectValid, setIsMultiSelectValid] = useState(
		selectedTeamMembers && selectedTeamMembers.length > 0 ? true : false
	);
	const [error, setError] = useState(false);

	useEffect(() => {
		dispatch(getUserList());
		dispatch(getClientList());
	}, [dispatch]);

	const onChangeSelectValue = (key, selectedValue) => {
		setValue(key, selectedValue);
	};

	const onChangeMultiSelectValue = (key, selectedValue) => {
		let team_members_ids = [];
		selectedValue.map((item) =>
			team_members_ids.push(parseInt(item.value))
		);
		setValue(key, team_members_ids);
		setIsMultiSelectValid(selectedValue.length > 0);
	};

	const handleEditorChange = (id, content) => {
		setValue(id, content);
	};

	const handleFileChange = (file) => {
		setSelectedFile([...file.target.files]);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (!isMultiSelectValid) {
			setError(true);
			return;
		} else {
			setError(false);
		}
		// Proceed with form submission
		handleSubmit(onSubmit)(e);
	};

	return (
		<form onSubmit={handleFormSubmit} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
							Project Information
						</h3>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
						<InputComponent
							id="project_name"
							title="Project Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmProjects}
						/>
						<InputComponent
							id="project_type"
							title="Project Type"
							// placeholder={"e.g., Development, Design, Consulting"}
							errors={errors}
							register={register}
							fieldvalidation={vsmProjects}
						/>
						<SelectComponent
							selectOptionLists={clientList}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedClient}
							title="Client"
							id="client"
							errors={errors}
							register={register}
							fieldvalidation={vsmProjects}
						/>
						<InputComponent
							id="budget"
							title="Budget"
							errors={errors}
							register={register}
							fieldvalidation={vsmProjects}
						/>
						<SelectComponent
							selectOptionLists={priorityList}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedPriority}
							title="Priority"
							id="priority"
							errors={errors}
							register={register}
							fieldvalidation={vsmProjects}
						/>
						<DatePickerComponent
							id="start_date"
							title="Project Start Date"
							errors={errors}
							register={register}
							fieldvalidation={vsmProjects}
						/>
						<DatePickerComponent
							id="end_date"
							title="Project End Date"
							errors={errors}
							register={register}
							fieldvalidation={vsmProjects}
						/>
						{!isUpdate && (
							<SelectComponent
								selectOptionLists={userList}
								onChangeSelectValue={onChangeSelectValue}
								selectedValue={selectedTeamLeader}
								title="Project Team Leader"
								id="team_leader"
								errors={errors}
								register={register}
								fieldvalidation={vsmProjects}
							/>
						)}
						{isUpdate && (
							<>
								<SelectComponent
									selectOptionLists={statusList}
									onChangeSelectValue={onChangeSelectValue}
									selectedValue={selectedStatus}
									title="Status"
									id="status"
									errors={errors}
									register={register}
									fieldvalidation={vsmProjects}
								/>
							</>
						)}
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-1 gap-2 mt-[15px] pb-2 textareaComponent">
						{isUpdate && (
							<SelectComponent
								selectOptionLists={userList}
								onChangeSelectValue={onChangeSelectValue}
								selectedValue={selectedTeamLeader}
								title="Project Team Leader"
								id="team_leader"
								errors={errors}
								register={register}
								fieldvalidation={vsmProjects}
							/>
						)}
						<MultiSelectComponent
							selectOptionLists={userList}
							onChangeSelectValue={onChangeMultiSelectValue}
							selectedValue={selectedTeamMembers}
							title="Project Team Members"
							id="team_members"
							error={error}
						/>
						<CKEditorComponent
							id="project_descriptions"
							title="Project Description"
							data={projectDescriptions}
							onChange={handleEditorChange}
						/>
						<FileComponent
							id="attachments"
							title="Attachments"
							register={register}
							fieldvalidation={vsmProjects}
							errors={errors}
							onChange={handleFileChange}
						/>
					</div>
				</div>
			</div>
			<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<Button
					buttonText={`${isUpdate ? 'Update' : 'Create'} Project`}
					linkTo="#"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default FormComponent;
