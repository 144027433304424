import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	myleavesList: [],
	leavesList: [],
	isFetching: false,
	totalPages: 0,
	totalRecords: 0,
};

const leavesSlice = createSlice({
	name: 'leaves',
	initialState,
	reducers: {
		setLeavesData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			leavesList: action.payload?.results,
		}),
		setMyLeavesData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			myleavesList: action.payload?.results,
		}),
		setFetching: (state, action) => ({
			...state,
			isFetching: action.payload,
			leavesList: [],
		}),
	},
});

export const { setLeavesData, setFetching, setMyLeavesData } =
	leavesSlice.actions;
export default leavesSlice.reducer;
