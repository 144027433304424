import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddRecruitmentComponent from './AddRecruitmentComponent';
import EditRecruitmentComponent from './EditRecruitmentComponent';
import ViewRecruitmentComponent from './ViewRecruitmentComponent';
import Dialogpopup from '../../../component/ui-component/Dailogpopup';
import { hasPermission, getDefaultProfile } from '../../../component/Common';
import UserProfile from '../../../component/ui-component/UserProfile';
import { getUserPermissions } from '../../../store/thunk/userThunk';
import {
	getInterviewList,
	updateRecruitmentOrder,
} from '../../../store/thunk/jobsThunk';
import {
	getInterviewListStatusWise,
	formatDate,
} from '../../../component/Common';
import { removeInterview } from '../../../store/thunk/jobsThunk';

const RecruitmentComponent = () => {
	const dispatch = useDispatch();
	const [addDrawerVisible, setAddDrawerVisible] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [viewDailogVisible, setViewDailogVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);
	const [interviewData, setInterviewData] = useState([]);
	const authUser = useSelector((state) => state.auth);
	const user_permissions = useSelector((state) => state.user.userPermissions);
	const interview_application = useSelector(
		(state) => state.jobs.interviewList
	);
	const handleSetEditValues = (item) => {
		setEditValues(item);
		onClick(item);
		setEditDrawerVisible(true);
	};
	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
		onClick(item);
	};
	const handleViewClick = (item) => {
		setEditValues(item);
		setViewDailogVisible(true);
	};
	useEffect(() => {
		dispatch(getUserPermissions());
		dispatch(getInterviewList());
	}, [dispatch]);
	useEffect(() => {
		let interview_data = getInterviewListStatusWise(interview_application);
		// if (interview_application.length > 0) {
		// 	interview_data = getInterviewListStatusWise(interview_application);
		// }
		setInterviewData(interview_data);
	}, [interview_application]);
	const [open, setOpen] = useState('');
	const onClick = (id) => {
		if (open) {
			setOpen('');
		} else {
			setOpen(id);
		}
	};
	const onDragEnd = (result) => {
		if (!result.destination) {
			return; // Dragged outside of a valid drop target
		}
		const newInterviewData = [...interviewData];
		const sourceColumnIndex = result.source.droppableId;
		const destinationColumnIndex = result.destination.droppableId;
		if (sourceColumnIndex === destinationColumnIndex) {
			// Reordering within the same column
			const sourceColumn = newInterviewData.find(
				(column) => column.status === sourceColumnIndex
			);
			const [movedTask] = sourceColumn.interviews.splice(
				result.source.index,
				1
			);
			sourceColumn.interviews.splice(
				result.destination.index,
				0,
				movedTask
			);
		} else {
			// Moving from one column to another
			const sourceColumn = newInterviewData.find(
				(column) => column.status === sourceColumnIndex
			);
			const destinationColumn = newInterviewData.find(
				(column) => column.status === destinationColumnIndex
			);
			const [movedTask] = sourceColumn.interviews.splice(
				result.source.index,
				1
			);
			destinationColumn.interviews.splice(
				result.destination.index,
				0,
				movedTask
			);
		}
		setInterviewData(newInterviewData);
		const orderRecruitmentOrder = newInterviewData.map((item) => {
			return {
				status: item.status,
				interviews: item.interviews.map((interview) =>
					interview.id.toString()
				),
			};
		});
		dispatch(
			updateRecruitmentOrder({ recruitment_order: orderRecruitmentOrder })
		);
	};
	return (
		<>
			<div className="p-[20px]">
				<div className="flex items-center justify-between md:py-5 py-0">
					<h2 className="dark:text-white text-[#4b5563] md:text-[22px] text-[18px] font-bold leading-7">
						Application Tracker
					</h2>
					{hasPermission(
						authUser?.userDetails,
						user_permissions,
						'add_interview'
					) && (
							<button
								className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
								onClick={() => setAddDrawerVisible(true)}
							>
								+ Create Applicant
							</button>
						)}
				</div>
				<div className="mb-[20px] md:p-4 p-3 mt-6 lg:mt-0 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
					<div className="block md:flex gap-6 overflow-x-auto top-scroll pb-[10px]">
						<DragDropContext onDragEnd={onDragEnd}>
							{Object.keys(interviewData).map((statusKey) => {
								const { status, interviews } =
									interviewData[statusKey];
								return (
									<Droppable
										key={status}
										droppableId={status}
									>
										{(provided, snapshot) => (
											<div
												{...provided.droppableProps}
												ref={provided.innerRef}
												className={`w-[325px] min-w-[325px] dark:bg-[#202D47] bg-[#f2f2f2] rounded-[5px] ${snapshot.isDraggingOver
													? 'border-2 border-[#38a169]'
													: ''
													}`}
											>
												<div
													className={`dark:bg-[#0f1a32] bg-[#f2f2f2] border border-[#22d3ee] flex items-center justify-between p-2.5 dark:text-[#fff] text-[#000]`}
												>
													<span className="status-title font-bold">
														{status}
													</span>
												</div>
												<div className="kanban-wrap p-2.5 pb-0">
													{interviews.map(
														(task, taskIndex) => (
															<Draggable
																key={task.id}
																draggableId={String(
																	task.id
																)}
																index={
																	taskIndex
																}
															>
																{(provided) => (
																	<div
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		ref={
																			provided.innerRef
																		}
																		style={{
																			userSelect:
																				'none',
																			...provided
																				.draggableProps
																				.style,
																		}}
																	>
																		<div className="card-panel mb-2.5 rounded-[5px] dark:bg-[#0f1a32] bg-[#fafafa]">
																			<div className="task-board-header flex items-center justify-between dark:text-white text-[#4b5563] p-2.5">
																				<span className="task-info-cont dark:text-white text-[#4b5563] text-left">
																					<span className="status-title flex">
																						{
																							task
																								?.candidate
																								?.full_name
																						}
																					</span>
																				</span>
																				<div
																					className="relative list-item-block cursor-pointer"
																					style={{
																						marginLeft:
																							'0px',
																					}}
																				>
																					<i
																						onClick={() =>
																							onClick(
																								task.id
																							)
																						}
																						className="fa-solid fa-ellipsis-h w-[25px]"
																					></i>
																					{open ===
																						task.id ? (
																						<div
																							className="z-[99999] mt-2 rounded-md shadow-lg absolute w-[100px] right-0 text-white"
																							style={{
																								zIndex: '99 !important',
																							}}
																						>
																							<div
																								onClick={() =>
																									onClick(
																										task.id
																									)
																								}
																								className="overlay fixed w-full h-full left-0 top-0"
																							/>
																							<div className=" relative z-[9] overlay-dropdown">
																								{hasPermission(
																									authUser?.userDetails,
																									user_permissions,
																									'change_interview'
																								) && (
																										<div
																											className="block px-4 py-1 text-[13px] text-left"
																											onClick={() =>
																												handleSetEditValues(
																													task
																												)
																											}
																										>
																											Edit
																										</div>
																									)}
																								{hasPermission(
																									authUser?.userDetails,
																									user_permissions,
																									'delete_interview'
																								) && (
																										<div
																											className="block px-4 py-1 text-[13px] text-left"
																											onClick={() =>
																												handleDeleteClick(
																													task
																												)
																											}
																										>
																											Delete
																										</div>
																									)}
																								<div
																									className="block px-4 py-1 text-[13px] text-left"
																									onClick={() =>
																										handleViewClick(
																											task
																										)
																									}
																								>
																									View
																								</div>
																							</div>
																						</div>
																					) : (
																						''
																					)}
																				</div>
																			</div>
																			<div className="kanban-footer flex justify-between px-2.5">
																				<span className="task-info-cont dark:text-white text-[#4b5563] text-left">
																					<span className="status-title flex underline underline-offset-4">
																						{
																							task
																								?.candidate
																								?.email
																						}
																					</span>
																					<span className="status-title flex">
																						{`Applied for :- ${task?.job?.title}`}
																					</span>
																				</span>
																			</div>
																			<div className="kanban-footer flex justify-between p-2.5">
																				<span className="task-info-cont dark:text-white text-[#4b5563] text-left">
																					<span className="task-date block text-[14px] mt-1">
																						<i className="fa-regular fa-clock"></i>{' '}
																						{`${formatDate(
																							task?.interview_date,
																							'MMM DD'
																						)}, ${task?.from_time ||
																						''
																							}`}
																					</span>
																				</span>
																				<span className="task-info-cont dark:text-white text-[#4b5563] text-left">
																					<ul className="team-members flex">
																						{task?.assigned_interviewers &&
																							task?.assigned_interviewers.map(
																								(
																									tuser,
																									tindex
																								) => (
																									<li
																										key={
																											tindex
																										}
																										className="mr-[-15px]"
																									>
																										<a
																											href={`/users/${tuser?.id}`}
																											target="__blank"
																											className="hover:cursor-pointer"
																										>
																											<UserProfile
																												imageUrl={
																													tuser?.profile_image_url
																												}
																												defaultImageUrl={getDefaultProfile(
																													tuser?.gender
																												)}
																												altName={
																													tuser?.fullname
																												}
																												className={
																													'h-[30px] w-[30px] mx-0 rounded-full bg-white border border-slate-300 p-0.5'
																												}
																											/>
																										</a>
																									</li>
																								)
																							)}
																					</ul>
																				</span>
																				<span className="task-info-cont dark:text-white text-[#4b5563] text-right mt-[2px]">
																					<span className="task-date block text-[14px]">
																						<div className="flex font-semibold">
																							<button
																								className={`inline-flex items-center gap-[5px] ${task?.status_interview
																									? 'bg-lime-800'
																									: 'bg-rose-800'
																									} text-white font-extrabold md:text-[14px] lg:text-[14px] text-[11px] px-4 py-0.5 rounded-[50px]`}
																								title="Interview Status"
																							>
																								{task?.status_interview
																									? 'Open'
																									: 'Close'}
																							</button>
																						</div>
																					</span>
																				</span>
																			</div>
																		</div>
																	</div>
																)}
															</Draggable>
														)
													)}
												</div>
											</div>
										)}
									</Droppable>
								);
							})}
						</DragDropContext>
					</div>
				</div>
			</div>
			{addDrawerVisible && (
				<AddRecruitmentComponent
					visible={addDrawerVisible}
					onClose={() => setAddDrawerVisible(false)}
				/>
			)}
			{editDrawerVisible && editValues && (
				<EditRecruitmentComponent
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
				/>
			)}
			{deleteDailogVisible && (
				<>
					<Dialogpopup
						onCancel={() => setDeleteDailogVisible(false)}
						deleteItem={editValues}
						removeThunkCall={removeInterview}
						message="Are you sure you want to delete this Interviews ?"
					/>
				</>
			)}
			{viewDailogVisible && editValues && (
				<ViewRecruitmentComponent
					visible={viewDailogVisible}
					onClose={() => setViewDailogVisible(false)}
					editValues={editValues}
				/>
			)}
		</>
	);
};

export default RecruitmentComponent;
