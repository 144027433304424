import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	notificationSuccess,
	notificationFail,
} from '../slices/notificationSlice';
import { setLoading } from '../slices/dataLoadingSlice';
import {
	setDepartmentData,
	setFetching,
	setXPEarningData,
} from '../slices/departmentSlice';

import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';
import { vsmDepartment, vsmXPEarning } from '../../utils/validations';

export const getDepartmentList = createAsyncThunk(
	'getDepartmentList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageNo, pageSize, searchText, sortBy } = _request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';

				if (sortField !== 'active' && sortField !== 'action') {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';

			const response = await apiClient(false).get(
				`/accounts/departments/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setDepartmentData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addDepartment = createAsyncThunk(
	'addDepartment',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().post(
				`/accounts/departments/`,
				_request
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getDepartmentList());
				dispatch(notificationSuccess(vsmDepartment?.addSucces));
				toast.success(vsmDepartment?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const editDepartment = createAsyncThunk(
	'editDepartment',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().patch(
				`/accounts/departments/${_request.id}/`,
				_request
			);

			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getDepartmentList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmDepartment?.updateSuccess);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeDepartment = createAsyncThunk(
	'removeDepartment',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(
				`/accounts/departments/${_request.id}/`
			);
			dispatch(setLoading(false));
			dispatch(getDepartmentList());
			dispatch(notificationSuccess(vsmDepartment?.deleteSuccess));
			toast.success(vsmDepartment?.deleteSuccess);
			// _request.callbackFunction();
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const getXPEarningList = createAsyncThunk(
	'getXPEarningList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageNo, pageSize, searchText, sortBy } = _request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';

				if (sortField !== 'active' && sortField !== 'action') {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';

			const response = await apiClient(false).get(
				`/accounts/xpearning/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setXPEarningData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addXPEarning = createAsyncThunk(
	'addXPEarning',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().post(
				`/accounts/xpearning/`,
				_request
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getXPEarningList());
				dispatch(notificationSuccess(vsmXPEarning?.addSucces));
				toast.success(vsmXPEarning?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const editXPEarning = createAsyncThunk(
	'editXPEarning',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().patch(
				`/accounts/xpearning/${_request.id}/`,
				_request
			);

			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getXPEarningList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmXPEarning?.updateSuccess);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeXPEarning = createAsyncThunk(
	'removeXPEarning',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(
				`/accounts/xpearning/${_request.id}/`
			);
			dispatch(setLoading(false));
			dispatch(getXPEarningList());
			dispatch(notificationSuccess(vsmXPEarning?.deleteSuccess));
			toast.success(vsmXPEarning?.deleteSuccess);
			// _request.callbackFunction();
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);
