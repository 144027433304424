import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetpassword } from '../store/thunk/authThunk';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import LoginUser from '../assets/images/login-user.png';
import Maskgroup from '../assets/images/Mask-group.png';
import KanhasoftLogo from '../assets/images/GAMECAN-logo.png';
import LoginBg from '../assets/images/modern-futuristic-login-bg.png';
import { useState } from 'react';

const ResetPassword = () => {
	const [showpassword, isShowpassword] = useState(false);
	const [showcpassword, isShowcpassword] = useState(false);
	const showPasswordbtn = (type) => {
		if (type === 'password') {
			isShowpassword(!showpassword);
		} else {
			isShowcpassword(!showcpassword);
		}
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loading = useSelector((state) => state.dataLoading.loading);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({});

	const { uid, token } = useParams();

	const onSubmit = (data) => {
		data.uid = uid;
		data.token = token;
		let request = {
			...data,
			navigate: navigate,
		};
		dispatch(resetpassword(request));
	};

	return (
		<>
			<div className="bg-primary ">
				<div className="bg-blue  p-10 min-h-screen items-center grid grid-cols-2 flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1">
					<div className="rounded-r-2xl p-4 py-6 relative md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly h-full">
						<img
							src={KanhasoftLogo}
							alt="GAMECANLogo"
							className="z-10  absolute top-8 left-8"
						/>
						<img
							src={LoginUser}
							alt="LoginUser"
							className="z-10 w-52 "
						/>
						<img
							src={LoginBg}
							alt="LoginBg"
							className="absolute w-full h-full z-0"
						/>
					</div>
					<div className="py-5 px-20 md:flex-1 relative h-full w-full">
						<div className="flex items-center h-full justify-center w-full">
							<div className=" px-2 z-10 w-full">
								<h3 className="my-4 text-3xl font-bold text-white font-family-Montserrat-bold">
									Reset Password
								</h3>
								<form
									className="flex flex-col space-y-5"
									onSubmit={handleSubmit(onSubmit)}
									noValidate
								>
									<div className="flex flex-col space-y-1">
										<div className="flex items-center justify-between">
											<label
												htmlFor="Reset Password"
												className="text-sm color-gray mb-4 font-family-OpenSans"
											>
												Password
											</label>
										</div>
										<div className="relative">
											<input
												type={
													showpassword
														? 'text'
														: 'password'
												}
												id="password"
												autoFocus
												className="px-4 py-2 w-full h-12 transition duration-300  rounded focus:border-transparent focus:outline-none bg-blue text-white"
												{...register('password', {
													required:
														'Password required!',
												})}
											/>
											<i
												className={`far ${
													showpassword
														? 'fa-eye'
														: 'fa-eye-slash'
												} absolute right-3 top-3 text-[#58688C] cursor-pointer`}
												onClick={() =>
													showPasswordbtn('password')
												}
											></i>
										</div>
										{errors?.password && (
											<p
												style={{
													color: 'red',
													fontSize: '13px',
													textAlign: 'left',
												}}
											>
												{errors?.password?.message}
											</p>
										)}
									</div>
									<div className="flex flex-col space-y-1">
										<div className="flex items-center justify-between">
											<label
												htmlFor="Reset Password"
												className="text-sm color-gray mb-4 font-family-OpenSans"
											>
												Confirm Password
											</label>
										</div>
										<div className="relative">
											<input
												type={
													showcpassword
														? 'text'
														: 'password'
												}
												id="confirm_password"
												autoFocus
												className="px-4 py-2 w-full h-12 transition duration-300  rounded focus:border-transparent focus:outline-none bg-blue text-white"
												{...register(
													'confirm_password',
													{
														required:
															'Confirm Password required!',
													}
												)}
											/>
											<i
												className={`far ${
													showcpassword
														? 'fa-eye'
														: 'fa-eye-slash'
												} absolute right-3 top-3 text-[#58688C] cursor-pointer`}
												onClick={() =>
													showPasswordbtn('cpassword')
												}
											></i>
										</div>
										{errors?.confirm_password && (
											<p
												style={{
													color: 'red',
													fontSize: '13px',
													textAlign: 'left',
												}}
											>
												{
													errors?.confirm_password
														?.message
												}
											</p>
										)}
									</div>
									<div>
										<button
											type="submit"
											disabled={loading ? true : false}
											className="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4 bg-button-gradiant "
										>
											{loading && (
												<svg
													aria-hidden="true"
													role="status"
													className="inline w-4 h-4 mr-3 text-white animate-spin"
													viewBox="0 0 100 101"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
														fill="#E5E7EB"
													/>
													<path
														d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
														fill="currentColor"
													/>
												</svg>
											)}
											{loading
												? 'Loading'
												: 'Reset Password'}
										</button>
									</div>
								</form>
							</div>
							<img
								src={Maskgroup}
								alt="Maskgroup"
								className="absolute w-full h-full z-0 top-0 left-0 z-0 "
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResetPassword;
