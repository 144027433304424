import { Draggable } from 'react-beautiful-dnd';

function DraggableRows({ page, prepareRow, rowOrder }) {
	const orderedRows = rowOrder.map((rowId) =>
		page.find((row) => row?.original?.id.toString() === rowId)
	);
	return (
		<>
			{orderedRows.map((row, i) => {
				if (row) {
					prepareRow(row);
					const firstCellContent = row.cells[0].render('Cell');

					return (
						<Draggable
							key={row.id}
							draggableId={row.id.toString()}
							index={i}
						>
							{(provided) => (
								<tr
									key={`tbr_${i}`}
									className="dark:shadow-md dark:hover:shadow-[#082f49] hover:cursor-pointer shadow hover:shadow-lg border-b border-solid border-[#ddd] dark:border-[#2e4062]  w-full flex items-center  h-[51.49px] px-2.5 py-0 justify-between"
									ref={provided.innerRef}
									{...row.getRowProps()}
									{...provided.draggableProps}
								>
									{/* Make the first cell draggable */}
									<td
										style={{
											textAlign: 'left',
											width: row.cells[0].column.width,
										}}
										key={`tb_td_0`}
										className="text-[16px]  dark:text-white text-black"
									>
										{/* Apply draggable properties to the element */}
										<div {...provided.dragHandleProps}>
											{firstCellContent}
										</div>
									</td>

									{/* Render the rest of the cells */}
									{row.cells
										.slice(1)
										.map((cell, cellIndex) => (
											<td
												style={{
													textAlign: 'left',
													width: cell.column.width,
												}}
												key={`tb_td_${cellIndex + 1}`}
												className={`${
													row?.original?.isSelected &&
													cellIndex === 1
														? 'ShowLine'
														: ''
												} text-[16px]  dark:text-white text-black`}
												{...cell.getCellProps()}
											>
												{cell.render('Cell')}
											</td>
										))}
								</tr>
							)}
						</Draggable>
					);
				}
				return null;
			})}
		</>
	);
}

export default DraggableRows;
