import React from 'react';
import { Select, Option } from '@material-tailwind/react';
import Ellipse25 from '../../assets/images/Ellipse-25.png';
import Ellipse26 from '../../assets/images/Ellipse-26.png';
import Ellipse27 from '../../assets/images/Ellipse-27.png';
import Xp2 from '../../assets/images/xp2.png';
import { useSelector } from 'react-redux';

function Submission() {
	const authUser = useSelector((state) => state.auth);

	const SubmittedQuests = [
		{
			no: '01',
			label: 'Santoshsmith - 5 page figma design...',
			month: 'July 5',
			className: 'text-[#00DD73]',
			Questsimages: [Ellipse25],
		},
		{
			no: '02',
			label: 'Santoshsmith - 5 page figma design...',
			month: 'July 5',
			className: 'text-[#00DD73]',
			Questsimages: [Ellipse25, Ellipse27],
		},
		{
			no: '03',
			label: 'Santoshsmith - 5 page figma design...',
			month: 'July 1',
			className: 'text-[#D00]',
			Questsimages: [Ellipse25, Ellipse26, Ellipse27],
		},
		{
			no: '04',
			label: 'Santoshsmith - 5 page figma design...',
			month: 'July 5',
			className: 'text-[#00DD73]',
			Questsimages: [Ellipse25, Ellipse26, Ellipse27],
		},
		{
			no: '05',
			label: 'Santoshsmith - 5 page figma design...',
			month: 'July 10',
			className: 'text-[#00DD73]',
			Questsimages: [Ellipse25, Ellipse26, Ellipse27],
		},
		{
			no: '06',
			label: 'Santoshsmith - 5 page figma design...',
			month: 'July 11',
			className: 'text-[#00DD73]',
			Questsimages: [Ellipse25, Ellipse26, Ellipse27],
		},
	];

	const gotquest = [
		{
			label: 'XP Level 1',
		},
		{
			label: 'XP Level 2',
		},
		{
			label: 'XP Level 3',
		},
		{
			label: 'XP Level 4',
		},
	];

	return (
		<>
			<div className="md:my-[38px] my-[20px] mx-[20px] relative">
				<div className="content text-left bg-[linear-gradient(323deg,#5438FF_0%,#3888FF_100%)] rounded-[15px] py-[41px] pl-[31px]">
					<h1 className="text-white text-4xl font-family-Montserrat-bold pb-[10px]">
						Hey,
						{` ${authUser?.userDetails?.first_name} ${authUser?.userDetails?.last_name}`}
					</h1>
					<p className="text-white text-2xl font-family-OpenSans">
						Your quest board has 3 quest in review! Got more to
						submit?
					</p>
				</div>
			</div>
			<div className="md:grid block grid-cols-12 mx-[20px] gap-[30px]">
				<div className="col-span-8 submitted-quests">
					<div className="mb-[40px]">
						<div className="flex justify-between">
							<h2 className="text-[22px] dark:text-white text-[#4b5563] font-bold leading-7">
								Submitted Quests
							</h2>
							<div className="selectbox submitted-quests">
								<Select variant="outlined" label="Filter All">
									<Option>HTML</Option>
									<Option>React</Option>
									<Option>Vue</Option>
									<Option>Angular</Option>
									<Option>Svelte</Option>
								</Select>
							</div>
						</div>
						<div className="dark-dark-light-card pt-[28px] pb-[65px] px-[20px] mt-[20px]">
							<div className="title flex flex-wrap pb-[10px]">
								<h6 className="name w-[70%] text-xs not-italic font-normal leading-4 dark:text-[#BFC6D2] text-[#54565C] text-left">
									Task Name
								</h6>
								<h6 className="date w-[15%] text-xs not-italic font-normal leading-4 dark:text-[#BFC6D2] text-[#54565C] text-center">
									Due Date
								</h6>
								<h6 className="collaborators w-[15%] text-xs not-italic font-normal leading-4 dark:text-[#BFC6D2] text-[#54565C] text-center">
									Collaborators
								</h6>
							</div>
							<div className="content">
								{SubmittedQuests.map((event, index) => (
									<div
										key={index}
										className="submittedQuests-row flex flex-wrap py-[15px] border-b border-solid dark:border-b-[#213252]"
									>
										<h3 className="text-base dark:text-[#fff] text-[#0C1932] font-semibold leading-[21px] text-left w-[70%]">
											<span className="number mr-[10px] text-sm font-bold leading-[18px] dark:text-[#31446A] text-[#BFC6D2]">
												{event.no}
											</span>
											{event.label}
										</h3>
										<span
											className={`date text-center w-[15%] text-sm font-semibold leading-[18px] ${event.className}`}
										>
											{event.month}
										</span>
										<div className="w-[15%]">
											<ul className="flex flex-wrap justify-center">
												{event.Questsimages.map(
													(image, imageIndex) => (
														<li
															key={imageIndex}
															className="h-[24px] w-[24px] rounded-full overflow-hidden -ml-2"
														>
															<img
																src={image}
																alt={`img${
																	imageIndex +
																	1
																}`}
																className="object-cover"
															/>
														</li>
													)
												)}
											</ul>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<div>
						<h2 className="text-[22px] dark:text-white text-[#4b5563] mb-[25px] font-bold leading-7 text-left">
							Got a Quest To Submit?
						</h2>
						<div className="dark-dark-light-card py-[28px] pt-[28px] pr-[10px] pl-[26px]">
							<div className="custom-scroll overflow-y-auto h-[250px]">
								<div className="pr-3 -mt-[22px]">
									{gotquest.map((index, i) => (
										<div
											key={i}
											className="flex flex-wrap justify-between items-start py-[26px] border-b dark:border-b-[#213252] border-b-[#E6E6E6]"
										>
											<div className="flex flex-wrap items-center">
												<span className="h-[50px] w-[50px] rounded-full bg-[#6F00FD] flex items-center justify-center mr-[15px]">
													<img
														src={Xp2}
														alt="img"
														className="max-w-[30px]"
													/>
												</span>
												<h3 className="text-[19px] not-italic font-semibold leading-[25px] dark:text-white text-[#0C1932]">
													{index.label}
												</h3>
											</div>

											<button className="expert-btn  text-sm leading-[18px] font-semibold text-white rounded-lg py-[14px] px-[24px]">
												Submit
											</button>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-span-4">
					<h2 className="text-[22px] dark:text-white text-[#4b5563] font-bold leading-7 text-left mb-[20px] md:mt-0 mt-[20px]">
						Quest Submission
					</h2>
					<div className="dark-dark-light-card text-left p-[20px] lg:p-[27px] h-[calc(100%_-_62px)] flex flex-col justify-between">
						<div>
							<h3 className="lg:text-[19px] text-[15px] font-semibold leading-[25px] dark:text-[#fff] text-[#0C1932] mb-[34px]">
								Santoshsmith - 5 page figma design and
								Development
							</h3>
							<div className="flex items-center mb-[30px]">
								<span className="text-sm dark:text-[#bfc6d2] text-[#54565C] font-normal leading-[18px] mr-[45px]">
									Assignee
								</span>
								<span className="flex items-center">
									<img
										src={Ellipse26}
										className="h-[35px] w-[35px] rounded-full border border-solid border-[#ffffff] object-cover"
										alt="img"
									/>
									<strong className="text-base dark:text-[#fff] text-[#0C1932] font-semibold leading-[21px] ml-[8px]">
										Tom Mate
									</strong>
								</span>
							</div>
							<div className="flex items-center mb-[30px]">
								<span className="text-sm dark:text-[#bfc6d2] text-[#54565C] font-normal leading-[18px] mr-[45px]">
									Due Date
								</span>
								<strong className="text-base text-[#00DD73] font-semibold leading-[21px] ml-[8px]">
									June 20
								</strong>
							</div>
							<div className="description text-[15px] dark:text-[#fff] text-[#0C1932] font-normal leading-[25px]">
								<h5 className="text-sm dark:text-[#bfc6d2] text-[#54565C] mb-4 font-normal leading-[18px] mr-[45px]">
									Description
								</h5>
								<p>
									Duis diam dolor, fringilla mattis
									ullamcorper at, blandit eget dui. Sed
									feugiat viverra varius. In nec posuere nunc,
									eu efficitur risus. Etiam ut rhoncus quam.
									Maecenas feugiat fringilla pretium. Cras
									aliquam tellus quis urna commodo, aliquam
									bibendum tortor feugiat. Etiam erat augue,
									malesuada sit amet velit nec, ultricies
									mollis lacus. Phasellus aliquet mauris quis
									nulla faucibus vulputate. Donec ullamcorper
									vitae turpis et tincidunt. Integer vel
									lectus blandit massa lobortis tincidunt ac
									vel libero. Vestibulum eleifend lacus
								</p>
							</div>
							<div className="button-grp mt-6 mb-4 flex">
								<button className="dark:bg-[#213050] bg-[#F7F7F7] font-semibold border-2 border-solid border-[#E6E6E6] dark:border-[#213050] rounded-[10px] py-[10px] px-[13px] dark:text-[#BFC6D2] text-[#0C1932] mr-3">
									Attachment 1
								</button>
								<button className="dark:bg-[#213050] bg-[#F7F7F7] font-semibold border-2 border-solid border-[#E6E6E6] dark:border-[#213050] rounded-[10px] py-[10px] px-[13px] dark:text-[#BFC6D2] text-[#0C1932]">
									Attachment 2
								</button>
							</div>
						</div>
						<div>
							<form>
								<div className="flex flex-wrap items-center">
									<img
										src={Ellipse26}
										alt="img"
										className="h-[36px] w-[36px] rounded-full border border-solid border-[#ffffff] object-cover"
									/>
									<input
										type="text"
										placeholder="SUBMIT QUEST!"
										className="rounded-[8px] h-[50px] w-[calc(100%_-_50px)] ml-[14px] px-[15px] dark:text-[#8EA2CA] text-[#293650] dark:bg-[#0C162B] bg-[#F7F7F7] border-2 border-solid border-[#E6E6E6] dark:border-[#0C162B]"
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Submission;
