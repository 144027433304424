import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobsList } from '../../../store/thunk/jobsThunk';
import ReactTablePagination from '../../../component/ui-component/pagination/ReactTablePagination';
import { hasPermission } from '../../../component/Common';
import Delete from '../../../assets/images/Delete.png';
import Edit from '../../../assets/images/Edit.png';

const ListComponent = ({
	handleSetEditValues,
	handleDeleteClick,
	handleUpdatestatus,
	userData,
	userPermissions,
}) => {
	const [recordsPerPage, setRecordsPerPage] = useState(10);

	const dispatch = useDispatch();

	const jobData = useSelector((state) => state.jobs);

	const loading = useSelector((state) => state.dataLoading.loading);

	const columns = [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: 'Title',
			accessor: 'title',
			width: '15%',
			resize: true,
		},
		{
			Header: 'Description',
			accessor: 'description',
			width: '35%',
			resize: true,
			Cell: ({ row, value }) => (
				<p
					className="text-[12px] dark:text-white text-[#0C1932]"
					dangerouslySetInnerHTML={{
						__html: `${row?.original?.description.substring(
							0,
							300
						)} .....`,
					}}
				></p>
			),
		},
		{
			Header: 'Requirement',
			accessor: 'requirements',
			width: '35%',
			resize: true,
			Cell: ({ row, value }) => (
				<p
					className="text-[12px] dark:text-white text-[#0C1932]"
					dangerouslySetInnerHTML={{
						__html: `${row?.original?.requirements.substring(
							0,
							300
						)} .....`,
					}}
				></p>
			),
		},
		{
			Header: 'Active',
			accessor: 'active',
			width: '10%',
			disableSortBy: true,
			Cell: ({ row, value }) => (
				<label className="relative inline-flex items-center cursor-pointer">
					<input
						type="checkbox"
						value=""
						defaultChecked={row?.original?.is_active}
						className="sr-only peer"
						onChange={() => handleUpdatestatus(row?.original)}
						disabled={loading}
					/>
					<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
					<span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
				</label>
			),
		},
		...(hasPermission(userData, userPermissions, 'change_job', 'delete_job')
			? [
					{
						Header: 'Action',
						accessor: 'action',
						disableSortBy: true,
						Cell: ({ row, value }) => {
							return (
								<div className="flex">
									{hasPermission(
										userData,
										userPermissions,
										'change_job'
									) && (
										<div
											className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full mr-[8px] border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
											onClick={() =>
												handleSetEditValues(
													row?.original
												)
											}
											title="Edit"
										>
											<img src={Edit} alt="Edit" />
										</div>
									)}
									{hasPermission(
										userData,
										userPermissions,
										'delete_job'
									) && (
										<div
											className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
											onClick={() =>
												handleDeleteClick(row?.original)
											}
											title="Delete"
										>
											<img src={Delete} alt="Delete" />
										</div>
									)}
								</div>
							);
						},
					},
			  ]
			: []),
	];

	const fetchData = useCallback(
		(pageSize, pageIndex, searchText, sortBy) => {
			dispatch(
				getJobsList({
					pageSize: pageSize,
					pageNo: pageIndex,
					searchText: searchText,
					sortBy: sortBy,
				})
			);
		},
		[dispatch]
	);

	return (
		<>
			<ReactTablePagination
				columns={columns}
				data={jobData?.jobsList}
				fetchData={fetchData}
				pageCount={Math.ceil(jobData?.totalRecords / recordsPerPage)}
				totalRecords={jobData?.totalRecords}
				recordsPerPage={recordsPerPage}
				setRecordsPerPage={setRecordsPerPage}
				showCSVButton={hasPermission(
					userData,
					userPermissions,
					'export_job'
				)}
				csvFileName="jobs_lists.csv"
			/>
		</>
	);
};

export default ListComponent;
