import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserList } from '../../store/thunk/userThunk';
import ReactTablePagination from '../../component/ui-component/pagination/ReactTablePagination';
import Delete from '../../assets/images/Delete.png';
import Edit from '../../assets/images/Edit.png';
import View from '../../assets/images/View.png';
import { getDefaultProfile } from '../../component/Common';
import UserProfile from '../../component/ui-component/UserProfile';

import { Link } from 'react-router-dom';

const moment = require('moment');

const ListComponent = ({
	handleSetEditValues,
	handleDeleteClick,
	handleUpdatestatus,
}) => {
	const [recordsPerPage, setRecordsPerPage] = useState(10);
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.user);
	const loading = useSelector((state) => state.dataLoading.loading);

	const columns = [
		{
			Header: 'User ID',
			accessor: 'id',
			// width: 100,
			Cell: ({ row, value }) => {
				return (
					<div>
						<h6 className="mb-0 font-bold">{`${row?.original?.company_name
							.substr(0, 3)
							.toUpperCase()} - ${value}`}</h6>
					</div>
				);
			},
		},
		{
			Header: 'User Name',
			accessor: 'fullname',
			width: '20%',
			Cell: ({ row, value }) => {
				return (
					<div className="flex">
						<UserProfile
							imageUrl={row?.original?.profile_image}
							defaultImageUrl={getDefaultProfile(
								row?.original?.gender
							)}
							altName={row?.original?.fullname}
							className={
								'mt-[5px] mr-[5px] h-[45px] w-[45px] rounded-full bg-white border border-slate-300 p-0.5 float-left'
							}
						/>
						<div className="ml-2 mt-1 sm:text-[10px] md:text-[12px] lg:text-[15px]">
							<h6 className="mb-0 font-bold">{value}</h6>
							<span className="text-muted">
								{row?.original?.email}
							</span>
						</div>
					</div>
				);
			},
		},
		{
			Header: 'Phone No',
			accessor: 'phone_number',
			// width: '10%',
		},
		{
			Header: 'Role',
			accessor: 'role.title',
			// width: '15%',
		},
		{
			Header: 'Department',
			accessor: 'department.title',
			// width: '15%',
		},
		{
			Header: 'Joining Date',
			accessor: 'joining_date',
			// width: '10%',
			Cell: ({ row, value }) => {
				return (
					<>{moment(value, 'YYYY-MM-DD').format('DD MMM, YYYY')}</>
				);
			},
		},

		{
			Header: 'Job Type',
			accessor: 'job_type_name',
			// width: '10%',
			Cell: ({ row, value }) => {
				let bgBackground = '';
				if (row?.original?.job_type === 1) {
					bgBackground = 'bg-green';
				} else if (row?.original?.job_type === 2) {
					bgBackground = 'bg-cyan';
				}

				return (
					<div className="flex font-semibold">
						<button
							className={`inline-flex items-center gap-[5px] ${bgBackground} text-white font-extrabold md:text-[14px] lg:text-[14px] text-[11px] px-3 py-1 rounded-[50px] shadow-xl dark:shadow-[#2e4062] shadow-[#cbd5e1]`}
						>
							<i className={`fa-regular fa-clock text-white`}></i>
							{row?.original?.job_type_name}
						</button>
					</div>
				);
			},
		},
		{
			Header: 'Active',
			accessor: 'active',
			// width: '10%',
			disableSortBy: true,
			Cell: ({ row, value }) => (
				<label className="relative inline-flex items-center cursor-pointer">
					<input
						type="checkbox"
						value=""
						defaultChecked={row?.original?.is_active}
						className="sr-only peer"
						onChange={() => handleUpdatestatus(row?.original)}
						disabled={loading}
					/>
					<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
					<span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
				</label>
			),
		},
		{
			Header: 'Action',
			accessor: 'action',
			// width: '10%',
			disableSortBy: true,
			Cell: ({ row, value }) => (
				<div className="flex">
					<div
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full mr-[8px] border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						onClick={() => handleSetEditValues(row?.original)}
						title="Edit"
					>
						<img src={Edit} alt="Edit" />
					</div>
					<Link // Use Link to navigate to the user details page
						to={`/users/${row?.original?.id}`} // Pass the user's ID as a route parameter
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full mr-[8px] border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						title="View"
					>
						<img src={View} alt="View" className="w-[22px]" />
					</Link>
					<div
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						onClick={() => handleDeleteClick(row?.original)}
						title="Delete"
					>
						<img src={Delete} alt="Delete" />
					</div>
				</div>
			),
		},
	];
	const fetchData = useCallback(
		(pageSize, pageIndex, searchText, sortBy) => {
			dispatch(
				getUserList({
					pageSize: pageSize,
					pageNo: pageIndex,
					searchText: searchText,
					sortBy: sortBy,
				})
			);
		},
		[dispatch]
	);

	return (
		<>
			<ReactTablePagination
				columns={columns}
				data={userData?.userList}
				fetchData={fetchData}
				pageCount={Math.ceil(userData?.totalRecords / recordsPerPage)}
				totalRecords={userData?.totalRecords}
				recordsPerPage={recordsPerPage}
				setRecordsPerPage={setRecordsPerPage}
				csvFileName="users_lists.csv"
			/>
		</>
	);
};

export default ListComponent;
