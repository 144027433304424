import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from '../../../assets/images/dots.png';
import { getProjectList } from '../../../store/thunk/projectsThunk';
import { Link } from 'react-router-dom';
import { getDefaultProfile } from '../../../component/Common';
import UserProfile from '../../../component/ui-component/UserProfile';

const moment = require('moment');

const ListProjectComponent = ({
	clientID,
	handleSetEditValues,
	handleDeleteClick,
}) => {
	const dispatch = useDispatch();
	const ref = useRef({});
	const projectData = useSelector((state) => state.projects.projectList);
	const [isOpenArray, setIsOpenArray] = useState([]);
	const toggleDiv = (index) => {
		const updatedOpenArray = [...isOpenArray];
		updatedOpenArray[index] = !updatedOpenArray[index];
		setIsOpenArray(updatedOpenArray);
	};
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				ref.current &&
				typeof ref.current.contains === 'function' &&
				!ref.current.contains(event.target)
			) {
				setIsOpenArray(projectData.map(() => false));
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [isOpenArray, projectData]);

	useEffect(() => {
		dispatch(getProjectList({ clientID: clientID }));
	}, [dispatch, clientID]);

	return (
		<>
			{projectData &&
				projectData.map((row, index) => (
					<div
						key={row.id}
						className="p-[20px] relative dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] rounded-[15px]"
					>
						<Link
							to={`/projects/${row?.id}`}
							className="text-lg project-title mb-4 font-semibold text-white transition duration-150 ease-in-out hover:text-sky-400 focus:text-sky-400"
						>
							{row.project_name}
						</Link>

						<div className="list-item-block">
							<span
								onClick={() => toggleDiv(index)}
								className="absolute text-[#fff] top-[25px] right-[15px] cursor-pointer"
							>
								<img
									src={Toggle}
									alt="dot"
									className="dot-toggle"
								/>
							</span>
							{isOpenArray[index] && (
								<div ref={ref}>
									<div
										className="menuDropdown absolute w-[160px] right-[0px] mt-[10px]"
										key={index}
										id={`toggle_${index}`}
									>
										<ul>
											<li
												className="dark:text-white text-[#47586f] text-left flex items-center"
												onClick={() =>
													handleSetEditValues(row)
												}
											>
												<div className="p-[7px] block w-full cursor-pointer">
													<i className="mr-3 fas fa-pencil text-[14px]"></i>
													Edit
												</div>
											</li>
											<li
												className="dark:text-white text-[#47586f] text-left flex items-center"
												onClick={() => {
													handleDeleteClick(row);
													toggleDiv(index);
												}}
											>
												<div className="p-[7px] block w-full cursor-pointer">
													<i className="mr-3 fas fa-trash text-[14px]"></i>
													Delete
												</div>
											</li>
										</ul>
									</div>
								</div>
							)}
						</div>

						<div className="flex">
							<small className="block text-ellipsis m-b-15 mt-2 dark:text-[#BFC6D2] text-[#54565C] float-left">
								<span className="text-xs">
									{row?.total_open_task}
								</span>{' '}
								<span className="text-muted">open tasks, </span>
								<span className="text-xs">
									{row?.total_completed_task}
								</span>{' '}
								<span className="text-muted">
									tasks completed
								</span>
							</small>
							<div className="mt-1 ml-auto">
								<span
									className="text-xs rounded-full p-1 px-4 font-extrabold block text-center"
									style={{
										background:
											'linear-gradient(137.02deg, #bd00ff 0%, #216cff 103.79%)',
									}}
								>
									{row?.project_type || ''}
								</span>
							</div>
						</div>
						<p
							className="text-muted py-2 text-[13px] dark:text-[#BFC6D2] text-[#54565C]"
							dangerouslySetInnerHTML={{
								__html: `${row?.project_descriptions.substring(
									0,
									250
								)} .....`,
							}}
						></p>
						<div className="pro-deadline mb-[15px] dark:text-[#BFC6D2] text-[#54565C]">
							<div className="sub-title text-[15px]">
								Deadline:
							</div>
							<div className="text-muted text-[15px]">
								{moment(row?.end_date, 'YYYY-MM-DD').format(
									'DD MMM, YYYY'
								)}
							</div>
						</div>
						<div className="project-members dark:text-[#BFC6D2] text-[#54565C]">
							<div className="text-[15px]">Project Leader :</div>
							<ul className="team-members">
								<li>
									<a
										href={`/users/${row?.team_leader?.id}`}
										target="__blank"
									>
										<UserProfile
											imageUrl={
												row?.team_leader
													?.profile_image_url
											}
											defaultImageUrl={getDefaultProfile(
												row?.team_leader?.gender
											)}
											altName={row?.team_leader?.fullname}
											className={
												'w-[45px] mx-0 my-2.5 rounded-full bg-white border border-slate-300 p-0.5'
											}
										/>
									</a>
								</li>
							</ul>
						</div>
						<div className="project-members m-b-15 dark:text-[#BFC6D2] text-[#54565C]">
							<div className="text-[15px]">Team :</div>
							<ul className="team-members flex">
								{row?.team_members &&
									row?.team_members.map((tuser, tindex) => (
										<li key={tindex} className="mr-[-20px]">
											<a
												href={`/users/${tuser?.id}`}
												target="__blank"
											>
												<UserProfile
													imageUrl={
														tuser?.profile_image_url
													}
													defaultImageUrl={getDefaultProfile(
														tuser?.gender
													)}
													altName={tuser?.fullname}
													className={
														'h-[45px] w-[45px] mx-0 rounded-full bg-white border border-slate-300 p-0.5 my-2.5'
													}
												/>
											</a>
										</li>
									))}
								{!row?.team_members && (
									<li className="border-2 border-solid border-white my-2.5">
										No members were added to this Projects.
									</li>
								)}
							</ul>
							<p className="m-b-5 flex items-center justify-between">
								Progress{' '}
								<span className="text-success float-end">
									40%
								</span>
							</p>
							<div className="progress progress-xs mb-0">
								<div
									className="progress-bar bg-success w-40"
									role="progressbar"
									data-bs-toggle="tooltip"
									aria-label="40%"
									data-bs-original-title="40%"
								></div>
							</div>
							<div className="flex-start flex h-1.5 w-full overflow-hidden rounded-sm bg-blue-gray-50 font-sans text-xs font-medium bg-[#101a30] mt-[10px]">
								<div
									className="flex h-full items-baseline justify-center overflow-hidden break-all bg-[#bd00ff] text-white"
									style={{ width: '50%' }}
								/>
							</div>
						</div>
					</div>
				))}
		</>
	);
};

export default ListProjectComponent;
