import React, { useState } from 'react';
import HeaderComponent from './header/HeaderComponent';

const LayoutComponent = ({ component, pageTitle }) => {
	const [isWidth, setIswidth] = useState(false);

	return (
		<div className="flex flex-wrap main-section">
			<div className={`main ml-auto ${isWidth ? 'active' : ''}`}>
				<HeaderComponent title={pageTitle} />
				<div className="lg:pt-[100px] pt-[60px] md:pt-[70px]  pb-8">
					{component}
				</div>
			</div>
		</div>
	);
};
export default LayoutComponent;
