import React from 'react';
import ClosestAchievement from './ClosestAchievement';
import MySkills from './MySkills';
import MyProfile from './MyProfile';

export default function DashboardProfile() {
	return (
		<div className="grid-cols-12 md:grid xs:block px-5 mb-[20px] lg:mb-[40px] gap-[30px]">
			<div className="col-span-8 text-white mb-[10px] md:mb-0">
				<ClosestAchievement />
				<MySkills />
			</div>
			<div className="col-span-4 text-white">
				<MyProfile />
			</div>
		</div>
	);
}
