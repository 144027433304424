import React from 'react';
import Delete from '../../assets/images/Delete.png';
import Edit from '../../assets/images/Edit.png';

const data = [
	{
		id: '01',
		skill: 'Communication',
		level: 'Level - 9',
		xp: 'XP - 900',
	},
	{
		id: '02',
		skill: 'Teamwork & Collaboration',
		level: 'Level - 8',
		xp: 'XP - 500',
	},
	{
		id: '03',
		skill: 'Creativity and Innovation',
		level: 'Level - 10',
		xp: 'XP - 600',
	},
	{
		id: '04',
		skill: 'Time Management',
		level: 'Level - 11',
		xp: 'XP - 800',
	},
	{
		id: '05',
		skill: 'Leadership & Mentoring ',
		level: 'Level - 5',
		xp: 'XP - 900',
	},
	{
		id: '06',
		skill: 'Project Management',
		level: 'Level - 5',
		xp: 'XP - 1000',
	},
	{
		id: '07',
		skill: 'Creativity & Innovation',
		level: 'Level - 9',
		xp: 'XP - 500',
	},
	{
		id: '08',
		skill: 'Analytical Skills',
		level: 'Level - 10',
		xp: 'XP - 300',
	},
];
const ContentManagement = () => {
	return (
		<div className="p-[20px]">
			<div className="flex items-center justify-between py-5">
				<h2 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7">
					Employee Skill Lists
				</h2>
				<button className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-lg p-[10px]">
					+ Create Skill Lists
				</button>
			</div>
			<div
				id="recipients"
				className="md:p-8 p-3 mt-6 lg:mt-0 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]"
			>
				<div className="overflow-auto custom-scroll pr-[12.27px]">
					<table
						id="example"
						className="stripe hover text-white"
						style={{
							width: '100%',
							paddingTop: '1em',
							paddingBottom: '1em',
						}}
					>
						<tbody className="rounded-[8px] mb-[17px] block bottom-scroll">
							{data.map((row, index) => (
								<tr
									key={row.id}
									className="h-[69.28px] border-b border-solid dark:border-[#333333] border-gray-300 w-full flex items-center justify-between"
								>
									<td className="w-[60%] text-left dark:text-white text-[#4b5563] text-[19px]">
										<span className="text-[14px] dark:text-white text-[#4b5563]">
											{row.id}.
										</span>{' '}
										{row.skill}
									</td>
									<td className="dark:text-white text-[#4b5563] text-[19px] w-[25%]">
										Level - 9 | XP - 900
									</td>
									<td className="w-[100px]">
										<div className="flex">
											<div className="flex items-center justify-center w-[39.56px] h-[39.56px] rounded-full mr-[8px] bg-[#fff] dark:bg-[#243555] border border-solid border-[#bfc6d2] dark:border-[transparent]">
												<img src={Edit} alt="Edit" />
											</div>
											<div className="flex items-center justify-center w-[39.56px] h-[39.56px] rounded-full  bg-[#fff] dark:bg-[#243555] border border-solid border-[#bfc6d2] dark:border-[transparent]">
												<img
													src={Delete}
													alt="Delete"
												/>
											</div>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};
export default ContentManagement;
