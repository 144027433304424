import React, { useState } from 'react';
import ListComponent from './ListComponent';
import Confirmpopup from '../../component/ui-component/Confirmpopup';
import ViewLeaveComponent from './ViewLeaveComponent';
import { updateLeaveStatus } from '../../store/thunk/leavesThunk';
import { useDispatch, useSelector } from 'react-redux';
import BreadcrumbsComponent from '../../component/ui-component/BreadcrumbsComponent';

const LeavesManagementComponent = () => {
	const dispatch = useDispatch();
	const [rejectLeaveDialogVisible, setRejectLeaveDialogVisible] =
		useState(false);
	const [viewDailogVisible, setViewDailogVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);
	const authUser = useSelector((state) => state.auth);

	const handleLeaveStatusChange = (leave_data, status) => {
		let assigned_user_ids = [];
		leave_data?.assigned_users.map((item) =>
			assigned_user_ids.push(parseInt(item.id))
		);

		let payload = {
			leave_status: status,
			id: leave_data?.id,
			approved_by_id: authUser?.userDetails?.id,
			user_data: authUser?.userDetails,
			assigned_users: assigned_user_ids,
		};
		if (status !== 3) {
			dispatch(updateLeaveStatus(payload));
		} else {
			setEditValues(payload);
			setRejectLeaveDialogVisible(true);
		}
	};
	const handleViewClick = (item) => {
		setEditValues(item);
		setViewDailogVisible(true);
	};
	return (
		<>
			<div className="p-[20px]">
				<div className="flex items-center justify-between px-2 py-5">
					<BreadcrumbsComponent
						showBreadCrumbs={true}
						pageTitle={'Leaves'}
					/>
				</div>
				<ListComponent
					handleLeaveStatusChange={handleLeaveStatusChange}
					handleViewClick={handleViewClick}
				/>
			</div>
			{rejectLeaveDialogVisible && (
				<>
					<Confirmpopup
						onCancel={() => setRejectLeaveDialogVisible(false)}
						editStatusItem={editValues}
						confirmThunkCall={updateLeaveStatus}
					/>
				</>
			)}
			{viewDailogVisible && (
				<ViewLeaveComponent
					visible={viewDailogVisible}
					onClose={() => setViewDailogVisible(false)}
					editValues={editValues}
				/>
			)}
		</>
	);
};
export default LeavesManagementComponent;
