import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHolidayList } from '../../store/thunk/holidaysThunk';
import ReactTablePagination from '../../component/ui-component/pagination/ReactTablePagination';
import { hasPermission } from '../../component/Common';
import Delete from '../../assets/images/Delete.png';
import Edit from '../../assets/images/Edit.png';
const moment = require('moment');

const ListComponent = ({
	handleSetEditValues,
	handleDeleteClick,
	userData,
	userPermissions,
}) => {
	const [recordsPerPage, setRecordsPerPage] = useState(10);

	const dispatch = useDispatch();

	const holidayData = useSelector((state) => state.holiday);

	const columns = [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: 'Title',
			accessor: 'title',
			width: '30%',
			resize: true,
		},
		{
			Header: 'Date',
			accessor: 'date',
			width: '30%',
			resize: true,
			Cell: ({ row, value }) => {
				return (
					<span>
						{moment(value, 'YYYY-MM-DD').format('DD MMM YYYY')}
					</span>
				);
			},
		},
		{
			Header: 'Day',
			accessor: 'day',
			width: '30%',
			resize: true,
			disableSortBy: true,
			Cell: ({ row, value }) => {
				return (
					<span>
						{moment(row?.original?.date, 'YYYY-MM-DD').format(
							'dddd'
						)}
					</span>
				);
			},
		},
		...(hasPermission(
			userData,
			userPermissions,
			'change_holidays',
			'delete_holidays'
		)
			? [
					{
						Header: 'Action',
						accessor: 'action',
						disableSortBy: true,
						Cell: ({ row, value }) => {
							return (
								<div className="flex">
									{hasPermission(
										userData,
										userPermissions,
										'change_holidays'
									) && (
										<div
											className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full mr-[8px] border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
											onClick={() =>
												handleSetEditValues(
													row?.original
												)
											}
											title="Edit"
										>
											<img src={Edit} alt="Edit" />
										</div>
									)}
									{hasPermission(
										userData,
										userPermissions,
										'delete_holidays'
									) && (
										<div
											className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
											onClick={() =>
												handleDeleteClick(row?.original)
											}
											title="Delete"
										>
											<img src={Delete} alt="Delete" />
										</div>
									)}
								</div>
							);
						},
					},
			  ]
			: []),
	];

	const fetchData = useCallback(
		(pageSize, pageIndex, searchText, sortBy) => {
			dispatch(
				getHolidayList({
					pageSize: pageSize,
					pageNo: pageIndex,
					searchText: searchText,
					sortBy: sortBy,
				})
			);
		},
		[dispatch]
	);

	return (
		<>
			<ReactTablePagination
				columns={columns}
				data={holidayData?.holidayList}
				fetchData={fetchData}
				pageCount={Math.ceil(
					holidayData?.totalRecords / recordsPerPage
				)}
				totalRecords={holidayData?.totalRecords}
				recordsPerPage={recordsPerPage}
				setRecordsPerPage={setRecordsPerPage}
				showCSVButton={hasPermission(
					userData,
					userPermissions,
					'export_holidays'
				)}
				csvFileName="holiday_lists.csv"
			/>
		</>
	);
};

export default ListComponent;
