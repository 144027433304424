import React from 'react';
import ActivityFeedImage from '../../assets/images/Create-Tasks.png';

const data = [
	{
		id: '1',
		title: 'How long did it take to get a PC for a new team member?',
		time: '4 Days ago',
		image: ActivityFeedImage,
	},
	{
		id: '2',
		title: 'How long did it take to get a PC for a new team member?',
		time: '4 Days ago',
		image: ActivityFeedImage,
	},
	{
		id: '3',
		title: 'How long did it take to get a PC for a new team member?',
		time: '4 Days ago',
		image: ActivityFeedImage,
	},
	{
		id: '4',
		title: 'How long did it take to get a PC for a new team member?',
		time: '4 Days ago',
		image: ActivityFeedImage,
	},
];

export default function WhatHappeningGamecan() {
	return (
		<div>
			<h2 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7 text-left pb-[25.92px]">
				What’s Happening at Gamecan
			</h2>
			<div className="mb-[39px] rounded-[15px] p-8 mt-6 pr-[10px] lg:mt-0 dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
				<div className="h-[271.81px] overflow-auto custom-scroll pr-[12.27px]">
					{data.map((row, index) => (
						<div
							key={row.id}
							className={`flex items-center border-b border-solid dark:border-[#333333] border-gray-300 flex justify-between text-left pb-[20px] mb-[20px]  ${
								index === data.length - 1 ? 'mb-[0]' : ''
							}`}
						>
							<div className="flex items-center">
								<img
									src={ActivityFeedImage}
									alt="ActivityFeed"
									className="mr-[12.16px]"
								/>
								<p className="dark:text-[#BFC6D2] text-black  text-[15px] leading-[25px] w-[100%]">
									{row.title}
								</p>
							</div>
							<span className="dark:text-[#BFC6D2] text-black  text-xs leading-[22px] text-right w-[18%]">
								{row.time}
							</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
