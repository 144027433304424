import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { updateCompanyProfile } from '../../store/thunk/companyThunk';

import FormComponent from './FormComponent';

const EditProfile = ({
	visible,
	onClose,
	editValues,
	isCompanyInformation,
}) => {
	const [uploadedFile, setUploadedFile] = useState();
	const loading = useSelector((state) => state.dataLoading.loading);
	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		setValue,
	} = useForm({
		defaultValues: {
			company_name: editValues?.company_name,
			company_address: editValues?.company_address,
			company_email: editValues?.company_email,
			company_contact_no: editValues?.company_contact_no,
			company_country: editValues?.company_country.id,
			company_postal_code: editValues?.company_postal_code,
			company_website: editValues?.company_website,
			company_industry_type: editValues?.company_industry_type,
			company_filling_date: editValues?.company_filling_date,
			registration_number: editValues?.registration_number,
			company_descriptions: editValues?.company_descriptions,
			company_city: editValues?.company_city,
			employee_count: editValues?.employee_count,
			company_linkedin: editValues?.company_linkedin,
			company_taxid_number: editValues?.company_taxid_number,
			first_name: editValues?.user_info?.first_name,
			middle_name: editValues?.user_info?.middle_name,
			last_name: editValues?.user_info?.last_name,
			email: editValues?.user_info?.email,
			phone_number: editValues?.user_info?.phone_number,
			dob: editValues?.user_info?.dob,
			profile_image: editValues?.user_info?.profile_image,
		},
	});

	const onSuccessCallback = () => {
		reset();
		onClose();
	};

	const onSubmit = (data) => {
		data.uid = editValues?.uid;
		data.callbackFunction = onSuccessCallback;
		data.setError = setError;
		if (uploadedFile !== undefined) {
			data.profile_image = uploadedFile;
		} else {
			delete data.profile_image;
		}
		data.is_update_logo = false;
		data.is_from_settings = false;
		dispatch(updateCompanyProfile(data));
	};

	return (
		<>
			<div
				className={` togglesidebar bg-[#ffffff57] fixed h-full w-full top-0 left-0 p-6 z-[99999]  ${
					visible ? 'active' : ''
				}`}
			>
				<div
					onClick={onClose}
					className="w-full bg-[#ffffff57] overlay bg-[#0f1a32] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
				/>
				<div className="dark:bg-[#12243d] bg-[#fafafa] dark:border-[#12243d] border-gray-300 border-l dark: w-[85%] lg:w-[50%] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]">
					<button
						className="close h-8 w-8 rounded-full absolute top-5 right-5 bg-[#fff]"
						onClick={onClose}
					>
						X
					</button>
					<h1 className="p-6 dark:text-white text-[#4b5563] font-bold text-[20px] text-left pb-[16px] border-solid border-b dark:border-[#333333] border-gray-300">
						Update Profile
					</h1>
					<div className="rounded-lg">
						<FormComponent
							loading={loading}
							register={register}
							handleSubmit={handleSubmit}
							onSubmit={onSubmit}
							errors={errors}
							isUpdate={true}
							setValue={setValue}
							selectedCountry={
								editValues?.company_country.id || ''
							}
							description={editValues?.company_descriptions || ''}
							setUploadedFile={setUploadedFile}
							existingProfileImage={
								editValues?.user_info?.profile_image_url
							}
							isCompanyInformation={isCompanyInformation}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditProfile;
