import React, { useEffect } from 'react';
import Profileblock from './Profileblock';
import NotificationBlock from './NotificationBlock';
import PrivacyBlock from './PrivacyBlock';
import { getUserData, UpdateUserProfile } from '../../store/thunk/userThunk';
import { useDispatch, useSelector } from 'react-redux';

export default function SettingPage() {
	const dispatch = useDispatch();
	const user_data = useSelector((state) => state.user.userData);
	const authUser = useSelector((state) => state.auth);

	useEffect(() => {
		dispatch(getUserData(authUser?.userDetails));
	}, [dispatch, authUser?.userDetails]);

	// Extract privacy settings from user data
	const {
		email_address,
		phone_number,
		personal_information,
		post,
		email_alert,
		device_alert,
		app_alert,
	} = user_data?.user_settings || {};

	const privacy_settings = {
		email_address,
		phone_number,
		personal_information,
		post,
	};

	const notification_preference = {
		email_alert,
		device_alert,
		app_alert,
	};

	const onChangeToggle = (switchStates) => {
		const data = {};
		data.user_settings = JSON.stringify(switchStates);
		data.id = authUser?.userDetails?.id;
		data.is_settings_update = true;
		dispatch(UpdateUserProfile(data));
	};

	return (
		<div className="md:my-[38px] my-[20px] mx-[20px] relative">
			<div className="grid-cols-12 md:grid block gap-[30px]">
				<div className="col-span-12 text-white">
					<Profileblock />
				</div>
				<div className="col-span-6 text-white mt-6 lg:mt-0">
					<PrivacyBlock
						user_settings={privacy_settings}
						onChangeToggle={onChangeToggle}
					/>
				</div>
				<div className="col-span-6 text-white mt-6 lg:mt-0">
					<NotificationBlock
						user_settings={notification_preference}
						onChangeToggle={onChangeToggle}
					/>
				</div>
			</div>
		</div>
	);
}
