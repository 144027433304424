import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompanyList } from '../../store/thunk/companyThunk';
import ReactTablePagination from '../../component/ui-component/pagination/ReactTablePagination';
import Edit from '../../assets/images/Edit.png';
import { getDefaultProfile } from '../../component/Common';
import UserProfile from '../../component/ui-component/UserProfile';
const moment = require('moment');

const ListComponent = ({
	handleSetEditValues,
	handleDeleteClick,
	handleUpdatestatus,
}) => {
	const [recordsPerPage, setRecordsPerPage] = useState(10);

	const dispatch = useDispatch();

	const companyData = useSelector((state) => state.company);
	const loading = useSelector((state) => state.dataLoading.loading);

	const columns = [
		{
			Header: 'Company Name',
			accessor: 'company_name',
			width: '15%',
			Cell: ({ row, value }) => {
				return (
					<div className="flex">
						<div className="ml-3">
							<h6 className="mb-0 font-bold">{`${row?.original?.company_name}`}</h6>
							<span className="text-muted">
								{row?.original?.company_email}
							</span>
						</div>
					</div>
				);
			},
		},
		{
			Header: 'User Name',
			accessor: 'fullname',
			width: '22%',
			Cell: ({ row, value }) => {
				return (
					<div className="flex">
						<UserProfile
							imageUrl={row?.original?.user_info?.profile_image}
							defaultImageUrl={getDefaultProfile(
								row?.original?.user_info?.gender
							)}
							altName={row?.original?.user_info?.fullname}
							className={
								'mt-[5px] mr-[5px] h-[40px] w-[40px] md:h-[40px] md:w-[40px] lg:h-[45px] lg:w-[45px] md:h-[45px] md:w-[45px] rounded-full bg-white border border-slate-300 p-0.5 float-left'
							}
						/>
						<div className="ml-2 mt-1">
							<h6 className="mb-0 font-bold">{`${row?.original?.user_info?.first_name} ${row?.original?.user_info?.last_name}`}</h6>
							<span className="text-muted">
								{row?.original?.user_info?.email}
							</span>
						</div>
					</div>
				);
			},
		},
		{
			Header: 'Contact No',
			accessor: 'company_contact_no',
			// width: '10%',
		},
		{
			Header: 'Address',
			accessor: 'company_address',
			// width: '13%',
		},
		{
			Header: 'Postal Code',
			accessor: 'company_postal_code',
			// width: '10%',
		},
		{
			Header: 'Country',
			accessor: 'company_country.name',
			// width: '10%',
		},
		{
			Header: 'Company Filing Date',
			accessor: 'company_filling_date',
			// width: '12%',
			Cell: ({ row, value }) => {
				return (
					<>
						{value
							? moment(value, 'YYYY-MM-DD').format('DD MMM, YYYY')
							: ''}
					</>
				);
			},
		},
		{
			Header: 'Active',
			accessor: 'active',
			// width: '4%',
			Cell: ({ row, value }) => (
				<label className="relative inline-flex items-center cursor-pointer">
					<input
						type="checkbox"
						value=""
						defaultChecked={row?.original?.user_info?.is_active}
						className="sr-only peer"
						onChange={() => handleUpdatestatus(row?.original)}
						disabled={loading}
					/>
					<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
					<span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
				</label>
			),
		},
		{
			Header: 'Action',
			accessor: 'action',
			// width: '4%',
			Cell: ({ row, value }) => (
				<div className="flex">
					<div
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full mr-[8px] border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						onClick={() => handleSetEditValues(row?.original)}
						title="Edit"
					>
						<img src={Edit} alt="Edit1" />
					</div>
					{/* <div
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35`px] h-[35`px] rounded-full border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						onClick={() => handleDeleteClick(row?.original)}
						title="Delete"
					>
						<img src={Delete} alt="Delete" />
					</div> */}
				</div>
			),
		},
	];

	const fetchData = useCallback(
		(pageSize, pageIndex, searchText, sortBy) => {
			dispatch(
				getCompanyList({
					pageSize: pageSize,
					pageNo: pageIndex,
					searchText: searchText,
					sortBy: sortBy,
				})
			);
		},
		[dispatch]
	);

	return (
		<>
			<ReactTablePagination
				columns={columns}
				data={companyData?.companyList}
				fetchData={fetchData}
				pageCount={Math.ceil(
					companyData?.totalRecords / recordsPerPage
				)}
				totalRecords={companyData?.totalRecords}
				recordsPerPage={recordsPerPage}
				setRecordsPerPage={setRecordsPerPage}
				csvFileName="company_lists.csv"
			/>
		</>
	);
};

export default ListComponent;
