import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	rolesList: [],
	isFetching: false,
	totalPages: 0,
	totalRecords: 0,
};

const rolesSlice = createSlice({
	name: 'roles',
	initialState,
	reducers: {
		setRolesData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			rolesList: action.payload?.results,
		}),
		setFetching: (state, action) => ({
			...state,
			isFetching: action.payload,
			rolesList: [],
		}),
	},
});

export const { setRolesData, setFetching } = rolesSlice.actions;
export default rolesSlice.reducer;
