import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '../../assets/images/Search.png';
import Switcher from '../Switcher';
import HeaderDropDown from './HeaderDropDown';
import NotificationDropdown from './NotificationDropdown';
import SidebarComponent from '../SidebarComponent';
import MobileSideBarComponent from '../ui-component/MobileSideBarComponent';

const HeaderComponent = ({ title }) => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [isWidth, setIswidth] = useState(false);
	const HandleCLick = () => {
		setIswidth(!isWidth);
	};
	const toggleClick = useCallback(() => {
		setSidebarOpen((prevSidebarOpen) => !prevSidebarOpen);
	}, []);

	return (
		<>
			<header className="dark:bg-[#0f1a32] bg-[#fafafa]">
				<div className="inner-header lg:border-0 border-b border-solid border-[#092e4d]">
					<nav className="border-gray-200 dark:bgTransparent">
						<div className="flex flex-wrap md:flex-nowrap justify-between items-center">
							<div className="lg:flex md:flex hidden justify-between flex items-center md:order-1 order-2 md:w-auto w-full mt-3 md:mt-0">
								<div className="md:pr-[61px] pr-0">
									<span className="self-center text-sm lg:text-2xl font-extrabold tracking-wider whitespace-nowrap dark:text-white text-[#4b5563]">
										{title}
									</span>
								</div>
								<div className="relative flex items-center rounded-md duration-300 cursor-pointer text-white md:ml-0 ml-[10px]">
									<img
										src={SearchIcon}
										alt="SearchIcon"
										className="absolute md:top-[13px] top-[9px] md:left-[15px] left-[10px]"
									/>
									<input
										type="text"
										placeholder="Search your plans..."
										className="md:text-[15px] text-[13px] md:px-12 pl-12 pr-2 focus:outline-none dark:bg-[#1A2844] bg-[#f2f2f2] md:h-12 h-10  w-[100%] lg:w-[417px] rounded-[24.65px] dark:text-white text-black"
									/>
								</div>
							</div>
							<div className="flex md:order-2 order-1 md:w-auto w-full">
								<div
									className="order-2 flex justify-end items-center w-full lg:w-auto lg:order-1"
									id="mobile-menu-2"
								>
									<ul className="flex flex-row lg:space-x-8 lg:mt-0 items-center">
										<li className="md:w-[49px] w-[40px] md:h-[49px] h-[40px] border rounded-[50%] border-solid dark:border-[#333333] border-gray-300">
											<Link
												to="#"
												className="flex h-full items-center justify-center dark:text-white rounded bg-transparent text-primary-700 p-0"
												aria-current="page"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="23"
													height="23"
													viewBox="0 0 23 23"
													fill="none"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M16.48 2.81055C17.7092 2.81055 18.8917 3.29638 19.7616 4.16813C20.6325 5.03805 21.1192 6.21138 21.1192 7.43971V14.6814C21.1192 17.2389 19.0384 19.3105 16.48 19.3105H7.42422C4.86581 19.3105 2.78589 17.2389 2.78589 14.6814V7.43971C2.78589 4.88221 4.85664 2.81055 7.42422 2.81055H16.48ZM17.9384 8.80555L18.0117 8.73221C18.2308 8.46638 18.2308 8.08138 18.0016 7.81555C17.8742 7.67896 17.6991 7.59555 17.5167 7.57721C17.3242 7.56713 17.1409 7.63221 17.0025 7.76055L12.8692 11.0605C12.3376 11.5015 11.5758 11.5015 11.0359 11.0605L6.91089 7.76055C6.62581 7.54971 6.23164 7.57721 5.99422 7.82471C5.74672 8.07221 5.71922 8.46638 5.92914 8.74138L6.04922 8.86055L10.2201 12.1147C10.7334 12.518 11.3558 12.738 12.0076 12.738C12.6575 12.738 13.2909 12.518 13.8033 12.1147L17.9384 8.80555Z"
														className="dark:fill-[#bfc6d2] fill-[#0c1932]"
													/>
												</svg>
											</Link>
										</li>
										<li className="relative list-item-block md:w-[49px] w-[40px] md:h-[49px] h-[40px] border rounded-[50%] border-solid dark:border-[#333333] border-gray-300 hover:cursor-pointer">
											<NotificationDropdown />
										</li>
										<li className="list-item-block margin-left-[0] md:w-[49px] w-[40px] md:h-[49px] h-[40px] border rounded-[50%] border-solid dark:border-[#333333] border-gray-300">
											<Link
												href="#"
												className="flex h-full items-center justify-center text-white rounded bg-transparent  p-0"
												aria-current="page"
											>
												<Switcher />
											</Link>
										</li>
										<li className="cursor-pointer list-item-block relative pr-[20px]">
											<HeaderDropDown />
										</li>
									</ul>
								</div>
								<div
									className="lg:hidden text-xl md:order-2 order-1 m-0 mt-1 md:p-5 p-2"
									onClick={toggleClick}
								>
									<i className="fas fa-bars dark:text-white text-black"></i>
								</div>
								{sidebarOpen ? (
									<MobileSideBarComponent
										setSidebarOpen={setSidebarOpen}
									/>
								) : (
									<SidebarComponent
										isWidth={isWidth}
										HandleCLick={HandleCLick}
									/>
								)}
							</div>
						</div>
					</nav>
				</div>
			</header>
		</>
	);
};
export default HeaderComponent;
