import React, { useEffect, useState } from 'react';
import Button from '../../component/ui-component/Button';
import DatePickerComponent from '../../component/ui-component/DatePickerComponent';
import { useDispatch, useSelector } from 'react-redux';
import { vsmLeaves } from '../../utils/validations';
import { getUserList } from '../../store/thunk/userThunk';
import MultiSelectComponent from '../../component/ui-component/MultiSelectComponent';
import SelectComponent from '../../component/ui-component/SelectComponent';
import CKEditorComponent from '../../component/ui-component/CKEditorComponent';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	setValue,
	selectedAssignedUsers,
	selectedLeaveType,
	reason,
}) => {
	const LeaveTypesList = [
		{ id: 1, name: 'Paid Leave' },
		{ id: 2, name: 'Casual Leave' },
		{ id: 3, name: 'Sick/Medical Leave' },
		{ id: 4, name: 'Unpaid Leave' },
		{ id: 5, name: 'Annual/Vacation Leave' },
		{ id: 6, name: 'Maternity Leave' },
		{ id: 7, name: 'Paternity Leave' },
		{ id: 8, name: 'Parental Leave' },
		{ id: 9, name: 'Personal Leave' },
		{ id: 10, name: 'Emergency Leave' },
	];
	const dispatch = useDispatch();
	const userList = useSelector((state) => state.user.userList);
	const [isMultiSelectValid, setIsMultiSelectValid] = useState(
		selectedAssignedUsers && selectedAssignedUsers.length > 0 ? true : false
	);
	const [error, setError] = useState(false);

	useEffect(() => {
		dispatch(getUserList());
	}, [dispatch]);

	const onChangeSelectValue = (key, selectedValue) => {
		setValue(key, selectedValue);
	};

	const onChangeMultiSelectValue = (key, selectedValue) => {
		let assigned_user_ids = [];
		selectedValue.map((item) =>
			assigned_user_ids.push(parseInt(item.value))
		);
		setValue(key, assigned_user_ids);
		setIsMultiSelectValid(selectedValue.length > 0);
	};

	const handleEditorChange = (id, content) => {
		setValue(id, content);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (!isMultiSelectValid) {
			setError(true);
			return;
		} else {
			setError(false);
		}
		// Proceed with form submission
		handleSubmit(onSubmit)(e);
	};

	return (
		<form onSubmit={handleFormSubmit} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
							Leave Information
						</h3>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
						<SelectComponent
							selectOptionLists={LeaveTypesList}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedLeaveType}
							title="Leave Type"
							id="leave_type"
							errors={errors}
							register={register}
							fieldvalidation={vsmLeaves}
						/>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mt-2">
						<DatePickerComponent
							id="start_date"
							title="From Date"
							errors={errors}
							register={register}
							fieldvalidation={vsmLeaves}
						/>
						<DatePickerComponent
							id="end_date"
							title="To Date"
							errors={errors}
							register={register}
							fieldvalidation={vsmLeaves}
						/>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
						<MultiSelectComponent
							selectOptionLists={userList}
							onChangeSelectValue={onChangeMultiSelectValue}
							selectedValue={selectedAssignedUsers}
							title="Approval Request To"
							id="assigned_users"
							error={error}
						/>
						<CKEditorComponent
							id="reason"
							title="Reason"
							data={reason}
							onChange={handleEditorChange}
						/>
					</div>
				</div>
			</div>
			<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<Button
					buttonText={`${isUpdate ? 'Update' : 'Apply'} Leave`}
					linkTo="#"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default FormComponent;
