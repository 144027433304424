import React, { useEffect, useState } from 'react';
import Branding from './Branding';
import EmailTemplate from './EmailTemplate';
import SecuritySettings from './SecuritySettings';
import SaveImage from '../../assets/images/save.png';
import EditImage from '../../assets/images/Edit.png';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCompanyData,
	updateCompanyProfile,
} from '../../store/thunk/companyThunk';

function TextInputField({
	label,
	value,
	onEditView,
	isEditMode,
	fieldName,
	onSave,
}) {
	const [editedValue, setEditedValue] = useState(value);

	useEffect(() => {
		setEditedValue(value);
	}, [value]);

	return (
		<div className="text-left relative">
			<label className="block mb-[15px] text-[#0C1932] dark:text-white text-sm not-italic font-normal leading-[18px]">
				{label}
			</label>
			{isEditMode ? (
				<div className="flex items-center justify-between">
					{fieldName !== 'about' ? (
						<input
							type="text"
							defaultValue={editedValue}
							onChange={(e) => setEditedValue(e.target.value)}
							className="text-base text-[#0C1932] dark:text-white pl-[19.34px] leading-[21px] w-full bg-[#f1f5f9] dark:bg-[#0F1A32] px-2 py-1 h-[47.983px] outline-none rounded text-[#000]"
						/>
					) : (
						<textarea
							defaultValue={editedValue}
							onChange={(e) => setEditedValue(e.target.value)}
							className="h-full text-base text-[#0C1932] dark:text-white  leading-[21px] w-full bg-[#f1f5f9] dark:bg-[#0F1A32] p-4 h-[47.983px] outline-none rounded text-[#000]"
						/>
					)}
					<img
						src={SaveImage}
						alt="Save"
						title="Save"
						className="cursor-pointer absolute right-[14.98px] bottom-[12px] h-[26px]"
						onClick={() => {
							onSave(fieldName, editedValue);
							onEditView();
						}}
					/>
				</div>
			) : (
				<div className="flex items-center justify-between">
					{fieldName !== 'about' ? (
						<input
							type="text"
							value={editedValue}
							readOnly
							className="text-base text-[#0C1932] dark:text-white pl-[19.34px] leading-[21px] w-full bg-[#f1f5f9] dark:bg-[#0F1A32] px-2 py-1 h-[47.983px] outline-none rounded text-[#000]"
						/>
					) : (
						<textarea
							value={editedValue}
							readOnly
							className="h-full text-base text-[#0C1932] dark:text-white  leading-[21px] w-full bg-[#f1f5f9] dark:bg-[#0F1A32] p-4 h-[47.983px] outline-none rounded text-[#000]"
						/>
					)}
					<img
						src={EditImage}
						alt="Edit"
						title="Edit"
						className="cursor-pointer absolute right-[14.98px] bottom-[15px]"
						onClick={onEditView}
					/>
				</div>
			)}
		</div>
	);
}

export default function SystemSetting() {
	const dispatch = useDispatch();
	const [isEditMode, setIsEditMode] = useState(false);
	const authUser = useSelector((state) => state.auth);
	const company_data = useSelector((state) => state.company.companyData);

	const onEditView = () => {
		setIsEditMode(true);
	};
	const handleSaveField = async (fieldName, value) => {
		let data = {
			uid: company_data?.uid,
			is_from_settings: true,
			...{ [fieldName]: value },
		};

		try {
			await dispatch(updateCompanyProfile(data));
			setIsEditMode(false);
		} catch (error) {
			console.error('Error updating user profile:', error);
		}
	};

	useEffect(() => {
		dispatch(getCompanyData(authUser?.userDetails));
	}, [dispatch, authUser?.userDetails]);

	return (
		<div className="md:p-5 p-2">
			<Branding />
			<div className="md:grid block grid-cols-12 gap-5">
				<div className="col-span-8">
					<h2 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7 text-left pb-[25.92px]">
						Branding
					</h2>
					<EmailTemplate />
				</div>
				<div className="col-span-4">
					<h2 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7 text-left pb-[25.92px] md:mt-0 mt-5">
						Security Settings
					</h2>
					<SecuritySettings />
				</div>
			</div>
			<div className="mt-6 shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] rounded-[15px] bg-[#fff] dark:bg-[#17243e] p-6">
				<h4 className="dark:text-white text-[#4b5563] text-[20px] font-bold leading-7 text-left pb-[25.92px] md:mt-0 mt-5">
					Master Leaves Count
				</h4>
				<div className="grid grid-cols-1 lg:grid-cols-4 gap-[17.84px]">
					<TextInputField
						label="Paid Leave"
						value={`${company_data?.master_paid_leave}`}
						onEditView={onEditView}
						isEditMode={isEditMode}
						fieldName="master_paid_leave"
						onSave={handleSaveField}
					/>
					<TextInputField
						label="Sick Leave"
						value={company_data?.master_sick_leave}
						onEditView={onEditView}
						isEditMode={isEditMode}
						fieldName="master_sick_leave"
						onSave={handleSaveField}
					/>
					<TextInputField
						label="Casual Leave"
						value={company_data?.master_casual_leave}
						onEditView={onEditView}
						isEditMode={isEditMode}
						fieldName="master_casual_leave"
						onSave={handleSaveField}
					/>
					<TextInputField
						label="Other Leave"
						value={company_data?.master_other_leave}
						onEditView={onEditView}
						isEditMode={isEditMode}
						fieldName="master_other_leave"
						onSave={handleSaveField}
					/>
				</div>
			</div>
		</div>
	);
}
