import React from 'react';
import { useDispatch } from 'react-redux';
import ListProjectComponent from './Projects/ListProjectComponent';
import ListTaskComponent from './Tasks/ListTaskComponent';
import { editTask } from '../../store/thunk/tasksThunk';

export default function Workflow({
	handleSetEditValues,
	handleDeleteClick,
	clientID,
	handleTaskEditValues,
	handleTaskDeleteClick,
	userData,
	userPermissions,
}) {
	const dispatch = useDispatch();
	const [openTab, setOpenTab] = React.useState(1);
	const handleTaskStatusChange = (
		record,
		status,
		show_all_project_task,
		projectId
	) => {
		let payload = {
			status: status,
			id: record?.id,
			assigned_to: record?.assigned_to?.id,
			is_status_update: true,
			project_id: parseInt(projectId),
			show_all_project_task: show_all_project_task,
		};
		dispatch(editTask(payload));
	};

	return (
		<div className="flex flex-wrap">
			<div className="w-full">
				<ul
					className="flex mb-0 list-none flex-wrap pt-3 flex-row mb-5 border-b-[#2C3E63] border-b border-solid"
					role="tablist"
				>
					<li className="-mb-px mr-2 last:mr-0">
						<a
							className={
								'border-b border-solid flex lg:text-base text-sm font-bold py-3 md:mr-[56.99px] lg:mr-[30px] mr-[18px] leading-normal ' +
								(openTab === 1
									? 'border-b-[#00FFF0] text-[#00FFF0]'
									: 'border-b-transparent text-[#BFC6D2]')
							}
							onClick={(e) => {
								e.preventDefault();
								setOpenTab(1);
							}}
							data-toggle="tab"
							href="#link1"
							role="tablist"
						>
							Projects
						</a>
					</li>
					<li className="-mb-px mr-2 last:mr-0">
						<a
							className={
								'border-b border-solid lg:text-base text-sm font-bold py-3 md:mr-[56.99px] lg:mr-[30px] mr-[18px] block leading-normal ' +
								(openTab === 2
									? 'border-b-[#00FFF0] text-[#00FFF0]'
									: 'border-b-transparent text-[#BFC6D2]')
							}
							onClick={(e) => {
								e.preventDefault();
								setOpenTab(2);
							}}
							data-toggle="tab"
							href="#link2"
							role="tablist"
						>
							Tasks
						</a>
					</li>
				</ul>
				<div>
					<div className="tab-content tab-space dark:text-white text-black">
						<div
							className={`grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 text-white text-left ${
								openTab === 1 ? 'block' : 'hidden'
							}`}
							id="link1"
						>
							<ListProjectComponent
								clientID={clientID}
								handleSetEditValues={handleSetEditValues}
								handleDeleteClick={handleDeleteClick}
							/>
						</div>
						<div
							className={openTab === 2 ? 'block' : 'hidden'}
							id="link2"
						>
							<ListTaskComponent
								handleTaskStatusChange={handleTaskStatusChange}
								clientID={clientID}
								handleSetEditValues={handleTaskEditValues}
								handleDeleteClick={handleTaskDeleteClick}
								userData={userData}
								userPermissions={userPermissions}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
