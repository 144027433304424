import React, { useState } from 'react';
import { removeSkill } from '../../store/thunk/skillsThunk';
import AddSkillComponent from './AddSkillComponent';
import EditSkillComponent from './EditSkillComponent';
import ListComponent from './ListComponent';
import Dialogpopup from '../../component/ui-component/Dailogpopup';
import { Link } from 'react-router-dom';

const SkillsComponent = () => {
	const [addDrawerVisible, setAddDrawerVisible] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);

	const handleSetEditValues = (item) => {
		setEditValues(item);
		setEditDrawerVisible(true);
	};
	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
	};

	return (
		<>
			<div className="md:p-[20px] p-[10px]">
				<div className="flex items-center justify-between md:px-2 px-0 md:py-5 py-0">
					<nav className="rounded-md">
						<ol className="list-reset flex">
							<li>
								<Link
									to="/admin-panel"
									className="font-medium text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
								>
									Dashboard
								</Link>
							</li>
							<li>
								<span className="mx-2 text-neutral-500 dark:text-neutral-400">
									/
								</span>
							</li>
							<li className="text-neutral-500 dark:text-neutral-400">
								Skills
							</li>
						</ol>
					</nav>
					<button
						className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
						onClick={() => setAddDrawerVisible(true)}
					>
						+ Create Skill
					</button>
				</div>
				<ListComponent
					setEditDrawerVisible={setEditDrawerVisible}
					handleSetEditValues={handleSetEditValues}
					handleDeleteClick={handleDeleteClick}
				/>
			</div>

			{addDrawerVisible && (
				<AddSkillComponent
					visible={addDrawerVisible}
					onClose={() => setAddDrawerVisible(false)}
				/>
			)}
			{editDrawerVisible && editValues && (
				<EditSkillComponent
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
				/>
			)}
			{deleteDailogVisible && (
				<>
					<Dialogpopup
						onCancel={() => setDeleteDailogVisible(false)}
						deleteItem={editValues}
						removeThunkCall={removeSkill}
						message="Are you sure you want to delete this skill ?"
					/>
				</>
			)}
		</>
	);
};
export default SkillsComponent;
