import React from 'react';
const moment = require('moment');

const ViewActivityComponent = ({ visible, onClose, viewActivity }) => {
	return (
		<>
			<div
				className={` togglesidebar bg-[#ffffff57] fixed h-full w-full top-0 left-0 p-6 z-[99999]  ${
					visible ? 'active' : ''
				}`}
			>
				<div
					onClick={onClose}
					className="w-full bg-[#ffffff57] overlay bg-[#0f1a32] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
				/>
				<div className="dark:bg-[#12243d] bg-[#fafafa] border-gray-300 border-l w-[85%] lg:w-[50%] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]">
					<button
						className="close h-8 w-8 rounded-full absolute top-5 right-5 bg-[#fff]"
						onClick={onClose}
					>
						X
					</button>
					<h1 className="p-6 dark:text-white text-[#4b5563] font-bold text-[20px] text-left pb-[20px] border-solid border-b dark:border-[#333333] border-gray-300">
						Activity Information
					</h1>
					<div className="rounded-lg">
						<div className="p-[20px] infobox custom-scroll h-[750px] overflow-y-auto top-scroll-activity ">
							<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 px-10 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
								{Object.entries(viewActivity).map(
									([key, value], index) => (
										<ol
											key={`outer-${key}-${index}`}
											className="border-l-2 border-neutral-300 dark:border-neutral-500"
										>
											{value.map((row) => (
												<li key={`${key}-${row.id}`}>
													<div className="flex-start flex items-center pt-1">
														<div className="-ml-[8px] mr-3 h-[14px] w-[14px] border-2 border-orange-500 rounded-full dark:bg-[#17243E] bg-[#fff]"></div>
														<span className="dark:text-white text-[#4b5563] text-lg font-semibold ml-4 tracking-wide mb-1 mt-1.5">
															{
																row?.punch_type_name
															}
														</span>
													</div>
													<div className="mb-4 ml-9 text-left">
														<p className="text-sm dark:text-white text-[#4b5563] tracking-wide">
															<i
																className="fa fa-clock"
																aria-hidden="true"
															></i>{' '}
															{moment(
																row?.timestamp
															).format(
																'hh:mm:ss A'
															)}
														</p>
													</div>
												</li>
											))}
										</ol>
									)
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewActivityComponent;
