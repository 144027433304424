import React from 'react';

export default function ProgressBar(props) {
	return (
		<div className="w-full">
			<div
				className="custom-progressbar mt-0 mb-2 "
				style={{
					background: `${props.mainColor}`,
					boxShadow: `0 0px 0 5px ${props.shadow1}, 0 3px 0 6px ${props.shadow2}`,
				}}
			>
				<div
					className="inner-progressbar 
        shadow-[0_1px_2px_#3471c0] 
        "
					style={{
						background: `linear-gradient(0deg, ${props.bgColor} 24%, ${props.bgColor2} 50%, ${props.bgColor3} 100%)`,
						boxShadow: `rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,  ${props.shadow3} 0px 1px 2px 0px`,
						width: `calc(${props.progress} - 10px)`,
					}}
				>
					<span
						className="sub-inner-progressbar "
						style={{
							background: `linear-gradient(to bottom,${props.color1} 0%,${props.color2} 100%)`,
						}}
					></span>
				</div>
			</div>
		</div>
	);
}
