import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getXPEarningList } from '../../store/thunk/departmentThunk';
import ReactTablePagination from '../../component/ui-component/pagination/ReactTablePagination';
import Delete from '../../assets/images/Delete.png';
import Edit from '../../assets/images/Edit.png';

const ListComponent = ({ handleSetEditValues, handleDeleteClick }) => {
	const [recordsPerPage, setRecordsPerPage] = useState(10);
	const dispatch = useDispatch();
	const departmentData = useSelector((state) => state.department);
	const columns = [
		{
			Header: '#',
			accessor: 'id',
			width: 20,
		},
		{
			Header: 'Activity / Achievement',
			accessor: 'activity_or_achievement',
			width: '30%',
		},
		{
			Header: 'XP Earned',
			accessor: 'xp_earned_points',
			width: '30%',
		},
		{
			Header: 'Applicable Department(s)',
			accessor: 'is_applicable_for_all',
			width: '40%',
			Cell: ({ row, value }) => {
				if (row?.original?.is_applicable_for_all) {
					return <div>All</div>;
				} else {
					const titles = row?.original?.applicable_departments.map(
						(obj) => obj.title
					);
					const departmentLists = titles.join(', ');
					return departmentLists;
				}
			},
		},
		{
			Header: 'Action',
			accessor: 'action',
			disableSortBy: true,
			Cell: ({ row, value }) => (
				<div className="flex">
					<div
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full mr-[8px] border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						onClick={() => handleSetEditValues(row?.original)}
						title="Edit"
					>
						<img src={Edit} alt="Edit" />
					</div>
					<div
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						onClick={() => handleDeleteClick(row?.original)}
						title="Delete"
					>
						<img src={Delete} alt="Delete" />
					</div>
				</div>
			),
		},
	];

	const fetchData = useCallback(
		(pageSize, pageIndex, searchText, sortBy) => {
			dispatch(
				getXPEarningList({
					pageSize: pageSize,
					pageNo: pageIndex,
					searchText: searchText,
					sortBy: sortBy,
				})
			);
		},
		[dispatch]
	);

	return (
		<>
			<ReactTablePagination
				columns={columns}
				data={departmentData?.xpearningList}
				fetchData={fetchData}
				pageCount={Math.ceil(
					departmentData?.totalRecords / recordsPerPage
				)}
				totalRecords={departmentData?.totalRecords}
				recordsPerPage={recordsPerPage}
				setRecordsPerPage={setRecordsPerPage}
				csvFileName="xpearning_master_lists.csv"
			/>
		</>
	);
};

export default ListComponent;
