import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import SearchIcon from '../../../assets/images/Search.png';

const GlobalSearch = ({ globalSearchText, setGlobalSearchText }) => {
	const [value, setValue] = useState(globalSearchText);
	const onChange = useAsyncDebounce((value) => {
		setGlobalSearchText(value);
	}, 500);
	return (
		<div className="search-block">
			<div className="relative flex items-center rounded-md  duration-300 cursor-pointer  text-white">
				<img
					src={SearchIcon}
					alt="SearchIcon"
					className=" absolute top-[9px] left-[15px]"
				/>
				<input
					type="text"
					value={value}
					onChange={(e) => {
						setValue(e.target.value);
						onChange(e.target.value);
					}}
					placeholder="Search your plans..."
					className="text-[13px] px-12 focus:outline-none dark:bg-[#0f1a32] bg-[#f2f2f2] h-[40px] min-w-[200px] rounded-[24.65px] dark:text-white text-black"
				/>
			</div>
		</div>
	);
};

export default GlobalSearch;
