import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	companyList: [],
	companyData: {},
	isFetching: false,
	totalPages: 0,
	totalRecords: 0,
	dashboardCounts: {},
	reportAnalytics: {},
};

const companySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {
		setCompanyData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			companyList: action.payload?.results,
		}),
		setSingleCompanyData: (state, action) => ({
			...state,
			isFetching: false,
			companyData: action.payload,
		}),
		setDashboardData: (state, action) => ({
			...state,
			isFetching: false,
			dashboardCounts: action.payload,
		}),
		setReportAnalyticdData: (state, action) => ({
			...state,
			isFetching: false,
			reportAnalytics: action.payload,
		}),
		setFetching: (state, action) => ({
			...state,
			isFetching: action.payload,
			companyList: [],
		}),
	},
});

export const {
	setCompanyData,
	setFetching,
	setSingleCompanyData,
	setDashboardData,
	setReportAnalyticdData,
} = companySlice.actions;
export default companySlice.reducer;
