import React from 'react';
import { readFileWithExtension, formatDate } from '../../../component/Common';
import UserProfile from '../../../component/ui-component/UserProfile';
import { getDefaultProfile } from '../../../component/Common';

const ViewRecruitmentComponent = ({ visible, onClose, editValues }) => {
	return (
		<>
			<div
				className={` togglesidebar bg-[#ffffff57] fixed h-full w-full top-0 left-0 p-6 z-[99999]  ${
					visible ? 'active' : ''
				}`}
			>
				<div
					onClick={onClose}
					className="w-full bg-[#ffffff57] overlay bg-[#0f1a32] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
				/>
				<div className="dark:bg-[#12243d] bg-[#fafafa] border-gray-300 border-l w-[85%] lg:w-[50%] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]">
					<button
						className="close h-8 w-8 rounded-full absolute top-5 right-5 bg-[#fff]"
						onClick={onClose}
					>
						X
					</button>
					<h1 className="p-6 dark:text-white text-[#4b5563] font-bold text-[20px] text-left pb-[20px] border-solid border-b dark:border-[#333333] border-gray-300">
						Recruitment Information
					</h1>
					<div className="rounded-lg">
						<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
							<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Full Name
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{editValues?.candidate?.full_name ||
												'N/A'}
										</h4>
									</div>
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Email
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{editValues?.candidate?.email ||
												'N/A'}
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Phone Number
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{editValues?.candidate
												?.phone_number || 'N/A'}
										</h4>
									</div>
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Address
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{editValues?.candidate?.address ||
												'N/A'}
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Skills
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{editValues?.candidate?.skills ||
												'N/A'}
										</h4>
									</div>
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Reference By
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{editValues?.candidate
												?.references || 'N/A'}
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-[33%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Resume
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{editValues?.candidate?.resume
												? readFileWithExtension(
														editValues?.candidate
															?.resume,
														'resumes/'
												  )
												: 'N/A'}
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Applied for Job
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{editValues?.job?.title || 'N/A'}
										</h4>
									</div>
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Interview Date
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{formatDate(
												editValues?.interview_date,
												'DD MMM YYYY'
											) || 'N/A'}
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Assigned Interviewer
										</p>
										{editValues?.assigned_interviewers &&
											editValues?.assigned_interviewers
												.length > 0 &&
											editValues?.assigned_interviewers.map(
												(user) => (
													<div
														key={user?.id}
														className="flex float-left mr-4 border-solid border-[#ddd] dark:border-[#2e4062] border p-1.5 px-2 rounded-lg shadow-xl hover:shadow-gray-400 dark:shadow-md dark:hover:shadow-[#0c4a6e] hover:cursor-pointer"
													>
														<UserProfile
															imageUrl={
																user?.profile_image
															}
															defaultImageUrl={getDefaultProfile(
																user?.gender
															)}
															altName={
																user?.fullname
															}
															className={
																'mr-[13px] h-[35px] w-[35px] rounded-full'
															}
														/>
														<span className="mt-[6.5px] text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
															{user?.fullname}
														</span>
													</div>
												)
											)}
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Notes
										</p>
										<h4
											className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]"
											dangerouslySetInnerHTML={{
												__html: `${editValues?.notes}`,
											}}
										></h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex mt-2">
									<div className="add-item w-full">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Feedback
										</p>
										<h4
											className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]"
											dangerouslySetInnerHTML={{
												__html: `${
													editValues?.feedback
														? editValues?.feedback
														: ''
												}`,
											}}
										></h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewRecruitmentComponent;
