import DarkIcon from '../../assets/images/dashboard-card.png';
import DatePicker from '../../component/ui-component/datePicker';
// import Vector from "../../assets/images/Vector.png";
import ExportImage from '../../assets/images/Export.png';
import { useSelector } from 'react-redux';

export default function WelcomeMassage() {
	const authUser = useSelector((state) => state.auth);

	return (
		<div className="md:my-[38px] my-[20px] mx-[20px] relative">
			<img
				src={DarkIcon}
				alt="DarkIcon"
				className="bg-[#5438FF] rounded-[15px] w-[100%] md:object-unset object-cover 
        h-[200px] md:h-unset"
			/>
			<div className="md:grid block grid-cols-2 md:py-[0px] md:px-[31.33px] px-[20px] py-[20px] custom-bg  absolute top-0 w-full h-full items-center">
				<div className="text-left">
					<h1 className="text-white md:text-4xl text-[18px] font-family-Montserrat-bold md:pb-[10px] pb-0">
						Good Morning,
						{` ${authUser?.userDetails?.first_name} ${authUser?.userDetails?.last_name}`}
					</h1>
					<p className="text-white text-sm md:text-2xl font-family-OpenSans md:my-0 my-2">
						Here’s your overview this week
					</p>
				</div>
				<div className="md:flex block justify-end ">
					<DatePicker />
					<button className="bg-white p-2 flex rounded-[8px] justify-center text-base color-dark items-center md:w-[97.17px] w-full md:mt-0 mt-[10px]">
						{/* <img src={Vector} alt="Vector" className="pr-1" /> */}
						<img
							src={ExportImage}
							alt="ExportImage"
							className="pr-1"
						/>
						Export
					</button>
				</div>
			</div>
		</div>
	);
}
