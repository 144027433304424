import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	holidayList: [],
	isFetching: false,
	totalPages: 0,
	totalRecords: 0,
};

const holidaysSlice = createSlice({
	name: 'holiday',
	initialState,
	reducers: {
		setHolidayData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			holidayList: action.payload?.results,
		}),
		setFetching: (state, action) => ({
			...state,
			isFetching: action.payload,
			holidayList: [],
		}),
	},
});

export const { setHolidayData, setFetching } = holidaysSlice.actions;
export default holidaysSlice.reducer;
