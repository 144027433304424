import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import InputComponent from '../../component/ui-component/InputComponent';
import Button from '../../component/ui-component/Button';
import { vsmUserEducation } from '../../utils/validations';
import DatePickerComponent from '../../component/ui-component/DatePickerComponent';
import { removeUserEducation } from '../../store/thunk/userThunk';
import { useDispatch } from 'react-redux';

const EducationForm = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	control,
}) => {
	const dispatch = useDispatch();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'education', // name of your field array
	});

	const handleAppend = useCallback(() => {
		append({
			institution: '',
			subject: '',
			starting_date: '',
			complete_date: '',
			degree: '',
			grade: '',
		});
	}, [append]);

	const handleRemove = useCallback(
		(index, education) => {
			if (education?.eid) {
				let userDetails = JSON.parse(localStorage.getItem('user_data'));
				let data = {
					eid: education?.eid,
					id: userDetails?.id,
				};
				dispatch(removeUserEducation(data));
			}
			remove(index);
		},
		[dispatch, remove]
	);

	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="px-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				{fields.map((education, index) => (
					<div
						key={index}
						className="hover:cursor-pointer dark:bg-[#12243d] bg-[#fafafa] rounded py-6 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1] mb-6"
					>
						<div>
							<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
								Education Information
								<div
									onClick={() =>
										handleRemove(index, education)
									}
									className="cursor-pointer float-right text-[#f83f37]"
									title="Delete"
								>
									<i
										className="fa fa-times"
										aria-hidden="true"
									></i>
								</div>
							</h3>
						</div>
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
							{education?.eid && education?.eid > 0 && (
								<InputComponent
									id={`education.${index}.eid`}
									title="Id"
									errors={errors}
									register={register}
									fieldvalidation={vsmUserEducation}
									inputType="hidden"
									index={index}
									isMultipleField={true}
								/>
							)}
							<InputComponent
								id={`education.${index}.institution`}
								title="Institution"
								errors={errors}
								register={register}
								fieldvalidation={vsmUserEducation}
								index={index}
								isMultipleField={true}
							/>
							<InputComponent
								id={`education.${index}.subject`}
								title="Subject"
								errors={errors}
								register={register}
								fieldvalidation={vsmUserEducation}
								index={index}
								isMultipleField={true}
							/>
							<DatePickerComponent
								id={`education.${index}.starting_date`}
								title="Starting Date"
								errors={errors}
								register={register}
								fieldvalidation={vsmUserEducation}
								index={index}
								isMultipleField={true}
							/>
							<DatePickerComponent
								id={`education.${index}.complete_date`}
								title="Complete Date"
								errors={errors}
								register={register}
								fieldvalidation={vsmUserEducation}
								index={index}
								isMultipleField={true}
							/>
							<InputComponent
								id={`education.${index}.degree`}
								title="Degree"
								errors={errors}
								register={register}
								fieldvalidation={vsmUserEducation}
								index={index}
								isMultipleField={true}
							/>
							<InputComponent
								id={`education.${index}.grade`}
								title="Grade"
								errors={errors}
								register={register}
								fieldvalidation={vsmUserEducation}
								index={index}
								isMultipleField={true}
							/>
						</div>
					</div>
				))}
				<div className="flex my-4">
					<div
						onClick={handleAppend}
						className="cursor-pointer dark:text-[#cbd5e1] text-blue-800 font-bold text-right"
					>
						<i
							className="fa fa-plus-circle dark:text-[#cbd5e1] text-blue-800"
							aria-hidden="true"
						></i>{' '}
						Add More
					</div>
				</div>
			</div>
			<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<Button
					buttonText="Submit"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default EducationForm;
