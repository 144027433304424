import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	countryList: [],
	loading: false,
};

export const countrySlice = createSlice({
	name: 'countries',
	initialState,
	reducers: {
		setCountryData: (state, action) => {
			return {
				...state,
				countryList: action.payload,
				loading: false,
			};
		},
		setLoading: (state) => {
			return {
				...state,
				loading: true, // Set loading to true when data fetch starts
			};
		},
	},
});

export const { setCountryData, setLoading } = countrySlice.actions;

export default countrySlice.reducer;
