import { createSlice } from '@reduxjs/toolkit';

// Country Slice
// const countryInitialState = {
//   countryList: [],
//   loading: false,
// };

// // Department Slice
// const departmentInitialState = {
//   departmentList: [],
//   loading: false,
// };

// // Roles Slice
// const roleInitialState = {
//   roleList: [],
//   loading: false,
// };

// export const countrySlice = createSlice({
//   name: "country",
//   initialState: countryInitialState,
//   reducers: {
//     setCountryData: (state, action) => {
//       return {
//         ...state,
//         countryList: action.payload,
//         loading: false,
//       };
//     },
//     setLoading: (state) => {
//       return {
//         ...state,
//         loading: true, // Set loading to true when data fetch starts
//       };
//     },
//   },
// });

// export const departmentSlice = createSlice({
//   name: "department",
//   initialState: departmentInitialState,
//   reducers: {
//     setDepartmentData: (state, action) => {
//       state.departmentList = action.payload;
//       state.loading = false;
//     },
//     setLoading: (state) => {
//       state.loading = true;
//     },
//   },
// });

// export const roleSlice = createSlice({
//   name: "role",
//   initialState: roleInitialState,
//   reducers: {
//     setRolesData: (state, action) => {
//       state.roleList = action.payload;
//       state.loading = false;
//     },
//     setLoading: (state) => {
//       state.loading = true;
//     },
//   },
// });

// export const { setCountryData, setLoading: setCountryLoading } = countrySlice.actions;
// export const { setDepartmentData, setLoading: setDepartmentLoading } = departmentSlice.actions;
// export const { setRolesData, setLoading: setRoleLoading } = roleSlice.actions;

// Combine the reducers of both slices
// export default {
// country: countrySlice.reducer,
// department: departmentSlice.reducer,
// role: roleSlice.reducer,
// };

const initialState = {
	countryList: [],
	departmentList: [],
	roleList: [],
	activityFeedList: [],
	loading: false,
};

export const commonSlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		setCountryData: (state, action) => {
			state.countryList = action.payload;
			state.loading = false;
		},
		setDepartmentData: (state, action) => {
			state.departmentList = action.payload;
			state.loading = false;
		},
		setRolesData: (state, action) => {
			state.roleList = action.payload;
			state.loading = false;
		},
		setActivityData: (state, action) => {
			state.activityFeedList = action.payload;
			state.loading = false;
		},
		setLoading: (state) => {
			state.loading = true;
		},
	},
});

export const {
	setCountryData,
	setDepartmentData,
	setRolesData,
	setActivityData,
	setLoading,
} = commonSlice.actions;
export default commonSlice.reducer;
