import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	skillsList: [],
	isFetching: false,
	totalPages: 0,
	totalRecords: 0,
};

const skillSlice = createSlice({
	name: 'skills',
	initialState,
	reducers: {
		setSkillsData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			skillsList: action.payload?.results,
		}),
		setFetching: (state, action) => ({
			...state,
			isFetching: action.payload,
			skillsList: [],
		}),
	},
});

export const { setSkillsData, setFetching } = skillSlice.actions;
export default skillSlice.reducer;
