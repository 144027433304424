import dashboard from '../../assets/images/dashboard-dark.png';
import skills from '../../assets/images/skill-icon.png';
import career from '../../assets/images/Career.png';
import profile from '../../assets/images/Profile.png';
import rewards from '../../assets/images/rewards.png';
import submission from '../../assets/images/submission.png';
import leaderboard from '../../assets/images/leaderboard.png';
import review_history from '../../assets/images/review-history.png';
import performance from '../../assets/images/360_performance.png';
import help_center from '../../assets/images/help_center.png';
import setting from '../../assets/images/setting.png';
import logout from '../../assets/images/logout.png';

const companyUserMenu = [
	{
		image: dashboard,
		url: '/dashboard',
		label: 'Dashboard',
		permission: '',
	},
	{
		image: skills,
		url: '/skills',
		label: 'Skills',
		permission: '',
	},
	{
		image: career,
		url: '/career',
		label: 'Career',
		permission: '',
	},
	// {
	// 	image: profile,
	// 	url: '/profile',
	// 	label: 'Profile',
	// 	permission: '',
	// },
	{
		image: submission,
		url: '/task-management',
		label: 'Tasks',
		permission: 'view_tasks',
	},
	{
		image: career,
		url: '/projects',
		label: 'Projects',
		permission: 'view_projects',
	},
	// {
	// 	image: career,
	// 	url: '/clients',
	// 	label: 'Clients',
	// 	permission: 'view_client',
	// },
	{
		image: submission,
		url: '/events',
		label: 'Events',
	},
	{
		image: career,
		url: '/leaves',
		label: 'Leave Requests',
		permission: 'view_userleaves',
	},
	{
		image: submission,
		url: '/holidays',
		label: 'Holidays',
		permission: 'view_holidays',
	},
	{
		image: leaderboard,
		url: '/interviews',
		label: 'Interviews',
		permission: 'view_interview',
	},
	{
		image: rewards,
		url: '/earning',
		label: 'XP Earning',
		permission: '',
	},
	{
		image: submission,
		url: '/submission',
		label: 'Submission',
		permission: '',
	},
	{
		image: leaderboard,
		url: '/leaderboard',
		label: 'Leaderboard',
		permission: '',
	},
	{
		image: review_history,
		url: '/review-history',
		label: 'Review History',
		permission: '',
	},
	{
		image: performance,
		url: '/checkpoints',
		label: 'Checkpoints',
		permission: '',
	},
	{
		image: leaderboard,
		url: '/attendance',
		label: 'Attendance',
		permission: '',
	},
	{
		image: profile,
		url: '/interviews',
		label: 'Recruitment',
		permission: 'view_interview',
	},
];
const companyAdminMenu = [
	{
		image: dashboard,
		url: '/admin-panel',
		label: 'Admin Panel',
	},
	{
		image: profile,
		url: '',
		label: 'Users',
		submenu: [
			{
				image: skills,
				url: '/users',
				label: 'All Users',
			},
			{
				image: career,
				url: '/leaves-management',
				label: 'Leaves Requests',
			},
			{
				image: career,
				url: '/users-away',
				label: "Who's away",
			},

			{
				image: skills,
				url: '/departments',
				label: 'Departments',
			},
			{
				image: skills,
				url: '/roles',
				label: 'Roles',
			},
			{
				image: skills,
				url: '/cskills',
				label: 'Skills',
			},
			{
				image: skills,
				url: '/xpearning',
				label: 'XP Earning Master',
			},
		],
	},
	{
		image: career,
		url: '',
		label: 'Projects',
		submenu: [
			{
				image: career,
				url: '/clients',
				label: 'Clients',
			},
			{
				image: career,
				url: '/projects',
				label: 'Projects',
			},
			{
				image: career,
				url: '/task-management',
				label: 'Tasks',
			},
		],
	},
	{
		image: leaderboard,
		url: '',
		label: 'Recruitment',
		submenu: [
			{
				image: career,
				url: '/jobs',
				label: 'Jobs',
			},
			{
				image: career,
				url: '/interviews',
				label: 'Interviews',
			},
			{
				image: career,
				url: '/linkedin',
				label: 'Linkedin',
			},
		],
	},
	{
		image: submission,
		url: '/holidays',
		label: 'Holidays',
	},
	{
		image: profile,
		url: '/reporting',
		label: 'Reporting',
	},
	{
		image: rewards,
		url: '/content',
		label: 'Content',
	},
	{
		image: submission,
		url: '/events',
		label: 'Events',
	},
];
const superAdminMenu = [
	{
		image: dashboard,
		url: '/admin-panel',
		label: 'Admin Panel',
	},
	{
		image: skills,
		url: '/companies',
		label: 'Company',
	},
];
const commonMenu = [
	{
		image: help_center,
		url: '/help-center',
		label: 'Help Center',
		company: true,
		user: true,
	},
	{
		image: setting,
		url: '/Setting',
		label: 'Setting',
		company: false,
		user: true,
	},
	{
		image: setting,
		url: '/system-setting',
		label: 'Setting',
		company: true,
		user: false,
	},
	{
		image: logout,
		url: '',
		label: 'Logout',
	},
];
export { superAdminMenu, companyUserMenu, companyAdminMenu, commonMenu };
