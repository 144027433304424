import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	jobsList: [],
	interviewList: [],
	isFetching: false,
	totalPages: 0,
	totalRecords: 0,
	accessToken: {},
};

const jobsSlice = createSlice({
	name: 'jobs',
	initialState,
	reducers: {
		setJobsData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			jobsList: action.payload?.results,
		}),
		setInterviewData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			interviewList: action.payload?.results,
		}),
		setAccessTokenData: (state, action) => ({
			...state,
			isFetching: false,
			accessToken: action.payload,
		}),
		setFetching: (state, action) => ({
			...state,
			isFetching: action.payload,
			jobsList: [],
			interviewList: [],
			accessToken: {},
		}),
	},
});

export const {
	setJobsData,
	setInterviewData,
	setAccessTokenData,
	setFetching,
} = jobsSlice.actions;
export default jobsSlice.reducer;
