import React, { useState, useEffect } from 'react';
import AchievementCard from '../../assets/images/Achievement-Card.png';
import ProgressBar from '../../component/ui-component/ProgressBar';
import Xp2 from '../../assets/images/xp2.png';
import Facebook from '../../assets/images/facebook.png';
import Twitter from '../../assets/images/twitter.png';
import Instagram from '../../assets/images/instagram.png';
import LinkedIn from '../../assets/images/inkedin.png';
import ProfileProgressbar from '../../pages/ProfilePage/ProfileProgressbar';
import Multiprogressbar from '../../pages/ProfilePage/Multiprogressbar';
import { getUserData } from '../../store/thunk/userThunk';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import BreadcrumbsComponent from '../../component/ui-component/BreadcrumbsComponent';
import { formatDate, getDefaultProfile } from '../../../src/component/Common';
import UserProfile from '../../component/ui-component/UserProfile';
const moment = require('moment');

export default function ViewUserComponent() {
	const { id } = useParams();
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const profile_data = useSelector((state) => state.user.profileData);
	const [age, setAge] = useState(null);

	useEffect(() => {
		let data = {
			id: id,
			is_view_profile: true,
		};
		dispatch(getUserData(data));
	}, [dispatch, id]);

	useEffect(() => {
		if (profile_data?.dob) {
			const startDate = moment(profile_data?.dob);
			const endDate = moment();
			const yearDiff = endDate.diff(startDate, 'years');
			setAge(yearDiff);
		}
	}, [profile_data?.dob]);

	const SocialMedia = [
		{
			img: Facebook,
			link: profile_data?.facebook_link,
		},
		{
			img: Twitter,
			link: profile_data?.twitter_link,
		},
		{
			img: Instagram,
			link: profile_data?.instagram_link,
		},
		{
			img: LinkedIn,
			link: profile_data?.linkedin_link,
		},
	];

	return (
		<>
			{profile_data && (
				<>
					<div className="md:p-[20px] p-[25px] mt-[10px]">
						<div className="float-left mt-2">
							<BreadcrumbsComponent
								showBreadCrumbs={true}
								pageTitle={'Users'}
								showMoreOptions={true}
								pageTitles={{
									page01_link: '/users',
									page01_title: 'Users',
									page02_title: 'View',
								}}
							/>
						</div>
						<div className="float-right">
							<button
								className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
								onClick={() => navigate(-1)}
							>
								<i
									className="fa fa-angle-double-left"
									aria-hidden="true"
								></i>{' '}
								Back
							</button>
						</div>
					</div>
					<div className="md:my-[38px] my-[20px] mx-[20px] relative">
						<div className="grid-cols-12 md:grid block gap-[30px] mb-[38px]">
							<div className="col-span-12">
								<div className="mt-[20px] relative h-full overflow-hidden rounded-[15px] p-5 dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
									<img
										src={AchievementCard}
										alt="AchievementCard"
										className="absolute w-full h-full object-cover top-0 left-0"
									/>
									<div className="flex flex-wrap relative p-2.5">
										<div className="relative lg:w-[48%] w-[100%] z-[99] pr-[35px] lg:border-r-2 border-dashed border-sky-800 mt-[10px]">
											<div className="user-profile relative flex items-center mb-[29px]">
												<span className="img h-[150px] w-[150px] inline-block rounded-full bg-white border border-slate-300">
													<UserProfile
														imageUrl={
															profile_data?.profile_image_url
														}
														defaultImageUrl={getDefaultProfile(
															profile_data?.gender
														)}
														altName={
															profile_data?.fullname
														}
														className={
															'h-full w-full object-cover rounded-full bg-white border border-slate-300 p-1'
														}
													/>
												</span>
												<div className="content text-left dark:text-white text-[#0C1932] ml-[22px]">
													<h2 className="text-[22px] not-italic font-bold leading-7 mb-[10px]">
														{
															profile_data?.first_name
														}{' '}
														{
															profile_data?.last_name
														}
													</h2>
													<p className="text-sm font-normal leading-[18px] mb-[5px]">
														{
															profile_data?.role
																?.title
														}
													</p>
													<p className="text-sm leading-[18px] mb-[5px] font-bold">
														User ID :{' '}
														{`${profile_data?.company_name
															.substr(0, 3)
															.toUpperCase()} - ${
															profile_data?.id
														}`}
													</p>
												</div>
											</div>

											<div className="relative z-[99] flex items-end">
												<img
													src={Xp2}
													alt="Xp2"
													className="h-[38.631px] mt-1"
												/>
												<div className="w-full pl-3 pt-2">
													<div className="relative z-[99] flex items-center justify-between mb-3">
														<p className="text-sm leading-[18px] font-family-OpenSans dark:text-[#fff] text-[#0C1932]  font-semibold">
															XP 750
														</p>
														<span className="text-right text-sm leading-[18px] font-family-OpenSans dark:text-[#fff] text-[#0C1932]  font-semibold">
															XP 1000
														</span>
													</div>

													<ProgressBar
														mainColor="#1F4466"
														progress="50%"
														shadow1="#2E6FAA"
														shadow2="#074675"
														shadow3="rgb(52, 113, 192)"
														bgColor="#3471c0"
														bgColor2="rgb(195,213,236)"
														bgColor3="rgb(255,255,255)"
														color1="#1ffbff"
														color2="#009eef"
													/>
												</div>
											</div>
										</div>
										<div className="contact lg:w-[48%] w-[100%] text-left mt-[10px] ml-[20px]">
											<div className="mb-[10px]">
												<p className="text-xs dark:text-white text-[#0C1932] font-normal leading-4 tracking-[5px]">
													Phone
												</p>
												<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px]">
													{profile_data?.phone_number}
												</h3>
											</div>
											<div className="mb-[10px]">
												<p className="text-xs dark:text-white text-[#0C1932] font-normal leading-4 tracking-[5px]">
													Email
												</p>
												<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px]">
													{profile_data?.email}
												</h3>
											</div>
											<div className="mb-[10px]">
												<p className="text-xs dark:text-white text-[#0C1932] font-normal leading-4 tracking-[5px]">
													Birthday
												</p>
												<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px]">
													{profile_data?.dob
														? moment(
																profile_data?.dob,
																'YYYY-MM-DD'
														  ).format(
																'DD MMM YYYY'
														  )
														: ''}
												</h3>
											</div>
											<div className="mb-[10px]">
												<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
													Address
												</p>
												<h3 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
													{`${
														profile_data?.address ||
														''
													}, ${
														profile_data?.city || ''
													}, ${
														profile_data?.postal_code ||
														''
													}`}
												</h3>
											</div>
											<div className="mb-[10px]">
												<p className="text-xs dark:text-white text-[#0C1932] font-normal leading-4 tracking-[5px]">
													Gender
												</p>
												<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px]">
													{profile_data?.gender
														.charAt(0)
														.toUpperCase() +
														profile_data?.gender.slice(
															1
														)}
												</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="grid-cols-12 md:grid block gap-[30px] mb-[38px]">
							<div className="lg:col-span-6 col-span-12 mt-5">
								<div className="basic-info-box relative h-full p-5 dark-dark-light-card">
									<h2 className="card-title">
										Basic Information
									</h2>
									<div className="information-boxes grid grid-cols-3 gap-[34px]">
										<div className="single-box text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[10px] p-[5px]">
											<p className="text-xs dark:text-white text-[#0C1932] text-center font-normal leading-4 tracking-[5px] pb-[7px]">
												Joined Date
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-center font-semibold leading-[21px] tracking-[1px]">
												{profile_data?.joining_date
													? moment(
															profile_data.joining_date,
															'YYYY-MM-DD'
													  ).format('DD MMM YYYY')
													: ''}
											</h4>
										</div>
										<div className="single-box text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[10px] p-[5px]">
											<p className="text-xs dark:text-white text-[#0C1932] text-center font-normal leading-4 tracking-[5px] pb-[7px]">
												Worked Id
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-center font-semibold leading-[21px] tracking-[1px]">
												{`${profile_data?.company_name
													.substr(0, 3)
													.toUpperCase()} - ${
													profile_data?.id
												}`}
											</h4>
										</div>
										<div className="single-box text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[10px] p-[5px]">
											<p className="text-xs dark:text-white text-[#0C1932] text-center font-normal leading-4 tracking-[5px] pb-[7px]">
												Worked For
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-center font-semibold leading-[21px] tracking-[1px]">
												{profile_data?.company_name}
											</h4>
										</div>
									</div>

									<div className="info-address pt-[47px] pb-[15px] flex">
										<div className="add-item w-[51%]">
											<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
												Address
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
												{profile_data?.address},{' '}
												{profile_data?.city},{' '}
												{profile_data?.postal_code}
											</h4>
										</div>
										<div className="add-item w-[30%]">
											<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
												Date of Birth
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
												{profile_data?.dob
													? moment(
															profile_data?.dob,
															'YYYY-MM-DD'
													  ).format('DD MMM YYYY')
													: ''}
											</h4>
										</div>
										<div className="add-item w-[19%] pl-5">
											<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
												Age
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
												{age}
											</h4>
										</div>
									</div>
									<div className="info-address pt-[47px] pb-[15px] flex">
										<div className="add-item w-[30%]">
											<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
												Marital Status
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
												{profile_data?.marital_status
													? profile_data?.marital_status
															.charAt(0)
															.toUpperCase() +
													  profile_data?.marital_status.slice(
															1
													  )
													: 'N/A'}
											</h4>
										</div>
										<div className="add-item w-[30%]">
											<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
												Passport Number
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
												{profile_data?.passport_number ||
													'N/A'}
											</h4>
										</div>
										<div className="add-item w-[30%]">
											<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
												Passport Expiry Date
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
												{profile_data?.passport_expiry_date ||
													'N/A'}
											</h4>
										</div>
									</div>
									<div className="info-address pt-[15px] pb-[42px] flex">
										<div className="add-item w-[100%]">
											<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
												About Me
											</p>
											<p
												className="dark:text-[#BFC6D2] text-left text-black text-[15px] leading-[25px]"
												dangerouslySetInnerHTML={{
													__html: `${
														profile_data?.about ||
														'N/A'
													}`,
												}}
											></p>
										</div>
									</div>

									<ul className="social-icon flex">
										{SocialMedia.map((i, item) => (
											<li
												key={item}
												className="mr-[22px]"
											>
												<a
													href={i.link}
													target="_blank"
													rel="noopener noreferrer"
													className="hover:cursor-pointer h-[35px] w-[35px] rounded-full  bg-[#223457] hover:bg-[linear-gradient(137.02deg,#bd00ff_0%,#216cff_103.79%)] flex items-center justify-center"
												>
													<img
														src={i.img}
														alt="img"
													/>
												</a>
											</li>
										))}
									</ul>
								</div>
							</div>

							<div className="lg:col-span-6 col-span-12 mt-5">
								<div className="relative p-5 dark-dark-light-card">
									<div className="relative">
										<h2 className="card-title">
											My Skills
										</h2>
										{/* <button className="absolute top-0 right-0 expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]">
											View All
										</button> */}
									</div>
									<ProfileProgressbar
										userSkills={profile_data?.skills}
									/>
								</div>
								<div className="relative p-5 dark-dark-light-card mt-8">
									<h2 className="card-title">
										Bank information
									</h2>
									<div className="info-address pb-[15px] flex">
										<div className="add-item w-[40%]">
											<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
												Bank name
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
												{profile_data?.bank_name ||
													'N/A'}
											</h4>
										</div>
										<div className="add-item w-[40%]">
											<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
												Bank account No.
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
												{profile_data?.bank_account_number ||
													'N/A'}
											</h4>
										</div>
									</div>
									<div className="info-address pb-[15px] flex">
										<div className="add-item w-[40%]">
											<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
												IFSC Code
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
												{profile_data?.bank_ifsc_number ||
													'N/A'}
											</h4>
										</div>
										<div className="add-item w-[40%]">
											<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
												PAN No
											</p>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
												{profile_data?.bank_pan_number ||
													'N/A'}
											</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="grid-cols-12 md:grid block gap-[30px] mb-[38px]">
							<div className="col-span-6  md:mb-0 mb-[20px]">
								<div className="basic-info-box relative h-full p-5 dark-dark-light-card">
									<h2 className="card-title">
										Education Informations
									</h2>
									<ol className="border-l border-neutral-300 dark:border-neutral-500">
										{profile_data?.education &&
											profile_data?.education.map(
												(education, index) => (
													<li key={index}>
														<div className="flex-start flex items-center pt-3">
															<div className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-neutral-300 dark:bg-neutral-500"></div>
															<p className="text-base text-neutral-500 dark:text-neutral-300 font-semibold">
																{
																	education?.institution
																}
															</p>
														</div>
														<div className="mb-6 ml-4 text-left">
															<div className="text-sm text-neutral-500 dark:text-neutral-300">
																{
																	education?.degree
																}
															</div>
															<p className="mb-3 text-sm text-neutral-500 dark:text-neutral-300">
																{`${moment(
																	education?.starting_date
																).year()} - ${moment(
																	education?.complete_date
																).year()}`}
															</p>
														</div>
													</li>
												)
											)}
									</ol>
									{profile_data?.education.length === 0 && (
										<>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold">
												{' '}
												N/A{' '}
											</h4>
										</>
									)}
								</div>
							</div>
							<div className="col-span-6  md:mb-0 mb-[20px]">
								<div className="basic-info-box relative h-full p-5 dark-dark-light-card">
									<h2 className="card-title">
										Experience Informations
									</h2>
									<ol className="border-l border-neutral-300 dark:border-neutral-500">
										{profile_data?.experience &&
											profile_data?.experience.map(
												(experience, index) => {
													const startDate = moment(
														experience?.start_date
													);
													const endDate = moment(
														experience?.end_date
													);
													const years = endDate.diff(
														startDate,
														'years'
													);
													const months =
														endDate.diff(
															startDate,
															'months'
														) % 12;

													return (
														<li key={index}>
															<div className="flex-start flex items-center pt-3">
																<div className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-neutral-300 dark:bg-neutral-500"></div>
																<p className="text-base text-neutral-500 dark:text-neutral-300 font-semibold">
																	{`${experience?.job_position} at ${experience?.company_name}`}
																</p>
															</div>
															<div className="mb-6 ml-4 text-left">
																<p className="mb-3 text-sm text-neutral-500 dark:text-neutral-300">
																	{`${formatDate(
																		experience?.start_date,
																		'MMM YYYY'
																	)} - ${
																		experience?.end_date
																			? formatDate(
																					experience?.end_date,
																					'MMM YYYY'
																			  )
																			: 'Present'
																	}`}
																	{` (${years} years ${months} months)`}
																</p>
															</div>
														</li>
													);
												}
											)}
									</ol>
									{profile_data?.experience.length === 0 && (
										<>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold">
												{' '}
												N/A{' '}
											</h4>
										</>
									)}
								</div>
							</div>
						</div>

						<div className="grid-cols-12 md:grid block gap-[30px] mb-[38px]">
							<div className="col-span-6  md:mb-0 mb-[20px]">
								<div className="basic-info-box relative h-full p-5 dark-dark-light-card">
									<h2 className="card-title">
										Emergency Contact
									</h2>
									{profile_data?.emergency_contact &&
										profile_data.emergency_contact.map(
											(ec, index) => (
												<div
													key={index}
													className="rounded-lg py-4 p-4 mt-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#c9c9c9]"
												>
													<h5 className="mb-2 text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
														{index === 0
															? 'Primary Contact'
															: 'Secondary Contact'}
													</h5>
													<div className="info-address pb-[15px] flex">
														<div className="add-item w-[30%]">
															<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
																Name
															</p>
															<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
																{ec?.name || ''}
															</h4>
														</div>
														<div className="add-item w-[30%]">
															<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
																Relationship
															</p>
															<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
																{ec?.relationship ||
																	''}
															</h4>
														</div>
														<div className="add-item w-[30%]">
															<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
																Phone
															</p>
															<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
																{`${ec?.phone1}, ${ec?.phone2}`}
															</h4>
														</div>
													</div>
												</div>
											)
										)}
									{profile_data?.emergency_contact.length ===
										0 && (
										<>
											<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold">
												N/A
											</h4>
										</>
									)}
								</div>
							</div>
							<div className="col-span-6  md:mb-0 mb-[20px]">
								<div className="basic-info-box relative h-full p-5 dark-dark-light-card">
									<h2 className="card-title">
										Family Informations
									</h2>
									<div className="rounded-lg py-4 p-4 mt-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#c9c9c9]">
										<div className="flex flex-col">
											<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
												<div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
													<div className="overflow-hidden">
														<table className="min-w-full text-left text-sm font-light">
															<thead className="font-medium">
																<tr>
																	<th
																		scope="col"
																		className="pb-3 text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
																	>
																		Name
																	</th>
																	<th
																		scope="col"
																		className="pb-3 text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
																	>
																		Relationship
																	</th>
																	<th
																		scope="col"
																		className="pb-3 text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
																	>
																		Date of
																		Birth
																	</th>
																	<th
																		scope="col"
																		className="pb-3 text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
																	>
																		Phone
																	</th>
																</tr>
															</thead>
															<tbody>
																{profile_data?.family_info &&
																	profile_data?.family_info.map(
																		(
																			familyinfo,
																			index
																		) => (
																			<tr
																				key={
																					index
																				}
																			>
																				<td className="pb-2 text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
																					{familyinfo?.name ||
																						''}
																				</td>
																				<td className="pb-2 text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
																					{familyinfo?.relationship ||
																						''}
																				</td>
																				<td className="pb-2 text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
																					{`${formatDate(
																						familyinfo?.dob,
																						'DD MMM YYYY'
																					)}`}
																				</td>
																				<td className="pb-2 text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
																					{
																						familyinfo?.phone
																					}
																				</td>
																			</tr>
																		)
																	)}
																{profile_data
																	?.family_info
																	.length ===
																	0 && (
																	<>
																		<tr colSpan="4">
																			<td className="text-base dark:text-white text-[#0C1932] text-left font-semibold">
																				N/A
																			</td>
																		</tr>
																	</>
																)}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="mb-[38px] overflow-x-auto top-scroll">
							<div className="items flex w-[max-content] pb-7">
								<Multiprogressbar
									number="5"
									name="Successfully complete a task"
								/>
								<Multiprogressbar
									number="20"
									name="Complete a project milestone"
								/>
								<Multiprogressbar
									number="10"
									name="Present a successful proposal"
								/>
								<Multiprogressbar
									number="5"
									name="Provide constructive peer feedback"
								/>
								<Multiprogressbar
									number="10"
									name="Receive positive peer feedback"
								/>
								<Multiprogressbar
									number="20"
									name="Successfully complete a task"
								/>
								<Multiprogressbar
									number="15"
									name="Complete a project milestone"
								/>
								<Multiprogressbar
									number="7"
									name="Present a successful proposal"
								/>
								<Multiprogressbar
									number="8"
									name="Provide constructive peer feedback"
								/>
								<Multiprogressbar
									number="2"
									name="Receive positive peer feedback"
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
