import React, { useEffect } from 'react';
import { getActivityFeedList } from '../../store/thunk/commonThunk';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultProfile } from '../../component/Common';
import UserProfile from '../../component/ui-component/UserProfile';

export default function ActivityFeed() {
	const dispatch = useDispatch();
	const authUser = useSelector((state) => state.auth);
	const activity_data = useSelector((state) => state.common.activityFeedList);

	useEffect(() => {
		if (!authUser?.userDetails?.is_superuser) {
			dispatch(getActivityFeedList({ pageSize: 10 }));
		}
	}, [dispatch, authUser?.userDetails]);

	return (
		<>
			<div className="flex items-center justify-between mb-[20px] h-[40px]">
				<h1 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7">
					Activity Feed
				</h1>
			</div>
			<div className="p-[21.84px] pr-[10px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] rounded-[15px]">
				<div className="h-[271.81px] overflow-auto custom-scroll pr-[12.27px]">
					{activity_data.map((row, index) => (
						<div
							key={row.id}
							className={`flex text-left rounded-[8px] bg-[#f2f2f2] dark:bg-[#0F1A32] py-[22.66px] mb-[17.22px] px-[15px] ${
								index === activity_data.length - 1
									? 'mb-[0]'
									: ''
							}`}
						>
							<UserProfile
								imageUrl={row?.created_by?.profile_image_url}
								defaultImageUrl={getDefaultProfile(
									row?.created_by?.gender
								)}
								altName={row?.created_by?.fullname}
								className={
									'h-[50px] w-[50px] mr-[12.16px] rounded-full'
								}
							/>
							<p
								className="dark:text-[#BFC6D2] text-black text-[15px] leading-[25px] w-[380px]"
								dangerouslySetInnerHTML={{
									__html: `${row?.message} <span className="font-black ml-[8px] inline-block whitespace-nowrap rounded-full bg-warning-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-xs leading-none text-warning-800">${row?.activity_type_name}</span>`,
								}}
							></p>
							<span className="dark:text-[#BFC6D2] text-black  text-xs leading-[22px] text-right w-[100px]">
								{row?.minutes_day_diff}
							</span>
						</div>
					))}
				</div>
			</div>
		</>
	);
}
