import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	addUserTimeTracking,
	getUserTimeTrackingList,
} from '../../store/thunk/userThunk';
import { formatTime } from '../../component/Common';

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
	const dispatch = useDispatch();
	const authUser = useSelector((state) => state.auth);
	const user_id = authUser?.userDetails?.id;

	const intervalRef = useRef(null);
	const stopTimeRef = useRef(null);

	const timerData =
		JSON.parse(localStorage.getItem(`timerData_${user_id}`)) || {};
	const { timer, now, isRunning, isInitial, lastUpdatedDay } = timerData;

	const [timerState, setTimerState] = useState({
		timer: timer !== undefined ? timer : Date.now(),
		now: now !== undefined ? now : Date.now(),
		isRunning: isRunning !== undefined ? isRunning : false,
		isInitial: isInitial !== undefined ? isInitial : false,
		lastUpdatedDay:
			lastUpdatedDay !== undefined ? lastUpdatedDay : new Date().getDay(),
	});

	useEffect(() => {
		if (user_id && !authUser?.userDetails?.is_companyadmin) {
			const newTimerState = {
				timer: timer !== undefined ? timer : Date.now(),
				now: now !== undefined ? now : Date.now(),
				isRunning: isRunning !== undefined ? isRunning : false,
				isInitial: isInitial !== undefined ? isInitial : false,
				lastUpdatedDay:
					lastUpdatedDay !== undefined
						? lastUpdatedDay
						: new Date().getDay(),
			};

			setTimerState(newTimerState);
			localStorage.setItem(
				`timerData_${user_id}`,
				JSON.stringify(newTimerState)
			);
		}
	}, [user_id, authUser, isInitial, isRunning, lastUpdatedDay, now, timer]);

	useEffect(() => {
		if (timerState.timer && timerState.isRunning) {
			intervalRef.current = setInterval(() => {
				setTimerState((prevState) => ({
					...prevState,
					timer: Date.now(),
				}));
			}, 10);
		}

		return () => {
			clearInterval(intervalRef.current);
		};
	}, [timerState]);

	const handleStartPauseClick = (argument = {}) => {
		setTimerState((prevState) => ({
			...prevState,
			isRunning: !prevState.isRunning,
		}));

		const currentDay = new Date().getDay();

		if (!timerState.isRunning) {
			// Start the timer
			if (
				timerState.isInitial ||
				currentDay !== timerState.lastUpdatedDay
			) {
				const initialTime = Date.now() - (stopTimeRef.current || 0);
				setTimerState((prevState) => ({
					...prevState,
					now: initialTime,
					timer: initialTime,
					isInitial: false,
					lastUpdatedDay: currentDay,
				}));
			} else {
				setTimerState((prevState) => {
					const newNow =
						prevState.now +
						(Date.now() - (stopTimeRef.current || Date.now()));
					return {
						...prevState,
						now: newNow,
						timer: Date.now(),
					};
				});
			}

			intervalRef.current = setInterval(() => {
				setTimerState((prevState) => ({
					...prevState,
					timer: prevState.timer + 10,
				}));
			}, 10);
		} else {
			// Stop the timer
			stopTimeRef.current = Date.now();
			clearInterval(intervalRef.current);
		}
		let data = {
			punch_type: timerState.isRunning ? 'out' : 'in',
			is_from_logout:
				argument && argument !== undefined
					? argument.is_from_logout
					: false,
		};
		dispatch(addUserTimeTracking(data));

		setTimeout(() => {
			if (!argument.is_from_logout) {
				dispatch(getUserTimeTrackingList({ show_all: true }));
			}
		}, 1000);
	};

	return (
		<TimerContext.Provider
			value={{
				...timerState,
				formatTime,
				handleStartPauseClick,
			}}
		>
			{children}
		</TimerContext.Provider>
	);
};

export const useTimer = () => useContext(TimerContext);
