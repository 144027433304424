import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	notificationSuccess,
	notificationFail,
} from '../slices/notificationSlice';
import { setLoading } from '../slices/dataLoadingSlice';
import {
	setUserData,
	setFetching,
	setUserRolesData,
	setUserProfileData,
	setProfileData,
	setUserPermissions,
	setUserTimeTrackingData,
	setuserTimeTrackingListsData,
	setStatisticsTrackingData,
} from '../slices/userSlice';

import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';
import {
	vsmUser,
	vsmUserEducation,
	vsmUserExperience,
	vsmUserEmergency,
	vsmUserFamilyInfo,
} from '../../utils/validations';

export const getUserList = createAsyncThunk(
	'getUserList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageNo, pageSize, searchText, sortBy } = _request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';
				if (sortField === 'fullname') {
					sortField = 'first_name';
				}
				if (sortField === 'department.title') {
					sortField = 'department__title';
				}
				if (sortField === 'role.title') {
					sortField = 'role__title';
				}
				if (sortField === 'job_type_name') {
					sortField = 'job_type';
				}

				if (sortField !== 'active' && sortField !== 'action') {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';

			const response = await apiClient(false).get(
				`/accounts/user/lists/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				let userData = response?.data?.results;
				response.data.results =
					userData &&
					userData.map((item, i) => {
						item.fullname = `${item?.first_name} ${item?.middle_name} ${item?.last_name}`;
						return item;
					});

				dispatch(setUserData(response?.data));
				dispatch(getRoleUserList({ pageSize: 10 }));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addUser = createAsyncThunk(
	'addUser',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().post(
				`/accounts/register/`,
				_request
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getUserList());
				dispatch(notificationSuccess(vsmUser?.addSucces));
				toast.success(vsmUser?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			const errorMessage =
				err?.response?.data?.message ||
				err?.message ||
				'An error occurred.';
			dispatch(notificationFail(errorMessage));
			toast.error(errorMessage);
		}
	}
);

export const editUser = createAsyncThunk(
	'editUser',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().patch(
				`/accounts/user/update/${_request.id}/`,
				_request
			);

			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getUserList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmUser?.updateSuccess);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			const errorMessage =
				err?.response?.data?.message ||
				err?.response?.data[0] ||
				err?.message ||
				'An error occurred.';
			dispatch(notificationFail(errorMessage));
			toast.error(errorMessage);
		}
	}
);

export const removeUser = createAsyncThunk(
	'removeUser',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(
				`/accounts/user/delete/${_request.id}/`
			);
			dispatch(setLoading(false));
			dispatch(getUserList());
			dispatch(notificationSuccess(vsmUser?.deleteSuccess));
			toast.success(vsmUser?.deleteSuccess);
		} catch (err) {
			dispatch(setLoading(false));
			const errorMessage =
				err?.response?.data?.message ||
				err?.message ||
				'An error occurred.';
			dispatch(notificationFail(errorMessage));
			toast.error(errorMessage);
		}
	}
);

export const updateUserStatus = createAsyncThunk(
	'updateUserStatus',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const { userid, is_active } = _request;
			const response = await apiClient(false).patch(
				`/accounts/user/statusupdate/${userid}/`,
				{ is_active }
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getUserList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmUser?.update_status_success);
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			const errorMessage =
				err?.response?.data?.message ||
				err?.message ||
				err?.response?.data[0] ||
				'An error occurred.';
			dispatch(notificationFail(errorMessage));
			toast.error(errorMessage);
		}
	}
);

export const getRoleUserList = createAsyncThunk(
	'getRoleUserList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageSize } = _request;
			let ordering = '&ordering=-id';

			const response = await apiClient(false).get(
				`/accounts/user/lists/?offset=${pageSize}${ordering}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setUserRolesData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const getUserData = createAsyncThunk(
	'getUserData',
	async (_request, { dispatch }) => {
		try {
			const { is_view_profile } = _request;
			dispatch(setLoading(true));
			const response = await apiClient().get(
				`/accounts/user/profile/${_request?.id}`
			);
			dispatch(setLoading(false));
			if (is_view_profile && is_view_profile !== undefined) {
				dispatch(setProfileData(response?.data));
			} else {
				dispatch(setUserProfileData(response?.data));
			}
			localStorage.setItem(
				'profile_image',
				response?.data?.user_info?.profile_image_url || ''
			);
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const UpdateUserProfile = createAsyncThunk(
	'UpdateUserProfile',
	async (_request, { dispatch }) => {
		try {
			const formData = new FormData();
			const { is_settings_update } = _request;
			delete _request.is_settings_update;

			// Append other form data fields
			Object.keys(_request).forEach((key) => {
				if (
					key !== 'skills' &&
					key !== 'profile_image' &&
					_request[key] !== null
				) {
					formData.append(key, _request[key]);
				}
			});

			// Append file data if provided
			if (_request.profile_image) {
				formData.append('profile_image', _request.profile_image);
			}

			if (_request.skills && _request.skills.length > 0) {
				_request.skills.map((item) => formData.append('skills', item));
			}

			dispatch(setLoading(true));
			let response = {};
			if (is_settings_update) {
				response = await apiClient(false).patch(
					`/accounts/user/update/${_request.id}/`,
					_request
				);
			} else {
				response = await apiClient(true).patch(
					`/accounts/user/update/${_request.id}/`,
					formData
				);
			}
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getUserData(_request));
				let message = '';
				if (!is_settings_update) {
					message = vsmUser?.profileUpdate;
				} else {
					message = vsmUser?.userSettings;
				}

				dispatch(notificationSuccess(message));
				toast.success(message);
				if (_request.callbackFunction) {
					_request.callbackFunction();
				}
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			const errorMessage =
				err?.response?.data?.message ||
				err?.response?.data[0] ||
				err?.message ||
				'An error occurred.';
			dispatch(notificationFail(errorMessage));
			toast.error(errorMessage);
		}
	}
);

export const updateUserEducation = createAsyncThunk(
	'updateUserEducation',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient().post(
				`/accounts/user/educations/`,
				_request.education
			);
			dispatch(setLoading(false));
			dispatch(getUserData(_request));
			_request.callbackFunction();
			toast.success(vsmUserEducation?.updateSuccess);
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeUserEducation = createAsyncThunk(
	'removeUserEducation',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(
				`/accounts/user/educations/${_request.eid}/`
			);
			dispatch(setLoading(false));
			dispatch(getUserData(_request));
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const updateUserExperience = createAsyncThunk(
	'updateUserExperience',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient().post(
				`/accounts/user/experience/`,
				_request.experience
			);
			dispatch(setLoading(false));
			dispatch(getUserData(_request));
			_request.callbackFunction();
			toast.success(vsmUserExperience?.updateSuccess);
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeUserExperience = createAsyncThunk(
	'removeUserExperience',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(
				`/accounts/user/experience/${_request.eid}/`
			);
			dispatch(setLoading(false));
			dispatch(getUserData(_request));
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const updateUserEC = createAsyncThunk(
	'updateUserEC',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient().post(
				`/accounts/user/emergency_contact/`,
				_request.emergencyc
			);
			dispatch(setLoading(false));
			dispatch(getUserData(_request));
			_request.callbackFunction();
			toast.success(vsmUserEmergency?.updateSuccess);
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const updateUserFamilyInfo = createAsyncThunk(
	'updateUserFamilyInfo',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient().post(
				`/accounts/user/family_information/`,
				_request.familyinfo
			);
			dispatch(setLoading(false));
			dispatch(getUserData(_request));
			_request.callbackFunction();
			toast.success(vsmUserFamilyInfo?.updateSuccess);
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeUserFamilyInfo = createAsyncThunk(
	'removeUserFamilyInfo',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(
				`/accounts/user/family_information/${_request.fiid}/`
			);
			dispatch(setLoading(false));
			dispatch(getUserData(_request));
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const getUserPermissions = createAsyncThunk(
	'getUserPermissions',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient(false).get(
				`/accounts/user/permissions/`
			);
			dispatch(setLoading(false));
			dispatch(setUserPermissions(response?.data));
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const getUserTimeTrackingList = createAsyncThunk(
	'getUserTimeTrackingList',
	async (_request = {}, { dispatch }) => {
		const { show_all, search_by_year, search_by_month, search_by_date } =
			_request;
		let searchByMonth = '',
			searchByYear = '',
			searchByDate = '';
		if (search_by_month) {
			searchByMonth = `&search_by_month=${search_by_month}`;
		}
		if (search_by_year) {
			searchByYear = `&search_by_year=${search_by_year}`;
		}
		if (search_by_date) {
			searchByDate = `&search_by_date=${search_by_date}`;
		}
		try {
			dispatch(setLoading(true));
			const response = await apiClient(false).get(
				`/accounts/user/timetracking/?show_all=${show_all}${searchByMonth}${searchByYear}${searchByDate}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				if (show_all) {
					dispatch(setuserTimeTrackingListsData(response?.data));
				} else {
					dispatch(setUserTimeTrackingData(response?.data));
				}
				dispatch(getStatisticsTimeTrackingList());
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const getStatisticsTimeTrackingList = createAsyncThunk(
	'getStatisticsTimeTrackingList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient(false).get(
				`/accounts/user/timetracking_statistics/`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setStatisticsTrackingData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addUserTimeTracking = createAsyncThunk(
	'addUserTimeTracking',
	async (_request, { dispatch }) => {
		const { is_from_logout } = _request;
		delete _request.is_from_logout;
		try {
			dispatch(setLoading(true));
			await apiClient().post(`/accounts/user/timetracking/`, _request);
			dispatch(setLoading(false));
			if (!is_from_logout) {
				dispatch(getUserTimeTrackingList({ show_all: false }));
			}
		} catch (err) {
			dispatch(setLoading(false));
			const errorMessage =
				err?.response?.data?.message ||
				err?.message ||
				'An error occurred.';
			dispatch(notificationFail(errorMessage));
			toast.error(errorMessage);
		}
	}
);
