import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getDefaultProfile } from '../../component/Common';
import UserProfile from './UserProfile';

export default function MultiSelectComponent({
	selectOptionLists,
	onChangeSelectValue,
	selectedValue,
	title,
	id,
	error,
}) {
	// const mapToOption = (item) => ({
	//   value: item.id.toString(),
	//   label: item.title || item.name || `${item.first_name} ${item.last_name}`,
	// });
	const themeMode = localStorage.getItem('theme');
	const mapToOption = (item) => ({
		value: item.id.toString(),
		label: (
			<div className="flex multi-options">
				{item?.first_name && (
					<UserProfile
						imageUrl={item?.profile_image_url}
						defaultImageUrl={getDefaultProfile(item?.gender)}
						altName={item?.first_name}
						className={
							'rounded-full h-[30px] w-[30px] inline-block mt-[2px]'
						}
					/>
				)}
				<div className="ml-3">
					<h6 className="font-bold mb-0 text-[12px]">
						{item?.title ||
							item?.name ||
							`${item?.first_name} ${item?.last_name}`}
					</h6>
					{item?.first_name && (
						<p className="text-[10px]">
							{item?.is_companyadmin
								? 'Company Admin'
								: item?.designation || ''}
						</p>
					)}
				</div>
				{/* <span>
          {item.title || item.name || `${item.first_name} ${item.last_name}`}
        </span> */}
			</div>
		),
	});

	let selectedOptionsVal = '';
	if (selectedValue) {
		selectedOptionsVal = selectedValue.map(mapToOption);
	}

	const [optionsReady, setOptionsReady] = useState(
		selectOptionLists.length > 0
	);
	const [selectedOptions, setSelectedOptions] = useState(selectedOptionsVal);

	useEffect(() => {
		setOptionsReady(selectOptionLists.length > 0);
	}, [selectOptionLists]);

	const handleSelectChange = (e) => {
		setSelectedOptions(e);
		onChangeSelectValue(id, e);
	};

	const colourStyles = {
		option: (styles, { isFocused, isSelected }) => ({
			...styles,
			background: isFocused
				? 'linear-gradient(137deg, #211ec1 0%, #00fff0 100%)' // Your gradient here
				: 'transparent',
			color: isFocused
				? '#fff'
				: isSelected
				? 'white'
				: themeMode === 'light'
				? '#0f172a'
				: '#fff',
		}),
	};

	return optionsReady ? (
		<div className="relative h-12 min-w-[200px] select-options-list mb-3">
			<label className="dark:text-white text-[#1f2937] text-left left-0 flex w-full mb-1 text-sm">
				{title}
			</label>
			<div className="selectbox">
				<Select
					options={selectOptionLists.map(mapToOption)}
					placeholder={title}
					value={selectedOptions}
					onChange={handleSelectChange}
					isSearchable={true}
					isMulti
					styles={colourStyles}
				/>
			</div>
			{error && (
				<p className="error-message mb-2">
					Please select at least one options!
				</p>
			)}
		</div>
	) : null;
}
