import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRolesList } from '../../store/thunk/rolesThunk';
import ReactTablePagination from '../../component/ui-component/pagination/ReactTablePagination';
import Delete from '../../assets/images/Delete.png';
import Edit from '../../assets/images/Edit.png';

const ListComponent = ({ handleSetEditValues, handleDeleteClick }) => {
	const [recordsPerPage, setRecordsPerPage] = useState(10);

	const dispatch = useDispatch();

	const rolesData = useSelector((state) => state.roles);

	const columns = [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: 'Role Name',
			accessor: 'title',
			width: '20%',
		},
		{
			Header: 'Department Name',
			accessor: 'department.title',
			width: '20%',
		},
		{
			Header: 'Description',
			accessor: 'description',
			width: '50%',
		},
		{
			Header: 'Action',
			accessor: 'action',
			disableSortBy: true,
			Cell: ({ row, value }) => (
				<div className="flex">
					<div
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[39.56px] h-[39.56px] rounded-full mr-[8px] border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						onClick={() => handleSetEditValues(row?.original)}
					>
						<img src={Edit} alt="Edit" />
					</div>
					<div
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[39.56px] h-[39.56px] rounded-full border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						onClick={() => handleDeleteClick(row?.original)}
						title="Delete"
					>
						<img src={Delete} alt="Delete" />
					</div>
				</div>
			),
		},
	];

	const fetchData = useCallback(
		(pageSize, pageIndex, searchText, sortBy) => {
			dispatch(
				getRolesList({
					pageSize: pageSize,
					pageNo: pageIndex,
					searchText: searchText,
					sortBy: sortBy,
				})
			);
		},
		[dispatch]
	);

	return (
		<>
			<ReactTablePagination
				columns={columns}
				data={rolesData?.rolesList}
				fetchData={fetchData}
				pageCount={Math.ceil(rolesData?.totalRecords / recordsPerPage)}
				totalRecords={rolesData?.totalRecords}
				recordsPerPage={recordsPerPage}
				setRecordsPerPage={setRecordsPerPage}
				csvFileName="roles_lists.csv"
			/>
		</>
	);
};

export default ListComponent;
