import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Accordion,
	AccordionHeader,
	AccordionBody,
} from '@material-tailwind/react';

const data = [
	{
		id: '1',
		queation: 'What are two things you can never eat for breakfast?',
		subQueation: 'What gets wetter the more it dries?',
		answer: 'Integer eu turpis eget ante viverra molestie non a nisi. Praesent sed est ultricies, posuere neque sed, molestie nisi. Nulla facilisi. Suspendisse rutrum porta sem',
	},
	{
		id: '2',
		queation: 'What are two things you can never eat for breakfast?',
		subQueation: 'What gets wetter the more it dries?',
		answer: 'Integer eu turpis eget ante viverra molestie non a nisi. Praesent sed est ultricies, posuere neque sed, molestie nisi. Nulla facilisi. Suspendisse rutrum porta sem',
	},
	{
		id: '3',
		queation: 'What are two things you can never eat for breakfast?',
		subQueation: 'What gets wetter the more it dries?',
		answer: 'Integer eu turpis eget ante viverra molestie non a nisi. Praesent sed est ultricies, posuere neque sed, molestie nisi. Nulla facilisi. Suspendisse rutrum porta sem',
	},
	{
		id: '4',
		queation: 'What are two things you can never eat for breakfast?',
		subQueation: 'What gets wetter the more it dries?',
		answer: 'Integer eu turpis eget ante viverra molestie non a nisi. Praesent sed est ultricies, posuere neque sed, molestie nisi. Nulla facilisi. Suspendisse rutrum porta sem',
	},
];
const data1 = [
	{
		id: '5',
		queation: 'What are two things you can never eat for breakfast?',
		subQueation: 'What gets wetter the more it dries?',
		answer: 'Integer eu turpis eget ante viverra molestie non a nisi. Praesent sed est ultricies, posuere neque sed, molestie nisi. Nulla facilisi. Suspendisse rutrum porta sem',
	},
	{
		id: '6',
		queation: 'What are two things you can never eat for breakfast?',
		subQueation: 'What gets wetter the more it dries?',
		answer: 'Integer eu turpis eget ante viverra molestie non a nisi. Praesent sed est ultricies, posuere neque sed, molestie nisi. Nulla facilisi. Suspendisse rutrum porta sem',
	},
	{
		id: '7',
		queation: 'What are two things you can never eat for breakfast?',
		subQueation: 'What gets wetter the more it dries?',
		answer: 'Integer eu turpis eget ante viverra molestie non a nisi. Praesent sed est ultricies, posuere neque sed, molestie nisi. Nulla facilisi. Suspendisse rutrum porta sem',
	},
	{
		id: '8',
		queation: 'What are two things you can never eat for breakfast?',
		subQueation: 'What gets wetter the more it dries?',
		answer: 'Integer eu turpis eget ante viverra molestie non a nisi. Praesent sed est ultricies, posuere neque sed, molestie nisi. Nulla facilisi. Suspendisse rutrum porta sem',
	},
];
function HelpCenter() {
	const [open, setOpen] = useState(1);
	const handleOpen = (value) => {
		setOpen(open === value ? 0 : value);
	};
	return (
		<div className="p-[20px]">
			<div className="md:my-[38px] my-[20px] mx-[20px]">
				<div className="lg:grid block grid-cols-2 gap-[30px]">
					<div className="md:p-8 p-4 dark-dark-light-card dark:text-[#BFC6D2] text-[#54565C] text-left lg:mb-0 mb-[20px]">
						<h2 className="card-title">User Guide</h2>
						<p>
							Cras sed dolor vulputate risus bibendum interdum at
							at massa. Donec tristique eros non enim posuere, et
							laoreet enim hendrerit. Etiam consectetur lacus
							felis, sed pretium justo rutrum quis. Vivamus
							sagittis risus et urna ultrices placerat.
							Suspendisse dictum orci quis faucibus sollicitudin.
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit.{' '}
							<button className="text-[#00DD73] bg-transparent border-none cursor-pointer">
								Read More
							</button>
						</p>
					</div>
					<div className="md:p-8 p-4 dark-dark-light-card dark:text-[#BFC6D2] text-[#54565C] text-left lg:mb-0 mb-[20px]">
						<h2 className="card-title">Our Mission</h2>
						<p>
							Cras sed dolor vulputate risus bibendum interdum at
							at massa. Donec tristique eros non enim posuere, et
							laoreet enim hendrerit. Etiam consectetur lacus
							felis, sed pretium justo rutrum quis. Vivamus
							sagittis risus et urna ultrices placerat.
							Suspendisse dictum orci quis faucibus sollicitudin.
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit.{' '}
							<button className="text-[#00DD73] bg-transparent border-none cursor-pointer">
								Read More
							</button>
						</p>
					</div>
					<div className="md:p-8 p-4 dark-dark-light-card dark:text-[#BFC6D2] text-[#54565C] text-left lg:mb-0 mb-[20px]">
						<h2 className="card-title">Our Vision</h2>
						<p>
							Cras sed dolor vulputate risus bibendum interdum at
							at massa. Donec tristique eros non enim posuere, et
							laoreet enim hendrerit. Etiam consectetur lacus
							felis, sed pretium justo rutrum quis. Vivamus
							sagittis risus et urna ultrices placerat.
							Suspendisse dictum orci quis faucibus sollicitudin.
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit.{' '}
							<button className="text-[#00DD73] bg-transparent border-none cursor-pointer">
								Read More
							</button>
						</p>
					</div>
					<div className="p-8 bg-[linear-gradient(323deg,#5438FF_0%,#3888FF_100%)] rounded-[15px]">
						<div className="text-left">
							<h2 className="text-[#fff] text-[22px] font-bold leading-7 mb-8">
								Support Center
							</h2>
							<div className="contact-group text-[#fff] mb-6">
								<p className="text-xs font-normal leading-4 tracking-[5px] mb-[5px]">
									Email
								</p>
								<h4 className="text-[19px] font-semibold leading-[25px]">
									<Link to="mailto:support@gamecan.com">
										support@gamecan.com
									</Link>
								</h4>
							</div>
							<div className="contact-group text-[#fff]">
								<p className="text-xs font-normal leading-4 tracking-[5px] mb-[5px]">
									Phone
								</p>
								<h4 className="text-[19px] font-semibold leading-[25px]">
									<Link to="tel:96123586669">
										+96 123 58 6669
									</Link>
								</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mx-[20px] faq-section">
				<h2 className="card-title dark:text-white text-[#4b5563]">
					Frequently Asked Questions
				</h2>
				<div className="md:grid block grid-cols-2 gap-5">
					<div className="-mx-4">
						{data.map((row, i) => (
							<div key={i}>
								<div className="px-4 abc">
									<Accordion
										open={open === row.id}
										className="mb-6"
										key={i}
									>
										<AccordionHeader
											onClick={() => handleOpen(row.id)}
											className="border-none p-5 dark-dark-light-card font-bold text-base leading-[21px] dark:text-white text-[#4b5563]"
										>
											{row.queation}
										</AccordionHeader>
										<AccordionBody className="faq-body text-[15px] text-[#fff] font-normal leading-[25px] text-left p-5 mt-6">
											<h3 className="text-base font-bold leading-[21px] text-[#fff] text-left mb-[12px]">
												{row.subQueation}
											</h3>
											<p>{row.answer}</p>
										</AccordionBody>
									</Accordion>
								</div>
							</div>
						))}
					</div>
					<div className="-mx-4">
						{data1.map((row, i) => (
							<div key={i}>
								<div className="px-4 abc">
									<Accordion
										open={open === row.id}
										className="mb-6"
										key={i}
									>
										<AccordionHeader
											onClick={() => handleOpen(row.id)}
											className="border-none p-5 dark-dark-light-card text-base font-bold leading-[21px] dark:text-white text-[#4b5563]"
										>
											{row.queation}
										</AccordionHeader>
										<AccordionBody className="faq-body text-[15px] text-[#fff] font-normal leading-[25px] text-left p-5 mt-6">
											<h3 className="text-base font-bold leading-[21px] text-[#fff] text-left mb-[12px]">
												{row.subQueation}
											</h3>
											<p>{row.answer}</p>
										</AccordionBody>
									</Accordion>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default HelpCenter;
