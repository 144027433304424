import { useEffect } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Calendar from '../../assets/images/Calendar.png';

export default function DatePicker() {
	useEffect(() => {
		flatpickr('#datepicker', {
			mode: 'range',
			minDate: 'today',
			dateFormat: 'd-F-Y',
			disable: [
				function (date) {
					return !(date.getDate() % 8);
				},
			],
		});
	}, []);
	return (
		<div className="relative md:pr-[14.58px] pr-0">
			<img
				src={Calendar}
				alt="Calendar"
				className="absolute top-[10px] left-[10px]"
			/>
			<input
				id="datepicker"
				type="text"
				placeholder="Select a date range"
				className=" p-2 focus-hidden rounded-[8px] h-[42px] text-dark font-[600] text-xs md:w-[220px] w-full pl-[36px]"
			/>
		</div>
	);
}
