import React, { useState, useEffect } from 'react';
import ListComponent from './ListComponent';
import ListApprovalComponent from './ListApprovalComponent';
import { updateLeaveStatus } from '../../store/thunk/leavesThunk';
import { useDispatch, useSelector } from 'react-redux';
import Confirmpopup from '../../component/ui-component/Confirmpopup';
import { getUserData } from '../../store/thunk/userThunk';

export default function Workflow({
	handleSetEditValues,
	handleDeleteClick,
	handleViewClick,
	userData,
	userPermissions,
}) {
	const dispatch = useDispatch();
	const [openTab, setOpenTab] = React.useState(1);
	const authUser = useSelector((state) => state.auth);
	const user_data = useSelector((state) => state.user.userData);
	const [rejectLeaveDialogVisible, setRejectLeaveDialogVisible] =
		useState(false);
	const [editValues, setEditValues] = useState(null);

	const handleLeaveStatusChange = (leave_data, status) => {
		let assigned_user_ids = [];
		leave_data?.assigned_users.map((item) =>
			assigned_user_ids.push(parseInt(item.id))
		);

		let payload = {
			leave_status: status,
			id: leave_data?.id,
			approved_by_id: authUser?.userDetails?.id,
			user_data: authUser?.userDetails,
			assigned_users: assigned_user_ids,
		};
		if (status !== 3) {
			dispatch(updateLeaveStatus(payload));
		} else {
			setEditValues(payload);
			setRejectLeaveDialogVisible(true);
		}
	};

	useEffect(() => {
		dispatch(getUserData(authUser?.userDetails));
	}, [dispatch, authUser?.userDetails]);

	return (
		<>
			<div className="flex flex-wrap">
				<div className="w-full">
					<ul
						className="flex list-none pt-3 flex-row mb-5 border-b-[#2C3E63] border-b border-solid"
						role="tablist"
					>
						<li className="-mb-px mr-2 last:mr-0">
							<a
								className={
									'border-b border-solid flex text-base font-bold py-3 md:mr-[56.99px] mr-[20px] leading-normal ' +
									(openTab === 1
										? 'border-b-[#00FFF0] text-[#00FFF0]'
										: 'border-b-transparent text-[#BFC6D2]')
								}
								onClick={(e) => {
									e.preventDefault();
									setOpenTab(1);
								}}
								data-toggle="tab"
								href="#link1"
								role="tablist"
							>
								My Leaves
							</a>
						</li>
						<li className="-mb-px mr-2 last:mr-0">
							<a
								className={
									'border-b border-solid text-base font-bold py-3 md:mr-[56.99px] mr-[20px] block leading-normal ' +
									(openTab === 2
										? 'border-b-[#00FFF0] text-[#00FFF0]'
										: 'border-b-transparent text-[#BFC6D2]')
								}
								onClick={(e) => {
									e.preventDefault();
									setOpenTab(2);
								}}
								data-toggle="tab"
								href="#link2"
								role="tablist"
							>
								Leave Approvals
							</a>
						</li>
					</ul>
					<div className="relative min-w-0 w-full mb-6 md:p-8 p-3 mt-6 lg:mt-6 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
						<div className="tab-content tab-space dark:text-white text-black">
							<div
								className={openTab === 1 ? 'block' : 'hidden'}
								id="link1"
							>
								<div className="information-boxes grid grid-cols-4 gap-[34px] mb-6">
									<div className="single-box text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[10px] p-[5px]">
										<p className="text-xs dark:text-white text-[#0C1932] text-center font-normal leading-4 tracking-[5px] pb-[7px]">
											Paid Leave
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-center font-semibold leading-[21px] tracking-[1px]">
											{user_data?.paid_leave}
										</h4>
									</div>
									<div className="single-box text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[10px] p-[5px]">
										<p className="text-xs dark:text-white text-[#0C1932] text-center font-normal leading-4 tracking-[5px] pb-[7px]">
											Casual Leave
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-center font-semibold leading-[21px] tracking-[1px]">
											{user_data?.casual_leave}
										</h4>
									</div>
									<div className="single-box text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[10px] p-[5px]">
										<p className="text-xs dark:text-white text-[#0C1932] text-center font-normal leading-4 tracking-[5px] pb-[7px]">
											Sick Leave
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-center font-semibold leading-[21px] tracking-[1px]">
											{user_data?.sick_leave}
										</h4>
									</div>
									<div className="single-box text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[10px] p-[5px]">
										<p className="text-xs dark:text-white text-[#0C1932] text-center font-normal leading-4 tracking-[5px] pb-[7px]">
											Other Leave
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-center font-semibold leading-[21px] tracking-[1px]">
											{user_data?.other_leave}
										</h4>
									</div>
								</div>
								<ListComponent
									handleSetEditValues={handleSetEditValues}
									handleDeleteClick={handleDeleteClick}
									handleViewClick={handleViewClick}
									userData={userData}
									userPermissions={userPermissions}
								/>
							</div>
							<div
								className={openTab === 2 ? 'block' : 'hidden'}
								id="link2"
							>
								<ListApprovalComponent
									handleLeaveStatusChange={
										handleLeaveStatusChange
									}
									handleSetEditValues={handleSetEditValues}
									handleDeleteClick={handleDeleteClick}
									userData={userData}
									userPermissions={userPermissions}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{rejectLeaveDialogVisible && (
				<>
					<Confirmpopup
						onCancel={() => setRejectLeaveDialogVisible(false)}
						editStatusItem={editValues}
						confirmThunkCall={updateLeaveStatus}
					/>
				</>
			)}
		</>
	);
}
