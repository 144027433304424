import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDepartmentList } from '../../store/thunk/departmentThunk';
import ReactTablePagination from '../../component/ui-component/pagination/ReactTablePagination';

import Delete from '../../assets/images/Delete.png';
import Edit from '../../assets/images/Edit.png';

const ListComponent = ({ handleSetEditValues, handleDeleteClick }) => {
	const [recordsPerPage, setRecordsPerPage] = useState(10);

	const dispatch = useDispatch();

	const departmentData = useSelector((state) => state.department);

	const columns = [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: 'Department Name',
			accessor: 'title',
			width: '20%',
			resize: true,
		},
		{
			Header: 'Description',
			accessor: 'description',
			width: '50%',
			resize: true,
		},
		{
			Header: 'Action',
			accessor: 'action',
			disableSortBy: true,
			Cell: ({ row, value }) => (
				<div className="flex">
					<div
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full mr-[8px] border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						onClick={() => handleSetEditValues(row?.original)}
						title="Edit"
					>
						<img src={Edit} alt="Edit" />
					</div>
					<div
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						onClick={() => handleDeleteClick(row?.original)}
						title="Delete"
					>
						<img src={Delete} alt="Delete" />
					</div>
				</div>
			),
		},
	];

	const fetchData = useCallback(
		(pageSize, pageIndex, searchText, sortBy) => {
			dispatch(
				getDepartmentList({
					pageSize: pageSize,
					pageNo: pageIndex,
					searchText: searchText,
					sortBy: sortBy,
				})
			);
		},
		[dispatch]
	);

	return (
		<>
			<ReactTablePagination
				columns={columns}
				data={departmentData?.deparmentList}
				fetchData={fetchData}
				pageCount={Math.ceil(
					departmentData?.totalRecords / recordsPerPage
				)}
				totalRecords={departmentData?.totalRecords}
				recordsPerPage={recordsPerPage}
				setRecordsPerPage={setRecordsPerPage}
				csvFileName="department_lists.csv"
			/>
		</>
	);
};

export default ListComponent;
