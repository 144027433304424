import React, { useState } from 'react';
import profileBg from '../../../assets/images/profile-bg.png';
import Proicon1 from '../../../assets/images/b1.png';
import Proicon2 from '../../../assets/images/b2.png';
import Proicon3 from '../../../assets/images/b3.png';
import Proicon4 from '../../../assets/images/b4.png';
import Proicon5 from '../../../assets/images/b5.png';
import Proicon6 from '../../../assets/images/b6.png';
import { useSelector } from 'react-redux';
import { getDefaultProfile } from '../../../component/Common';
import UserProfile from '../../../component/ui-component/UserProfile';

export default function MyProfile() {
	const [activeIndex, setActiveIndex] = useState(0);
	const authUser = useSelector((state) => state.auth);

	const handleClick = (index) => {
		setActiveIndex(index);
	};

	return (
		<div className="user-profile rounded-2xl dark:bg-[#1A2844] bg-[#fff] relative h-full pt-[34px] px-[20px] md:px-[40px] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
			<img
				src={profileBg}
				alt="profileBg"
				className="absolute top-0 left-0 h-full w-full object-cover"
			/>

			<div className="relative">
				<div className="user text-center">
					<UserProfile
						imageUrl={authUser?.userDetails?.profile_image}
						defaultImageUrl={getDefaultProfile(
							authUser?.userDetails?.gender
						)}
						altName={authUser?.userDetails?.first_name}
						className={
							'h-[60px] w-[60px] rounded-full overflow-hidden border border-solid border-[#FFE334] inline-block'
						}
					/>
				</div>
				<h3 className="text-[22px] leading-7 font-bold mt-3 mb-1 dark:text-[#fff] text-[#0C1932]">
					{` ${authUser?.userDetails?.first_name} ${authUser?.userDetails?.last_name}`}
				</h3>
				<p className=" text-sm leading-[18px]  dark:text-[#fff] text-[#0C1932]">
					{`${authUser?.userDetails?.role}`}
				</p>

				<div className="working-hours mt-8">
					<p className="mb-4 dark:text-[#fff] text-[#0C1932]">
						Working Hours
					</p>
					<div className="working-box rounded-lg dark:bg-[#293856] bg-[#F7F7F7]  p-1 grid grid-cols-2 gap-2 md:mb-7 mb-2 border border-solid border-transparent">
						<div
							className={`${
								activeIndex === 0 ? 'active' : ''
							} box py-3 px-2 cursor-pointer rounded-lg`}
							onClick={() => handleClick(0)}
						>
							<p className="text-sm leading-[18px] mb-1">
								Work Start
							</p>
							<h3 className="text-[19px] leading-6 font-bold ">
								09:00 AM
							</h3>
						</div>
						<div
							className={`${
								activeIndex === 1 ? 'active' : ''
							} box py-3 px-2 cursor-pointer rounded-lg`}
							onClick={() => handleClick(1)}
						>
							<p className="text-sm leading-[18px] mb-1">
								Work End
							</p>
							<h3 className="text-[19px] leading-6 font-bold ">
								05:00 PM
							</h3>
						</div>
					</div>
				</div>
				<h3 className="text-[19px] font-semibold leading-6 mb-2 text-left dark:text-[#fff] text-[#0C1932]">
					Dhaka City
				</h3>
				<p className="text-base text-left dark:text-[#fff] text-[#0C1932]">
					Astana Dhaka GMT +06:00
				</p>

				<ul className="flex  mt-2 items-center pb-[20px]">
					<li>
						<img src={Proicon1} alt="img" />
					</li>
					<li>
						<img src={Proicon2} alt="img" />
					</li>
					<li>
						<img src={Proicon3} alt="img" />
					</li>
					<li>
						<img src={Proicon4} alt="img" />
					</li>
					<li>
						<img src={Proicon5} alt="img" />
					</li>
					<li>
						<img src={Proicon6} alt="img" />
					</li>
				</ul>
			</div>
		</div>
	);
}
