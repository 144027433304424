import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Statistics from './Statistics';
import Activity from './Activity';
import Timesheet from './Timesheet';
import View from '../../assets/images/View.png';
import { useDispatch, useSelector } from 'react-redux';
import { getUserTimeTrackingList } from '../../store/thunk/userThunk';
import BreadcrumbsComponent from '../../component/ui-component/BreadcrumbsComponent';
import ViewActivityComponent from './ViewActivityComponent';
import {
	getYearList,
	getMonthList,
	getCurrentMonthDates,
	calculateWorkedHours,
	formatTime,
	getLastRecord,
} from '../../component/Common';
import { Datepicker, Input, initTE } from 'tw-elements';

const moment = require('moment');
function AttendanceComponent() {
	useEffect(() => {
		initTE({ Datepicker, Input });
	}, []);

	const { handleSubmit, setValue, register, reset } = useForm({});

	const dispatch = useDispatch();
	const [viewDailogVisible, setViewDailogVisible] = useState(false);
	const [viewActivity, setViewActivity] = useState(null);
	const [year, setYear] = useState(null);
	const [month, setMonth] = useState(null);
	const activityTrack = useSelector(
		(state) => state.user.userTimeTrackingLists
	);
	const statisticsTracking = useSelector(
		(state) => state.user.statisticsTracking
	);
	const punchInTime = getLastRecord(activityTrack[0]);

	const monthsLists = getMonthList();
	const yearLists = getYearList(8);
	const currentMonthDates = getCurrentMonthDates(month, year);

	// Updating the activity track list with missing dates like holidays.
	const today = moment();
	const missingDates = currentMonthDates.filter(
		(date) =>
			!activityTrack.some((item) => item.hasOwnProperty(date)) &&
			moment(date).isBefore(today) &&
			moment(date).isBefore(moment().startOf('day'))
	);
	const updatedActivityLists = activityTrack.concat(
		missingDates.map((date) => ({ [date]: [] }))
	);
	updatedActivityLists.sort((a, b) =>
		moment(Object.keys(b)[0]).diff(moment(Object.keys(a)[0]))
	);
	// End

	useEffect(() => {
		let data = { show_all: true };
		dispatch(getUserTimeTrackingList(data));
	}, [dispatch]);

	const handleViewClick = (item) => {
		setViewActivity(item);
		setViewDailogVisible(true);
	};

	const renderTimeCell = (timestamp, format = 'hh:mm A') => (
		<td className="pb-2 sm:text-xs lg:text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px] pt-[15px]">
			{timestamp ? moment(timestamp).format(format) : '-'}
		</td>
	);

	const renderDurationCell = (punchIn, punchOut, value) => {
		if (!punchOut || punchOut.punch_type !== 'out') {
			return (
				<td className="pb-2 sm:text-xs lg:text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px] pt-[15px]">
					-
				</td>
			);
		}

		let totalSecondsWorked = calculateWorkedHours(value);
		return (
			<td className="pb-2 sm:text-xs lg:text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px] pt-[15px]">
				{`${formatTime(totalSecondsWorked, 'HH : mm')} hrs`}
			</td>
		);
	};

	const renderViewCell = (handleViewClick, length) => {
		if (length > 0) {
			return (
				<td className="pb-2 sm:text-xs lg:text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px] pt-[15px]">
					<div
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full mr-[8px] border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						onClick={handleViewClick}
						title="View"
					>
						<img src={View} alt="View" className="w-[22px]" />
					</div>
				</td>
			);
		}
	};

	const renderTableRow = (row, handleViewClick, rowIndex) => (
		<tr key={rowIndex}>
			{Object.entries(row).map(([key, value], columnIndex) => {
				return (
					<React.Fragment key={columnIndex}>
						{renderTimeCell(key, 'DD-MM-YYYY')}
						{renderTimeCell(value[value.length - 1]?.timestamp)}
						{renderTimeCell(
							value[0]?.punch_type === 'out'
								? value[0]?.timestamp
								: '',
							'hh:mm A'
						)}
						{renderDurationCell(
							value[value.length - 1],
							value[0],
							value
						)}
						{renderViewCell(
							() => handleViewClick(row),
							value.length
						)}
					</React.Fragment>
				);
			})}
		</tr>
	);

	const handleSearchResults = (key, selectedValue) => {
		setValue(key, selectedValue);
	};

	const handleClearFilter = () => {
		// Reset the form fields
		reset();
		let data = { show_all: true };
		dispatch(getUserTimeTrackingList(data));
	};

	const onSubmit = (data) => {
		let request = {
			...data,
			show_all: true,
		};
		setYear(data.search_by_year);
		setMonth(data.search_by_month);
		dispatch(getUserTimeTrackingList(request));
	};

	return (
		<>
			<div className="p-[20px]">
				<div className="flex justify-between items-center mb-5">
					<BreadcrumbsComponent
						showBreadCrumbs={true}
						pageTitle={'Attendance'}
					/>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 text-white text-left">
					<Timesheet
						punchInTime={punchInTime}
						statisticsData={statisticsTracking}
					/>
					<Statistics statisticsData={statisticsTracking} />
					<Activity />
				</div>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<div className="flex">
						<div
							className="relative h-12 w-80 min-w-[200px] mt-8 mr-6"
							id="datepicker-close-without-confirmation"
							data-te-format="yyyy-mm-dd"
							data-te-datepicker-init
							data-te-input-wrapper-init
							data-te-inline="true"
						>
							<input
								id="search_by_date"
								type="text"
								className="dark:text-white text-[#4b5563] border-t-0 peer h-full w-full rounded-[7px] border bg-transparent px-3 pt-4 pb-3 font-sans text-base font-normal outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-[#0c4a6e] focus:border-1 focus:border-t-[#0c4a6e] focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 dark:border-[#0c4a6e] border-[#c9c9c9] hover:cursor-pointer"
								placeholder=""
								data-te-datepicker-toggle-ref
								data-te-datepicker-toggle-button-ref
								autoComplete="off"
								{...register('search_by_date', '')}
							/>
							<label
								htmlFor="floatingInput"
								className="dark:text-white text-[#4b5563] before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[15px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l dark:before:border-[#0c4a6e] before:border-[#c9c9c9] before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r dark:after:border-[#0c4a6e] after:border-[#c9c9c9] after:transition-all peer-placeholder-shown:text-base peer-placeholder-shown:leading-[3.80] dark:peer-placeholder-shown:text-white peer-placeholder-shown:text-[#4b5563]peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[15px] peer-focus:leading-tight dark:peer-focus:text-white peer-focus:text-[#4b5563] peer-focus:before:border-t-2 peer-focus:before:border-l-2 dark:peer-focus:before:border-[#0c4a6e] peer-focus:before:border-[#e5e7eb] peer-focus:after:border-t-2 peer-focus:after:border-r-2 dark:peer-focus:after:border-[#0c4a6e] peer-focus:after:border-[#e5e7eb] peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent dark:peer-disabled:peer-placeholder-shown:text-white peer-disabled:peer-placeholder-shown:text-[#4b5563]"
							>
								Select Date
							</label>
						</div>
						<div className="relative h-12 w-80 min-w-[200px] mt-8 mr-6">
							<select
								className="dark:text-white text-[#4b5563] border-t-0 peer h-full w-full rounded-[7px] border bg-transparent px-3 pt-4 pb-3 font-sans text-base font-normal outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-[#0c4a6e] focus:border-1 focus:border-t-[#0c4a6e] focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 dark:border-[#0c4a6e] border-[#c9c9c9] hover:cursor-pointer"
								onChange={(e) =>
									handleSearchResults(
										'search_by_month',
										e.target.value
									)
								}
							>
								{monthsLists.map((row, index) => (
									<option key={index} value={row?.id}>
										{row?.name}
									</option>
								))}
							</select>
							<label className="dark:text-white text-[#4b5563] before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[15px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l dark:before:border-[#0c4a6e] before:border-[#c9c9c9] before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r dark:after:border-[#0c4a6e] after:border-[#c9c9c9] after:transition-all peer-placeholder-shown:text-base peer-placeholder-shown:leading-[3.80] dark:peer-placeholder-shown:text-white peer-placeholder-shown:text-[#4b5563]peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[15px] peer-focus:leading-tight dark:peer-focus:text-white peer-focus:text-[#4b5563] peer-focus:before:border-t-2 peer-focus:before:border-l-2 dark:peer-focus:before:border-[#0c4a6e] peer-focus:before:border-[#e5e7eb] peer-focus:after:border-t-2 peer-focus:after:border-r-2 dark:peer-focus:after:border-[#0c4a6e] peer-focus:after:border-[#e5e7eb] peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent dark:peer-disabled:peer-placeholder-shown:text-white peer-disabled:peer-placeholder-shown:text-[#4b5563]">
								Month
							</label>
						</div>
						<div className="relative h-12 w-80 min-w-[200px] mt-8 mr-6">
							<div className="years">
								<select
									className="dark:text-white text-[#4b5563] border-t-0 peer h-full w-full rounded-[7px] border bg-transparent px-3 pt-4 pb-3 font-sans text-base font-normal outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-[#0c4a6e] focus:border-1 focus:border-t-[#0c4a6e] focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 dark:border-[#0c4a6e] border-[#c9c9c9] hover:cursor-pointer"
									onChange={(e) =>
										handleSearchResults(
											'search_by_year',
											e.target.value
										)
									}
								>
									{yearLists.map((row, index) => (
										<option key={index} value={row}>
											{row}
										</option>
									))}
								</select>
								<label className="dark:text-white text-[#4b5563] before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[15px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l dark:before:border-[#0c4a6e] before:border-[#c9c9c9] before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r dark:after:border-[#0c4a6e] after:border-[#c9c9c9] after:transition-all peer-placeholder-shown:text-base peer-placeholder-shown:leading-[3.80] dark:peer-placeholder-shown:text-white peer-placeholder-shown:text-[#4b5563]peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[15px] peer-focus:leading-tight dark:peer-focus:text-white peer-focus:text-[#4b5563] peer-focus:before:border-t-2 peer-focus:before:border-l-2 dark:peer-focus:before:border-[#0c4a6e] peer-focus:before:border-[#e5e7eb] peer-focus:after:border-t-2 peer-focus:after:border-r-2 dark:peer-focus:after:border-[#0c4a6e] peer-focus:after:border-[#e5e7eb] peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent dark:peer-disabled:peer-placeholder-shown:text-white peer-disabled:peer-placeholder-shown:text-[#4b5563]">
									Year
								</label>
							</div>
						</div>
						<div className="relative h-12 w-80 min-w-[120px] mt-8 mr-[0.5rem]">
							<button className="bg-purple text-lg tracking-wide leading-[18px] font-bold text-white rounded-full   py-4 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)] w-full ">
								Search
							</button>
						</div>
						<div className="relative h-12 w-80 min-w-[120px] mt-8 mr-[0.5rem]">
							<button
								type="button"
								className="bg-purple text-lg tracking-wide leading-[18px] font-bold text-white rounded-full  py-4 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)] w-full "
								onClick={handleClearFilter}
							>
								Clear Filter
							</button>
						</div>
					</div>
				</form>
				<div className="rounded-lg py-4 p-4 mt-6 shadow-md dark:shadow-[#0c4a6e] shadow-[#c9c9c9]">
					<div className="flex flex-col">
						<div className="overflow-x-auto">
							<div className="inline-block min-w-full py-2">
								<div className="overflow-hidden">
									<table className="min-w-full text-left text-base font-light">
										<thead className="font-medium">
											<tr className="border-solid border-b dark:border-[#0c4a6e] border-[#c9c9c9]">
												<th
													scope="col"
													className="pb-3 sm:text-xs lg:text-base mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
												>
													Date
												</th>
												<th
													scope="col"
													className="pb-3 sm:text-xs lg:text-base mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
												>
													Punch In
												</th>
												<th
													scope="col"
													className="pb-3 sm:text-xs lg:text-base mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
												>
													Punch Out
												</th>
												<th
													scope="col"
													className="pb-3 sm:text-xs lg:text-base mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
												>
													Completed Hrs
												</th>
												<th
													scope="col"
													className="pb-3 sm:text-xs lg:text-base mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
												>
													Action
												</th>
											</tr>
										</thead>
										<tbody>
											{updatedActivityLists.length > 0 &&
												updatedActivityLists.map(
													(row, rowIndex) =>
														renderTableRow(
															row,
															handleViewClick,
															rowIndex
														)
												)}
											{updatedActivityLists.length ===
												0 && (
												<tr className="border-solid border-b dark:border-[#0c4a6e] border-[#c9c9c9]">
													<td
														colSpan={5}
														className="py-6 pb-3 sm:text-xs lg:text-base mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4"
													>
														No Records Found
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{viewDailogVisible && (
				<ViewActivityComponent
					visible={viewDailogVisible}
					onClose={() => setViewDailogVisible(false)}
					viewActivity={viewActivity}
				/>
			)}
		</>
	);
}

export default AttendanceComponent;
