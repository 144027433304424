import React, { useEffect } from 'react';
import InputComponent from '../../component/ui-component/InputComponent';
import Button from '../../component/ui-component/Button';
import SelectComponent from '../../component/ui-component/SelectComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryList } from '../../store/thunk/commonThunk';
import { vsmCompany } from '../../utils/validations';
import DatePickerComponent from '../../component/ui-component/DatePickerComponent';
import { resendVerifyEmail } from '../../store/thunk/commonThunk';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	selectedCountry,
	setValue,
	editUserData,
	selectedGender,
}) => {
	const dispatch = useDispatch();
	const countryList = useSelector((state) => state.common.countryList);

	const genderList = [
		{
			id: 'male',
			name: 'Male',
		},
		{
			id: 'female',
			name: 'Female',
		},
	];

	useEffect(() => {
		dispatch(getCountryList());
	}, [dispatch]);

	const onChangeSelectValue = (key, selectedValue) => {
		setValue(key, selectedValue);
	};

	const handleresendEmail = () => {
		dispatch(resendVerifyEmail(editUserData));
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-6 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="dark:text-white text-[#4b5563] font-bold text-[16px] text-left pb-[20px] text-[18px]">
							Company Information
						</h3>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
						<InputComponent
							id="company_name"
							title="Company Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<InputComponent
							id="company_address"
							title="Company Address"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<InputComponent
							id="company_city"
							title="Company City"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<InputComponent
							id="company_postal_code"
							title="Company Postal Code"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<InputComponent
							id="company_email"
							title="Company Email"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<InputComponent
							id="company_contact_no"
							title="Company Contact No"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<InputComponent
							id="company_website"
							title="Company Website"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<InputComponent
							id="company_linkedin"
							title="Company Linkedin Profile"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<DatePickerComponent
							id="company_filling_date"
							title="Company Filling Date"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<SelectComponent
							selectOptionLists={countryList}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedCountry}
							title="Company Country of Origin"
							id="company_country"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
					</div>
				</div>
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-6 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="dark:text-white text-[#4b5563] font-bold text-[16px] text-left pb-[20px] text-[18px]">
							User Information
						</h3>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-[20px]">
						<InputComponent
							id="first_name"
							title="First Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<InputComponent
							id="middle_name"
							title="Middle Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<InputComponent
							id="last_name"
							title="Last Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<InputComponent
							id="phone_number"
							title="Phone Number"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						<InputComponent
							id="email"
							title="Email Address"
							errors={errors}
							register={register}
							readOnly={isUpdate ? true : false}
							fieldvalidation={vsmCompany}
						/>
						<SelectComponent
							selectOptionLists={genderList}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedGender}
							title="Select Gender"
							id="gender"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/>
						{/* {!isUpdate && (
							<InputComponent
								id="password"
								inputType={"password"}
								title="Password"
								errors={errors}
								register={register}
							/>
						)} */}
					</div>
				</div>
			</div>
			<div className="pt-[20px] p-6 border-solid border-t border-[#333333] fixed w-[calc(100%_-_50%)] bottom-0">
				<div className="float-left">
					<Button
						buttonText={`${isUpdate ? 'Update' : 'Create'} Company`}
						linkTo="#"
						customValue="175px"
						inputType="submit"
						loading={loading}
					/>
				</div>
				{editUserData?.is_verified ||
				editUserData?.is_verified === undefined ? (
					''
				) : (
					<div className="float-right">
						<a
							href="#!"
							className="px-8 expert-btn text-sm leading-[18px] font-semibold text-white rounded-full p-[10px] shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)] mx-[10px] w-full float-right"
							onClick={(e) => {
								e.preventDefault(); // Prevent the default link behavior
								handleresendEmail(); // Call your function
							}}
						>
							{loading && (
								<svg
									aria-hidden="true"
									role="status"
									className="inline w-4 h-4 mr-3 text-white animate-spin"
									viewBox="0 0 100 101"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
										fill="#E5E7EB"
									/>
									<path
										d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
										fill="currentColor"
									/>
								</svg>
							)}
							Resend Verify Email
						</a>
					</div>
				)}
			</div>
		</form>
	);
};
export default FormComponent;
