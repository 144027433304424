import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextareaComponent from '../../component/ui-component/TextareaComponent';
import { vsmLeaves } from '../../utils/validations';
import { Button } from '@material-tailwind/react';

export default function Confirmpopup({
	onCancel,
	editStatusItem,
	confirmThunkCall,
}) {
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.dataLoading.loading);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({});

	const onSubmit = (data) => {
		editStatusItem.additional_notes = data?.additional_notes;
		dispatch(confirmThunkCall(editStatusItem));
		onCancel();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div
				id="modal"
				className="fixed inset-0 flex items-center justify-center z-[999] bg-[#ffffff75]"
			>
				<div className="p-6 rounded shadow-md bg-[#12243b] w-[550px]">
					<div className="relative w-full min-w-[200px]">
						<TextareaComponent
							id="additional_notes"
							title="Reason"
							errors={errors}
							register={register}
							fieldvalidation={vsmLeaves}
						/>
					</div>
					<div>
						<div className="flex p-2 bottom-0 float-right">
							<button
								variant="text"
								onClick={onCancel}
								className="mx-2 py-2 px-5 p-[10px] text-sm font-medium text-gray-500 bg-white rounded-full border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-none hover:text-gray-900 focus:z-10 text-gray-700  dark:border-gray-500 dark:focus:none shadow-lg hover:shadow-gray-600/50"
							>
								Cancel
							</button>
							<Button
								color="blue"
								type="submit"
								disabled={!!loading}
								className="capitalize expert-btn text-sm leading-[18px] font-semibold text-white rounded-full p-[10px] px-5 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
							>
								{loading && (
									<svg
										aria-hidden="true"
										role="status"
										className="inline w-4 h-4 mr-3 text-white animate-spin"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="#E5E7EB"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentColor"
										/>
									</svg>
								)}
								{loading ? 'Loading' : 'Update Status'}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
}
