import React from 'react';

export default function PresentPreferenceReview() {
	return (
		<div>
			<h2 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7 text-left pb-[25.92px]">
				Present Preference Review
			</h2>
			<div className="mb-[39px] p-[21px] pr-[15px] mt-6 lg:mt-0 rounded-[15px]  shadow-none dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] dark:bg-[#17243e] bg-[#f2f2f2]">
				<h2 className="dark:text-white text-[#000] font-bold leading-7 text-left pb-[25.92px]">
					April-June (2023)
				</h2>
				<div className="past-preference-review">
					<div className="grid grid-cols-2 mb-[16.52px]">
						<div className="grid grid-cols-2 text-white">
							<div className=" text-[14px] dark:text-[#BFC6D2] text-black text-left">
								Communication
							</div>
							<div className="font-bold text-[19px] dark:text-white text-black">
								XP 5
							</div>
						</div>
						<div className="grid grid-cols-2 text-white">
							<div className=" text-[14px] dark:text-[#BFC6D2] text-black text-left">
								Development
							</div>
							<div className="font-bold text-[19px] dark:text-white text-black">
								XP 2
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2 mb-[16.52px]">
						<div className="grid grid-cols-2 text-white">
							<div className=" text-[14px] dark:text-[#BFC6D2] text-black text-left">
								Concept Art
							</div>
							<div className="font-bold text-[19px] dark:text-white text-black">
								XP 7
							</div>
						</div>
						<div className="grid grid-cols-2 text-white">
							<div className=" text-[14px] dark:text-[#BFC6D2] text-black text-left">
								Illustration
							</div>
							<div className="font-bold text-[19px] dark:text-white text-black">
								XP 3
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2">
						<div className="grid grid-cols-2 text-white">
							<div className=" text-[14px] dark:text-[#BFC6D2] text-black text-left">
								Lead
							</div>
							<div className="font-bold text-[19px] dark:text-white text-black">
								XP 3
							</div>
						</div>
						<div className="grid grid-cols-2 text-white">
							<div className=" text-[14px] dark:text-[#BFC6D2] text-black text-left">
								Communication
							</div>
							<div className="font-bold text-[19px] dark:text-white text-black">
								XP 5
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
