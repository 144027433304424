import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	removeCompany,
	updateCompanyStatus,
} from '../../store/thunk/companyThunk';
import AddCompanyComponent from './AddCompanyComponent';
import EditCompanyComponent from './EditCompanyComponent';
import ListComponent from './ListComponent';

const CompanyComponent = () => {
	const [addDrawerVisible, setAddDrawerVisible] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);
	const companyList = useSelector((state) => state.company.companyList);
	const dispatch = useDispatch();
	const handleSetEditValues = (item) => {
		setEditValues(item);
		setEditDrawerVisible(true);
	};
	const handleDeleteClick = (record) => {
		if (
			window.confirm(
				<div>
					<p>{`Are you sure want to delete company ${record.company_name}?`}</p>
					<p>This action cannot be undone.</p>
				</div>
			)
		) {
			dispatch(removeCompany(record));
		}
	};
	const handleUpdatestatus = (record) => {
		let payload = {
			is_active: !record.user_info.is_active,
			userid: record?.user_info?.id,
		};
		dispatch(updateCompanyStatus(payload));
	};

	return (
		<>
			<div className="p-[20px]">
				<div className="flex items-center justify-between px-2 py-5">
					<h2 className="dark:text-white text-black text-[22px] font-bold leading-7">
						Company Management
					</h2>
					<button
						className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
						onClick={() => setAddDrawerVisible(true)}
					>
						+ Create New Company
					</button>
				</div>

				<ListComponent
					companyList={companyList}
					setEditDrawerVisible={setEditDrawerVisible}
					handleSetEditValues={handleSetEditValues}
					handleDeleteClick={handleDeleteClick}
					handleUpdatestatus={handleUpdatestatus}
				/>
			</div>

			{addDrawerVisible && (
				<AddCompanyComponent
					visible={addDrawerVisible}
					onClose={() => setAddDrawerVisible(false)}
				/>
			)}
			{editDrawerVisible && editValues && (
				<EditCompanyComponent
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
				/>
			)}
		</>
	);
};
export default CompanyComponent;
