import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserExperience } from '../../store/thunk/userThunk';
import ExperienceForm from './ExperienceForm';

const EditExperience = ({ visible, onClose, editValues }) => {
	const loading = useSelector((state) => state.dataLoading.loading);
	const dispatch = useDispatch();
	let experienceLists = [];
	if (editValues && editValues.length > 0) {
		editValues.forEach((experience, index) => {
			experienceLists.push({
				eid: experience?.id || '',
				company_name: experience?.company_name || '',
				location: experience?.location || '',
				job_position: experience?.job_position || '',
				start_date: experience?.start_date || '',
				end_date: experience?.end_date || '',
			});
		});
	} else {
		experienceLists.push({
			company_name: '',
			location: '',
			job_position: '',
			start_date: '',
			end_date: '',
		});
	}

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		setValue,
	} = useForm({
		defaultValues: {
			experience: experienceLists,
		},
	});

	const onSuccessCallback = () => {
		reset();
		onClose();
	};

	const onSubmit = (data) => {
		let userDetails = JSON.parse(localStorage.getItem('user_data'));
		data.callbackFunction = onSuccessCallback;
		data.setError = setError;
		data.id = userDetails?.id;
		dispatch(updateUserExperience(data));
	};

	return (
		<>
			<div
				className={` togglesidebar bg-[#ffffff57] fixed h-full w-full top-0 left-0 p-6 z-[99999]  ${
					visible ? 'active' : ''
				}`}
			>
				<div
					onClick={onClose}
					className="w-full bg-[#ffffff57] overlay bg-[#0f1a32] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
				/>
				<div className="dark:bg-[#12243d] bg-[#fafafa] border-gray-300 border-l w-[85%] lg:w-[50%] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]">
					<button
						className="close h-8 w-8 rounded-full absolute top-5 right-5 bg-[#fff]"
						onClick={onClose}
					>
						X
					</button>
					<h1 className="p-6 dark:text-white text-[#4b5563] font-bold text-[20px] text-left pb-[20px] border-solid border-b dark:border-[#333333] border-gray-300">
						Update Experience Details
					</h1>
					<div className="rounded-lg">
						<ExperienceForm
							loading={loading}
							register={register}
							handleSubmit={handleSubmit}
							onSubmit={onSubmit}
							errors={errors}
							isUpdate={true}
							setValue={setValue}
							control={control}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditExperience;
