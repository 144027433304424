import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeProject } from '../../store/thunk/projectsThunk';
import { getUserPermissions } from '../../store/thunk/userThunk';
import AddProjectComponent from './AddProjectComponent';
import EditProjectComponent from './EditProjectComponent';
import ListComponent from './ListComponent';
import Dialogpopup from '../../component/ui-component/Dailogpopup';
import BreadcrumbsComponent from '../../component/ui-component/BreadcrumbsComponent';
import { hasPermission } from '../../component/Common';

function ProjectsComponent() {
	const dispatch = useDispatch();
	const [addDrawerVisible, setAddDrawerVisible] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);
	const authUser = useSelector((state) => state.auth);
	const user_permissions = useSelector((state) => state.user.userPermissions);
	const handleSetEditValues = (item) => {
		setEditValues(item);
		setEditDrawerVisible(true);
	};
	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
	};
	useEffect(() => {
		dispatch(getUserPermissions());
	}, [dispatch]);
	return (
		<>
			<div className="p-[20px]">
				<div className="flex justify-between items-center mb-5">
					<BreadcrumbsComponent
						showBreadCrumbs={true}
						pageTitle={'Projects'}
					/>
					{hasPermission(
						authUser?.userDetails,
						user_permissions,
						'add_projects'
					) && (
						<button
							className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
							onClick={() => setAddDrawerVisible(true)}
						>
							+ Create Project
						</button>
					)}
				</div>
				<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 text-white text-left">
					<ListComponent
						handleSetEditValues={handleSetEditValues}
						handleDeleteClick={handleDeleteClick}
						userData={authUser?.userDetails}
						userPermission={user_permissions}
					/>
				</div>
			</div>
			{addDrawerVisible && (
				<AddProjectComponent
					visible={addDrawerVisible}
					onClose={() => setAddDrawerVisible(false)}
				/>
			)}
			{editDrawerVisible && editValues && (
				<EditProjectComponent
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
				/>
			)}
			{deleteDailogVisible && (
				<>
					<Dialogpopup
						onCancel={() => setDeleteDailogVisible(false)}
						deleteItem={editValues}
						removeThunkCall={removeProject}
						message="Are you sure you want to delete this projects ?"
					/>
				</>
			)}
		</>
	);
}
export default ProjectsComponent;
