import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser, updateUserStatus } from '../../store/thunk/userThunk';
import AddUserComponent from './AddUserComponent';
import EditUserComponent from './EditUserComponent';
import ListComponent from './ListComponent';
import Dialogpopup from '../../component/ui-component/Dailogpopup';
import UserRoles from './UserRoles';
import BreadcrumbsComponent from '../../component/ui-component/BreadcrumbsComponent';

const UserComponent = () => {
	const [addDrawerVisible, setAddDrawerVisible] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);
	const userList = useSelector((state) => state.user.userList);
	const dispatch = useDispatch();

	const handleSetEditValues = (item) => {
		setEditValues(item);
		setEditDrawerVisible(true);
	};

	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
	};

	const handleUpdatestatus = (record) => {
		let payload = {
			is_active: !record.is_active,
			userid: record?.id,
		};
		dispatch(updateUserStatus(payload));
	};

	return (
		<>
			<div className="p-[20px]">
				<div className="flex items-center justify-between  md:py-5 py-0">
					<h2 className="dark:text-white text-[#4b5563] md:text-[22px] text-[18px] font-bold leading-7">
						Users Roles
					</h2>

					<button
						className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
						onClick={() => setAddDrawerVisible(true)}
					>
						+ Create New User
					</button>
				</div>
				<UserRoles />
				<div className="flex items-center justify-between px-2 py-5">
					<BreadcrumbsComponent
						showBreadCrumbs={true}
						pageTitle={'Users'}
					/>
				</div>
				<ListComponent
					userList={userList}
					setEditDrawerVisible={setEditDrawerVisible}
					handleSetEditValues={handleSetEditValues}
					handleDeleteClick={handleDeleteClick}
					handleUpdatestatus={handleUpdatestatus}
				/>
			</div>

			{addDrawerVisible && (
				<AddUserComponent
					visible={addDrawerVisible}
					onClose={() => setAddDrawerVisible(false)}
				/>
			)}
			{editDrawerVisible && editValues && (
				<EditUserComponent
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
				/>
			)}
			{deleteDailogVisible && (
				<>
					<Dialogpopup
						onCancel={() => setDeleteDailogVisible(false)}
						deleteItem={editValues}
						removeThunkCall={removeUser}
						message="Are you sure you want to delete this user ?"
					/>
				</>
			)}
		</>
	);
};
export default UserComponent;
