import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeHoliday } from '../../store/thunk/holidaysThunk';
import AddHolidayComponent from './AddHolidayComponent';
import EditHolidayComponent from './EditHolidayComponent';
import ListComponent from './ListComponent';
import Dialogpopup from '../../component/ui-component/Dailogpopup';
import { Link } from 'react-router-dom';
import { getUserPermissions } from '../../store/thunk/userThunk';
import { hasPermission } from '../../component/Common';

const HolidaysComponent = () => {
	const dispatch = useDispatch();
	const [addDrawerVisible, setAddDrawerVisible] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);
	const authUser = useSelector((state) => state.auth);
	const user_permissions = useSelector((state) => state.user.userPermissions);

	const handleSetEditValues = (item) => {
		setEditValues(item);
		setEditDrawerVisible(true);
	};

	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
	};

	useEffect(() => {
		dispatch(getUserPermissions());
	}, [dispatch]);

	return (
		<>
			<div className="p-[20px]">
				<div className="flex items-center justify-between px-2 py-5">
					{/* <h2 className="dark:text-white text-black text-[22px] font-bold leading-7">
            Departments
          </h2> */}
					<nav className="rounded-md">
						<ol className="list-reset flex">
							<li>
								<Link
									to="/admin-panel"
									className="font-medium text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
								>
									Dashboard
								</Link>
							</li>
							<li>
								<span className="mx-2 text-neutral-500 dark:text-neutral-400">
									/
								</span>
							</li>
							<li className="text-neutral-500 dark:text-neutral-400">
								Holidays
							</li>
						</ol>
					</nav>
					{hasPermission(
						authUser?.userDetails,
						user_permissions,
						'add_holidays'
					) && (
						<button
							className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
							onClick={() => setAddDrawerVisible(true)}
						>
							+ Create Holiday
						</button>
					)}
				</div>
				<ListComponent
					setEditDrawerVisible={setEditDrawerVisible}
					handleSetEditValues={handleSetEditValues}
					handleDeleteClick={handleDeleteClick}
					userData={authUser?.userDetails}
					userPermissions={user_permissions}
				/>
			</div>

			{addDrawerVisible && (
				<AddHolidayComponent
					visible={addDrawerVisible}
					onClose={() => setAddDrawerVisible(false)}
				/>
			)}
			{editDrawerVisible && editValues && (
				<EditHolidayComponent
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
				/>
			)}
			{deleteDailogVisible && (
				<>
					<Dialogpopup
						onCancel={() => setDeleteDailogVisible(false)}
						deleteItem={editValues}
						removeThunkCall={removeHoliday}
						message="Are you sure you want to delete this holiday ?"
					/>
				</>
			)}
		</>
	);
};
export default HolidaysComponent;
