import React from 'react';
import SkillDevelopmentInsight from './SkillDevelopmentInsight';
import UserEngagement from './UserEngagement';
import Performance from './Performance';
import Statistics from './Statistics';
import WhatHappeningGamecan from './WhatHappeningGamecan';

export default function Reporting() {
	return (
		<div className="p-[20px]">
			<div className="md:grid block grid-cols-2 gap-7">
				<SkillDevelopmentInsight />
				<UserEngagement />
			</div>
			<div className="md:grid block grid-cols-2 gap-7 dark:text-white text-black">
				<Performance />
				<Statistics />
			</div>
			<div className="grid grid-cols-1 dark:text-white text-black">
				<WhatHappeningGamecan />
			</div>
		</div>
	);
}
