import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	notificationSuccess,
	notificationFail,
} from '../slices/notificationSlice';
import { setLoading } from '../slices/dataLoadingSlice';
import {
	setLeavesData,
	setFetching,
	setMyLeavesData,
} from '../slices/leavesSlice';

import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';
import { vsmLeaves } from '../../utils/validations';

export const getAllLeavesList = createAsyncThunk(
	'getAllLeavesList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageNo, pageSize, searchText, sortBy, approvalLeave } =
				_request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';
				if (sortField === 'user.fullname') {
					sortField = 'user__first_name';
				}
				if (sortField === 'leave_status_name') {
					sortField = 'leave_status';
				}

				if (
					sortField !== 'no_of_days' &&
					sortField !== 'active' &&
					sortField !== 'action'
				) {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			let approvalLeaves = `&apleaves=${approvalLeave || false}`;

			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';

			const response = await apiClient(false).get(
				`/accounts/leaves/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}${approvalLeaves}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				if (!approvalLeave) {
					dispatch(setMyLeavesData(response?.data));
				} else {
					dispatch(setLeavesData(response?.data));
				}
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addLeaves = createAsyncThunk(
	'addLeaves',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().post(
				`/accounts/leaves/`,
				_request
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getAllLeavesList());
				dispatch(notificationSuccess(vsmLeaves?.addSucces));
				toast.success(vsmLeaves?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const editLeaves = createAsyncThunk(
	'editLeaves',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().patch(
				`/accounts/leaves/${_request.id}/`,
				_request
			);

			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getAllLeavesList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmLeaves?.updateSuccess);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeLeaves = createAsyncThunk(
	'removeLeaves',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(`/accounts/leaves/${_request.id}/`);
			dispatch(setLoading(false));
			dispatch(getAllLeavesList());
			dispatch(notificationSuccess(vsmLeaves?.deleteSuccess));
			toast.success(vsmLeaves?.deleteSuccess);
			// _request.callbackFunction();
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const updateLeaveStatus = createAsyncThunk(
	'updateLeaveStatus',
	async (_request, { dispatch }) => {
		dispatch(setLoading(true));
		const { user_data } = _request;
		delete _request.user_data;
		let is_approval_list = '';
		if (!user_data?.is_companyadmin) {
			is_approval_list = `?apleaves=${true}`;
		}
		const response = await apiClient().patch(
			`/accounts/leaves/${_request.id}/${is_approval_list}`,
			_request
		);

		dispatch(setLoading(false));
		if (response?.data) {
			if (user_data?.is_companyadmin) {
				dispatch(getAllLeavesList());
			} else {
				dispatch(getAllLeavesList({ approvalLeave: true }));
			}
			dispatch(notificationSuccess(response?.data?.message));
			toast.success(vsmLeaves?.leaveStatus);
		} else {
			let msg =
				'Failed to login using this credentials' ||
				Messages.ERROR.DEFAULT;
			dispatch(notificationFail(msg));
		}
	}
);
