import React from 'react';
import ActivityFeedImage from '../../assets/images/activity-feed.png';

export default function EmailTemplate() {
	const data = [
		{
			id: '1',
			title: 'Ben Smith',
			subtitle: 'UI/UX Designer',
			image: ActivityFeedImage,
		},
		{
			id: '2',
			title: 'Ben Smith',
			subtitle: 'UI/UX Designer',
			image: ActivityFeedImage,
		},
		{
			id: '3',
			title: 'Ben Smith',
			subtitle: 'UI/UX Designer',
			image: ActivityFeedImage,
		},
		{
			id: '4',
			title: 'Ben Smith',
			subtitle: 'UI/UX Designer',
			image: ActivityFeedImage,
		},
	];
	return (
		<>
			<div className="md:h-[362.382px] h-auto pr-3 md:px-[22px] px-[10px] md:py-[20px] py-[10px] dark-dark-light-card dark:text-[#BFC6D2] text-[#54565C] text-left">
				<div className="md:grid block grid-cols-12 gap-5">
					<div className="col-span-8">
						<h5 className="dark:text-white text-[#4b5563] text-base font-bold leading-[21px] pb-[16px]">
							Send a Email
						</h5>
						<p className="dark:text-[#BFC6D2] text-black text-[13px] lg:text-[15px] leading-[20px] lg:leading-[25px]">
							Hi Ben Smith,
							<br /> Pellentesque at blandit libero. Class aptent
							taciti sociosqu ad litora torquent per conubia
							nostra, per inceptos himenaeos. Sed faucibus
							ullamcorper magna at imperdiet. Pellentesque
							habitant morbi tristique senectus et netus et
							malesuada fames ac turpis egestas. Etiam leo nibh,
							faucibus non eros nec
						</p>
						<div className="mt-5">
							<textarea
								rows="4"
								cols="50"
								placeholder="Type your messages"
								className="resize-none w-full bg-[#f1f1f1] dark:bg-[#101B31] px-[18px] py-[13px]  focus:outline-none rounded-[10px] text-[#BFC6D2] text-[12px]"
							/>
						</div>
					</div>
					<div className="col-span-4">
						<div className="px-2  h-[325.86px] overflow-y-auto custom-scroll">
							{data.map((row, index) => (
								<div
									key={row.id}
									className={`flex text-left rounded-[8px] bg-white dark:bg-[#0F1A32] p-[10px] mb-[17.22px] ${
										index === data.length - 1
											? 'mb-[0]'
											: ''
									}`}
								>
									<img
										src={ActivityFeedImage}
										alt="ActivityFeed"
										className="mr-[12.16px] h-full"
									/>
									<div className="w-full">
										<p className="dark:text-[#BFC6D2] text-[10px] md:text-[13px] text-black lg:text-[15px] leading-[25px]">
											{row.title}
										</p>
										<span className="text-[#BFC6D2] text-[10px] leading-[15px] block">
											{row.subtitle}
										</span>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
