import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function SkillDevelopmentInsight() {
	const chartData = {
		series: [35, 20, 25, 15],
		options: {
			chart: {
				type: 'pie',
			},
			labels: ['Design', 'Development', 'Animation', 'UI/UX Design'],
			colors: ['#FFC700', '#DB00FF', '#00FFFF', '#00FF66'],
			legend: {
				labels: {
					colors: '#FFF',
					align: 'left',
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 300,
						},
						legend: {
							position: 'bottom',
						},
					},
				},
			],
			dataLabels: {
				enabled: false,
			},
		},
	};
	const customCSS = `
    .apexcharts-legend-series {
      text-align: left !important;
    }
    .apexcharts-legend-text {
      font-size: 18px !important;
      line-height: 30px;
    }
    .apexcharts-pie-series path {
      stroke:transparent !important;
    }
  `;
	return (
		<div>
			<h2 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7 text-left pb-[25.92px]">
				Skill Development Insight
			</h2>
			<div className="mb-[39px] p-8 mt-6 lg:mt-0 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
				<div className="flex items-center justify-between mb-5 pb-5 dark:border-[#333333] border-gray-300 border-b border-solid">
					<p className=" text-[19px] font-semibold leading-[25px] dark:text-white text-[#4b5563]">
						James Smith
					</p>
					<i className="fa fa-angle-down text-[#BFC6D2] text-[22px]"></i>
				</div>
				<ReactApexChart
					options={chartData.options}
					series={chartData.series}
					type="pie"
					height={300}
				/>
			</div>
			<style>{customCSS}</style>
		</div>
	);
}
