import React, { useEffect } from 'react';
import InputComponent from '../../component/ui-component/InputComponent';
import TextareaComponent from '../../component/ui-component/TextareaComponent';
import Button from '../../component/ui-component/Button';
import { useDispatch, useSelector } from 'react-redux';
import SelectComponent from '../../component/ui-component/SelectComponent';
import { getDepartmentList } from '../../store/thunk/commonThunk';

import { vsmRoles } from '../../utils/validations';
const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	setValue,
	selectedDepartment,
}) => {
	const dispatch = useDispatch();
	const departmentList = useSelector((state) => state.common.departmentList);

	useEffect(() => {
		dispatch(getDepartmentList());
	}, [dispatch]);

	const onChangeSelectValue = (key, selectedValue) => {
		setValue(key, selectedValue);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
							Role Information
						</h3>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
						<InputComponent
							id="title"
							title="Role Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmRoles}
						/>
						<SelectComponent
							selectOptionLists={departmentList}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedDepartment}
							title="Select Department"
							id="department"
							errors={errors}
							register={register}
							fieldvalidation={vsmRoles}
						/>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-1 gap-2 mt-[2px] textareaComponent">
						<TextareaComponent
							id="description"
							title="Description"
							errors={errors}
							register={register}
							fieldvalidation={vsmRoles}
							customClass="mt-[12px]"
						/>
					</div>
				</div>
			</div>
			<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<Button
					buttonText={`${isUpdate ? 'Update' : 'Create'} Role`}
					linkTo="#"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default FormComponent;
