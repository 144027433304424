const MESSAGES = {
	ERROR: {
		DEFAULT: 'Something went wrong',
		NO_DATA_FOUND: 'No data found',
		LOGIN: 'Something went wrong',
		REGISTER: 'Something went wrong',
		RESETFAIL: 'Email not found',
	},
	SUCCESS: {
		DEFAULT: 'SUCCESS',
		LOGIN: 'Logged in successfully',
		REGISTER: 'Registration successfully',
		RESETSUCCESS: 'Reset password link shared to your email.',
	},
};

export default MESSAGES;
