import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateMeeting } from '../../store/thunk/meetingThunk';
import FormComponent from './FormComponent';
import { formatDate } from '../../../src/component/Common';

const EditEventComponent = ({ visible, onClose, editValues }) => {
	const [uploadedFile, setUploadedFile] = useState();
	const loading = useSelector((state) => state.dataLoading.loading);
	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		setValue,
	} = useForm({
		defaultValues: {
			title: editValues?.title,
			description: editValues?.description,
			meeting_date: formatDate(editValues?.start, 'YYYY-MM-DD'),
			from_time: editValues?.from_time,
			to_time: editValues?.to_time,
		},
	});

	const onSuccessCallback = () => {
		reset();
		onClose();
	};

	const onSubmit = (data) => {
		data.id = editValues?.id;
		data.callbackFunction = onSuccessCallback;
		data.setError = setError;
		if (uploadedFile !== undefined) {
			data.files = uploadedFile;
		} else {
			delete data.files;
		}
		if (data.users === undefined) {
			let assigned_user_ids = [];
			editValues?.users.map((item) =>
				assigned_user_ids.push(parseInt(item.id))
			);
			data.users = assigned_user_ids;
		}
		dispatch(updateMeeting(data));
	};

	return (
		<>
			<div
				className={` togglesidebar bg-[#ffffff57] fixed h-full w-full top-0 left-0 p-6 z-[99999]  ${
					visible ? 'active' : ''
				}`}
			>
				<div
					onClick={onClose}
					className="w-full bg-[#ffffff57] overlay bg-[#0f1a32] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
				/>
				<div className="dark:bg-[#12243d] bg-[#fafafa] border-gray-300 border-l w-[85%] lg:w-[50%] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]">
					<button
						className="close h-8 w-8 rounded-full absolute top-5 right-5 bg-[#fff]"
						onClick={onClose}
					>
						X
					</button>
					<h1 className="p-6 dark:text-white text-[#4b5563] font-bold text-[20px] text-left pb-[20px] border-solid border-b dark:border-[#333333] border-gray-300">
						Update Leave Details
					</h1>
					<div className="rounded-lg">
						<FormComponent
							loading={loading}
							register={register}
							handleSubmit={handleSubmit}
							onSubmit={onSubmit}
							errors={errors}
							isUpdate={true}
							setValue={setValue}
							selectedAssignedUsers={editValues?.users}
							setUploadedFile={setUploadedFile}
							fileURL={editValues?.files}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditEventComponent;
