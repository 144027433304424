import React from 'react';
import { useTimer } from './TimerContext';
// import { useSelector } from 'react-redux';

const moment = require('moment');

const Timesheet = ({ punchInTime, statisticsData }) => {
	// const authUser = useSelector((state) => state.auth);
	// const user_id = authUser?.userDetails?.id;
	const { timer, now, isRunning, handleStartPauseClick, formatTime } =
		useTimer();
	let timepassed = (timer - (now || 0)) / 1000;

	return (
		<>
			<div className="p-7 relative dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] rounded-[15px]">
				<div className="text-center	profile-widget content-center">
					<div className="text-left dark:text-white text-[#4b5563] text-2xl font-bold tracking-wider">
						Timesheet{' '}
						<span className="text-[#94a3b8] text-xl">
							{moment().format('DD MMM YYYY')}
						</span>
					</div>
					<div className="mt-5 p-5 dark:bg-[#101B32] bg-[#f2f2f2] rounded-[5px] text-left dark:text-white text-[#4b5563] font-semibold tracking-wide">
						<h4>Punch In at</h4>
						<p className="text-[#94a3b8]">
							{punchInTime
								? moment(punchInTime?.timestamp).format(
										'dddd, DD MMM YYYY hh:mm A'
								  )
								: moment().format('dddd, DD MMM YYYY')}
						</p>
					</div>
					<div className="flex p-5 mt-6 rounded-full font-semibold tracking-wide text-[18px] h-[140px] w-[140px] m-auto items-center dark:bg-[#101B32] bg-[#f2f2f2] dark:text-white text-[#4b5563]">
						<span>{formatTime(timepassed)}</span>
					</div>

					<div className="block text-center my-6">
						<button
							className="bg-purple text-lg tracking-wide leading-[18px] font-bold text-white rounded-full px-6 lg:px-12 py-4 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
							onClick={() =>
								handleStartPauseClick({ is_from_logout: false })
							}
						>
							{isRunning ? 'Punch Out' : 'Punch In'}
						</button>
					</div>
					<div className="block text-center my-6">
						<div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 text-white text-left">
							<div className="text-center dark:bg-[#101B32] bg-[#f2f2f2] rounded-[5px] px-6 lg:px-12 p-4 dark:text-white text-[#4b5563] font-semibold tracking-wide flex flex-col items-center justify-center">
								<p>Break</p>
								<h6>1 hrs</h6>
							</div>
							<div className="text-center dark:bg-[#101B32] bg-[#f2f2f2] rounded-[5px] px-6 lg:px-12 p-4 dark:text-white text-[#4b5563] font-semibold tracking-wide flex flex-col items-center justify-center">
								<p>Overtime</p>
								<h6>
									{`${
										statisticsData?.total_monthly_hours >
										160
											? statisticsData?.total_monthly_hours -
											  160
											: 0
									} hrs`}
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Timesheet;
