import React from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const CKEditorComponent = ({ id, title, data, onChange }) => {
	const handleSelectChange = (event, editor) => {
		const content = editor.getData();
		onChange(id, content);
	};

	return (
		<div className="editor-container lg:mt-[15px] mt-[60px]">
			<label
				htmlFor={title}
				className="dark:text-white text-[#1f2937] text-left left-0 flex w-full mb-[10px] text-[15px]"
			>
				{title}
			</label>
			<CKEditor
				id={id}
				name={title}
				editor={ClassicEditor}
				data={data}
				onChange={handleSelectChange}
			/>
		</div>
	);
};

export default CKEditorComponent;
