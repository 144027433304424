import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

export default function Statistics() {
	const options = {
		plotOptions: {
			bar: {
				columnWidth: '25%',
				borderRadius: 6,
				dataLabels: {
					enabled: false, // Disable data labels on bars
				},
			},
		},
		chart: {
			type: 'bar',
			toolbar: {
				show: false,
			},
		},
		xaxis: {
			categories: ['Jan', 'Feb', 'Mar', 'Apl', 'May', 'Jun', 'Jul'],
			tick: false,
		},
		yaxis: {
			tickAmount: 6,
		},
		stroke: {
			width: 3,
		},
		colors: ['#C5CC05'],
		legend: {
			show: false,
		},
		theme: {
			mode: 'light',
		},
	};

	const series = [
		{
			data: [30, 40, 25, 50, 49, 60, 100],
		},
	];
	const customCSS = `
    .apexcharts-legend-series {
      text-align: left !important;
    }
    .apexcharts-bar-area path {
      border-radius: 50%;
    }
    .apexcharts-legend-text {
      font-size: 18px !important;
      line-height: 30px;
    }
    .apexcharts-inner .apexcharts-xaxis-tick {
      display: none;
  }
  `;
	const getBarGradient = () => {
		return (
			<defs>
				<linearGradient
					id="barGradient"
					gradientTransform="rotate(180)"
				>
					<stop offset="0%" stopColor="#01D9EC" />
					<stop offset="100%" stopColor="#C5CC05" />
				</linearGradient>
			</defs>
		);
	};
	return (
		<div>
			<h2 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7 text-left pb-[25.92px]">
				Statistics
			</h2>
			<div className="mb-[39px] text-black p-8 mt-6 lg:mt-0 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
				<div className="dark:text-white text-[#4b5563]  flex items-center justify-between mb-5 pb-5 dark:border-[#333333] border-gray-300 border-b border-solid">
					<p className=" text-[19px] font-semibold leading-[25px]">
						Sara Kabir
					</p>
					<div>
						<i className="fa-solid fa-angle-left h-[25px] w-[25px] bg-[#bfc6d2] hover:text-black hover:bg-[#ffe334] rounded-[50%] leading-6 mr-[10px]"></i>
						<i className="fa-solid fa-angle-right h-[25px] w-[25px] bg-[#bfc6d2] hover:text-black hover:bg-[#ffe334] rounded-[50%] leading-6"></i>
					</div>
				</div>
				<div className="h-[292px]">
					{getBarGradient()}
					<ReactApexChart
						options={options}
						series={series}
						type="bar"
						height="100%"
						fill="url(#barGradient)"
					/>
				</div>
			</div>
			<style>{customCSS}</style>
		</div>
	);
}
// import React from "react";
// import ReactApexChart from "react-apexcharts";

// const Statistics = () => {
//   const options = {
//     plotOptions: {
//       bar: {
//         columnWidth: "50%",
//         borderRadius: 6,
//         dataLabels: {
//           enabled: false,
//         },
//       },
//       group: {
//         groupPadding: 5,
//       },
//     },
//     chart: {
//       type: "bar",
//       toolbar: {
//         show: false,
//       },
//     },
//     xaxis: {
//       categories: ["Jan", "Feb", "Mar", "Apl", "May", "Jun", "Jul"],
//       labels: {
//         style: {
//           colors: "#FFF",
//         },
//       },
//       tick: false,
//     },
//     yaxis: {
//       labels: {
//         style: {
//           colors: "#FFF",
//         },
//       },
//       tickAmount: 6,
//     },
//     stroke: {
//       width: 3,
//     },
//     colors: ["#C5CC05", "#01D9EC"], // Colors for the bars
//     legend: {
//       show: true,
//     },
//     theme: {
//       mode: "light",
//     },
//   };

//   const series = [
//     {
//       name: "Series 1",
//       data: [30, 40, 25, 50, 49, 60, 70],
//     },
//     {
//       name: "Series 2",
//       data: [20, 25, 30, 35, 40, 45, 50],
//     },
//   ];

//   return (
//     <div>
//       <h2 className="dark:text-white text-[#000] text-[22px] font-bold leading-7 text-left pb-[25.92px]">
//         Multi-Bar Chart
//       </h2>
//       <div className="mb-[39px] text-black p-8 mt-6 lg:mt-0 rounded-[15px] shadow-none dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] dark:bg-[#17243e] bg-[#f2f2f2]">
//         <div className="h-[292px]">
//           <ReactApexChart
//             options={options}
//             series={series}
//             type="bar"
//             height="100%"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Statistics;
