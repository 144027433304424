import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLeavesList } from '../../store/thunk/leavesThunk';
import ReactTablePagination from '../../component/ui-component/pagination/ReactTablePagination';
import StatusDropDown from '../../component/ui-component/StatusDropDown';
import { getDefaultProfile } from '../../component/Common';
import View from '../../assets/images/View.png';
import UserProfile from '../../component/ui-component/UserProfile';

const statusOptions = [
	{ value: 1, label: 'Pending', iconClass: 'text-sky-500' },
	{ value: 2, label: 'Approved', iconClass: 'text-green-500' },
	{ value: 3, label: 'Rejected', iconClass: 'text-red-500' },
	// { value: 4, label: "Processing", iconClass: "text-orange-500" },
	// { value: 5, label: "Withdrawn", iconClass: "text-yellow-700" },
];

const moment = require('moment');

const ListComponent = ({ handleLeaveStatusChange, handleViewClick }) => {
	const [recordsPerPage, setRecordsPerPage] = useState(10);

	const dispatch = useDispatch();

	const leavesData = useSelector((state) => state.leaves);

	const columns = [
		{
			Header: 'Employee',
			accessor: 'user.fullname',
			// width: '15%',
			resize: true,
			Cell: ({ row, value }) => (
				<div className="flex">
					<div>
						<UserProfile
							imageUrl={row?.original?.user?.profile_image}
							defaultImageUrl={getDefaultProfile(
								row?.original?.user?.gender
							)}
							altName={row?.original?.user?.fullname}
							className={
								'mr-[13px] h-[40px] w-[40px] rounded-full bg-white border border-slate-300 p-0.5'
							}
						/>
					</div>
					<span className="mt-[8px]">
						{row?.original?.user?.fullname}
					</span>
				</div>
			),
		},
		{
			Header: 'Leave Type',
			accessor: 'leave_type_name',
			// width: '15%',
			resize: true,
		},
		{
			Header: 'From Date',
			accessor: 'start_date',
			// width: '10%',
			resize: true,
			Cell: ({ row, value }) => {
				return <>{moment(value, 'YYYY-MM-DD').format('DD MMM YYYY')}</>;
			},
		},
		{
			Header: 'To Date',
			accessor: 'end_date',
			// width: '10%',
			resize: true,
			Cell: ({ row, value }) => {
				return <>{moment(value, 'YYYY-MM-DD').format('DD MMM YYYY')}</>;
			},
		},
		{
			Header: 'No.Of Days',
			accessor: 'no_of_days',
			// width: '8%',
			resize: true,
			Cell: ({ row, value }) => {
				return (
					<>{`${row?.original?.no_of_days} ${
						row?.original?.no_of_days > 1 ? 'days' : 'day'
					}`}</>
				);
			},
		},
		{
			Header: 'Reason',
			accessor: 'reason',
			// width: '30%',
			resize: true,
			Cell: ({ row, value }) => {
				return (
					<>
						<p
							className="text-muted py-2 text-[13px] dark:text-[#fff] text-[#000]"
							dangerouslySetInnerHTML={{
								__html: `${row?.original?.reason.substring(
									0,
									50
								)} ${
									row?.original?.reason.length > 50
										? '.....'
										: ''
								}`,
							}}
						></p>
					</>
				);
			},
		},
		{
			Header: 'Status',
			accessor: 'leave_status_name',
			// width: '12%',
			resize: true,
			Cell: ({ row, value }) => (
				<>
					<StatusDropDown
						statusData={row?.original}
						statusOptions={statusOptions}
						selectedOptionsValue={row?.original?.leave_status}
						handleOnChange={handleLeaveStatusChange}
					/>
				</>
			),
		},
		{
			Header: 'Action',
			accessor: 'action',
			disableSortBy: true,
			Cell: ({ row, value }) => {
				return (
					<div
						className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full mr-[8px] border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
						onClick={() => handleViewClick(row?.original)}
						title="View"
					>
						<img src={View} alt="View" className="w-[22px]" />
					</div>
				);
			},
		},
	];

	const fetchData = useCallback(
		(pageSize, pageIndex, searchText, sortBy) => {
			dispatch(
				getAllLeavesList({
					pageSize: pageSize,
					pageNo: pageIndex,
					searchText: searchText,
					sortBy: sortBy,
				})
			);
		},
		[dispatch]
	);

	return (
		<>
			<ReactTablePagination
				columns={columns}
				data={leavesData?.myleavesList}
				fetchData={fetchData}
				pageCount={Math.ceil(leavesData?.totalRecords / recordsPerPage)}
				totalRecords={leavesData?.totalRecords}
				recordsPerPage={recordsPerPage}
				setRecordsPerPage={setRecordsPerPage}
				csvFileName="users_leave_lists.csv"
			/>
		</>
	);
};

export default ListComponent;
