import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeLeaves } from '../../store/thunk/leavesThunk';
import Workflow from './Workflow';
import AddLeaveComponent from './AddLeaveComponent';
import EditLeaveComponent from './EditLeaveComponent';
import ViewLeaveComponent from './ViewLeaveComponent';
import Dialogpopup from '../../component/ui-component/Dailogpopup';
import BreadcrumbsComponent from '../../component/ui-component/BreadcrumbsComponent';
import { hasPermission } from '../../component/Common';
import { getUserPermissions } from '../../store/thunk/userThunk';

const UserLeavesManagementComponent = () => {
	const dispatch = useDispatch();
	const [addDrawerVisible, setAddDrawerVisible] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [viewDailogVisible, setViewDailogVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);
	const authUser = useSelector((state) => state.auth);
	const user_permissions = useSelector((state) => state.user.userPermissions);

	const handleSetEditValues = (item) => {
		setEditValues(item);
		setEditDrawerVisible(true);
	};

	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
	};

	const handleViewClick = (item) => {
		setEditValues(item);
		setViewDailogVisible(true);
	};

	useEffect(() => {
		dispatch(getUserPermissions());
	}, [dispatch]);

	return (
		<>
			<div className="p-[20px]">
				<div className="flex items-center justify-between">
					<BreadcrumbsComponent
						showBreadCrumbs={true}
						pageTitle={'Leaves'}
					/>
					{hasPermission(
						authUser?.userDetails,
						user_permissions,
						'add_userleaves'
					) && (
						<button
							className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
							onClick={() => setAddDrawerVisible(true)}
						>
							+ Apply Leave
						</button>
					)}
				</div>
				<Workflow
					handleSetEditValues={handleSetEditValues}
					handleDeleteClick={handleDeleteClick}
					handleViewClick={handleViewClick}
					userData={authUser?.userDetails}
					userPermissions={user_permissions}
				/>
			</div>

			{addDrawerVisible && (
				<AddLeaveComponent
					visible={addDrawerVisible}
					onClose={() => setAddDrawerVisible(false)}
				/>
			)}
			{editDrawerVisible && editValues && (
				<EditLeaveComponent
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
				/>
			)}
			{deleteDailogVisible && (
				<>
					<Dialogpopup
						onCancel={() => setDeleteDailogVisible(false)}
						deleteItem={editValues}
						removeThunkCall={removeLeaves}
						message="Are you sure you want to delete this Leaves ?"
					/>
				</>
			)}
			{viewDailogVisible && (
				<ViewLeaveComponent
					visible={viewDailogVisible}
					onClose={() => setViewDailogVisible(false)}
					editValues={editValues}
				/>
			)}
		</>
	);
};
export default UserLeavesManagementComponent;
