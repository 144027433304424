import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, getDefaultProfile } from '../../../component/Common';
import { getAllMeetingList } from '../../../store/thunk/meetingThunk';
import UserProfile from '../../../component/ui-component/UserProfile';

import BgImg1 from '../../../assets/images/upcoming-image1.png';
import Clock from '../../../assets/images/clock.png';
import Toggle from '../../../assets/images/dots.png';

export default function UpcomingEvents() {
	const dispatch = useDispatch();
	const meetingsLists = useSelector((state) => state.meetings?.meetingList);

	useEffect(() => {
		dispatch(getAllMeetingList());
	}, [dispatch]);
	return (
		<div className="upcomming-box expert-grid relative md:p-[20px] p-[10px] dark-dark-light-card">
			<h2 className="card-title text-left dark:text-white text-[#4b5563]">
				Upcomming Events
			</h2>
			<span className="absolute text-[#fff] top-5 right-5 cursor-pointer">
				<img src={Toggle} alt="dot" className="dot-toggle" />
			</span>
			<div className="overflow-x-auto top-scroll">
				<div className="items w-[max-content] pb-7">
					{meetingsLists.length > 0 &&
						meetingsLists.map((row, i) => (
							<div
								key={i}
								className="relative item w-[230px] mr-[22px] text-left rounded-[10px] "
							>
								<div className=" z-[1] relative p-2.5 blue-bg-gradient text-white rounded-[10px]">
									<p className="text-[12px] mb-[5px] font-bold">
										{formatDate(
											row.meeting_date,
											'DD MMM - YYYY'
										)}
									</p>
									<h2 className="text-base font-bold leading-[21px] mb-3 dark:text-[#fff]">
										{row.title}
									</h2>
									<ul>
										<li className="flex items-center text-sm leading-[18px] mb-3 dark:text-[#fff]">
											<span className="mr-2 icon h-[24px] w-[24px] rounded-full inline-flex items-center justify-center">
												<img
													src={Clock}
													alt="clock"
													className="h-[20px] w-[20px]"
												/>
											</span>
											{`${row.from_time} - ${row.to_time}`}
										</li>
									</ul>
									<ul className="user pl-7">
										{row?.users.length > 0 &&
											row?.users.map((user, index) => (
												<li
													key={index}
													className="inline-block hover:cursor-pointer"
												>
													<UserProfile
														imageUrl={
															user?.profile_image_url
														}
														defaultImageUrl={getDefaultProfile(
															user?.gender
														)}
														altName={user?.fullname}
														className={
															'rounded-full h-[34px] w-[34px]'
														}
													/>
												</li>
											))}
									</ul>
								</div>
								<div className="absolute z-0 h-full w-full left-0 top-0">
									<img
										src={BgImg1}
										alt="bgImg"
										className="h-full w-full"
									/>
								</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
}
