import React, { useEffect } from 'react';
import InputComponent from '../../../component/ui-component/InputComponent';
import Button from '../../../component/ui-component/Button';
import SelectComponent from '../../../component/ui-component/SelectComponent';
import DatePickerComponent from '../../../component/ui-component/DatePickerComponent';
import { useDispatch, useSelector } from 'react-redux';
import { vsmTask } from '../../../utils/validations';
import TextareaComponent from '../../../component/ui-component/TextareaComponent';
import { getUserList } from '../../../store/thunk/userThunk';
import { getProjectList } from '../../../store/thunk/projectsThunk';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	setValue,
	selectedPriority,
	selectedProject,
	selectedAssignedUser,
}) => {
	const priorityList = [
		{
			id: 1,
			name: 'Low',
		},
		{
			id: 2,
			name: 'Medium',
		},
		{
			id: 3,
			name: 'High',
		},
	];
	const dispatch = useDispatch();
	const userList = useSelector((state) => state.user.userList);
	const projectLists = useSelector((state) => state.projects.projectList);
	const authUser = useSelector((state) => state.auth);

	useEffect(() => {
		dispatch(getUserList());
		dispatch(getProjectList());
	}, [dispatch]);

	const onChangeSelectValue = (key, selectedValue) => {
		setValue(key, selectedValue);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
							Task Information
						</h3>
					</div>
					<div className="grid gap-5 mb-[20px]">
						<InputComponent
							id="title"
							title="Task Title"
							errors={errors}
							register={register}
							fieldvalidation={vsmTask}
						/>
					</div>
					<div className="grid grid-cols-2 gap-5">
						<SelectComponent
							selectOptionLists={projectLists}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedProject}
							title="Project"
							id="project"
							errors={errors}
							register={register}
							fieldvalidation={vsmTask}
						/>
						<SelectComponent
							selectOptionLists={priorityList}
							onChangeSelectValue={onChangeSelectValue}
							selectedValue={selectedPriority}
							title="Priority"
							id="priority"
							errors={errors}
							register={register}
							fieldvalidation={vsmTask}
						/>
						<DatePickerComponent
							id="due_date"
							title="Due Date"
							errors={errors}
							register={register}
							fieldvalidation={vsmTask}
						/>
						{selectedAssignedUser !== authUser?.userDetails?.id && (
							<SelectComponent
								selectOptionLists={userList}
								onChangeSelectValue={onChangeSelectValue}
								selectedValue={selectedAssignedUser}
								title="Assigned User"
								id="assigned_to"
								errors={errors}
								register={register}
								fieldvalidation={vsmTask}
							/>
						)}
					</div>
					<div className="grid gap-5 textareaComponent">
						<TextareaComponent
							id="description"
							title="Task Description"
							errors={errors}
							register={register}
							fieldvalidation={vsmTask}
							customClass="mt-[20px]"
						/>
					</div>
				</div>
			</div>
			<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<Button
					buttonText={`${isUpdate ? 'Update' : 'Create'} Task`}
					linkTo="#"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default FormComponent;
