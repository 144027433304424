import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const RatingCircleChart = () => {
	const [chartData, setChartData] = useState({
		series: [50],
		options: {
			chart: {
				// height: 350,
				type: 'radialBar',
				toolbar: {
					show: false, // Hide the menu icon
				},
			},
			plotOptions: {
				radialBar: {
					startAngle: -135,
					endAngle: 225,
					hollow: {
						margin: 0,
						size: '70%',
						background: '#17243E',
						image: undefined,
						imageOffsetX: 0,
						imageOffsetY: 0,
						position: 'front',
						dropShadow: {
							enabled: true,
							top: 3,
							left: 0,
							blur: 4,
							opacity: 0.24,
						},
					},
					track: {
						background: ['#BD00FF', '#216CFF'],
						strokeWidth: '70%',
						margin: 0,
						dropShadow: {
							enabled: true,
							top: -3,
							left: 0,
							blur: 4,
							opacity: 0.35,
						},
					},
					dataLabels: {
						show: true,
						name: {
							offsetY: -10,
							show: true,
							color: '#FFF',
							fontSize: '17px',
						},
						value: {
							formatter: function (val) {
								return parseInt(val) + '%';
							},
							color: '#FFF',
							fontSize: '36px',
							show: true,
						},
						group: {
							textAnchor: 'start',
						},
					},
				},
			},
			labels: ['Soft Ratio', 'Hard Ratio'],
			fill: {
				type: 'gradient',
				gradient: {
					shade: 'dark',
					type: 'horizontal',
					shadeIntensity: 0.5,
					gradientToColors: ['#C5CC05', '#FF0000'], // Colors for the fill gradient
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 100],
				},
			},
			stroke: {
				lineCap: 'round',
			},
			legend: {
				show: true,
				position: 'right', // Set the legend position to "right"
				horizontalAlign: 'center', // Align the legend to the center
				formatter: function (seriesName, opts) {
					return (
						seriesName +
						': ' +
						opts.w.globals.series[opts.seriesIndex] +
						'%'
					);
				},
			},
		},
	});
	const customCSS = `
  .apexcharts-legend-text {
    font-size: 14px !important;
    color: #fff !important;
  }
  .apexcharts-legend {
    display:flex;
    justify-content:center !important;
  }
`;
	return (
		<>
			<div
				id="chart"
				className="h-[300.473px] p-[21px] pr-[15px] mt-6 lg:mt-0 rounded-[15px]  shadow-none dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] dark:bg-[#17243e] bg-[#f2f2f2]"
			>
				<ReactApexChart
					options={chartData.options}
					series={chartData.series}
					type="radialBar"
					height="100%"
				/>
			</div>
			<style>{customCSS}</style>
		</>
	);
};

export default RatingCircleChart;
