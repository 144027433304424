import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	notificationSuccess,
	notificationFail,
} from '../slices/notificationSlice';
import { setLoading } from '../slices/dataLoadingSlice';
import {
	setCompanyData,
	setFetching,
	setSingleCompanyData,
	setDashboardData,
	setReportAnalyticdData,
} from '../slices/companySlice';

import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';
import { vsmCompany } from '../../utils/validations';

export const getCompanyList = createAsyncThunk(
	'getCompanyList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageNo, pageSize, searchText, sortBy } = _request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';
				if (sortField === 'company_country.name') {
					sortField = 'company_country__name';
				}

				if (sortField !== 'active' && sortField !== 'action') {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';

			const response = await apiClient().get(
				`/accounts/company/lists/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setCompanyData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addCompany = createAsyncThunk(
	'addCompany',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().post(
				`/accounts/register/`,
				_request
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getCompanyList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmCompany?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			const errorMessage =
				err?.response?.data?.message ||
				err?.message ||
				'An error occurred.';
			dispatch(notificationFail(errorMessage));
			toast.error(errorMessage);
		}
	}
);

export const editCompany = createAsyncThunk(
	'editCompany',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().patch(
				`/accounts/company/update/${_request.uid}/`,
				_request
			);

			dispatch(setLoading(false));
			if (response?.data) {
				let message = vsmCompany?.updateSuccess;
				dispatch(getCompanyList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(message);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			const errorMessage =
				err?.response?.data?.message ||
				err?.message ||
				err?.response?.data[0] ||
				'An error occurred.';
			dispatch(notificationFail(errorMessage));
			toast.error(errorMessage);
		}
	}
);

export const removeCompany = createAsyncThunk(
	'removeCompany',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient(false, _request.uid).delete(
				`/accounts/company/delete/${_request.uid}/`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getCompanyList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmCompany?.deleteSuccess);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const updateCompanyStatus = createAsyncThunk(
	'updateCompanyStatus',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const { userid, is_active } = _request;
			const response = await apiClient(false).patch(
				`/accounts/user/statusupdate/${userid}/`,
				{ is_active }
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getCompanyList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmCompany?.update_status_success);
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const getCompanyData = createAsyncThunk(
	'getCompanyData',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().get(
				`/accounts/company/profile/${
					_request?.company_id || _request?.uid
				}`
			);
			dispatch(setLoading(false));
			dispatch(setSingleCompanyData(response?.data));
			localStorage.setItem(
				'profile_image',
				response?.data?.user_info?.profile_image_url
			);
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const updateCompanyProfile = createAsyncThunk(
	'updateCompanyProfile',
	async (_request, { dispatch }) => {
		try {
			const { is_update_logo, is_from_settings } = _request;
			delete _request.is_update_logo;
			delete _request.is_from_settings;

			const formData = new FormData();

			// Append other form data fields
			Object.keys(_request).forEach((key) => {
				if (
					key !== 'profile_image' ||
					(key !== 'company_logo' && _request[key] !== null)
				) {
					formData.append(key, _request[key]);
				}
			});

			// Append file data if provided
			if (_request.profile_image) {
				formData.append('profile_image', _request.profile_image);
			}

			if (_request.company_logo) {
				formData.append('company_logo', _request.company_logo);
			}

			dispatch(setLoading(true));
			const response = await apiClient(true).patch(
				`/accounts/company/update/${_request.uid}/`,
				formData
			);

			dispatch(setLoading(false));
			if (response?.data) {
				let message = is_update_logo
					? vsmCompany?.logoUpdate
					: vsmCompany?.profileUpdate;
				dispatch(getCompanyData(_request));
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(message);
				if (!is_update_logo && !is_from_settings) {
					_request.callbackFunction();
				}
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			const errorMessage =
				err?.response?.data?.message ||
				err?.response?.data[0] ||
				err?.message ||
				'An error occurred.';
			dispatch(notificationFail(errorMessage));
			toast.error(errorMessage);
		}
	}
);

export const getDashboardData = createAsyncThunk(
	'getDashboardData',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().get(
				`/accounts/dashboard-counts/`
			);
			dispatch(setLoading(false));
			dispatch(setDashboardData(response?.data));
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const getReportAnalytics = createAsyncThunk(
	'getReportAnalytics',
	async (_request, { dispatch }) => {
		try {
			let { start_date, end_date } = _request;
			dispatch(setLoading(true));
			const response = await apiClient().get(
				`/accounts/dashboard-report-analytics/?start_date=${start_date}&end_date=${end_date}`
			);
			dispatch(setLoading(false));
			dispatch(setReportAnalyticdData(response?.data));
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);
