import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	notificationSuccess,
	notificationFail,
} from '../slices/notificationSlice';
import { setLoading } from '../slices/dataLoadingSlice';
import { setHolidayData, setFetching } from '../slices/holidaysSlice';
import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';
import { vsmHoliday } from '../../utils/validations';

export const getHolidayList = createAsyncThunk(
	'getHolidayList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageNo, pageSize, searchText, sortBy } = _request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';

				if (
					sortField !== 'day' &&
					sortField !== 'active' &&
					sortField !== 'action'
				) {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';

			const response = await apiClient(false).get(
				`/accounts/holidays/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setHolidayData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addHoliday = createAsyncThunk(
	'addHoliday',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().post(
				`/accounts/holidays/`,
				_request
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getHolidayList());
				dispatch(notificationSuccess(vsmHoliday?.addSucces));
				toast.success(vsmHoliday?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const editHoliday = createAsyncThunk(
	'editHoliday',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().patch(
				`/accounts/holidays/${_request.id}/`,
				_request
			);

			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getHolidayList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmHoliday?.updateSuccess);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeHoliday = createAsyncThunk(
	'removeHoliday',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(`/accounts/holidays/${_request.id}/`);
			dispatch(setLoading(false));
			dispatch(getHolidayList());
			dispatch(notificationSuccess(vsmHoliday?.deleteSuccess));
			toast.success(vsmHoliday?.deleteSuccess);
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);
