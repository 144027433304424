import React, { useState, useEffect } from 'react';

export default function NotificationBlock({ user_settings, onChangeToggle }) {
	const [userSettingStates, setuserSettingStates] = useState([]);
	const userSettingLabels = Object.keys(user_settings);

	useEffect(() => {
		setuserSettingStates(Object.values(user_settings));
	}, [user_settings]);

	const handleSwitchToggle = (index) => {
		const updateduserSettingStates = [...userSettingStates];
		updateduserSettingStates[index] = !updateduserSettingStates[index];
		setuserSettingStates(updateduserSettingStates);

		const updatedNotificationPreference = {
			...user_settings,
			[userSettingLabels[index]]: updateduserSettingStates[index],
		};

		onChangeToggle(updatedNotificationPreference);
	};

	const formatLabel = (label) =>
		label
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	return (
		<>
			<h1 className="mb-[28.58px] dark:text-white text-[#000] text-[22px] not-italic font-bold leading-7  text-left">
				Notification Preference
			</h1>
			<div className="h-[358.843px] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] rounded-[15px] bg-[#fff] dark:bg-[#17243e] p-[35.25px]">
				{userSettingLabels.map((row, i) => (
					<div
						key={i}
						className="text-left relative flex items-center justify-between mb-[22px] pb-[22px] border-b-2 dark:border-b-[#2A3A5A] border-b-[#E6E6E6] border-solid"
					>
						<p className="text-[#0C1932] dark:text-white">
							{formatLabel(row)}
						</p>
						<div className="customcheck">
							<label className="switch w-[86px] h-[33px] relative inline-block cursor-pointer bg-black shadow-[1px_1px_1px_0px_#e7d4d426] rounded-[50px]">
								<input
									type="checkbox"
									className="absolute h-full w-full opacity-0 invisible z-[99] left-0 top-0;"
									checked={userSettingStates[i]}
									onChange={() => handleSwitchToggle(i)}
								/>
								<span className="circle h-10 w-10 absolute bg-[#464757] z-[1] transition-[0.5s] duration-[all] flex items-center justify-center shadow-[inset_0px_0px_4px_0px_rgb(0_0_0)] rounded-[50%] -left-0.5 -top-1"></span>
								<span className="yes absolute text-sm not-italic font-semibold leading-[18px] text-[#81DE54] uppercase right-[17px] top-[7px]">
									Yes
								</span>
								<span className="on absolute text-sm not-italic font-semibold leading-[18px] text-[#DF4F75] uppercase left-[17px] top-[7px]">
									No
								</span>
							</label>
						</div>
					</div>
				))}
			</div>
		</>
	);
}
