import React from 'react';
import ProgressBar from '../../component/ui-component/ProgressBar';

const skillprogress = [
	{
		title: 'Sara',
		level: '40hr to 175hr',
		percantage: '55%',
		mainColor: '#412d71',
		shadow1: '#8456f9',
		shadow2: '#482c90',
		shadow3: '#8e37e2',
		color2: '#4f309f',
		color1: '#804dff',
		bgColor: '#b37bda',
		bgColor2: '#dcc3ea',
		bgColor3: '#fff',
	},
	{
		title: 'Ben Smith',
		level: '40hr to 175hr',
		percantage: '55%',
		mainColor: '#073a56',
		shadow1: '#0070a7',
		shadow2: '#004773',
		shadow3: '#000',
		color2: '#3f576e',
		color1: '#99d1f2',
		bgColor: '#4d5e6e',
		bgColor2: '#c3c9ce',
		bgColor3: '#fff',
	},
	{
		title: 'Jon Doe',
		level: '40hr to 175hr',
		percantage: '95%',
		mainColor: '#674306',
		shadow1: '#AA622E',
		shadow2: '#753507',
		shadow3: '#cc925a',
		bgColor: '#cc925a',
		bgColor2: '#ecd7c3',
		bgColor3: '#fff',
		color1: '#ffc800',
		color2: '#ff8c00',
	},
	{
		title: 'Minahj',
		level: '40hr to 175hr',
		percantage: '55%',
		mainColor: '#073a56',
		shadow1: '#0070a7',
		shadow2: '#004773',
		shadow3: '#000',
		color2: '#3f576e',
		color1: '#99d1f2',
		bgColor: '#4d5e6e',
		bgColor2: '#c3c9ce',
		bgColor3: '#fff',
	},
	{
		title: 'Robinson',
		level: '40hr to 175hr',
		percantage: '55%',
		mainColor: '#412d71',
		shadow1: '#8456f9',
		shadow2: '#482c90',
		shadow3: '#8e37e2',
		color2: '#4f309f',
		color1: '#804dff',
		bgColor: '#b37bda',
		bgColor2: '#dcc3ea',
		bgColor3: '#fff',
	},
];
export default function Performance() {
	return (
		<div>
			<h2 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7 text-left pb-[25.92px]">
				Performance
			</h2>
			<div className="mb-[39px] md:p-8 p-4 pr-[15px] mt-6 lg:mt-0 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
				<div className="mr-[20px] flex items-center justify-between mb-5 pb-5 dark:border-[#333333] border-gray-300 border-b border-solid">
					<p className="dark:text-white text-[#4b5563] text-[19px] font-semibold leading-[25px]">
						Week
					</p>
					<i className="fa fa-angle-down text-[#BFC6D2] text-[22px]"></i>
				</div>
				<div className="performance-row-lists custom-scroll pr-[15px]">
					{skillprogress.map((data, index) => (
						<div key={index} className="progressbar-row mb-2">
							<div className="progressbar-title items-center flex flex-wrap justify-between dark:text-white text-[#0C1932] mb-[15px]">
								<p className="text-sm font-normal leading-[18px] w-[70px] text-left">
									{data.title}
								</p>
								<div className="progressbar p-[7px] w-[50%]">
									<ProgressBar
										progress={data.percantage}
										mainColor={data.mainColor}
										shadow1={data.shadow1}
										shadow2={data.shadow2}
										shadow3={data.shadow3}
										bgColor={data.bgColor}
										bgColor2={data.bgColor2}
										bgColor3={data.bgColor3}
										color1={data.color1}
										color2={data.color2}
									/>
								</div>
								<span className=" text-sm not-italic font-semibold leading-[18px] flex">
									{data.level}
								</span>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
