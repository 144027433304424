import React, { useState } from 'react';
import UserIcon from '../../assets/images/user.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { setUserData } from '../../store/slices/authSlice';
import ChangePassword from '../../pages/ChangePassword';
import { getUserData } from '../../store/thunk/userThunk';
import { getDefaultProfile } from '../../component/Common';
import UserProfile from '../ui-component/UserProfile';
import { useTimer } from '../../pages/Attendance/TimerContext';

export default function HeaderDropDown() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const ref = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [isClose, setIsClose] = useState(false);
	const [clicked, setClicked] = useState(false);
	const user_data = useSelector((state) => state.user.userData);
	const authUser = useSelector((state) => state.auth);
	const { isRunning, handleStartPauseClick } = useTimer();

	useEffect(() => {
		dispatch(getUserData(authUser?.userDetails));
	}, [dispatch, authUser?.userDetails]);

	const toggleDiv = () => {
		setIsOpen(!isOpen);
	};
	const handleButtonClick = () => {
		setClicked(true);
	};
	const CloseButtonClick = () => {
		setIsOpen(false);
		setClicked(false);
	};
	const handleLogout = () => {
		if (isRunning) {
			handleStartPauseClick({ is_from_logout: true });
		}
		setIsOpen(false);
		localStorage.removeItem('user_data');
		localStorage.removeItem('auth_tokens');
		localStorage.removeItem('profile_image');
		dispatch(setUserData(null));
		navigate('/login');
	};
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setIsOpen(false);
			}
			setIsClose(true);
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [isOpen]);

	let profileLink = '/profile';
	let settingLink = '/setting';

	if (authUser?.userDetails?.is_companyadmin) {
		profileLink = '/cprofile';
		settingLink = '/system-setting';
	}

	let default_profile = '';
	if (user_data?.is_superuser) {
		default_profile = UserIcon;
	} else {
		default_profile = getDefaultProfile(user_data?.gender);
	}

	return (
		<div ref={ref}>
			<div onClick={toggleDiv} className="flex">
				<span>
					<UserProfile
						imageUrl={user_data?.profile_image_url}
						defaultImageUrl={default_profile}
						altName={`${user_data?.first_name} ${user_data?.last_name}`}
						className={
							'img md:w-[50px] w-[40px] md:h-[50px] h-[40px]  inline-block rounded-full border dark:border-[#333333] border-gray-300 p-0.5'
						}
					/>
				</span>
				<span className="usertoggle absolute top-[10px] right-[0px] cursor-pointer dark:text-white text-[#0C1932]">
					<i className="fas fa-chevron-down"></i>
				</span>
			</div>
			{isOpen && (
				<div className="menuDropdown absolute w-[200px] right-[0px] mt-[10px]">
					<ul>
						{!user_data?.is_superuser && (
							<>
								<li
									onClick={CloseButtonClick}
									className="dark:text-white text-[#47586f] text-left flex items-center"
								>
									<Link
										className=" p-2.5 block w-full"
										to={profileLink}
									>
										<i className="mr-3 fas fa-user"></i>
										Profile
									</Link>
								</li>
								<li
									onClick={CloseButtonClick}
									className="dark:text-white text-[#47586f] text-left flex items-center "
								>
									<Link
										className="p-2.5 block w-full"
										to={settingLink}
									>
										<i className="mr-3 fas fa-cog"></i>
										Setting
									</Link>
								</li>
							</>
						)}
						<li className="dark:text-white text-[#47586f] text-left flex items-center ">
							<div
								onClick={handleButtonClick}
								className="p-2.5 block  w-full"
							>
								<i className="mr-3 fas fa-key"></i>
								Change Password
							</div>
							{clicked && (
								<>
									<div className="fixed h-full w-full top-0 left-0 p-6 z-[99999]">
										<div
											onClick={CloseButtonClick}
											className="w-full bg-[#ffffff57] overlay absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
										/>
										<div className="absolute -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 z-[999999] transition-[0.5s] max-w-[600px] w-full">
											<ChangePassword />
										</div>
									</div>
								</>
							)}
						</li>
						<li
							onClick={handleLogout}
							className="dark:text-white text-[#47586f] flex items-center text-left border-t-2 border-t-[#ddd] dark:border-t-[rgb(71, 88, 111)] border-solid"
						>
							<Link className="p-2.5 block w-full">
								<i className="mr-3 fas fa-sign-out-alt"></i>
								Logout
							</Link>
						</li>
					</ul>
				</div>
			)}
		</div>
	);
}
