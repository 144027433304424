import React from 'react';

export default function SecuritySettings() {
	return (
		<div className="md:h-[362.382px] h-auto md:px-[22px] px-[10px] md:py-[33px] py-[10px] dark-dark-light-card dark:text-[#BFC6D2] text-[#54565C] text-left">
			<div className="flex justify-between items-center">
				<p>Two Factor Authentication</p>
				<div className="security-setting">
					<input type="checkbox" id="myCheckbox" className="hidden" />
					<label htmlFor="myCheckbox" className="checkbox-label">
						<span className="checkbox-icon"></span>
					</label>
				</div>
			</div>
			<p className="pb-[11.78px] pt-[22.68px] text-xs dark:text-[#BFC6D2] text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
				Password
			</p>
			<div className="p-3 dark:bg-[#0F1B33] bg-[#fff] rounded-[10px] ">
				<div className="flex justify-between items-center mb-[16.36px]">
					<p className="text-black dark:text-white text-[16px] font-bold">
						Capital Letters
					</p>
					<div className="security-setting">
						<input
							type="checkbox"
							id="myCheckbox1"
							className="hidden"
						/>
						<label htmlFor="myCheckbox1" className="checkbox-label">
							<span className="checkbox-icon"></span>
						</label>
					</div>
				</div>
				<div className="flex justify-between items-center mb-[16.36px]">
					<p className="text-black dark:text-white text-[16px] font-bold">
						Numbers
					</p>
					<div className="security-setting">
						<input
							type="checkbox"
							id="myCheckbox2"
							className="hidden"
						/>
						<label htmlFor="myCheckbox2" className="checkbox-label">
							<span className="checkbox-icon"></span>
						</label>
					</div>
				</div>
				<div className="flex justify-between items-center">
					<p className="text-black dark:text-white text-[16px] font-bold">
						Special Characters
					</p>
					<div className="security-setting">
						<input
							type="checkbox"
							id="myCheckbox3"
							className="hidden"
						/>
						<label htmlFor="myCheckbox3" className="checkbox-label">
							<span className="checkbox-icon"></span>
						</label>
					</div>
				</div>
			</div>
			<div className="flex justify-between items-center mt-[22.68px]">
				<p>Capturing Login</p>
				<div className="security-setting">
					<input
						type="checkbox"
						id="myCheckbox4"
						className="hidden"
					/>
					<label htmlFor="myCheckbox4" className="checkbox-label">
						<span className="checkbox-icon"></span>
					</label>
				</div>
			</div>
		</div>
	);
}
