import React, { useState, useEffect } from 'react';
import AchievementCard from '../../assets/images/Achievement-Card.png';
import ProgressBar from '../../component/ui-component/ProgressBar';
import Xp2 from '../../assets/images/xp2.png';
import BgImg2 from '../../assets/images/Maskgroup4.png';
import Clock from '../../assets/images/clock.png';
import Facebook from '../../assets/images/facebook.png';
import Twitter from '../../assets/images/twitter.png';
import Instagram from '../../assets/images/instagram.png';
import LinkedIn from '../../assets/images/inkedin.png';
import Edit from '../../assets/images/Edit.png';
import ProfileProgressbar from '../../pages/ProfilePage/ProfileProgressbar';
import Multiprogressbar from '../../pages/ProfilePage/Multiprogressbar';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { getUserData } from '../../store/thunk/userThunk';
import { useDispatch, useSelector } from 'react-redux';
import EditProfile from './EditProfile';
import EditEducation from './EditEducation';
import EditExperience from './EditExperience';
import EditEmergency from './EditEmergency';
import EditFamilyInfo from './EditFamilyInfo';
import { getDefaultProfile, formatDate } from '../../component/Common';
import UserProfile from '../../component/ui-component/UserProfile';
import { getAllMeetingList } from '../../store/thunk/meetingThunk';
import { Link } from 'react-router-dom';

const moment = require('moment');

export default function UserProfilePage() {
	const dispatch = useDispatch();
	const user_data = useSelector((state) => state.user.userData);
	const authUser = useSelector((state) => state.auth);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [editEducationVisible, setEditEducationVisible] = useState(false);
	const [editExperienceVisible, setEditExperienceVisible] = useState(false);
	const [editECVisible, setEditECVisible] = useState(false);
	const [editFamilyInfoVisible, setEditFamilyInfoVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);
	const [showPortion, setShowPortion] = useState(null);
	const [age, setAge] = useState(null);
	const meetingsLists = useSelector((state) => state.meetings?.meetingList);

	const handleSetEditValues = (item, portion) => {
		setEditValues(item);
		setEditDrawerVisible(true);
		setShowPortion(portion);
	};

	const handleSetEditEducation = () => {
		setEditEducationVisible(true);
	};

	const handleSetEditExperience = () => {
		setEditExperienceVisible(true);
	};

	const handleSetEditEC = () => {
		setEditECVisible(true);
	};

	const handleSetEditFamilyInfo = () => {
		setEditFamilyInfoVisible(true);
	};

	useEffect(() => {
		dispatch(getUserData(authUser?.userDetails));
		dispatch(getAllMeetingList());
	}, [dispatch, authUser?.userDetails]);

	useEffect(() => {
		if (user_data?.dob) {
			const startDate = moment(user_data?.dob);
			const endDate = moment();
			const yearDiff = endDate.diff(startDate, 'years');
			setAge(yearDiff);
		}
	}, [user_data?.dob]);

	const SocialMedia = [
		{
			img: Facebook,
			link: user_data?.facebook_link,
		},
		{
			img: Twitter,
			link: user_data?.twitter_link,
		},
		{
			img: Instagram,
			link: user_data?.instagram_link,
		},
		{
			img: LinkedIn,
			link: user_data?.linkedin_link,
		},
	];

	const Slicksettings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<>
			<div className="md:my-[38px] my-[20px] mx-[20px] relative">
				<div className="grid-cols-12 md:grid block gap-[30px] mb-[38px]">
					<div className="col-span-8 md:mb-0 mb-[20px]">
						<div className="relative h-full overflow-hidden rounded-[15px] md:p-5 p-4 dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
							<img
								src={AchievementCard}
								alt="AchievementCard"
								className="absolute w-full h-full object-cover top-0 left-0"
							/>
							<div className="flex flex-wrap relative mt-3">
								<div className="relative md:w-[65%] w-full z-[99] md:pr-[35px] pr-0 mr-[10px]">
									<div className="user-profile relative flex items-center md:mb-[29px] mb-[20px]">
										<span>
											<UserProfile
												imageUrl={
													user_data?.profile_image_url
												}
												defaultImageUrl={getDefaultProfile(
													user_data?.gender
												)}
												altName={user_data?.first_name}
												className={
													'img h-[130px] w-[130px] inline-block rounded-full bg-white border border-slate-300 p-1'
												}
											/>
										</span>
										<div className="content text-left dark:text-[#BFC6D2] text-[#54565C] ml-[22px]">
											<h2 className="text-[22px] not-italic font-bold leading-7 mb-[10px]">
												{`${user_data?.first_name} ${user_data?.last_name}`}
											</h2>
											<p className="text-sm font-normal leading-[18px] mb-[5px]">
												{user_data?.role?.title}
											</p>
											<p className="text-sm leading-[18px] mb-[5px] font-bold">
												User ID :{' '}
												{`${user_data?.company_name
													.substr(0, 3)
													.toUpperCase()} - ${
													user_data?.id
												}`}
											</p>
											<h4 className="text-[13px] md:text-base not-italic font-semibold leading-[21px] md:mt-[20px] mt-[10px]">
												Level 14 Team Member
											</h4>
										</div>
									</div>
									<div className="relative z-[99] flex items-end">
										<img
											src={Xp2}
											alt="Xp2"
											className="h-[38.631px] mt-1"
										/>
										<div className="w-full pl-3 pt-2">
											<div className="relative z-[99] flex items-center justify-between mb-3">
												<p className="text-sm leading-[18px] font-family-OpenSans dark:text-[#fff] text-[#0C1932]  font-semibold">
													XP 750
												</p>
												<span className="text-right text-sm leading-[18px] font-family-OpenSans dark:text-[#fff] text-[#0C1932]  font-semibold">
													XP 1000
												</span>
											</div>
											<ProgressBar
												mainColor="#1F4466"
												progress="50%"
												shadow1="#2E6FAA"
												shadow2="#074675"
												shadow3="rgb(52, 113, 192)"
												bgColor="#3471c0"
												bgColor2="rgb(195,213,236)"
												bgColor3="rgb(255,255,255)"
												color1="#1ffbff"
												color2="#009eef"
											/>
										</div>
									</div>
								</div>
								<div className="contact w-[30%] text-left">
									<div className="mb-[15px]">
										<p className="text-xs dark:text-[#BFC6D2] text-[#0C1932] font-normal leading-4 tracking-[5px]">
											Department
										</p>
										<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px]">
											{user_data?.department?.title}
										</h3>
									</div>
									<div className="mb-[15px]">
										<p className="text-xs dark:text-[#BFC6D2] text-[#0C1932] font-normal leading-4 tracking-[5px]">
											Phone
										</p>
										<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px]">
											{user_data?.phone_number}
										</h3>
									</div>
									<div className="mb-[15px]">
										<p className="text-xs dark:text-[#BFC6D2] text-[#0C1932] font-normal leading-4 tracking-[5px]">
											Email
										</p>
										<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px]">
											{user_data?.email}
										</h3>
									</div>
									<div className="mb-[15px]">
										<p className="text-xs dark:text-[#BFC6D2] text-[#0C1932] font-normal leading-4 tracking-[5px]">
											Gender
										</p>
										<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px]">
											{user_data?.gender
												.charAt(0)
												.toUpperCase() +
												user_data?.gender.slice(1)}
										</h3>
									</div>
								</div>
								<span className="toggle absolute top-0 right-0">
									<div
										className="cursor-pointer flex items-center justify-center w-[39.56px] h-[39.56px] rounded-full mr-[8px]"
										onClick={() =>
											handleSetEditValues(user_data, 1)
										}
									>
										<img src={Edit} alt="edit" />
									</div>
								</span>
							</div>
						</div>
					</div>
					<div className="col-span-4">
						<div className="upcommingsection relative dark-dark-light-card p-5">
							<h2 className="card-title">Upcoming Events</h2>
							<Slider {...Slicksettings}>
								{meetingsLists.length > 0 &&
									meetingsLists.map((event, index) => (
										<div key={index}>
											<div className="relative item text-left rounded-[10px] overflow-hidden">
												<div className="add-time-user z-[1] relative p-5 blue-bg-gradient text-[#fff]">
													<p className="text-[12px] mb-[5px] text-[#fff]">
														{formatDate(
															event.meeting_date,
															'DD MMM - YYYY'
														)}
													</p>
													<h2 className="text-base font-bold leading-[21px] mb-3 text-[#fff]">
														{event.title}
													</h2>
													<ul className="add-time">
														<li className="flex items-center text-sm leading-[18px] mb-3 text-[#fff]">
															<span className="mr-2 icon h-[24px] w-[24px] rounded-full inline-flex items-center justify-center">
																<img
																	src={Clock}
																	alt="clock"
																	className="h-[20px] w-[20px]"
																/>
															</span>
															{`${event.from_time} - ${event.to_time}`}
														</li>
													</ul>
													<ul className="user-img flex pl-7">
														{event?.users.length >
															0 &&
															event?.users.map(
																(
																	user,
																	index
																) => (
																	<li
																		key={
																			index
																		}
																		className="inline-block hover:cursor-pointer"
																	>
																		<UserProfile
																			imageUrl={
																				user?.profile_image_url
																			}
																			defaultImageUrl={getDefaultProfile(
																				user?.gender
																			)}
																			altName={
																				user?.fullname
																			}
																			className={
																				'rounded-full h-[30px] w-[30px]'
																			}
																		/>
																	</li>
																)
															)}
													</ul>
												</div>
												<div className="absolute z-0 h-full w-full left-0 top-0">
													<img
														src={BgImg2}
														alt="bgImg"
														className="user-role-block h-full w-full object-cover"
													/>
												</div>
											</div>
										</div>
									))}
							</Slider>
						</div>
					</div>
				</div>

				<div className="grid-cols-12 md:grid block gap-[30px] mb-[38px]">
					<div className="col-span-6  md:mb-0 mb-[20px]">
						<div className="basic-info-box relative h-full p-5 dark-dark-light-card">
							<h2 className="card-title dark:text-white text-[#4b5563]">
								Basic Information
							</h2>
							<span className="toggle absolute top-3 right-1">
								<div
									className="cursor-pointer flex items-center justify-center w-[39.56px] h-[39.56px] rounded-full mr-[8px]"
									onClick={() =>
										handleSetEditValues(user_data, 2)
									}
								>
									<img src={Edit} alt="edit" />
								</div>
							</span>
							<div className="information-boxes md:grid block grid-cols-3 gap-[34px]">
								<div className="single-box text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[10px] p-[5px] md:mb-0 mb-[10px]">
									<p className="text-xs dark:text-white text-[#0C1932] text-center font-normal leading-4 tracking-[5px] pb-[7px]">
										Joined Date
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-center font-semibold leading-[21px] tracking-[1px]">
										{user_data?.joining_date
											? formatDate(
													user_data.joining_date,
													'DD MMM YYYY'
											  )
											: ''}
									</h4>
								</div>
								<div className="single-box text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[10px] p-[5px] md:mb-0 mb-[10px]">
									<p className="text-xs dark:text-white text-[#0C1932] text-center font-normal leading-4 tracking-[5px] pb-[7px]">
										Worked Id
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-center font-semibold leading-[21px] tracking-[1px]">
										{`${user_data?.company_name
											.substr(0, 3)
											.toUpperCase()} - ${user_data?.id}`}
									</h4>
								</div>
								<div className="single-box text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[10px] p-[5px] md:mb-0 mb-[10px]">
									<p className="text-xs dark:text-white text-[#0C1932] text-center font-normal leading-4 tracking-[5px] pb-[7px]">
										Worked For
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-center font-semibold leading-[21px] tracking-[1px]">
										{user_data?.company_name}
									</h4>
								</div>
							</div>

							<div className="info-address md:pt-[40px] pt-[20px] pb-[15px] flex">
								<div className="add-item w-[51%]">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Address
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
										{`${user_data?.address} ${user_data?.city} ${user_data?.postal_code}`}
									</h4>
								</div>
								<div className="add-item w-[30%]">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Date of Birth
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
										{user_data?.dob
											? formatDate(
													user_data?.dob,
													'DD MMM YYYY'
											  )
											: ''}
									</h4>
								</div>
								<div className="add-item w-[19%] pl-5">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Age
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
										{age}
									</h4>
								</div>
							</div>
							<div className="info-address pt-[30px] pb-[15px] flex">
								<div className="add-item w-[30%]">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Marital Status
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
										{user_data?.marital_status
											? user_data?.marital_status
													.charAt(0)
													.toUpperCase() +
											  user_data?.marital_status.slice(1)
											: 'N/A'}
									</h4>
								</div>
								<div className="add-item w-[30%]">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Passport Number
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
										{user_data?.passport_number || 'N/A'}
									</h4>
								</div>
								<div className="add-item w-[30%]">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Passport Expiry Date
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
										{user_data?.passport_expiry_date
											? formatDate(
													user_data?.passport_expiry_date,
													'DD MMM YYYY'
											  )
											: 'N/A'}
									</h4>
								</div>
							</div>
							<div className="info-address pt-[15px] md:pb-[42px] pb-[20px] flex">
								<div className="add-item w-[100%]">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										About Me
									</p>
									<p
										className="dark:text-white text-left text-[#0C1932] text-[15px] leading-[25px]"
										dangerouslySetInnerHTML={{
											__html: `${
												user_data?.about || 'N/A'
											}`,
										}}
									></p>
								</div>
							</div>

							<ul className="social-icon flex">
								{SocialMedia.map((i, item) => (
									<li key={item} className="mr-[22px]">
										<a
											href={i.link}
											target="_blank"
											rel="noopener noreferrer"
											className="cursor-pointer h-[35px] w-[35px] rounded-full  bg-[#223457] hover:bg-[linear-gradient(137.02deg,#bd00ff_0%,#216cff_103.79%)] flex items-center justify-center"
										>
											<img src={i.img} alt="img" />
										</a>
									</li>
								))}
							</ul>
						</div>
					</div>

					<div className="col-span-6">
						<div className="relative p-5 dark-dark-light-card">
							<div className="relative">
								<h2 className="card-title dark:text-white text-[#4b5563]">
									My Skills
								</h2>
								<Link
									className="absolute top-0 right-0 expert-btn  text-sm leading-[18px] font-semibold text-white rounded-full p-[10px] px-5 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
									to="/skills"
								>
									View All
								</Link>
							</div>
							<ProfileProgressbar
								userSkills={user_data?.skills}
							/>
						</div>

						<div className="relative p-5 dark-dark-light-card mt-8">
							<h2 className="card-title dark:text-white text-[#4b5563]">
								Bank information
							</h2>
							<span className="toggle absolute top-3 right-1">
								<div
									className="cursor-pointer flex items-center justify-center w-[39.56px] h-[39.56px] rounded-full mr-[8px]"
									onClick={() =>
										handleSetEditValues(user_data, 3)
									}
								>
									<img src={Edit} alt="edit" />
								</div>
							</span>
							<div className="rounded-lg py-4 p-4 mt-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#c9c9c9]">
								<div className="info-address pb-[15px] flex">
									<div className="add-item w-[40%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Bank name
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{user_data?.bank_name || 'N/A'}
										</h4>
									</div>
									<div className="add-item w-[40%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											Bank account No.
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{user_data?.bank_account_number ||
												'N/A'}
										</h4>
									</div>
								</div>
								<div className="info-address pb-[15px] flex">
									<div className="add-item w-[40%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											IFSC Code
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{user_data?.bank_ifsc_number ||
												'N/A'}
										</h4>
									</div>
									<div className="add-item w-[40%]">
										<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
											PAN No
										</p>
										<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{user_data?.bank_pan_number ||
												'N/A'}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="grid-cols-12 md:grid block gap-[30px] mb-[38px]">
					<div className="col-span-6  md:mb-0 mb-[20px]">
						<div className="basic-info-box relative h-full p-5 dark-dark-light-card">
							<h2 className="card-title dark:text-white text-[#4b5563]">
								Education Informations
							</h2>
							<span className="toggle absolute top-3 right-1">
								<div
									className="cursor-pointer flex items-center justify-center w-[39.56px] h-[39.56px] rounded-full mr-[8px]"
									onClick={() => handleSetEditEducation()}
								>
									<img src={Edit} alt="edit" />
								</div>
							</span>
							<ol className="border-l border-neutral-300 dark:border-neutral-500">
								{user_data?.education &&
									user_data?.education.map(
										(education, index) => (
											<li key={index}>
												<div className="flex-start flex items-center pt-3">
													<div className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-neutral-300 dark:bg-neutral-500"></div>
													<p className="text-base text-neutral-500 dark:text-neutral-300 font-semibold">
														{education?.institution}
													</p>
												</div>
												<div className="mb-6 ml-4 text-left">
													<div className="text-sm text-neutral-500 dark:text-neutral-300">
														{education?.degree}
													</div>
													<p className="mb-3 text-sm text-neutral-500 dark:text-neutral-300">
														{`${moment(
															education?.starting_date
														).year()} - ${moment(
															education?.complete_date
														).year()}`}
													</p>
												</div>
											</li>
										)
									)}
							</ol>
							{user_data?.education.length === 0 && (
								<>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold">
										{' '}
										N/A{' '}
									</h4>
								</>
							)}
						</div>
					</div>
					<div className="col-span-6  md:mb-0 mb-[20px]">
						<div className="basic-info-box relative h-full p-5 dark-dark-light-card">
							<h2 className="card-title dark:text-white text-[#4b5563]">
								Experience Informations
							</h2>
							<span className="toggle absolute top-3 right-1">
								<div
									className="cursor-pointer flex items-center justify-center w-[39.56px] h-[39.56px] rounded-full mr-[8px]"
									onClick={() => handleSetEditExperience()}
								>
									<img src={Edit} alt="edit" />
								</div>
							</span>
							<ol className="border-l border-neutral-300 dark:border-neutral-500">
								{user_data?.experience &&
									user_data?.experience.map(
										(experience, index) => {
											const startDate = moment(
												experience?.start_date
											);
											const endDate = moment(
												experience?.end_date ||
													new Date()
											);

											const years = endDate.diff(
												startDate,
												'years'
											);
											const months =
												endDate.diff(
													startDate,
													'months'
												) % 12;

											return (
												<li key={index}>
													<div className="flex-start flex items-center pt-3">
														<div className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-neutral-300 dark:bg-neutral-500"></div>
														<p className="text-base text-neutral-500 dark:text-neutral-300 font-semibold">
															{`${experience?.job_position} at ${experience?.company_name}`}
														</p>
													</div>
													<div className="mb-6 ml-4 text-left">
														<p className="mb-3 text-sm text-neutral-500 dark:text-neutral-300">
															{`${formatDate(
																experience?.start_date,
																'MMM YYYY'
															)} - ${
																experience?.end_date
																	? formatDate(
																			experience?.end_date,
																			'MMM YYYY'
																	  )
																	: 'Present'
															}`}
															{` (${years} years ${months} months)`}
														</p>
													</div>
												</li>
											);
										}
									)}
							</ol>
							{user_data?.experience.length === 0 && (
								<>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold">
										{' '}
										N/A{' '}
									</h4>
								</>
							)}
						</div>
					</div>
				</div>

				<div className="grid-cols-12 md:grid block gap-[30px] mb-[38px]">
					<div className="col-span-6  md:mb-0 mb-[20px]">
						<div className="basic-info-box relative h-full p-5 dark-dark-light-card">
							<h2 className="card-title dark:text-white text-[#4b5563]">
								Emergency Contact
							</h2>
							<span className="toggle absolute top-3 right-1">
								<div
									className="cursor-pointer flex items-center justify-center w-[39.56px] h-[39.56px] rounded-full mr-[8px]"
									onClick={() => handleSetEditEC()}
								>
									<img src={Edit} alt="edit" />
								</div>
							</span>
							{user_data?.emergency_contact &&
								user_data.emergency_contact.map((ec, index) => (
									<div
										key={index}
										className="rounded-lg py-4 p-4 mt-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#c9c9c9]"
									>
										<h5 className="mb-2 text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
											{index === 0
												? 'Primary Contact'
												: 'Secondary Contact'}
										</h5>
										<div className="info-address pb-[15px] flex">
											<div className="add-item w-[30%]">
												<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
													Name
												</p>
												<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
													{ec?.name || ''}
												</h4>
											</div>
											<div className="add-item w-[30%]">
												<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
													Relationship
												</p>
												<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
													{ec?.relationship || ''}
												</h4>
											</div>
											<div className="add-item w-[30%]">
												<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
													Phone
												</p>
												<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
													{`${ec?.phone1}, ${ec?.phone2}`}
												</h4>
											</div>
										</div>
									</div>
								))}
							{user_data?.emergency_contact.length === 0 && (
								<>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold">
										N/A
									</h4>
								</>
							)}
						</div>
					</div>
					<div className="col-span-6  md:mb-0 mb-[20px]">
						<div className="basic-info-box relative h-full p-5 dark-dark-light-card">
							<h2 className="card-title dark:text-white text-[#4b5563]">
								Family Informations
							</h2>
							<span className="toggle absolute top-3 right-1">
								<div
									className="cursor-pointer flex items-center justify-center w-[39.56px] h-[39.56px] rounded-full mr-[8px]"
									onClick={() => handleSetEditFamilyInfo()}
								>
									<img src={Edit} alt="edit" />
								</div>
							</span>
							<div className="rounded-lg py-4 p-4 mt-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#c9c9c9]">
								<div className="flex flex-col">
									<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
										<div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
											<div className="overflow-hidden">
												<table className="min-w-full text-left text-sm font-light">
													<thead className="font-medium">
														<tr>
															<th
																scope="col"
																className="pb-3 text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
															>
																Name
															</th>
															<th
																scope="col"
																className="pb-3 text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
															>
																Relationship
															</th>
															<th
																scope="col"
																className="pb-3 text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
															>
																Date of Birth
															</th>
															<th
																scope="col"
																className="pb-3 text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]"
															>
																Phone
															</th>
														</tr>
													</thead>
													<tbody>
														{user_data?.family_info &&
															user_data?.family_info.map(
																(
																	familyinfo,
																	index
																) => (
																	<tr
																		key={
																			index
																		}
																		className="border-solid border-b dark:border-[#0c4a6e] border-[#c9c9c9]"
																	>
																		<td className="py-2 text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
																			{familyinfo?.name ||
																				''}
																		</td>
																		<td className="py-2 text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
																			{familyinfo?.relationship ||
																				''}
																		</td>
																		<td className="py-2 text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
																			{`${formatDate(
																				familyinfo?.dob,
																				'DD MMM YYYY'
																			)}`}
																		</td>
																		<td className="py-2 text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
																			{
																				familyinfo?.phone
																			}
																		</td>
																	</tr>
																)
															)}
														{user_data?.family_info
															.length === 0 && (
															<>
																<tr colSpan="4">
																	<td className="text-base dark:text-white text-[#0C1932] text-left font-semibold">
																		N/A
																	</td>
																</tr>
															</>
														)}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="mb-[38px] overflow-x-auto top-scroll">
					<div className="items flex w-[max-content] pb-7">
						<Multiprogressbar
							number="5"
							name="Successfully complete a task"
						/>
						<Multiprogressbar
							number="20"
							name="Complete a project milestone"
						/>
						<Multiprogressbar
							number="10"
							name="Present a successful proposal"
						/>
						<Multiprogressbar
							number="5"
							name="Provide constructive peer feedback"
						/>
						<Multiprogressbar
							number="10"
							name="Receive positive peer feedback"
						/>
						<Multiprogressbar
							number="20"
							name="Successfully complete a task"
						/>
						<Multiprogressbar
							number="15"
							name="Complete a project milestone"
						/>
						<Multiprogressbar
							number="7"
							name="Present a successful proposal"
						/>
						<Multiprogressbar
							number="8"
							name="Provide constructive peer feedback"
						/>
						<Multiprogressbar
							number="2"
							name="Receive positive peer feedback"
						/>
					</div>
				</div>
			</div>
			{editDrawerVisible && editValues && (
				<EditProfile
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
					showPortion={showPortion}
				/>
			)}

			{editEducationVisible && user_data?.education && (
				<EditEducation
					visible={editEducationVisible}
					onClose={() => setEditEducationVisible(false)}
					editValues={user_data?.education}
				/>
			)}

			{editExperienceVisible && user_data?.experience && (
				<EditExperience
					visible={editExperienceVisible}
					onClose={() => setEditExperienceVisible(false)}
					editValues={user_data?.experience}
				/>
			)}
			{editECVisible && user_data?.emergency_contact && (
				<EditEmergency
					visible={editECVisible}
					onClose={() => setEditECVisible(false)}
					editValues={user_data?.emergency_contact}
				/>
			)}
			{editFamilyInfoVisible && user_data?.family_info && (
				<EditFamilyInfo
					visible={editFamilyInfoVisible}
					onClose={() => setEditFamilyInfoVisible(false)}
					editValues={user_data?.family_info}
				/>
			)}
		</>
	);
}
