import React from 'react';
import ProgressBar from '../../component/ui-component/ProgressBar';

import Heart from '../../assets/images/heart.png';
import FillHeart from '../../assets/images/fill-heart.png';

const ProfileProgressbar = ({ userSkills }) => {
	const skillprogress = [
		{
			title: 'C++ Programming',
			level: 'Level 10',
			images: Heart,
			percantage: '65%',
			mainColor: '#2f4b0f',
			shadow1: '#00ab58',
			shadow2: '#00761b',
			shadow3: '#000',
			color2: '#3aaa35',
			color1: '#9de800',
			bgColor: '#3aaa35',
			bgColor2: '#c4e6c3',
			bgColor3: '#fff',
		},
		{
			title: 'Communication',
			level: 'Level 9',
			images: Heart,
			percantage: '70%',
			mainColor: '#073a56',
			shadow1: '#0070a7',
			shadow2: '#004773',
			shadow3: '#000',
			color2: '#3f576e',
			color1: '#99d1f2',
			bgColor: '#4d5e6e',
			bgColor2: '#c3c9ce',
			bgColor3: '#fff',
		},
		{
			title: 'Problem Solving',
			level: 'Level 8',
			images: Heart,
			percantage: '95%',
			mainColor: '#674306',
			shadow1: '#AA622E',
			shadow2: '#753507',
			shadow3: '#cc925a',
			bgColor: '#cc925a',
			bgColor2: '#ecd7c3',
			bgColor3: '#fff',
			color1: '#ffc800',
			color2: '#ff8c00',
		},
		{
			title: 'Leadership',
			level: 'Level 7',
			images: FillHeart,
			percantage: '55%',
			mainColor: '#412d71',
			shadow1: '#8456f9',
			shadow2: '#482c90',
			shadow3: '#8e37e2',
			color2: '#4f309f',
			color1: '#804dff',
			bgColor: '#b37bda',
			bgColor2: '#dcc3ea',
			bgColor3: '#fff',
		},
	];

	let updatedSkillprogress = [];

	if (userSkills && userSkills.length > 0) {
		updatedSkillprogress = userSkills.map((user_skill, index) => {
			const skillIndex = index % skillprogress.length;
			const skill = skillprogress[skillIndex];

			return {
				...skill,
				title: user_skill.name,
			};
		});
	}

	return (
		<>
			<div className="mt-10">
				{updatedSkillprogress.map((data, index) => (
					<div key={index} className="progressbar-row mb-2">
						<div className="progressbar-title flex flex-wrap justify-between dark:text-white text-[#0C1932] mb-[5px]">
							<p className="text-sm font-normal leading-[18px]">
								{data.title}
							</p>
							<span className=" text-sm not-italic font-semibold leading-[18px] flex">
								{data.level}
								<img
									src={data.images}
									alt="heart"
									className="ml-2"
								/>
							</span>
						</div>
						<div className="progressbar p-[7px]">
							<ProgressBar
								progress={data.percantage}
								mainColor={data.mainColor}
								shadow1={data.shadow1}
								shadow2={data.shadow2}
								shadow3={data.shadow3}
								bgColor={data.bgColor}
								bgColor2={data.bgColor2}
								bgColor3={data.bgColor3}
								color1={data.color1}
								color2={data.color2}
							/>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default ProfileProgressbar;
