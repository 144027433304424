import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const gradientId = 'progress-gradient';

export default function CircleProgressBar() {
	const progress = 80;
	const strokeWidth = 10;
	const trailWidth = 5;

	return (
		<>
			<div className="h-[120px] w-[120px] relative custom-graph">
				<svg style={{ height: 0, width: 0, position: 'absolute' }}>
					<defs>
						<linearGradient id={gradientId}>
							<stop offset="0%" stopColor="#BD00FF" />
							<stop offset="100%" stopColor="#216CFF" />
						</linearGradient>
						<mask id="progress-mask">
							<rect
								x="0"
								y="0"
								width="100%"
								height="100%"
								fill={`url(#${gradientId})`}
							/>
						</mask>
					</defs>
				</svg>
				<CircularProgressbar
					className="font-family-Montserrat-bold text-[32px]"
					value={progress}
					text={`${progress} `}
					strokeWidth={strokeWidth}
					trailWidth={trailWidth}
					styles={buildStyles({
						pathColor: `url(#${gradientId})`,
						trailColor: 'transparent',
					})}
				/>
				<span className="absolute h-[100px] w-[100px] rounded "></span>
				<div className="absolute w-[100px] rounded bottom-[25px] text-[13px] left-[8%] align-[center] text-[#BFC6D2]">
					Days
				</div>
			</div>
		</>
	);
}
