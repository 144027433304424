import React, { useEffect, useState } from 'react';

const Statistics = ({ statisticsData }) => {
	const [dayPercentage, setDayPercentage] = useState(0);
	const [weekPercentage, setWeekPercentage] = useState(0);
	const [monthPercentage, setMonthPercentage] = useState(0);
	const [remainingPercentage, setRemainingPercentage] = useState(0);
	const [overtimePercentage, setOverTimePercentage] = useState(0);
	let overtimehrs = 0;
	useEffect(() => {
		if (statisticsData?.current_hours_worked) {
			let percentage = (statisticsData?.current_hours_worked / 8) * 100;
			setDayPercentage(percentage);
		}
		if (statisticsData?.total_week_hours) {
			let percentage = (statisticsData?.total_week_hours / 40) * 100;
			setWeekPercentage(percentage);
		}
		if (statisticsData?.total_monthly_hours) {
			let percentage = (statisticsData?.total_monthly_hours / 160) * 100;
			let remaining =
				((160 - statisticsData?.total_monthly_hours) / 160) * 100;
			overtimehrs =
				statisticsData?.total_monthly_hours > 160
					? statisticsData?.total_monthly_hours - 160
					: 0;
			let overtimePercentage = (overtimehrs / 160) * 100;
			setMonthPercentage(percentage);
			setRemainingPercentage(remaining);
			setOverTimePercentage(overtimePercentage);
		}
	}, [statisticsData, overtimehrs]);

	return (
		<>
			<div className="p-7 relative dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] rounded-[15px]">
				<div className="text-center	profile-widget content-center">
					<div className="text-left dark:text-white text-[#4b5563] text-2xl font-bold tracking-wider">
						Statistics
					</div>
					<div className="mt-5 p-5 dark:bg-[#101B32] bg-[#f2f2f2] rounded-[5px] text-left dark:text-white text-[#4b5563] font-semibold tracking-wide">
						<div className="pb-5">
							<p className="float-left">Today</p>
							<p className="float-right">
								<strong>
									{statisticsData?.current_hours_worked}{' '}
									<small>/ 8 hrs</small>
								</strong>
							</p>
						</div>
						<div className="flex-start flex h-1.5 w-full overflow-hidden rounded-sm bg-blue-gray-50 font-sans text-xs font-medium bg-[#212e4b] mt-[10px]">
							<div
								className="flex h-full items-baseline justify-center overflow-hidden break-all bg-[#ff9b44] text-white"
								style={{ width: `${dayPercentage}%` }}
							/>
						</div>
					</div>
					<div className="mt-5 p-5 dark:bg-[#101B32] bg-[#f2f2f2] rounded-[5px] text-left dark:text-white text-[#4b5563] font-semibold tracking-wide">
						<div className="pb-5">
							<p className="float-left">This Week</p>
							<p className="float-right">
								<strong>
									{statisticsData?.total_week_hours}{' '}
									<small>/ 40 hrs</small>
								</strong>
							</p>
						</div>
						<div className="flex-start flex h-1.5 w-full overflow-hidden rounded-sm bg-blue-gray-50 font-sans text-xs font-medium bg-[#212e4b] mt-[10px]">
							<div
								className="flex h-full items-baseline justify-center overflow-hidden break-all bg-[#bd00ff] text-white"
								style={{ width: `${weekPercentage}%` }}
							/>
						</div>
					</div>
					<div className="mt-5 p-5 dark:bg-[#101B32] bg-[#f2f2f2] rounded-[5px] text-left dark:text-white text-[#4b5563] font-semibold tracking-wide">
						<div className="pb-5">
							<p className="float-left">This Month</p>
							<p className="float-right">
								<strong>
									{statisticsData?.total_monthly_hours}{' '}
									<small>/ 160 hrs</small>
								</strong>
							</p>
						</div>
						<div className="flex-start flex h-1.5 w-full overflow-hidden rounded-sm bg-blue-gray-50 font-sans text-xs font-medium bg-[#212e4b] mt-[10px]">
							<div
								className="flex h-full items-baseline justify-center overflow-hidden break-all bg-[#55ce63] text-white"
								style={{ width: `${monthPercentage}%` }}
							/>
						</div>
					</div>
					<div className="mt-5 p-5 dark:bg-[#101B32] bg-[#f2f2f2] rounded-[5px] text-left dark:text-white text-[#4b5563] font-semibold tracking-wide">
						<div className="pb-5">
							<p className="float-left">Remaining</p>
							<p className="float-right">
								<strong>
									{statisticsData?.total_monthly_hours
										? parseFloat(
												160 -
													statisticsData?.total_monthly_hours
										  ).toFixed(2)
										: 0}{' '}
									<small>/ 160 hrs</small>
								</strong>
							</p>
						</div>
						<div className="flex-start flex h-1.5 w-full overflow-hidden rounded-sm bg-blue-gray-50 font-sans text-xs font-medium bg-[#212e4b] mt-[10px]">
							<div
								className="flex h-full items-baseline justify-center overflow-hidden break-all bg-[#f62d51] text-white"
								style={{ width: `${remainingPercentage}%` }}
							/>
						</div>
					</div>
					<div className="mt-5 p-5 dark:bg-[#101B32] bg-[#f2f2f2] rounded-[5px] text-left dark:text-white text-[#4b5563] font-semibold tracking-wide">
						<div className="pb-5">
							<p className="float-left">Overtime</p>
							<p className="float-right">
								<strong>{overtimehrs}</strong>
							</p>
						</div>
						<div className="flex-start flex h-1.5 w-full overflow-hidden rounded-sm bg-blue-gray-50 font-sans text-xs font-medium bg-[#212e4b] mt-[10px]">
							<div
								className="flex h-full items-baseline justify-center overflow-hidden break-all bg-[#009efb] text-white"
								style={{ width: `${overtimePercentage}%` }}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Statistics;
