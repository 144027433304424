import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	tasksLists: [],
	pendingTaskLists: [],
	completedTaskLists: [],
	isFetching: false,
	totalPages: 0,
	totalRecords: 0,
	taskDashboardLists: [],
};

const tasksSlice = createSlice({
	name: 'tasks',
	initialState,
	reducers: {
		setTasksData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			tasksLists: action.payload?.results,
		}),
		setPendingTasksData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			pendingTaskLists: action.payload?.results,
		}),
		setCompletedTasksData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			completedTaskLists: action.payload?.results,
		}),
		setTaskDashboard: (state, action) => ({
			...state,
			isFetching: false,
			taskDashboardLists: action.payload,
		}),
		setFetching: (state, action) => ({
			...state,
			isFetching: action.payload,
			tasksLists: [],
			taskDashboardLists: [],
		}),
	},
});

export const {
	setTasksData,
	setFetching,
	setTaskDashboard,
	setPendingTasksData,
	setCompletedTasksData,
} = tasksSlice.actions;
export default tasksSlice.reducer;
