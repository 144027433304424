import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from '../src/store/store';
import { Provider } from 'react-redux';
import '../src/assets/css/index.css';
import App from './App';
import { TimerProvider } from './pages/Attendance/TimerContext';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(
	'Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCekx0TXxbf1x0ZF1MYl5bQXNPMyBoS35RckVgW3ZeeHZRR2ZaVUFx'
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
	//   <Provider store={store}>
	//     <App />
	//   </Provider>
	// </React.StrictMode>
	<Provider store={store}>
		<TimerProvider>
			<App />
		</TimerProvider>
	</Provider>
);
