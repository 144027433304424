import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from '../slices/dataLoadingSlice';
import {
	notificationSuccess,
	notificationFail,
} from '../slices/notificationSlice';
import {
	setDepartmentData,
	setRolesData,
	setCountryData,
	setActivityData,
} from '../slices/commonSlice';

import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';
import { getCompanyList } from './companyThunk';
import { getUserList } from './userThunk';

export const getCountryList = createAsyncThunk(
	'getCountryList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient(false).get(
				`/common/country/lists/`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setCountryData(response?.data));
			} else {
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const getDepartmentList = createAsyncThunk(
	'getDepartmentList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient(false).get(
				`/accounts/departments/?offset=500`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setDepartmentData(response?.data?.results));
			} else {
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const getRolesList = createAsyncThunk(
	'getRolesList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { department } = _request;
			let pageSize = 500;
			if (department !== null && typeof department !== 'undefined') {
				department = `&department=${department}`;
			} else {
				department = '';
			}
			const response = await apiClient(false).get(
				`/accounts/roles/?offset=${pageSize}${department}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setRolesData(response?.data?.results));
			} else {
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const resendVerifyEmail = createAsyncThunk(
	'resendVerifyEmail',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().get(
				`/accounts/resend-verify-link/${_request?.id}`
			);
			dispatch(setLoading(false));
			if (_request.is_companyadmin) {
				dispatch(getCompanyList());
			} else {
				dispatch(getUserList());
			}
			dispatch(notificationSuccess(response?.data?.message));
			toast.success(response?.data?.message);
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const getActivityFeedList = createAsyncThunk(
	'getActivityFeedList',
	async (_request = {}, { dispatch }) => {
		try {
			let { pageSize } = _request;
			pageSize = pageSize || 10;
			dispatch(setLoading(true));
			const response = await apiClient(false).get(
				`/accounts/activity-feed-lists/?offset=${pageSize}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setActivityData(response?.data?.results));
			} else {
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);
