import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from '../../assets/images/dots.png';
import { getClientList } from '../../store/thunk/projectsThunk';
import profile_default from '../../assets/images/user.png';
import { Link } from 'react-router-dom';

const ListComponent = ({ handleSetEditValues, handleDeleteClick }) => {
	const dispatch = useDispatch();
	const ref = useRef({});
	const clientData = useSelector((state) => state.projects.clientList);
	const [isOpenArray, setIsOpenArray] = useState([]);
	const toggleDiv = (index) => {
		const updatedOpenArray = [...isOpenArray];
		updatedOpenArray[index] = !updatedOpenArray[index];
		setIsOpenArray(updatedOpenArray);
	};
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				ref.current &&
				typeof ref.current.contains === 'function' &&
				!ref.current.contains(event.target)
			) {
				setIsOpenArray(clientData.map(() => false));
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [isOpenArray, clientData]);

	useEffect(() => {
		dispatch(getClientList());
	}, [dispatch]);
	return (
		<>
			{clientData &&
				clientData.map((row, index) => (
					<div
						key={row.id}
						className="p-[10px] relative dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] rounded-[15px]"
					>
						<div className="list-item-block">
							<span
								onClick={() => toggleDiv(index)}
								className="absolute text-[#fff] top-[25px] right-[15px] cursor-pointer"
							>
								<img
									src={Toggle}
									alt="dot"
									className="dot-toggle"
								/>
							</span>
							{isOpenArray[index] && (
								<div ref={ref}>
									<div
										className="menuDropdown absolute w-[160px] right-[0px] mt-[25px]"
										key={index}
										id={`toggle_${index}`}
									>
										<ul>
											<li
												className="dark:text-white text-[#47586f] text-left flex items-center"
												onClick={() => {
													handleSetEditValues(row);
													toggleDiv(index);
												}}
											>
												<div className="p-[7px] block w-full cursor-pointer">
													<i className="mr-3 fas fa-pencil text-[14px]"></i>
													Edit
												</div>
											</li>
											<li
												className="dark:text-white text-[#47586f] text-left flex items-center"
												onClick={() => {
													handleDeleteClick(row);
													toggleDiv(index);
												}}
											>
												<div className="p-[7px] block w-full cursor-pointer">
													<i className="mr-3 fas fa-trash text-[14px]"></i>
													Delete
												</div>
											</li>
										</ul>
									</div>
								</div>
							)}
						</div>
						<div className="text-center	profile-widget content-center">
							<div className="w-[90px] relative m-auto mb-4">
								<Link
									to={`/clients/${row?.id}`}
									className="text-lg project-title mb-4 font-semibold text-white transition duration-150 ease-in-out hover:text-sky-400 focus:text-sky-400"
								>
									<img
										alt="title"
										src={
											row?.profile_pic || profile_default
										}
										className="w-[90px] h-[90px] rounded-full bg-white border border-slate-300 p-1"
									/>
								</Link>
							</div>
							<Link
								to={`/clients/${row?.id}`}
								className="dark:text-white text-[#4b5563] text-lg font-bold tracking-wider"
							>
								{row?.company_name}
							</Link>
							<h5 className="text-base mb-0 dark:text-white text-[#4b5563]">
								<a href="/react/template/client-profile">{`${row?.first_name} ${row?.last_name}`}</a>
							</h5>
							<div className="text-sm text-muted text-slate-400 mb-4">
								{row?.position}
							</div>
							<Link
								to={`/clients/${row?.id}`}
								className="px-4 py-1.5 bg-purple rounded text-white transition duration-150 ease-in-out shadow-xl dark:shadow-[#2e4062] shadow-[#cbd5e1]"
							>
								View Profile
							</Link>
							<div className="mt-4 px-6">
								<div className="float-left dark:text-white text-[#4b5563] w-[100px]">
									<label className="text-base">Project</label>
									<h4 className="font-bold">
										{row?.projects.length}
									</h4>
								</div>
								<div className="float-right dark:text-white text-[#4b5563] w-[100px]">
									<label className="text-base">Deal</label>
									<h4 className="font-bold">$2,510</h4>
								</div>
							</div>
						</div>
					</div>
				))}
		</>
	);
};

export default ListComponent;
