import React from 'react';
import AchievementCard from '../../../assets/images/Achievement-Card.png';
import CurrentLevelCard from '../../../assets/images/Current-Level-Card.png';
import ProgressBar from '../../../component/ui-component/ProgressBar';
import Xp2 from '../../../assets/images/xp2.png';
import B2 from '../../../assets/images/Group1.png';
import Toggle from '../../../assets/images/dots.png';

export default function ClosestAchievement() {
	return (
		<div className=" md:grid xs:block grid-cols-2 gap-[30px]">
			<div className="relative rounded-[15px] overflow-hidden p-5 pb-2 dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] md:mb-0 mb-[20px]">
				<img
					src={AchievementCard}
					alt="AchievementCard"
					className="absolute w-full h-full object-cover top-0 left-0"
				/>
				<h2 className="card-title dark:text-white text-[#374151]">
					Closest Achievement
				</h2>
				<span className="absolute text-[#fff] top-5 right-5 cursor-pointer">
					<img src={Toggle} alt="dot" className="dot-toggle" />
				</span>

				<div className="relative z-[99] flex items-center justify-center mb-7">
					<img src={Xp2} alt="Xp2" />
					<h1 className="text-[40px] font-bold leading-[45px] ml-[10.23px] dark:text-white text-[#374151]">
						x3
					</h1>
				</div>
				<div className="relative z-[99] flex items-start">
					<img src={Xp2} alt="Xp2" className="h-[38.631px] mt-1" />
					<div className="w-full pl-3 pt-2">
						<ProgressBar
							mainColor="#1F4466"
							progress="50%"
							shadow1="#2E6FAA"
							shadow2="#074675"
							shadow3="rgb(52, 113, 192)"
							bgColor="#3471c0"
							bgColor2="rgb(195,213,236)"
							bgColor3="rgb(255,255,255)"
							color1="#1ffbff"
							color2="#009eef"
						/>

						<div className="relative z-[99] flex items-center justify-between py-[10px]">
							<p className="text-sm leading-[18px] font-family-OpenSans dark:text-white text-[#374151] font-semibold">
								Days to work on time
							</p>
							<span className="text-right text-sm leading-[18px] font-family-OpenSans dark:text-white text-[#374151]">
								27/ <strong>30</strong>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="relative rounded-[15px] overflow-hidden p-5 pb-2 dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
				<img
					src={CurrentLevelCard}
					alt="CurrentLevelCard"
					className="absolute w-full h-full object-cover top-0 left-0"
				/>

				<h2 className="card-title dark:text-white text-[#374151]">
					My Current Level
				</h2>

				<span className="absolute text-[#fff] top-5 right-5 cursor-pointer">
					<img src={Toggle} alt="dot" className="dot-toggle" />
				</span>
				<div className="relative z-[99] flex items-center justify-center mb-7">
					<img src={B2} alt="B2" />
					<h1 className="text-[40px] font-bold leading-[45px] ml-[10.23px] dark:text-white text-[#374151]">
						5
					</h1>
				</div>
				<div className="relative z-[99] flex items-start">
					<img src={B2} alt="B2" className="h-[38.631px] mt-1" />
					<div className="w-full pl-3 pt-2">
						<ProgressBar
							mainColor="#674306"
							progress="80%"
							shadow1="#AA622E"
							shadow2="#753507"
							shadow3="#cc925a"
							color2="#ff8c00"
							color1="#ffc800"
							bgColor="#cc925a"
							bgColor2="#ecd7c3"
							bgColor3="#fff"
						/>
						<div className="relative z-[99] flex items-center justify-between py-[10px]">
							<p className="text-sm leading-[18px] font-family-OpenSans dark:text-white text-[#374151] font-semibold">
								2030 to next level
							</p>
							<span className="text-right text-sm leading-[18px] font-family-OpenSans dark:text-white text-[#374151] font-bold">
								65%
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
