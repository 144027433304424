import React, { useState } from 'react';
import { removeRole } from '../../store/thunk/rolesThunk';
import AddRolesComponent from './AddRolesComponent';
import EditRolesComponent from './EditRolesComponent';
import ListComponent from './ListComponent';
import Dialogpopup from '../../component/ui-component/Dailogpopup';
import BreadcrumbsComponent from '../../component/ui-component/BreadcrumbsComponent';

const RolesComponent = () => {
	const [addDrawerVisible, setAddDrawerVisible] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);

	const handleSetEditValues = (item) => {
		setEditValues(item);
		setEditDrawerVisible(true);
	};

	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
	};

	return (
		<>
			<div className="p-[20px]">
				<div className="flex items-center justify-between px-2 py-5">
					{/* <h2 className="dark:text-white text-black text-[22px] font-bold leading-7">
            Roles
          </h2> */}
					<BreadcrumbsComponent
						showBreadCrumbs={true}
						pageTitle={'Roles'}
					/>
					<button
						className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 pb-3 pt-3 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
						onClick={() => setAddDrawerVisible(true)}
					>
						+ Create Role
					</button>
				</div>
				<ListComponent
					setEditDrawerVisible={setEditDrawerVisible}
					handleSetEditValues={handleSetEditValues}
					handleDeleteClick={handleDeleteClick}
				/>
			</div>

			{addDrawerVisible && (
				<AddRolesComponent
					visible={addDrawerVisible}
					onClose={() => setAddDrawerVisible(false)}
				/>
			)}
			{editDrawerVisible && editValues && (
				<EditRolesComponent
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
				/>
			)}
			{deleteDailogVisible && (
				<>
					<Dialogpopup
						onCancel={() => setDeleteDailogVisible(false)}
						deleteItem={editValues}
						removeThunkCall={removeRole}
						message="Are you sure you want to delete this roles ?"
					/>
				</>
			)}
		</>
	);
};
export default RolesComponent;
