import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	notificationSuccess,
	notificationFail,
} from '../slices/notificationSlice';
import { setLoading } from '../slices/dataLoadingSlice';
import {
	setTasksData,
	setFetching,
	setTaskDashboard,
	setPendingTasksData,
	setCompletedTasksData,
} from '../slices/tasksSlice';

import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';
import { vsmTask } from '../../utils/validations';

export const getTasksList = createAsyncThunk(
	'getTasksList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let {
				pageNo,
				pageSize,
				searchText,
				sortBy,
				status,
				projectID,
				clientID,
			} = _request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';

				if (sortField === 'assigned_to.fullname') {
					sortField = 'assigned_to__first_name';
				}
				if (sortField === 'project.project_name') {
					sortField = 'project__project_name';
				}
				if (sortField === 'priority_name') {
					sortField = 'priority';
				}

				if (sortField !== 'active' && sortField !== 'action') {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';
			const statusSearch = status ? `&status=${status}` : '';
			const projectIdSearch = projectID ? `&project_id=${projectID}` : '';
			const clientIDSearch = clientID ? `&client_id=${clientID}` : '';

			const response = await apiClient(false).get(
				`/accounts/tasks/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}${statusSearch}${projectIdSearch}${clientIDSearch}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				if (status && status === 1) {
					dispatch(setPendingTasksData(response?.data));
				} else if (status && status === 3) {
					dispatch(setCompletedTasksData(response?.data));
				} else {
					dispatch(setTasksData(response?.data));
				}
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addTask = createAsyncThunk(
	'addTask',
	async (_request, { dispatch }) => {
		try {
			const { is_dashboardtask } = _request;
			delete _request.is_dashboardtask;

			dispatch(setLoading(true));
			const response = await apiClient().post(
				`/accounts/tasks/`,
				_request
			);
			dispatch(setLoading(false));
			if (response?.data) {
				if (is_dashboardtask) {
					dispatch(getDashboardTasks());
				} else {
					dispatch(getTasksList());
				}
				dispatch(notificationSuccess(vsmTask?.addSucces));
				toast.success(vsmTask?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const editTask = createAsyncThunk(
	'editTask',
	async (_request, { dispatch }) => {
		try {
			const {
				is_status_update,
				status,
				project_id,
				show_all_task,
				show_all_project_task,
			} = _request;
			delete _request.is_status_update;
			delete _request.project_id;
			delete _request.show_all_task;
			delete _request.show_all_project_task;

			dispatch(setLoading(true));
			const response = await apiClient().patch(
				`/accounts/tasks/${_request.id}/`,
				_request
			);

			dispatch(setLoading(false));
			if (response?.data) {
				let message = '';
				let filter = '';
				let applyFilter = '';
				if (is_status_update) {
					message = vsmTask?.update_status_success;
					filter = show_all_project_task
						? { projectID: project_id }
						: {
								projectID: project_id,
								status: status === 1 ? 3 : 1,
						  };
					applyFilter = show_all_task ? '' : filter;
				} else {
					message = vsmTask?.updateSuccess;
					_request.callbackFunction();
				}
				dispatch(getTasksList(applyFilter));
				dispatch(notificationSuccess(message));
				toast.success(message);
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeTask = createAsyncThunk(
	'removeTask',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(`/accounts/tasks/${_request.id}/`);
			dispatch(setLoading(false));
			dispatch(getTasksList());
			dispatch(notificationSuccess(vsmTask?.deleteSuccess));
			toast.success(vsmTask?.deleteSuccess);
			// _request.callbackFunction();
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const updateTaskOrder = createAsyncThunk(
	'updateTaskOrder',
	async (_request, { dispatch }) => {
		try {
			const { task_order } = _request;
			dispatch(setLoading(true));
			await apiClient().post(`/accounts/tasks/update-task-order/`, {
				task_order,
			});
			dispatch(setLoading(false));
			dispatch(getTasksList());
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const getDashboardTasks = createAsyncThunk(
	'getDashboardTasks',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().get(
				`/accounts/tasks/dashboard-task-lists/`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setTaskDashboard(response?.data));
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);
