import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLeavesList } from '../../store/thunk/leavesThunk';
import ReactTablePagination from '../../component/ui-component/pagination/ReactTablePagination';
import Delete from '../../assets/images/Delete.png';
import Edit from '../../assets/images/Edit.png';
import View from '../../assets/images/View.png';
import { getLeaveStatusInfo, getDefaultProfile } from '../../component/Common';
import UserProfile from '../../component/ui-component/UserProfile';
import { hasPermission } from '../../component/Common';

const moment = require('moment');

const ListComponent = ({
	handleSetEditValues,
	handleDeleteClick,
	handleViewClick,
	userData,
	userPermissions,
}) => {
	const [recordsPerPage, setRecordsPerPage] = useState(10);

	const dispatch = useDispatch();

	const leavesData = useSelector((state) => state.leaves);

	const columns = [
		{
			Header: 'Leave Type',
			accessor: 'leave_type_name',
			width: '15%',
			resize: true,
		},
		{
			Header: 'From Date',
			accessor: 'start_date',
			width: '10%',
			resize: true,
			Cell: ({ row, value }) => {
				return (
					<>{moment(value, 'YYYY-MM-DD').format('DD MMM, YYYY')}</>
				);
			},
		},
		{
			Header: 'To Date',
			accessor: 'end_date',
			width: '10%',
			resize: true,
			Cell: ({ row, value }) => {
				return (
					<>{moment(value, 'YYYY-MM-DD').format('DD MMM, YYYY')}</>
				);
			},
		},
		{
			Header: 'No.Of Days',
			accessor: 'no_of_days',
			width: '10%',
			resize: true,
			Cell: ({ row, value }) => {
				return (
					<>{`${row?.original?.no_of_days} ${
						row?.original?.no_of_days > 1 ? 'days' : 'day'
					}`}</>
				);
			},
		},
		{
			Header: 'Reason',
			accessor: 'reason',
			width: '35%',
			resize: true,
			Cell: ({ row, value }) => {
				const reason = row?.original?.reason;
				const displayReason =
					reason.length > 120
						? `${reason.substring(0, 120)}...`
						: reason;
				return (
					<span
						className="noti-title"
						dangerouslySetInnerHTML={{ __html: displayReason }}
					></span>
				);
			},
		},
		{
			Header: 'Status',
			accessor: 'leave_status_name',
			width: '12%',
			resize: true,
			Cell: ({ row, value }) => {
				const { leaveStatusName, leaveTextcolor } = getLeaveStatusInfo(
					row?.original?.leave_status
				);
				return (
					<div className="flex font-semibold">
						<button
							className={`inline-flex items-center gap-[5px] bg-white border ${leaveTextcolor} font-semibold text-[14px] px-3 py-1 pr-[15px] rounded-[50px] border-solid`}
						>
							{' '}
							<i
								className={`fa-regular fa-circle-dot ${leaveTextcolor}`}
							></i>{' '}
							{leaveStatusName}
						</button>
					</div>
				);
			},
		},
		{
			Header: 'Approved By',
			accessor: 'approved_by.fullname',
			width: '16%',
			resize: true,
			Cell: ({ row, value }) => {
				if (
					row?.original?.approved_by &&
					[2, 3].includes(row?.original?.leave_status)
				) {
					return (
						<div className="flex">
							<UserProfile
								imageUrl={
									row?.original?.approved_by?.profile_image
								}
								defaultImageUrl={getDefaultProfile(
									row?.original?.approved_by?.gender
								)}
								altName={row?.original?.approved_by?.fullname}
								className={
									'h-[35px] w-[35px] rounded-full float-left'
								}
							/>
							<div className="ml-3">
								<h6 className="mb-0 font-bold text-[15px]">
									{row?.original?.approved_by?.fullname}
								</h6>
								<p className="text-[10px]">
									{row?.original?.approved_by?.is_companyadmin
										? 'Company Admin'
										: row?.original?.approved_by
												?.designation}
								</p>
							</div>
						</div>
					);
				}
			},
		},
		{
			Header: 'Action',
			accessor: 'action',
			disableSortBy: true,
			Cell: ({ row, value }) => {
				if ([1].includes(row?.original?.leave_status)) {
					return (
						<div className="flex">
							{hasPermission(
								userData,
								userPermissions,
								'change_userleaves'
							) && (
								<div
									className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full mr-[8px] border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
									onClick={() =>
										handleSetEditValues(row?.original)
									}
									title="Edit"
								>
									<img src={Edit} alt="Edit" />
								</div>
							)}
							{hasPermission(
								userData,
								userPermissions,
								'delete_userleaves'
							) && (
								<div
									className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
									onClick={() =>
										handleDeleteClick(row?.original)
									}
									title="Delete"
								>
									<img src={Delete} alt="Delete" />
								</div>
							)}
						</div>
					);
				} else if ([2, 3].includes(row?.original?.leave_status)) {
					return (
						<div
							className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full mr-[8px] border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
							onClick={() => handleViewClick(row?.original)}
							title="View"
						>
							<img src={View} alt="View" className="w-[22px]" />
						</div>
					);
				}
			},
		},
	];

	const fetchData = useCallback(
		(pageSize, pageIndex, searchText, sortBy) => {
			dispatch(
				getAllLeavesList({
					pageSize: pageSize,
					pageNo: pageIndex,
					searchText: searchText,
					sortBy: sortBy,
					approvalLeave: false,
				})
			);
		},
		[dispatch]
	);

	return (
		<>
			<ReactTablePagination
				columns={columns}
				data={leavesData?.myleavesList}
				fetchData={fetchData}
				pageCount={Math.ceil(leavesData?.totalRecords / recordsPerPage)}
				totalRecords={leavesData?.totalRecords}
				recordsPerPage={recordsPerPage}
				showContentClass={false}
				setRecordsPerPage={setRecordsPerPage}
				showCSVButton={hasPermission(
					userData,
					userPermissions,
					'export_userleaves'
				)}
				csvFileName="myleave_lists.csv"
			/>
		</>
	);
};

export default ListComponent;
