import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityFeedList } from '../../store/thunk/commonThunk';
import { Link } from 'react-router-dom';
import { getDefaultProfile } from '../../component/Common';
import UserProfile from '../ui-component/UserProfile';

export default function NotificationDropdown() {
	const dispatch = useDispatch();
	const authUser = useSelector((state) => state.auth);
	const ref = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	const activity_data = useSelector((state) => state.common.activityFeedList);

	useEffect(() => {
		if (!authUser?.userDetails?.is_superuser) {
			dispatch(getActivityFeedList({ pageSize: 10 }));
		}
	}, [dispatch, authUser?.userDetails]);

	const toggleDiv = () => {
		setIsOpen(!isOpen);
	};
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setIsOpen(false);
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [isOpen]);

	return (
		<div ref={ref} style={{ height: '100%' }}>
			<div onClick={toggleDiv} style={{ height: '100%' }}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="23"
					height="23"
					viewBox="0 0 23 23"
					fill="none"
					style={{ margin: '0 auto', height: '100%' }}
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M17.3922 8.12401C17.3922 9.27528 17.6965 9.95386 18.3661 10.7358C18.8736 11.3119 19.0357 12.0514 19.0357 12.8537C19.0357 13.6551 18.7724 14.4158 18.2449 15.0335C17.5544 15.7739 16.5805 16.2466 15.5865 16.3288C14.1462 16.4516 12.7049 16.555 11.2445 16.555C9.78321 16.555 8.34285 16.4931 6.9025 16.3288C5.90764 16.2466 4.93374 15.7739 4.24409 15.0335C3.71659 14.4158 3.45239 13.6551 3.45239 12.8537C3.45239 12.0514 3.61547 11.3119 4.12201 10.7358C4.81257 9.95386 5.09682 9.27528 5.09682 8.12401V7.73349C5.09682 6.19169 5.48128 5.18352 6.27297 4.19658C7.45003 2.75726 9.3368 1.89404 11.2035 1.89404H11.2855C13.1923 1.89404 15.1401 2.79881 16.2971 4.29999C17.0478 5.26661 17.3922 6.23231 17.3922 7.73349V8.12401ZM8.56159 18.4498C8.56159 17.9882 8.98523 17.7768 9.37697 17.6863C9.83523 17.5894 12.6276 17.5894 13.0858 17.6863C13.4776 17.7768 13.9012 17.9882 13.9012 18.4498C13.8784 18.8893 13.6206 19.2789 13.2644 19.5263C12.8025 19.8864 12.2604 20.1144 11.6937 20.1966C11.3804 20.2372 11.0724 20.2381 10.77 20.1966C10.2024 20.1144 9.66031 19.8864 9.19932 19.5254C8.84219 19.2789 8.58437 18.8893 8.56159 18.4498Z"
						className="dark:fill-[#bfc6d2] fill-[#0c1932]"
					/>
				</svg>
			</div>
			{isOpen && (
				<div className="menuDropdown absolute w-[352px] dark:text-white text-black right-[0px] mt-[10px] notification-bg">
					<div className="topnav-dropdown-header flex items-center justify-between p-3 border-b-[#004887] border-b border-solid">
						<span className="notification-title text-[14px]">
							Notifications
						</span>
						<a
							href="#"
							className="clear-noti text-[12px] text-[#f83f37] font-bold"
						>
							{' '}
							CLEAR ALL{' '}
						</a>
					</div>
					<div className="noti-content">
						{activity_data.length > 0 && (
							<ul
								className={`notification-list custom-scroll overflow-auto ${
									activity_data.length >= 3 ? 'h-[275px]' : ''
								}`}
							>
								{activity_data.map((row, index) => (
									<li
										key={row.id}
										className={`notification-message p-2 hover:bg-sky-700 ${
											index + 1 !== activity_data.length
												? 'border-b-[#004887] border-b border-solid'
												: ''
										}`}
									>
										<a href="#">
											<div className="chat-block flex">
												<span
													className="avatar"
													style={{ width: '20%' }}
												>
													<UserProfile
														imageUrl={
															row?.created_by
																?.profile_image_url
														}
														defaultImageUrl={getDefaultProfile(
															row?.created_by
																?.gender
														)}
														altName={
															row?.created_by
																?.fullname
														}
														className={
															'h-[50px] w-[50px] rounded-full'
														}
													/>
												</span>
												<div
													className="media-body"
													style={{ width: '90%' }}
												>
													<p className="noti-details text-left text-[13px] ml-2.5">
														<span
															className="noti-title"
															dangerouslySetInnerHTML={{
																__html: `${row?.message}`,
															}}
														></span>
													</p>
													<p className="noti-time text-left text-[13px] ml-2.5 mt-2">
														<span className="notification-time float-left">
															{
																row?.minutes_day_diff
															}
														</span>
														<span className="notification-time font-black ml-[8px] inline-block whitespace-nowrap rounded-full bg-warning-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-xs leading-none text-warning-800 float-right">
															{
																row?.activity_type_name
															}
														</span>
													</p>
												</div>
											</div>
										</a>
									</li>
								))}
							</ul>
						)}
						{activity_data.length === 0 && (
							<ul
								className={`notification-list custom-scroll overflow-auto`}
							>
								<li>No notification found..</li>
							</ul>
						)}
					</div>
					<div className="topnav-dropdown-footer h-10 leading-10 text-[14px] px-3 py-0 border-t-[#004887] border-t border-solid">
						{/* <a href="/activities">View all Notifications</a> */}
						<Link to="/activities">View all Notifications</Link>
					</div>
				</div>
			)}
		</div>
	);
}
