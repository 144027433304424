import { ToastContainer } from 'react-toastify';
import '../src/assets/css/App.scss';
import 'react-toastify/dist/ReactToastify.css';
import UserRoutes from './routes/UserRoutes';

function App() {
	return (
		<div className="App">
			<ToastContainer
				position="bottom-right"
				autoClose={2000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<UserRoutes />
		</div>
	);
}

export default App;
