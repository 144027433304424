import React, { useEffect } from 'react';
import { Breadcrumbs } from '@material-tailwind/react';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityFeedList } from '../../store/thunk/commonThunk';
import { getDefaultProfile } from '../../component/Common';
import UserProfile from '../ui-component/UserProfile';

function NotificationActivities() {
	const dispatch = useDispatch();
	const authUser = useSelector((state) => state.auth);
	const activity_data = useSelector((state) => state.common.activityFeedList);

	useEffect(() => {
		if (!authUser?.userDetails?.is_superuser) {
			dispatch(getActivityFeedList({ pageSize: 150 }));
		}
	}, [dispatch, authUser?.userDetails]);

	return (
		<div className="content md:p-[30px] p-[10px]">
			<div className="page-header mb-[1.875rem]">
				<div className="row">
					<div className="col-sm-12">
						<h3 className="dark:text-[white] text-black text-[26px] font-semibold mb-[5px] text-left">
							Activities
						</h3>
						<Breadcrumbs className="dark:text-white text-black pl-0">
							<a href="/" className="opacity-60">
								Dashboard
							</a>
							<a href="/activities">Activities</a>
						</Breadcrumbs>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<div className="activity">
						<div className="activity-box">
							<ul className="activity-list">
								{activity_data.map((row, index) => (
									<li key={row.id}>
										<div className="activity-user">
											<a
												href="#"
												className="avatar"
												data-bs-toggle="tooltip"
												aria-label="Lesley Grauer"
												data-bs-original-title="Lesley Grauer"
											>
												<UserProfile
													imageUrl={
														row?.created_by
															?.profile_image_url
													}
													defaultImageUrl={getDefaultProfile(
														row?.created_by?.gender
													)}
													altName={
														row?.created_by
															?.fullname
													}
													className={
														'rounded-full h-[45px] w-[45px]'
													}
												/>
											</a>
										</div>
										<div className=" relative ml-10 mr-0 my-0 p-0 text-left">
											<div className="timeline-content text-[14px]">
												<p className="noti-details text-left text-[13px] dark:text-[#fff] text-[#000]">
													<span
														className="noti-title"
														dangerouslySetInnerHTML={{
															__html: `${row?.message}`,
														}}
													></span>
												</p>
												<div className="time dark:text-[#bdbdbd] text-[#000] block text-[13px]">
													{row?.minutes_day_diff}
													<span className="notification-time font-black ml-[8px] inline-block whitespace-nowrap rounded-full bg-warning-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-xs leading-none text-warning-800 float-right">
														{
															row?.activity_type_name
														}
													</span>
												</div>
											</div>
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NotificationActivities;
