import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { editXPEarning } from '../../store/thunk/departmentThunk';
import FormComponent from './FormComponent';

const EditXpEarningComponent = ({ visible, onClose, editValues }) => {
	const loading = useSelector((state) => state.dataLoading.loading);
	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		setValue,
	} = useForm({
		defaultValues: {
			activity_or_achievement: editValues?.activity_or_achievement,
			xp_earned_points: editValues?.xp_earned_points,
			is_applicable_for_all: editValues?.is_applicable_for_all,
		},
	});

	const onSuccessCallback = () => {
		reset();
		onClose();
	};

	const onSubmit = (data) => {
		data.uid = editValues?.uid;
		data.callbackFunction = onSuccessCallback;
		data.setError = setError;
		data.id = editValues?.id;
		if (data.applicable_departments === undefined) {
			let selected_applicable_departments_ids = [];
			editValues?.applicable_departments.map((item) =>
				selected_applicable_departments_ids.push(parseInt(item.id))
			);
			data.applicable_departments = selected_applicable_departments_ids;
		}
		if (data.applicable_departments.length === 0) {
			delete data.applicable_departments;
		}
		dispatch(editXPEarning(data));
	};

	return (
		<>
			<div
				className={` togglesidebar bg-[#ffffff57] fixed h-full w-full top-0 left-0 p-6 z-[99999]  ${
					visible ? 'active' : ''
				}`}
			>
				<div
					onClick={onClose}
					className="w-full bg-[#ffffff57] overlay bg-[#0f1a32] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
				/>
				<div className="dark:bg-[#12243d] bg-[#fafafa] border-gray-300 border-l w-[85%] lg:w-[50%] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]">
					<button
						className="close h-8 w-8 rounded-full absolute top-5 right-5 bg-[#fff]"
						onClick={onClose}
					>
						X
					</button>
					<h1 className="p-6 dark:text-white text-[#4b5563] font-bold text-[20px] text-left pb-[20px] border-solid border-b dark:border-[#333333] border-gray-300">
						Update XP Earning Data
					</h1>
					<div className="rounded-lg">
						<FormComponent
							loading={loading}
							register={register}
							handleSubmit={handleSubmit}
							onSubmit={onSubmit}
							errors={errors}
							isUpdate={true}
							setValue={setValue}
							selectedOptions={editValues?.is_applicable_for_all}
							selectedAssignedDepartment={
								editValues?.applicable_departments
							}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditXpEarningComponent;
