import React, { useState, useEffect } from 'react';
import { getDashboardTasks } from '../../../store/thunk/tasksThunk';
import { useDispatch, useSelector } from 'react-redux';
import BgImg1 from '../../../assets/images/LandingPage.png';
import AddTaskComponent from './AddTaskComponent';
import { getDefaultProfile } from '../../../component/Common';
import UserProfile from '../../../component/ui-component/UserProfile';

// Define the month names
const month_names = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export default function TaskManagement() {
	const dispatch = useDispatch();
	const [addDrawerVisible, setAddDrawerVisible] = useState(false);
	const task_data = useSelector((state) => state.tasks.taskDashboardLists);
	const [activeTab, setActiveTab] = useState('tabt1');

	useEffect(() => {
		dispatch(getDashboardTasks());
	}, [dispatch]);

	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	return (
		<div>
			{/* Header */}
			<div className="flex items-center justify-between mb-[20px] h-[40px]">
				<h1 className="dark:text-white text-[#4b5563] text-[16px] md:text-[22px] font-bold leading-7">
					Task Management
				</h1>
				<button
					className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full p-[10px] px-5 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
					onClick={() => setAddDrawerVisible(true)}
				>
					+ Create Task
				</button>
			</div>
			{addDrawerVisible && (
				<AddTaskComponent
					visible={addDrawerVisible}
					onClose={() => setAddDrawerVisible(false)}
				/>
			)}

			{/* Tabs */}
			<div className="h-[358.842px] p-[19.38px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] rounded-[15px]">
				<div className="tabs">
					<div className="top-tab-menu border-b dark:border-[#273758] border-gray-300">
						<ul className="overflow-auto top-scroll tab-menu flex mb-6 pb-4 text-block border border-solid border-transparent task-dates">
							{Object.keys(task_data).map((date, i) => {
								const due_date = new Date(date);
								const isActive = activeTab === `tabt${i + 1}`;

								return (
									<li
										key={i}
										className={`mr-5 task-managment-dates p-[10px] text-center rounded-[10px] w-[78px] h-[75px] dark:bg-[#101B32] bg-[#f2f2f2] tabt-item dark:text-white text-black hover:text-white hover:cursor-pointer ${
											isActive ? 'active' : ''
										}`}
										data-tab={`tabt${i + 1}`}
										onClick={() =>
											handleTabChange(`tabt${i + 1}`)
										}
									>
										<p className="text-[16px] leading-[18px] dark:text-[#BFC6D2] text-black font-bold pb-[2px]">
											{month_names[due_date.getMonth()]}
										</p>
										<h1 className="text-black dark:text-white text-[28px] font-extrabold font-family-Montserrat-bold">
											{String(
												due_date.getDate()
											).padStart(2, '0')}
										</h1>
									</li>
								);
							})}
						</ul>
					</div>
					<div className="tab-content">
						{Object.keys(task_data).map(
							(date, i) =>
								activeTab === `tabt${i + 1}` && (
									<div
										key={`tab-content-${i}`}
										id={`tabt${i + 1}`}
										className="tabt-pane"
										style={{ color: '#fff' }}
									>
										<div className="overflow-x-auto top-scroll">
											<div className="flex items w-[max-content] pb-7 pt-7">
												{task_data[date].map(
													(task, index) => (
														<div
															key={index}
															className="task-managment-box relative item w-[235px] h-[130px] mr-[17px] text-left rounded-[10px] "
														>
															<div className=" z-[1] relative p-[18px]">
																<p className="text-[12px] mb-[5px] text-[#54565C] dark:text-white">
																	Due Date -{' '}
																	{
																		month_names[
																			new Date(
																				task.due_date
																			).getMonth()
																		]
																	}{' '}
																	{String(
																		new Date(
																			task.due_date
																		).getDate()
																	).padStart(
																		2,
																		'0'
																	)}
																</p>
																<h2 className="text-base font-bold leading-[21px] mb-[15px] text-black dark:text-white">
																	{task.title.substring(
																		0,
																		20
																	)}
																	{task.title
																		.length >
																	20
																		? ' ...'
																		: ''}
																</h2>
																<ul className="user">
																	<li className="h-[40px] w-[40px] inline-block">
																		<UserProfile
																			imageUrl={
																				task
																					?.assigned_to
																					?.profile_image_url
																			}
																			defaultImageUrl={getDefaultProfile(
																				task
																					?.assigned_to
																					?.gender
																			)}
																			altName={
																				task
																					?.assigned_to
																					?.fullname
																			}
																			className={
																				'rounded-full'
																			}
																		/>
																	</li>
																</ul>
															</div>
															<div className="absolute z-0 h-full w-full left-0 top-0">
																<img
																	src={BgImg1}
																	alt="bgImg"
																	className="h-full w-full"
																/>
															</div>
														</div>
													)
												)}
											</div>
										</div>
									</div>
								)
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
