import React, { useEffect, useState } from 'react';
import InputComponent from '../../component/ui-component/InputComponent';
import TextareaComponent from '../../component/ui-component/TextareaComponent';
import Button from '../../component/ui-component/Button';
import RadioComponent from '../../component/ui-component/RadioComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getRolesList } from '../../store/thunk/commonThunk';
import MultiSelectComponent from '../../component/ui-component/MultiSelectComponent';
import { vsmSkills } from '../../utils/validations';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	setValue,
	selectedOptions,
	selectedAssignedRoles,
}) => {
	const dispatch = useDispatch();
	const roleList = useSelector((state) => state.common.roleList);
	const [showMultipleRolesOptions, setShowMultipleRolesOptions] = useState(
		!selectedOptions || false
	);

	const [isMultiSelectValid, setIsMultiSelectValid] = useState(
		selectedAssignedRoles && selectedAssignedRoles.length > 0 ? true : false
	);
	const [error, setError] = useState(false);

	useEffect(() => {
		dispatch(getRolesList());
	}, [dispatch]);

	const optionsLists = [
		{ id: 'yes', name: 'Yes', value: true },
		{ id: 'no', name: 'No', value: false },
	];

	const onChangeSelectValue = (key, selectedValue) => {
		if (selectedValue === 'false') {
			setShowMultipleRolesOptions(true);
		} else {
			setShowMultipleRolesOptions(false);
		}
		setValue(key, selectedValue);
	};

	const onChangeMultiSelectValue = (key, selectedValue) => {
		let role_ids = [];
		selectedValue.map((item) => role_ids.push(parseInt(item.value)));
		setValue(key, role_ids);
		setIsMultiSelectValid(selectedValue.length > 0);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (!isMultiSelectValid && showMultipleRolesOptions) {
			setError(true);
			return;
		} else {
			setError(false);
		}
		// Proceed with form submission
		handleSubmit(onSubmit)(e);
	};

	return (
		<form onSubmit={handleFormSubmit} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
							Skill Information
						</h3>
					</div>
					<div className="grid grid-cols-1 gap-2">
						<InputComponent
							id="name"
							title="Skill Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmSkills}
						/>
						<RadioComponent
							id="applicable_for_all_roles"
							title="Applicable for all roles"
							errors={errors}
							register={register}
							fieldvalidation={vsmSkills}
							optionsLists={optionsLists}
							selectedOptions={selectedOptions}
							onChangeSelectValue={onChangeSelectValue}
						/>
						{showMultipleRolesOptions && (
							<MultiSelectComponent
								selectOptionLists={roleList}
								onChangeSelectValue={onChangeMultiSelectValue}
								selectedValue={selectedAssignedRoles}
								title="Select Applicable Roles"
								id="roles"
								error={error}
							/>
						)}
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-1 gap-2 mt-[12px] textareaComponent">
						<TextareaComponent
							id="description"
							title="Description"
							errors={errors}
							register={register}
							fieldvalidation={vsmSkills}
							customClass="mt-[20px]"
						/>
					</div>
				</div>
			</div>
			<div className="flex md:p-6 p-3 border-solid border-t dark:border-[#333333] border-gray-300 absolute w-full bottom-0">
				<Button
					buttonText={`${isUpdate ? 'Update' : 'Create'} Skills`}
					linkTo="#"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default FormComponent;
