import React, { useState, useEffect } from 'react';
import { useRef } from 'react';

function StatusDropDown({
	statusData,
	statusOptions,
	selectedOptionsValue,
	handleOnChange,
}) {
	const ref = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(selectedOptionsValue);
	const [selectedLabel, setSelectedLabel] = useState(
		statusOptions.find((options) => options.value === selectedOption)
	);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (option) => {
		setSelectedOption(option.value);
		setSelectedLabel(option);
		handleOnChange(statusData, option.value);
		setIsOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setIsOpen(false);
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [isOpen]);

	return (
		<div ref={ref}>
			<div className=" w-[130px]" style={{ position: 'relative' }}>
				<button
					className={`w-[130px] dropdown-toggle justify-between inline-flex items-center gap-[5px] border bg-white ${selectedLabel?.iconClass} font-semibold text-[14px] px-3 py-1 rounded-[50px]`}
					onClick={toggleDropdown}
				>
					<i
						className={`fa-regular fa-circle-dot ${selectedLabel?.iconClass}`}
					></i>
					{selectedLabel?.label}
				</button>
				{isOpen && (
					<ul
						className="leave-status w-[130px] mt-[5px] text-black text-[14px] font-semibold border border-solid border-[rgba(0,0,0,0.1)] absolute left-0 z-[99]"
						style={{ background: '#fff' }}
					>
						{statusOptions.map((option) => (
							<li
								key={option.value}
								onClick={() => handleOptionClick(option)}
								className="p-[5px] ps-2 cursor-pointer hover:bg-sky-700"
							>
								<i
									className={`mr-[5px] fa-regular fa-circle-dot ${option.iconClass}`}
								></i>
								{option.label}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
}

export default StatusDropDown;
