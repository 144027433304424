import { useState } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import useDarkSide from '../hooks/useDarkSide';
import MoonIcon from '../assets/images/moon.png';
import SunIcon from '../assets/images/career-icon.png';

export default function Switcher() {
	const [colorTheme, setTheme] = useDarkSide('dark');
	const [darkSide, setDarkSide] = useState(
		colorTheme === 'light' ? true : false
	);

	const toggleDarkMode = (checked) => {
		localStorage.setItem('theme', colorTheme);
		setTheme(colorTheme);
		setDarkSide(checked);
	};

	return (
		<>
			<DarkModeSwitch
				className="toggle-dark-mode-new"
				style={{ fontSize: '20px' }}
				checked={darkSide}
				onChange={toggleDarkMode}
				size={20}
				icons={{
					checked: <img src={MoonIcon} alt="MoonIcon" />,
					unchecked: <img src={SunIcon} alt="SunIcon" />,
				}}
			/>
		</>
	);
}
