import React, { useEffect, useState } from 'react';
import {
	useTable,
	usePagination,
	useSortBy,
	useResizeColumns,
} from 'react-table';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Pagination from './Pagination';
import GlobalSearch from './GlobalSearch';
import DraggableRows from './DraggableRows';
import ExportButton from '../ExportButton';

function ReactTablePaginationDragable({
	columns,
	data,
	fetchData,
	loading,
	pageCount: controlledPageCount,
	totalRecords,
	recordsPerPage,
	setRecordsPerPage,
	showHeader = true,
	showContentClass = true,
	updateRowOrders,
	showCSVButton = true,
	csvFileName = '',
}) {
	const [globalSearchText, setGlobalSearchText] = useState('');
	const [sortBy, setSortBy] = useState([]);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: recordsPerPage,
				sortBy,
			}, // Pass our hoisted table state
			manualPagination: true, // Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: controlledPageCount,
		},
		useSortBy,
		usePagination,
		useResizeColumns
	);

	// Listen for changes in pagination and use the state to fetch our new data
	useEffect(() => {
		fetchData(pageSize, pageIndex + 1, globalSearchText, sortBy);
	}, [fetchData, pageIndex, pageSize, globalSearchText, sortBy]);

	const [rowOrder, setRowOrder] = useState([]);

	useEffect(() => {
		if (data.length > 0) {
			setRowOrder(data.map((row) => row.id.toString()));
		}
	}, [data]);

	const handleDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		// Reorder the data array based on the drag-and-drop result
		const reorderedData = Array.from(rowOrder);
		const [movedRow] = reorderedData.splice(result.source.index, 1);
		reorderedData.splice(result.destination.index, 0, movedRow);
		setRowOrder(reorderedData);
		updateRowOrders(reorderedData);

		// Update the data array and any other state as needed
		// Remember to update any server-side data as well if necessary
	};

	return (
		<>
			<div
				id="recipients"
				className={`${
					showContentClass
						? 'relative min-w-0 w-full md:p-8 p-3 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]'
						: ''
				}`}
			>
				<div className="flex items-center justify-between filter-block mb-[20px]">
					<GlobalSearch
						setGlobalSearchText={setGlobalSearchText}
						globalSearchText={globalSearchText}
					/>

					{showCSVButton && (
						<ExportButton
							data={data}
							columns={columns}
							filename={csvFileName}
						/>
					)}
				</div>

				<div className="mb-[20px] ">
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable
							droppableId="table-body"
							direction="vertical"
						>
							{(provided) => (
								<div
									className="overflow-auto top-scroll"
									ref={provided.innerRef}
								>
									<table
										id="example"
										className="stripe hover text-white lg:w-full md:w-max w-max"
										// style={{
										// 	width: "100%",
										// 	paddingTop: "1em",
										// 	paddingBottom: "1em",
										// }}
										ref={provided.innerRef}
										{...getTableProps()}
										{...provided.droppableProps}
									>
										{showHeader && (
											<thead className="dark:bg-[#202D47] bg-[#f2f2f2] h-[51.49px] rounded-[8px] block">
												{headerGroups.map(
													(headerGroup) => (
														<tr
															className=" w-full flex items-center  h-[50px] px-2.5 py-0 rounded-lg justify-between"
															{...headerGroup.getHeaderGroupProps()}
														>
															{headerGroup.headers.map(
																(column) => (
																	<th
																		key={
																			column.id
																		}
																		className="text-left text-[14px] text-[#101B32] dark:text-[#fff] table-header-cell"
																		{...column.getHeaderProps(
																			column.getSortByToggleProps(),
																			column.getResizerProps()
																		)}
																		onClick={() => {
																			setSortBy(
																				[
																					{
																						id: column.id,
																						desc: !column.isSortedDesc,
																					},
																				]
																			);
																		}}
																		style={{
																			width: column.width,
																		}}
																	>
																		{column.render(
																			'Header'
																		)}
																		<span className="mr-1">
																			{column.isSorted ? (
																				column.isSortedDesc ? (
																					<i className="fas fa-arrow-down text-[13px] ml-[5px]"></i>
																				) : (
																					<i className="fas fa-arrow-up text-[13px] ml-[5px]"></i>
																				)
																			) : (
																				''
																			)}
																		</span>
																		<div
																			{...column.getResizerProps()}
																			className={`resizer ${
																				column.isResizing
																					? 'isResizing'
																					: ''
																			}`}
																		/>
																	</th>
																)
															)}
														</tr>
													)
												)}
											</thead>
										)}
										<tbody
											className="rounded-[8px] mb-[17px] block bottom-scroll "
											{...getTableBodyProps()}
										>
											{page.length === 0 ? (
												<tr>
													<td
														colSpan={columns.length}
														className="pt-[20px] text-center text-gray-600 dark:text-[#fff] mt-[20px]"
													>
														No records found.
													</td>
												</tr>
											) : (
												<DraggableRows
													page={page}
													prepareRow={prepareRow}
													rowOrder={rowOrder}
												/>
											)}
										</tbody>
									</table>
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
				<Pagination
					totalRecords={totalRecords}
					canPreviousPage={canPreviousPage}
					gotoPage={gotoPage}
					previousPage={previousPage}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
					canNextPage={canNextPage}
					nextPage={nextPage}
					pageCount={pageCount}
					pageSize={pageSize}
					setPageSize={setPageSize}
					setRecordsPerPage={setRecordsPerPage}
				/>
			</div>
		</>
	);
}

export default ReactTablePaginationDragable;
