import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	Typography,
	List,
	ListItem,
	ListItemPrefix,
	Accordion,
	AccordionHeader,
	AccordionBody,
} from '@material-tailwind/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import {
	companyUserMenu,
	companyAdminMenu,
	superAdminMenu,
} from './header/menuList';

import { getUserPermissions } from '../store/thunk/userThunk';
import { hasPermission } from './Common';

const generateMenuItem = (
	menuList,
	isActive,
	accordionOpen,
	handleOpen,
	user_permissions,
	authUser
) => {
	return (
		<>
			<p className="text-xs font-normal leading-4 px-[15px] text-left dark:text-[#fff] text-[#0C1932]">
				Main Menu
			</p>
			<div className="menulist mt-3 ml-3 ">
				<List className="p-0 ">
					{menuList.map((row, i) => (
						<React.Fragment key={row.label}>
							{row.submenu ? (
								<Accordion
									open={accordionOpen[i]}
									icon={
										<ChevronRightIcon
											strokeWidth={2.5}
											className={`mx-auto h-4 w-4 transition-transform p-0 ${
												accordionOpen[i]
													? 'rotate-90'
													: ''
											}`}
										/>
									}
								>
									<ListItem
										className="p-0 sidebar-list-item p-2.5 md:p-2.5 lg:p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white"
										selected={accordionOpen[i]}
									>
										<NavLink
											key={i}
											to={row.url}
											style={{ width: '100%' }}
											className={isActive ? 'active' : ''}
										>
											<AccordionHeader
												onClick={() => handleOpen(i)}
												className="border-b-0 p-0 m-0 text-xs"
											>
												<ListItemPrefix className="mr-3">
													<img
														src={row.image}
														alt="icons"
													/>
												</ListItemPrefix>
												<span
													color="blue-gray"
													className="text-[14px] mr-auto font-bold text-[#47586f] dark:text-[#bfc6d2] font-[OpenSans-Regular]"
												>
													{row.label}
												</span>
											</AccordionHeader>
										</NavLink>
									</ListItem>
									<AccordionBody className="py-1">
										<List className="p-0 ">
											{row.submenu.map(
												(child_menu, cindex) => (
													<NavLink
														key={cindex}
														to={child_menu.url}
														style={{
															width: '100%',
														}}
														className={
															isActive
																? 'active'
																: ''
														}
													>
														<ListItem
															key={
																child_menu.label
															}
															className="childmenu p-0 sidebar-list-item p-2.5 md:p-2.5 lg:p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white "
														>
															<Typography
																color="blue-gray"
																className="float-left ml-8 text-[14px] mr-auto font-bold text-[#47586f] dark:text-[#bfc6d2] font-[OpenSans-Regular]"
															>
																{
																	child_menu.label
																}
															</Typography>
														</ListItem>
													</NavLink>
												)
											)}
										</List>
									</AccordionBody>
								</Accordion>
							) : (
								<NavLink
									key={i}
									to={row.url}
									className={isActive ? 'active' : ''}
								>
									{row?.permission &&
										hasPermission(
											authUser?.userDetails,
											user_permissions,
											row?.permission
										) && (
											<div className="sidebar-list-item p-2.5 md:p-2.5 lg:p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
												<img
													src={row.image}
													alt="icons"
												/>
												<span className="text-[14px] ml-3 text-gray-200 font-bold">
													{row.label}
												</span>
											</div>
										)}
									{!row?.permission && (
										<div className="sidebar-list-item p-2.5 md:p-2.5 lg:p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
											<img src={row.image} alt="icons" />
											<span className="text-[14px] ml-3 text-gray-200 font-bold">
												{row.label}
											</span>
										</div>
									)}
								</NavLink>
							)}
						</React.Fragment>
					))}
				</List>
			</div>
		</>
	);
};

export default function TabComponent() {
	const dispatch = useDispatch();
	const [isActive, setIsActive] = useState(false);
	const authUser = useSelector((state) => state.auth);
	const [activeTab, setActiveTab] = useState('tab2');
	const [open, setOpen] = React.useState(0);
	const user_permissions = useSelector((state) => state.user.userPermissions);

	const handleOpen = (index) => {
		const newAccordionOpen = [...accordionOpen];
		newAccordionOpen[index] = !newAccordionOpen[index];
		setAccordionOpen(newAccordionOpen);
	};

	const [accordionOpen, setAccordionOpen] = useState(
		companyUserMenu.map(() => false) // Initialize all as closed
	);

	useEffect(() => {
		const tabItems = document.querySelectorAll('.tab-item');
		const tabPanes = document.querySelectorAll('.tab-pane');
		tabItems.forEach((item) => {
			item.addEventListener('click', () => {
				tabItems.forEach((tabItem) =>
					tabItem.classList.remove('active')
				);
				tabPanes.forEach((tabPane) => tabPane.classList.add('hidden'));
				const { tab } = item.dataset;
				item.classList.add('active');
				document.getElementById(tab).classList.remove('hidden');
			});
		});
	}, []);

	useEffect(() => {
		dispatch(getUserPermissions());
	}, [dispatch]);

	if (authUser?.userDetails?.is_superuser) {
		return generateMenuItem(
			superAdminMenu,
			isActive,
			accordionOpen,
			handleOpen,
			user_permissions,
			authUser
		);
	} else if (!authUser?.userDetails?.is_companyadmin) {
		return generateMenuItem(
			companyUserMenu,
			isActive,
			accordionOpen,
			handleOpen,
			user_permissions,
			authUser
		);
	}

	const tabTypesList = ['Personal', 'Admin'];
	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	return (
		<>
			<div className="tabs">
				<div className="top-tab-menu">
					<ul className="tab-menu flex mb-4 dark:bg-[#1A2844] bg-[#fff] text-block border border-solid border-transparent">
						{tabTypesList &&
							tabTypesList.map((m, i) => {
								return (
									<li
										key={i}
										className={`tab-item mr-2 dark:text-white text-black ${
											activeTab === `tab${i + 1}`
												? 'active'
												: ''
										}`}
										data-tab={`tab${i + 1}`}
										onClick={() =>
											handleTabChange(`tab${i + 1}`)
										}
									>
										{m}
									</li>
								);
							})}
					</ul>
				</div>
				<div className="tab-content mt-[24px]">
					<div id="tab1" className="tab-pane">
						<div className="menulist mt-3 ">
							{activeTab === 'tab1' &&
								generateMenuItem(
									companyUserMenu,
									isActive,
									accordionOpen,
									handleOpen,
									user_permissions,
									authUser
								)}
						</div>
					</div>
					<div id="tab2" className="tab-pane">
						<div className="menulist mt-3 ">
							{activeTab === 'tab2' &&
								generateMenuItem(
									companyAdminMenu,
									isActive,
									accordionOpen,
									handleOpen,
									user_permissions,
									authUser
								)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
