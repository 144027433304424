import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChart = () => {
	const options = {
		chart: {
			id: 'line-chart',
			type: 'line',
			toolbar: {
				show: false,
			},
		},
		xaxis: {
			categories: [
				'1',
				'2',
				'3',
				'4',
				'5',
				'6',
				'7',
				'8',
				'9',
				'10',
				'11',
				'12',
				'13',
				'14',
				'15',
				'16',
				'17',
				'18',
				'19',
				'20',
				'21',
				'22',
				'23',
				'24',
				'25',
				'26',
				'27',
				'28',
				'29',
				'30',
			],
			tick: false,
		},
		yaxis: {
			categories: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
			],
		},
		stroke: {
			width: 3,
		},
		colors: ['#D1B400', '#00A3FF'],
		legend: {
			show: false,
		},
		theme: {
			mode: 'light',
		},
	};

	const series = [
		{
			name: 'XP',
			data: [
				30, 40, 25, 50, 49, 60, 70, 91, 30, 40, 25, 50, 49, 60, 70, 91,
				30, 40, 25, 50, 49, 60, 70, 91, 30, 40, 25, 50, 49, 60,
			],
		},
		{
			name: 'Level',
			data: [
				23, 12, 54, 61, 32, 56, 81, 99, 23, 12, 54, 61, 32, 56, 81, 99,
				23, 12, 54, 61, 32, 56, 81, 99, 23, 12, 54, 61, 32, 56,
			],
		},
	];
	const customCSS = `
    .apexcharts-legend-series {
      text-align: left !important;
    }
    .apexcharts-legend-text {
      font-size: 18px !important;
      line-height: 30px;
    }
    .apexcharts-pie-series path {
      stroke:transparent !important;
    }
    .apexcharts-inner .apexcharts-xaxis-tick {
      display: none;
    }
  `;

	return (
		<>
			<div className="mb-[39px] p-[21px] pr-[15px] mt-6 lg:mt-0 rounded-[15px]  shadow-none dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] dark:bg-[#17243e] bg-[#f2f2f2]">
				<div className="flex items-center justify-between  ml-[15px]">
					<p className=" text-[19px] font-semibold leading-[25px]">
						<button className="text-[19px] bg-[#00A3FF] rounded-[10px] text-white mr-[10px] py-[14px] px-[21px]">
							XP
						</button>
						<button className="text-[19px] bg-[#D1B400] rounded-[10px] text-white mr-[10px] py-[14px] px-[21px]">
							Level
						</button>
					</p>
					<i className="fa fa-angle-down text-[#BFC6D2] text-[22px]"></i>
				</div>
				<ReactApexChart
					options={options}
					series={series}
					type="line"
					height={300}
				/>
			</div>
			<style>{customCSS}</style>
		</>
	);
};

export default LineChart;
