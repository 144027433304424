import React from 'react';

import Ellipse26 from '../../assets/images/Ellipse-26.png';
import Ellipse27 from '../../assets/images/Ellipse-27.png';

function Leaderboard() {
	const rankuserhead = ['Name', 'Title', 'Department', 'xp'];
	const rankuserbody = [
		{
			no: '01.',
			img: Ellipse26,
			name: 'Mary San',
			title: 'Communication',
			department: 'Network',
			xp: '105',
		},
		{
			no: '01',
			img: Ellipse26,
			name: 'James Smith',
			title: 'UI/UX Designer',
			department: 'Design',
			xp: '99',
		},
		{
			no: '01',
			img: Ellipse27,
			name: 'Sanjon',
			title: 'React',
			department: 'Developer',
			xp: '85',
		},
		{
			no: '01',
			img: Ellipse27,
			name: 'Jon Doe',
			title: 'Motion Designer',
			department: 'Motion',
			xp: '110',
		},
	];

	const futureroleshead = ['Name', 'xp'];
	const futurerolesbody = [
		{
			no: '01.',
			img: Ellipse26,
			name: 'Mary San',
			designation: 'Lead Designer',
			xp: '105',
		},
		{
			no: '01.',
			img: Ellipse26,
			name: 'Mary San',
			designation: 'Lead Designer',
			xp: '105',
		},
		{
			no: '01.',
			img: Ellipse26,
			name: 'Mary San',
			designation: 'Lead Designer',
			xp: '105',
		},
	];
	return (
		<>
			<div className="md:my-[38px] my-[20px] mx-[20px] relative">
				<div className="leaderboard mb-[30px] dark-dark-light-card text-left pl-[17px] pr-[10px] h-[62px] flex justify-between items-center">
					<span className="text-base dark:text-[#fff] text-[#0C1932] font-normal leading-[21px]">
						Leaderboard
					</span>
					<div className="filter h-[44px] w-[44px] flex items-center justify-center cursor-pointer rounded-[8px]">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="34"
							height="34"
							viewBox="0 0 34 34"
							fill="none"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M12.3006 8.959C12.3006 11.5257 10.1847 13.606 7.5722 13.606C4.96124 13.606 2.84375 11.5257 2.84375 8.959C2.84375 6.39383 4.96124 4.31348 7.5722 4.31348C10.1847 4.31348 12.3006 6.39383 12.3006 8.959ZM28.2721 6.92367C29.4149 6.92367 30.3438 7.83627 30.3438 8.959C30.3438 10.0832 29.4149 10.9958 28.2721 10.9958H19.2308C18.0865 10.9958 17.1576 10.0832 17.1576 8.959C17.1576 7.83627 18.0865 6.92367 19.2308 6.92367H28.2721ZM4.91693 22.1307H13.9583C15.1026 22.1307 16.0315 23.0433 16.0315 24.1675C16.0315 25.2902 15.1026 26.2043 13.9583 26.2043H4.91693C3.77263 26.2043 2.84375 25.2902 2.84375 24.1675C2.84375 23.0433 3.77263 22.1307 4.91693 22.1307ZM25.6153 28.7579C28.2278 28.7579 30.3438 26.6776 30.3438 24.1124C30.3438 21.5457 28.2278 19.4654 25.6153 19.4654C23.0043 19.4654 20.8869 21.5457 20.8869 24.1124C20.8869 26.6776 23.0043 28.7579 25.6153 28.7579Z"
								fill="white"
							></path>
						</svg>
					</div>
				</div>
			</div>
			<div className="mb-[42px] mx-[20px]">
				<h2 className="card-title dark:text-white text-[#4b5563]">
					Ranking Users
				</h2>
				<div className="dark-dark-light-card ranking-users p-4 overflow-auto">
					<table className="w-full">
						<thead>
							<tr className="dark:bg-[#2B3C5E] bg-[#f7f7f7] ">
								{rankuserhead.map((title, index) => (
									<th
										key={index}
										className={`px-3 py-4 text-[16px] font-normal leading-4 dark:text-[#BFC6D2] text-[#54565C] ${
											index === 0 ? 'text-left' : ''
										}`}
									>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{rankuserbody.map((rank, i) => (
								<tr key={i}>
									<td className="py-[15px] border-b border-solid dark:border-b-[#213252] text-left">
										<div className="inline-flex flex-wrap items-center">
											<span className="number text-sm font-bold leading-[18px] dark:text-[#fff] text-[#BFC6D2]">
												{rank.no}
											</span>
											<img
												src={rank.img}
												alt="img"
												className="mx-[14px] w-[40px] h-[40px]"
											/>
											<h4 className="text-base dark:text-[#fff] text-[#0C1932] font-semibold leading-[21px] text-left ">
												{rank.name}
											</h4>
										</div>
									</td>
									<td className="py-[15px] border-b border-solid dark:border-b-[#213252]">
										<h4 className="text-base dark:text-[#fff] text-[#0C1932] font-semibold leading-[21px]">
											{rank.title}
										</h4>
									</td>
									<td className="py-[15px] border-b border-solid dark:border-b-[#213252]">
										<h4 className="text-base dark:text-[#fff] text-[#0C1932] font-semibold leading-[21px]">
											{rank.department}
										</h4>
									</td>
									<td className="py-[15px] border-b border-solid dark:border-b-[#213252]">
										<h4 className="text-base dark:text-[#fff] text-[#0C1932] font-semibold leading-[21px]">
											{rank.xp}
										</h4>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<div className="mx-[20px]">
				<h2 className="card-title dark:text-white text-[#4b5563]">
					Top Ten Future Roles
				</h2>
				<div className="dark-dark-light-card ranking-users future-role p-4 overflow-auto">
					<div className="md:grid block grid-cols-3 gap-5">
						<table className="w-full">
							<thead>
								<tr className="dark:bg-[#2B3C5E] bg-[#f7f7f7] px-2">
									{futureroleshead.map((title, index) => (
										<th
											key={index}
											className={`px-2 py-4 text-[16px] font-normal leading-4 dark:text-[#BFC6D2] text-[#54565C] ${
												index === 0 ? 'text-left' : ''
											}`}
										>
											{title}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{futurerolesbody.map((rank, i) => (
									<tr key={i}>
										<td className="py-[15px] border-b border-solid dark:border-b-[#213252] text-left">
											<div className="inline-flex flex-wrap items-center">
												<span className="number text-sm font-bold leading-[18px] dark:text-[#fff] text-[#BFC6D2]">
													{rank.no}
												</span>
												<img
													src={rank.img}
													alt="img"
													className="w-[40px] h-[40px] mx-[14px]"
												/>
												<div className="ml-3">
													<h6 className="mb-0 font-bold dark:text-white text-[#4b5563]">
														{rank.name}
													</h6>
													<span className="text-xs dark:text-white text-[#4b5563]">
														{rank.designation}
													</span>
												</div>
											</div>
										</td>
										<td className="py-[15px] border-b border-solid dark:border-b-[#213252]">
											<h4 className="text-base dark:text-[#fff] text-[#0C1932] font-semibold leading-[21px]">
												{rank.xp}
											</h4>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<table className="w-full">
							<thead>
								<tr className="dark:bg-[#2B3C5E] bg-[#f7f7f7] ">
									{futureroleshead.map((title, index) => (
										<th
											key={index}
											className={`px-2 py-4 text-[16px] font-normal leading-4 dark:text-[#BFC6D2] text-[#54565C] ${
												index === 0 ? 'text-left' : ''
											}`}
										>
											{title}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{futurerolesbody.map((rank, i) => (
									<tr key={i}>
										<td className="py-[15px] border-b border-solid dark:border-b-[#213252] text-left">
											<div className="inline-flex flex-wrap items-center">
												<span className="number text-sm font-bold leading-[18px] dark:text-[#fff] text-[#BFC6D2]">
													{rank.no}
												</span>
												<img
													src={rank.img}
													alt="img"
													className="w-[40px] h-[40px] mx-[14px]"
												/>
												<div className="ml-3">
													<h6 className="mb-0 font-bold dark:text-white text-[#4b5563]">
														{rank.name}
													</h6>
													<span className="text-xs dark:text-white text-[#4b5563]">
														{rank.designation}
													</span>
												</div>
											</div>
										</td>
										<td className="py-[15px] border-b border-solid dark:border-b-[#213252]">
											<h4 className="text-base dark:text-[#fff] text-[#0C1932] font-semibold leading-[21px]">
												{rank.xp}
											</h4>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<table className="w-full">
							<thead>
								<tr className="dark:bg-[#2B3C5E] bg-[#f7f7f7]">
									{futureroleshead.map((title, index) => (
										<th
											key={index}
											className={`px-2 py-4 text-[16px] font-normal leading-4 dark:text-[#BFC6D2] text-[#54565C] ${
												index === 0 ? 'text-left' : ''
											}`}
										>
											{title}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{futurerolesbody.map((rank, i) => (
									<tr key={i}>
										<td className="py-[15px] border-b border-solid dark:border-b-[#213252] text-left">
											<div className="inline-flex flex-wrap items-center">
												<span className="number text-sm font-bold leading-[18px] dark:text-[#fff] text-[#BFC6D2]">
													{rank.no}
												</span>
												<img
													src={rank.img}
													alt="img"
													className="w-[40px] h-[40px] mx-[14px]"
												/>
												<div className="ml-3">
													<h6 className="mb-0 font-bold dark:text-white text-[#4b5563]">
														{rank.name}
													</h6>
													<span className="text-xs dark:text-white text-[#4b5563]">
														{rank.designation}
													</span>
												</div>
											</div>
										</td>
										<td className="py-[15px] border-b border-solid dark:border-b-[#213252]">
											<h4 className="text-base dark:text-[#fff] text-[#0C1932] font-semibold leading-[21px]">
												{rank.xp}
											</h4>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}

export default Leaderboard;
