import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	userList: [],
	userRoleList: [],
	userData: null,
	userPermissions: [],
	userTimeTracking: [],
	userTimeTrackingLists: [],
	statisticsTracking: [],
	profileData: null,
	isFetching: false,
	totalPages: 0,
	totalRecords: 0,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			userList: action.payload?.results,
		}),
		setUserRolesData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			userRoleList: action.payload?.results,
		}),
		setUserProfileData: (state, action) => ({
			...state,
			isFetching: false,
			userData: action.payload,
		}),
		setProfileData: (state, action) => ({
			...state,
			isFetching: false,
			profileData: action.payload,
		}),
		setUserPermissions: (state, action) => ({
			...state,
			isFetching: false,
			userPermissions: action.payload,
		}),
		setFetching: (state, action) => ({
			...state,
			isFetching: action.payload,
			userList: [],
			userRoleList: [],
			userData: null,
			profileData: null,
		}),
		setUserTimeTrackingData: (state, action) => ({
			...state,
			isFetching: false,
			userTimeTracking: action.payload,
		}),
		setStatisticsTrackingData: (state, action) => ({
			...state,
			isFetching: false,
			statisticsTracking: action.payload,
		}),
		setuserTimeTrackingListsData: (state, action) => ({
			...state,
			isFetching: false,
			userTimeTrackingLists: action.payload,
		}),
	},
});

export const {
	setUserData,
	setUserRolesData,
	setUserProfileData,
	setProfileData,
	setFetching,
	setUserPermissions,
	setUserTimeTrackingData,
	setuserTimeTrackingListsData,
	setStatisticsTrackingData,
} = userSlice.actions;
export default userSlice.reducer;
