import React from 'react';
import InputComponent from '../../component/ui-component/InputComponent';
import Button from '../../component/ui-component/Button';
import { vsmClient } from '../../utils/validations';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	setValue,
	setUploadedFile,
	existingProfileImage,
}) => {
	const handleFileChange = (file) => {
		const selectedFile = file.target.files[0];
		setUploadedFile(selectedFile);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
							Client Information
						</h3>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
						<InputComponent
							id="first_name"
							title="First Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmClient}
						/>
						<InputComponent
							id="middle_name"
							title="Middle Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmClient}
						/>
						<InputComponent
							id="last_name"
							title="Last Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmClient}
						/>
						<InputComponent
							id="company_name"
							title="Company Name"
							errors={errors}
							register={register}
							fieldvalidation={vsmClient}
						/>
						<InputComponent
							id="email"
							title="Email"
							errors={errors}
							register={register}
							fieldvalidation={vsmClient}
						/>
						<InputComponent
							id="phone_number"
							title="Phone Number"
							errors={errors}
							register={register}
							fieldvalidation={vsmClient}
						/>
						<InputComponent
							id="position"
							title="Position"
							errors={errors}
							register={register}
							fieldvalidation={vsmClient}
						/>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-1 gap-2 mt-[15px] textareaComponent">
						<InputComponent
							id="profile_pic"
							inputType={'file'}
							title="Profile Pic"
							errors={errors}
							register={register}
							fieldvalidation={vsmClient}
							onChange={handleFileChange}
							existingProfileImage={existingProfileImage}
						/>
					</div>
				</div>
			</div>
			<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<Button
					buttonText={`${isUpdate ? 'Update' : 'Create'} Client`}
					linkTo="#"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default FormComponent;
