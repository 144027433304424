import React from 'react';
import ProgressBar from '../../../component/ui-component/ProgressBar';
import Toggle from '../../../assets/images/dots.png';

export default function MySkills() {
	const list = [
		{
			skill: 'Graphics Design',
			percantage: '55%',
			mainColor: '#2f4b0f',

			shadow1: '#00ab58',
			shadow2: '#00761b',

			shadow3: '#000',

			color2: '#3aaa35',
			color1: '#9de800',

			bgColor: '#3aaa35',
			bgColor2: '#c4e6c3',
			bgColor3: '#fff',
		},
		{
			skill: 'HTML / CSS',
			percantage: '55%',
			mainColor: '#073a56',

			shadow1: '#0070a7',
			shadow2: '#004773',

			shadow3: '#000',

			color2: '#3f576e',
			color1: '#99d1f2',

			bgColor: '#4d5e6e',
			bgColor2: '#c3c9ce',
			bgColor3: '#fff',
		},
		{
			skill: 'Animation',
			percantage: '55%',
			mainColor: '#412d71',

			shadow1: '#8456f9',
			shadow2: '#482c90',

			shadow3: '#8e37e2',

			color2: '#4f309f',
			color1: '#804dff',

			bgColor: '#b37bda',
			bgColor2: '#dcc3ea',
			bgColor3: '#fff',
		},
		{
			skill: 'UI/UX Design',
			percantage: '95%',
			mainColor: '#674306',
			shadow1: '#AA622E',
			shadow2: '#753507',
			shadow3: '#cc925a',
			bgColor: '#cc925a',
			bgColor2: '#ecd7c3',
			bgColor3: '#fff',
			color1: '#ffc800',
			color2: '#ff8c00',
		},
	];

	return (
		<div className="gap-[38px] md:mt-[38px] mt-[20px] md:mb-0 mb-[20px]">
			<div className="expert-grid p-[19px] rounded-[15px] dark-dark-light-card  relative">
				<div className=" flex justify-between items-center  mb-6">
					<h1 className="text-[19px] font-bold leading-[25px] font-family-OpenSans dark:text-[#fff] text-[#374151]">
						My Skills
					</h1>
					<span className="absolute text-[#fff] top-5 right-5 cursor-pointer">
						<img src={Toggle} alt="dot" className="dot-toggle" />
					</span>
				</div>
				<div className="md:grid xs:block grid-cols-2 gap-x-[42px] gap-y-[25px]">
					{list.map((row, i) => (
						<div key={i} className="">
							<div className="flex items-center justify-between py-[10px]">
								<p className="dark:text-[#fff] text-[#374151] text-sm leading-[18px] font-family-OpenSans">
									{row.skill}
								</p>
								<span className="dark:text-[#fff] text-[#374151] text-right text-sm leading-[18px] font-family-OpenSans">
									{row.percantage}
								</span>
							</div>
							<ProgressBar
								progress={row.percantage}
								mainColor={row.mainColor}
								shadow1={row.shadow1}
								shadow2={row.shadow2}
								shadow3={row.shadow3}
								bgColor={row.bgColor}
								bgColor2={row.bgColor2}
								bgColor3={row.bgColor3}
								color1={row.color1}
								color2={row.color2}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
