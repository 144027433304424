import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from '../../../assets/images/dots.png';
import AddMeetingComponent from './AddMeetingComponent';
import {
	getAllMeetingList,
	removeMeeting,
} from '../../../store/thunk/meetingThunk';
import { useRef } from 'react';
import EditMeetingComponent from './EditMeetingComponent';
import Dialogpopup from '../../../component/ui-component/Dailogpopup';

// Define the month names
const month_names = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export default function Meetings() {
	const dispatch = useDispatch();
	const ref = useRef(null);
	const [addDrawerVisible, setAddDrawerVisible] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [editValues, setEditValues] = useState(null);

	const meetingsLists = useSelector((state) => state.meetings?.meetingList);

	const [isOpenArray, setIsOpenArray] = useState(
		meetingsLists.map(() => false)
	);

	const [openRowIndex, setOpenRowIndex] = useState(null);

	const handleSetEditValues = (item) => {
		setEditValues(item);
		setEditDrawerVisible(true);
		setIsOpenArray(meetingsLists.map(() => false));
	};

	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
		setIsOpenArray(meetingsLists.map(() => false));
	};

	useEffect(() => {
		dispatch(getAllMeetingList());
	}, [dispatch]);

	const toggleDiv = (index) => {
		const updatedIsOpenArray = [...isOpenArray];
		updatedIsOpenArray[index] = !updatedIsOpenArray[index];
		if (openRowIndex !== null && openRowIndex !== index) {
			updatedIsOpenArray[openRowIndex] = false;
		}

		setIsOpenArray(updatedIsOpenArray);
		setOpenRowIndex(index);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setIsOpenArray(meetingsLists.map(() => false));
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [isOpenArray, meetingsLists]);

	return (
		<>
			<div className="flex items-center justify-between mb-[20px] h-[40px] md:mt-0 mt-[20px]">
				<h1 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7">
					Meetings
				</h1>
				<button
					className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full p-[10px] px-5 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
					onClick={() => setAddDrawerVisible(true)}
				>
					+ Create Meeting
				</button>
			</div>
			{addDrawerVisible && (
				<AddMeetingComponent
					visible={addDrawerVisible}
					onClose={() => setAddDrawerVisible(false)}
				/>
			)}
			{editDrawerVisible && editValues && (
				<EditMeetingComponent
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
				/>
			)}
			{deleteDailogVisible && (
				<>
					<Dialogpopup
						onCancel={() => setDeleteDailogVisible(false)}
						deleteItem={editValues}
						removeThunkCall={removeMeeting}
						message="Are you sure you want to delete this meetings ?"
					/>
				</>
			)}

			<div className="p-[21.84px] pr-[10px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] rounded-[15px]">
				<div className="h-[271.81px] overflow-auto custom-scroll pr-[12.27px]">
					{meetingsLists.map((row, index) => {
						const meeting_date = new Date(row?.meeting_date);
						return (
							<div
								key={row.id}
								className={`flex items-center text-left pb-[23.96px] dark:border-b-[#273758] border-b-[#dbdbdb] border-b ${
									index === meetingsLists.length - 1
										? 'mb-[0]'
										: ''
								} border-solid mb-[23.96px]`}
							>
								<div className="p-[16px] text-center text-white rounded-[10px] w-[90.808px] h-[92.784px] bg-button-gradiant2">
									<p className="text-lg leading-[18px]">
										{month_names[meeting_date.getMonth()]}
									</p>
									<h1 className="text-[42px] font-extrabold leading-[42px] font-family-Montserrat-bold mt-1">
										{String(
											meeting_date.getDate()
										).padStart(2, '0')}
									</h1>
								</div>
								<div className="ml-[20.81px] w-[80%]">
									<p className="dark:text-white text-black text-[20px] leading-[25px] mb-[10px]">
										{row?.title}
									</p>
									<span className="dark:text-[#BFC6D2] text-[16px] leading-[22px] w-[18%]">
										{row?.from_time} - {row?.to_time}
									</span>
								</div>
								{/* <img src={Toggle} alt="dot" className="dot-toggle" /> */}
								<div>
									<div className="cursor-pointer relative list-item-block">
										<img
											src={Toggle}
											alt="dot"
											className="dot-toggle"
											onClick={() => toggleDiv(index)}
										/>
										{isOpenArray[index] && (
											<div ref={ref}>
												<div className="menuDropdown absolute w-[160px] right-[0px] mt-[10px]">
													<ul>
														<li
															className="dark:text-white text-[#47586f] text-left flex items-center cursor-pointer"
															onClick={() =>
																handleSetEditValues(
																	row
																)
															}
														>
															<div className="p-2.5 block w-full">
																<i className="mr-3 fas fa-pencil text-[14px]"></i>
																Edit
															</div>
														</li>
														<li
															className="dark:text-white text-[#47586f] text-left flex items-center cursor-pointer"
															onClick={() => {
																handleDeleteClick(
																	row
																);
																toggleDiv(
																	index
																);
															}}
														>
															<div className="p-2.5 block w-full">
																<i className="mr-3 fas fa-trash text-[14px]"></i>
																Delete
															</div>
														</li>
													</ul>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}
