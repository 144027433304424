import { Routes, Route, BrowserRouter } from 'react-router-dom';
import LayoutComponent from '../component/LayoutComponent';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Unauthorized from '../pages/commonpage/Unauthorized';
import PageNotFound from '../pages/commonpage/PageNotFound';
import DashBoard from '../pages/DashBoard';
import SkillComponent from '../pages/Skills';
import UserManagement from '../pages/UserManagement';
import AdminPanel from '../pages/AdminPanel';
import CompanyComponent from '../pages/Company';
import ProfilePage from '../pages/ProfilePage';
import SettingPage from '../pages/SettingPage';
import Earning from '../pages/Earning/Earning';
import Career from '../pages/Career/Career';
import Submission from '../pages/Submission/Submission';
import Leaderboard from '../pages/Leaderboard/Leaderboard';
import HelpCenter from '../pages/HelpCenter/HelpCenter';
import TaskManagement from '../pages/TaskManagement';
import Reporting from '../pages/Reporting';
import ContentManagement from '../pages/ContentManagement';
import ReviewHistory from '../pages/ReviewHistory';
import Checkpoints from '../pages/Checkpoints';
import SystemSetting from '../pages/SystemSetting';
import Departments from '../pages/Departments';
import Roles from '../pages/Roles';
import CompanySkills from '../pages/CompanySkills';
import CompanyProfile from '../pages/CompanyProfile';
import LeavesManagementComponent from '../pages/LeaveManagement';
import UserLeaveManagementComponent from '../pages/UserLeaveManagement';
import NotificationActivities from '../component/header/NotificationActivities';
import ViewUserComponent from '../pages/UserManagement/ViewUserComponent';
import ViewProjectComponent from '../pages/Projects/ViewProjectComponent';
import ProjectsComponent from '../pages/Projects';
import HolidaysComponent from '../pages/Holidays';
import ClientsComponent from '../pages/Clients';
import ViewClientComponent from '../pages/Clients/ViewClientComponent';
import EventsComponent from '../pages/Events';
import AttendanceComponent from '../pages/Attendance';
import RecruitmentComponent from '../pages/Recruitment/Recruitment';
import JobsComponent from '../pages/Recruitment/Jobs';
import UserAwayComponent from '../pages/LeaveManagement/UserAwayComponent';
import XPEarningComponent from '../pages/XpEarning';
import LinkedinComponent from '../pages/Recruitment/Linkedin';

function UserRoutes() {
	// Define an array to store the routes and loop through it to create the routes
	const routes = [
		{
			path: '/',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<DashBoard />}
							pageTitle={'Dashboard'}
						/>
					}
				/>
			),
		},
		{
			path: '/dashboard',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<DashBoard />}
							pageTitle={'Dashboard'}
						/>
					}
				/>
			),
		},
		{
			path: '/projects',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<ProjectsComponent />}
							pageTitle={'Projects'}
						/>
					}
				/>
			),
		},
		{
			path: '/clients',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<ClientsComponent />}
							pageTitle={'Clients'}
						/>
					}
				/>
			),
		},
		{
			path: '/skills',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<SkillComponent />}
							pageTitle={'Skills'}
						/>
					}
				/>
			),
		},
		{
			path: '/career',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<Career />}
							pageTitle={'Career'}
						/>
					}
				/>
			),
		},
		{
			path: '/profile',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<ProfilePage />}
							pageTitle={'Profile'}
						/>
					}
				/>
			),
		},
		{
			path: '/activities',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<NotificationActivities />}
							pageTitle={'Profile'}
						/>
					}
				/>
			),
		},

		{
			path: '/earning',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<Earning />}
							pageTitle={'XP Earning Activity'}
						/>
					}
				/>
			),
		},
		{
			path: '/submission',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<Submission />}
							pageTitle={'Activity Submission'}
						/>
					}
				/>
			),
		},
		{
			path: '/leaderboard',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<Leaderboard />}
							pageTitle={'Leaderboard'}
						/>
					}
				/>
			),
		},
		{
			path: '/review-history',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<ReviewHistory />}
							pageTitle={'Review History'}
						/>
					}
				/>
			),
		},
		{
			path: '/checkpoints',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<Checkpoints />}
							pageTitle={'Checkpoint'}
						/>
					}
				/>
			),
		},
		{
			path: '/setting',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<SettingPage />}
							pageTitle={'Settings'}
						/>
					}
				/>
			),
		},
		{
			path: '/system-setting',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<SystemSetting />}
							pageTitle={'Settings'}
						/>
					}
				/>
			),
		},
		{
			path: '/users',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<UserManagement />}
							pageTitle={'Users Management'}
						/>
					}
				/>
			),
		},
		{
			path: '/cskills',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<CompanySkills />}
							pageTitle={'Skills'}
						/>
					}
				/>
			),
		},
		{
			path: '/xpearning',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<XPEarningComponent />}
							pageTitle={'Xp Earning Master'}
						/>
					}
				/>
			),
		},
		{
			path: '/cprofile',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<CompanyProfile />}
							pageTitle={'Profile'}
						/>
					}
				/>
			),
		},
		{
			path: '/departments',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<Departments />}
							pageTitle={'Departments'}
						/>
					}
				/>
			),
		},
		{
			path: '/roles',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<Roles />}
							pageTitle={'Roles'}
						/>
					}
				/>
			),
		},
		{
			path: '/task-management',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<TaskManagement />}
							pageTitle={'Task Management'}
						/>
					}
				/>
			),
		},
		{
			path: '/projects',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<ProjectsComponent />}
							pageTitle={'Projects'}
						/>
					}
				/>
			),
		},
		{
			path: '/reporting',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<Reporting />}
							pageTitle={'Reporting'}
						/>
					}
				/>
			),
		},
		{
			path: '/content',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<ContentManagement />}
							pageTitle={'Content Management'}
						/>
					}
				/>
			),
		},
		{
			path: '/events',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<EventsComponent />}
							pageTitle={'Events'}
						/>
					}
				/>
			),
		},
		{
			path: '/help-center',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<HelpCenter />}
							pageTitle={'Help & Support'}
						/>
					}
				/>
			),
		},
		{
			path: '/admin-panel',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<AdminPanel />}
							pageTitle={'Admin Panel'}
						/>
					}
				/>
			),
		},
		{
			path: '/companies',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<CompanyComponent />}
							pageTitle={'Company Management'}
						/>
					}
				/>
			),
		},
		{
			path: '/leaves-management',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<LeavesManagementComponent />}
							pageTitle={'Leaves Management'}
						/>
					}
				/>
			),
		},
		{
			path: '/users-away',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<UserAwayComponent />}
							pageTitle={"Who's away"}
						/>
					}
				/>
			),
		},
		{
			path: '/leaves',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<UserLeaveManagementComponent />}
							pageTitle={'Leaves Requests'}
						/>
					}
				/>
			),
		},
		{
			path: '/holidays',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<HolidaysComponent />}
							pageTitle={'Holidays'}
						/>
					}
				/>
			),
		},
		{
			path: '/attendance',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<AttendanceComponent />}
							pageTitle={'Attendance'}
						/>
					}
				/>
			),
		},
		{
			path: '/interviews',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<RecruitmentComponent />}
							pageTitle={'Interviews'}
						/>
					}
				/>
			),
		},
		{
			path: '/jobs',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<JobsComponent />}
							pageTitle={'Jobs'}
						/>
					}
				/>
			),
		},
		{
			path: '/linkedin',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<LinkedinComponent />}
							pageTitle={'Linkedin Connections'}
						/>
					}
				/>
			),
		},
		{
			path: '/users/:id',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<ViewUserComponent />}
							pageTitle={'User View'}
						/>
					}
				/>
			),
		},
		{
			path: '/projects/:id',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<ViewProjectComponent />}
							pageTitle={'Project View'}
						/>
					}
				/>
			),
		},
		{
			path: '/clients/:id',
			element: (
				<PrivateRoute
					component={
						<LayoutComponent
							component={<ViewClientComponent />}
							pageTitle={'Client View'}
						/>
					}
				/>
			),
		},

		{ path: '/login', element: <PublicRoute component={<Login />} /> },
		{
			path: '/forgot-password',
			element: <PublicRoute component={<ForgotPassword />} />,
		},
		{
			path: '/reset-password/:uid/:token',
			element: <PublicRoute component={<ResetPassword />} />,
		},

		{ path: '/page-not-found', element: <PageNotFound /> },
		{ path: '*', element: <Unauthorized /> },
	];

	return (
		<BrowserRouter>
			<Routes>
				{routes.map((route, index) => (
					<Route
						key={index}
						path={route.path}
						element={route.element}
					/>
				))}
			</Routes>
		</BrowserRouter>
	);
}

export default UserRoutes;
