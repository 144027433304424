import React, { useEffect } from 'react';
import InputComponent from '../../component/ui-component/InputComponent';
import Button from '../../component/ui-component/Button';
import SelectComponent from '../../component/ui-component/SelectComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryList } from '../../store/thunk/commonThunk';
import { vsmCompany } from '../../utils/validations';
import DatePickerComponent from '../../component/ui-component/DatePickerComponent';
import CKEditorComponent from '../../component/ui-component/CKEditorComponent';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	selectedCountry,
	setValue,
	description,
	setUploadedFile,
	existingProfileImage,
	isCompanyInformation,
}) => {
	const dispatch = useDispatch();
	const countryList = useSelector((state) => state.common.countryList);

	useEffect(() => {
		dispatch(getCountryList());
	}, [dispatch]);

	const onChangeSelectValue = (key, selectedValue) => {
		setValue(key, selectedValue);
	};

	const handleEditorChange = (id, content) => {
		setValue(id, content);
	};

	const handleFileChange = (file) => {
		const selectedFile = file.target.files[0];
		setUploadedFile(selectedFile);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				{isCompanyInformation && (
					<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-6 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
						<h2 className="dark:text-white text-[#4b5563] font-bold text-[16px] text-left pb-[20px] text-[18px]">
							Company Information
						</h2>
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
							<InputComponent
								id="company_name"
								title="Company Name"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="company_address"
								title="Company Address"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="company_city"
								title="Company City"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="company_postal_code"
								title="Company Postal Code"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="company_email"
								title="Company Email"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="company_contact_no"
								title="Company Contact No"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="company_website"
								title="Company Website"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="company_linkedin"
								title="Company Linkedin Profile"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="employee_count"
								title="Total Employees"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="company_industry_type"
								title="Company Industry Type"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="company_taxid_number"
								title="Company Tax ID Number"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="registration_number"
								title="Registration Number"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<DatePickerComponent
								id="company_filling_date"
								title="Company Filling Date"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<SelectComponent
								selectOptionLists={countryList}
								onChangeSelectValue={onChangeSelectValue}
								selectedValue={selectedCountry}
								title="Company Country of Origin"
								id="company_country"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
						</div>
						<div className="grid grid-cols-1 lg:grid-cols-1 gap-2 mt-[15px] textareaComponent">
							{/* <TextareaComponent
							id="company_descriptions"
							title="Company Description"
							errors={errors}
							register={register}
							fieldvalidation={vsmCompany}
						/> */}
							<CKEditorComponent
								id="company_descriptions"
								title="Company Description"
								data={description}
								onChange={handleEditorChange}
							/>
						</div>
					</div>
				)}

				{!isCompanyInformation && (
					<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-6 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
						<h2 className="dark:text-white text-[#4b5563] font-bold text-[16px] text-left pb-[20px] text-[18px]">
							User Information
						</h2>
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-[20px]">
							<InputComponent
								id="first_name"
								title="First Name"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="middle_name"
								title="Middle Name"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="last_name"
								title="Last Name"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="phone_number"
								title="Phone Number"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							<InputComponent
								id="email"
								title="Email Address"
								errors={errors}
								register={register}
								readOnly={isUpdate ? true : false}
								fieldvalidation={vsmCompany}
							/>
							<DatePickerComponent
								id="dob"
								title="Date of Birth"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
							/>
							{/* {!isUpdate && (
								<InputComponent
									id="password"
									inputType={"password"}
									title="Password"
									errors={errors}
									register={register}
								/>
							)} */}
							<InputComponent
								id="profile_image"
								inputType={'file'}
								title="Profile Pic"
								errors={errors}
								register={register}
								fieldvalidation={vsmCompany}
								onChange={handleFileChange}
								existingProfileImage={existingProfileImage}
							/>
						</div>
					</div>
				)}
			</div>
			<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<Button
					buttonText={`${isUpdate ? 'Update' : 'Create'} Profile`}
					linkTo="#"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default FormComponent;
