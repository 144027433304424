import React from 'react';
import InputComponent from '../../../component/ui-component/InputComponent';
import CKEditorComponent from '../../../component/ui-component/CKEditorComponent';
import Button from '../../../component/ui-component/Button';
import { vsmJobs } from '../../../utils/validations';

const FormComponent = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	isUpdate,
	description,
	requirements,
	setValue,
}) => {
	const handleEditorChange = (id, content) => {
		setValue(id, content);
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="p-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				<div className="dark:bg-[#12243d] bg-[#fafafa] rounded py-8 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1]">
					<div>
						<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
							Job Information
						</h3>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
						<InputComponent
							id="title"
							title="Job Title"
							errors={errors}
							register={register}
							fieldvalidation={vsmJobs}
						/>
						<CKEditorComponent
							id="description"
							title="Job Description"
							data={description}
							onChange={handleEditorChange}
						/>
						<CKEditorComponent
							id="requirements"
							title="Job Requirement"
							data={requirements}
							onChange={handleEditorChange}
						/>
					</div>
				</div>
			</div>
			<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<Button
					buttonText={`${isUpdate ? 'Update' : 'Create'} Job`}
					linkTo="#"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default FormComponent;
