import React from 'react';
import { useDispatch } from 'react-redux';

export default function Dialogpopup({
	onCancel,
	deleteItem,
	removeThunkCall,
	message,
}) {
	const dispatch = useDispatch();
	const onConfirm = () => {
		dispatch(removeThunkCall(deleteItem));
		onCancel();
	};

	return (
		<>
			<div
				id="modal"
				className="fixed inset-0 flex items-center justify-center z-[999] bg-[#ffffff75]"
			>
				<div className="p-6 rounded shadow-md bg-[#12243b] w-[450px]">
					<svg
						className="text-gray-400 dark:text-white w-11 h-11  mx-auto"
						aria-hidden="true"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
							clipRule="evenodd"
						></path>
					</svg>
					<p className="text-white my-5">{message}</p>
					<div>
						<button
							variant="text"
							onClick={onCancel}
							className="mx-2 py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-none hover:text-gray-900 focus:z-10 text-gray-700  dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:none"
						>
							No, Cancel
						</button>
						<button
							variant="gradient"
							onClick={onConfirm}
							className="mx-2 py-2 px-3 text-sm font-medium text-center text-white expert-btn rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
						>
							Yes, Delete it
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
