import React, { useEffect, useState, useCallback } from 'react';
import { Select, initTE } from 'tw-elements';
import Theme1 from '../../assets/images/theme1.png';
import Theme2 from '../../assets/images/theme2.png';
import Theme3 from '../../assets/images/theme3.png';
import Theme4 from '../../assets/images/theme4.png';
import Theme5 from '../../assets/images/theme5.png';
import Theme6 from '../../assets/images/theme6.png';
import Theme7 from '../../assets/images/theme7.png';
import Theme8 from '../../assets/images/theme8.png';
import Theme9 from '../../assets/images/theme9.png';
import BrandingLogo from '../../assets/images/Branding-logo.png';
import {
	getCompanyData,
	updateCompanyProfile,
} from '../../store/thunk/companyThunk';
import { useDispatch, useSelector } from 'react-redux';
import { getBase64 } from '../../component/ui-component/utils';

const data = [
	{
		image: Theme1,
	},
	{
		image: Theme2,
	},
	{
		image: Theme3,
	},
	{
		image: Theme4,
	},
	{
		image: Theme5,
	},
	{
		image: Theme6,
	},
	{
		image: Theme7,
	},
	{
		image: Theme8,
	},
	{
		image: Theme9,
	},
];

const fontOptions = ['Arial', 'Montserrat', 'Inter', 'Raleway'];
const clogo = [];

export default function Branding() {
	useEffect(() => {
		initTE({ Select });
	}, []);
	const dispatch = useDispatch();
	const company_data = useSelector((state) => state.company.companyData);
	const authUser = useSelector((state) => state.auth);

	const [image, setImage] = useState(BrandingLogo);
	const handleInputChange = useCallback(
		async (event) => {
			const base64 = await getBase64(event.target.files[0]);
			setImage(base64);

			clogo.uid = company_data?.uid;
			if (event.target.files[0] !== undefined) {
				clogo.company_logo = event.target.files[0];
			}
			clogo.is_update_logo = true;
			dispatch(updateCompanyProfile(clogo));
		},
		[dispatch, company_data?.uid]
	);

	useEffect(() => {
		dispatch(getCompanyData(authUser?.userDetails));
		if (company_data?.company_logo) {
			setImage(company_data?.company_logo);
		}
	}, [dispatch, authUser?.userDetails, company_data?.company_logo]);

	// ---------------------------------------------------------------------------------------------------------------

	const [isOverlayVisible, setIsOverlayVisible] = useState(
		new Array(data.length).fill(false)
	);
	const handleOverlayClick = (index) => {
		const updatedVisibility = isOverlayVisible.map(
			(value, i) => i === index
		);
		setIsOverlayVisible(updatedVisibility);
	};
	return (
		<div>
			<h2 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7 text-left md:pb-[25.92px] pb-[0px]">
				Branding
			</h2>
			<div className="md:grid block grid-cols-3 gap-5">
				<div className="md:h-[270.232px] h-auto md:mb-[39px] mb-[20px] lg:p-[21px] p-[10px] mt-6 lg:mt-0 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
					<h2 className="dark:text-white text-[#4b5563] font-bold leading-7 text-left pb-[25.92px]">
						Select The Theme
					</h2>
					<div className="flex flex-wrap gap-2 justify-between cursor-pointer">
						<div className="flex flex-wrap gap-2 justify-between cursor-pointer">
							{data.map((row, index) => (
								<div
									key={index}
									className={`theme-block w-[30%] relative ${
										isOverlayVisible[index]
											? 'selected'
											: ''
									}`}
									onClick={() => handleOverlayClick(index)}
								>
									<img
										src={row.image}
										alt="theme"
										className="w-full"
									/>
									{isOverlayVisible[index] && (
										<div className="theme-overlay absolute w-full h-full rounded-md top-0 bg-[#050a1463]">
											<i className="fa fa-check text-white flex items-center justify-center h-full"></i>
										</div>
									)}
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="md:h-[270.232px] h-auto mb-[39px] mt-6 lg:mt-0 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
					<h2 className="p-[21px] dark:text-white text-[#4b5563] font-bold leading-7 text-left pb-[25.92px]">
						Choose a Font
					</h2>
					<div className="expert-btn-new choose-setting-block">
						<select
							data-te-select-init
							className="text-white h-[42px] rounded-none"
						>
							{fontOptions.map((font, index) => (
								<option
									key={index}
									className="flex items-center gap-2 justify-between expert-btn-new"
								>
									<span className="font-black text-base">
										{font}
									</span>
									<i className="fas fa-check"></i>
								</option>
							))}
						</select>
					</div>
					{/* <div className="flex w-full flex-col gap-6 selectbox choose-font">
						<Select
							className="text-white h-[42px] expert-btn rounded-none"
							label=" "
						>
							{fontOptions.map((font, index) => (
								<Option
									key={index}
									className="flex items-center gap-2 justify-between"
								>
									<span className="font-black text-base">
										{font}
									</span>
									<i className="fas fa-check"></i>
								</Option>
							))}
						</Select>
					</div> */}
				</div>
				<div className="h-[270.232px] mb-[39px] lg:p-[21px] p-[10px] mt-6 lg:mt-0 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
					<div className="flex items-center h-full">
						<img
							src={image}
							alt="BrandingLogo"
							style={{ width: '150px' }}
						/>
						<div className="text-left pl-5">
							<p className="dark:text-white text-[#4b5563] text-l font-bold leading-[25px] pb-[21px]">
								Change The Logo
							</p>
							<button className="cursor-pointer expert-btn text-sm leading-[18px] font-semibold text-white rounded-lg p-[10px] relative">
								<input
									type="file"
									onChange={handleInputChange}
									style={{
										opacity: 0,
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
										cursor: 'pointer',
									}}
								/>
								Upload File
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
