import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllLeavesList } from '../../store/thunk/leavesThunk';
import ReactTablePagination from '../../component/ui-component/pagination/ReactTablePagination';
import StatusDropDown from '../../component/ui-component/StatusDropDown';
import { getDefaultProfile } from '../../component/Common';
import UserProfile from '../../component/ui-component/UserProfile';
import { hasPermission } from '../../component/Common';
const moment = require('moment');

const statusOptions = [
	{ value: 1, label: 'Pending', iconClass: 'text-sky-500' },
	{ value: 2, label: 'Approved', iconClass: 'text-green-500' },
	{ value: 3, label: 'Rejected', iconClass: 'text-red-500' },
	// { value: 4, label: "Processing", iconClass: "text-orange-500" },
	// { value: 5, label: "Withdrawn", iconClass: "text-yellow-700" },
];

const ListApprovalComponent = ({
	handleSetEditValues,
	handleDeleteClick,
	handleLeaveStatusChange,
	userData,
	userPermissions,
}) => {
	const [recordsPerPage, setRecordsPerPage] = useState(10);

	const dispatch = useDispatch();

	const approvedleavesData = useSelector((state) => state.leaves);

	const columns = [
		{
			Header: 'Employee',
			accessor: 'user.fullname',
			width: '16%',
			resize: true,
			Cell: ({ row, value }) => (
				<div className="flex">
					<div>
						<UserProfile
							imageUrl={row?.original?.user?.profile_image}
							defaultImageUrl={getDefaultProfile(
								row?.original?.user?.gender
							)}
							altName={row?.original?.user?.fullname}
							className={
								'mr-[13px] h-[40px] w-[40px] rounded-full'
							}
						/>
					</div>
					<span className="mt-[8px]">
						{row?.original?.user?.fullname}
					</span>
				</div>
			),
		},
		{
			Header: 'Leave Type',
			accessor: 'leave_type_name',
			width: '10%',
			resize: true,
		},
		{
			Header: 'From Date',
			accessor: 'start_date',
			width: '10%',
			resize: true,
			Cell: ({ row, value }) => {
				return (
					<>{moment(value, 'YYYY-MM-DD').format('DD MMM, YYYY')}</>
				);
			},
		},
		{
			Header: 'To Date',
			accessor: 'end_date',
			width: '10%',
			resize: true,
			Cell: ({ row, value }) => {
				return (
					<>{moment(value, 'YYYY-MM-DD').format('DD MMM, YYYY')}</>
				);
			},
		},
		{
			Header: 'No.Of Days',
			accessor: 'no_of_days',
			width: '10%',
			resize: true,
			Cell: ({ row, value }) => {
				return (
					<>{`${row?.original?.no_of_days} ${
						row?.original?.no_of_days > 1 ? 'days' : 'day'
					}`}</>
				);
			},
		},
		{
			Header: 'Reason',
			accessor: 'reason',
			width: '42%',
			resize: true,
			Cell: ({ row, value }) => {
				const reason = row?.original?.reason;
				const displayReason =
					reason.length > 120
						? `${reason.substring(0, 120)}...`
						: reason;
				return (
					<span
						className="noti-title"
						dangerouslySetInnerHTML={{ __html: displayReason }}
					></span>
				);
			},
		},
		{
			Header: 'Status',
			accessor: 'leave_status_name',
			width: '12%',
			resize: true,
			Cell: ({ row, value }) => (
				<>
					<StatusDropDown
						statusData={row?.original}
						statusOptions={statusOptions}
						selectedOptionsValue={row?.original?.leave_status}
						handleOnChange={handleLeaveStatusChange}
					/>
				</>
			),
		},
		{
			Header: 'Approved By',
			accessor: 'approved_by.fullname',
			width: '18%',
			resize: true,
			Cell: ({ row, value }) => {
				if (
					row?.original?.approved_by &&
					[2, 3].includes(row?.original?.leave_status)
				) {
					return (
						<div className="flex">
							<UserProfile
								imageUrl={
									row?.original?.approved_by?.profile_image
								}
								defaultImageUrl={getDefaultProfile(
									row?.original?.approved_by?.gender
								)}
								altName={row?.original?.approved_by?.fullname}
								className={
									'h-[35px] w-[35px] rounded-full float-left'
								}
							/>
							<div className="ml-3">
								<h6 className="mb-0 font-bold text-[15px]">
									{row?.original?.approved_by?.fullname}
								</h6>
								<p className="text-[10px]">
									{row?.original?.approved_by?.is_companyadmin
										? 'Company Admin'
										: row?.original?.approved_by
												?.designation}
								</p>
							</div>
						</div>
					);
				}
			},
		},
	];

	const fetchData = useCallback(
		(pageSize, pageIndex, searchText, sortBy) => {
			dispatch(
				getAllLeavesList({
					pageSize: pageSize,
					pageNo: pageIndex,
					searchText: searchText,
					sortBy: sortBy,
					approvalLeave: true,
				})
			);
		},
		[dispatch]
	);

	return (
		<>
			<ReactTablePagination
				columns={columns}
				data={approvedleavesData?.leavesList}
				fetchData={fetchData}
				pageCount={Math.ceil(
					approvedleavesData?.totalRecords / recordsPerPage
				)}
				totalRecords={approvedleavesData?.totalRecords}
				recordsPerPage={recordsPerPage}
				showContentClass={false}
				setRecordsPerPage={setRecordsPerPage}
				showCSVButton={hasPermission(
					userData,
					userPermissions,
					'export_userleaves'
				)}
				csvFileName="user_leave_lists.csv"
			/>
		</>
	);
};

export default ListApprovalComponent;
