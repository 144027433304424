import React from 'react';
import Expertimg from '../../../assets/images/b1.png';
import Button from '../../../component/ui-component/Button';
import CircleProgressBar from '../../../component/ui-component/CircleProgressBar';
import Toggle from '../../../assets/images/dots.png';

export default function AvailableVacations() {
	return (
		<div className=" md:grid xs:block grid-cols-2 gap-[38px]  px-5 h-full lg:mb-0 mb-[20px]">
			<div className="expert-grid relative dark-dark-light-card p-6 h-[280px]">
				<span className="absolute text-[#fff] top-5 right-5 cursor-pointer">
					<img src={Toggle} alt="dot" className="dot-toggle" />
				</span>
				<div className="img text-center mb-4">
					<img
						src={Expertimg}
						alt="img"
						className="inline-block w-24 h-24"
					/>
				</div>
				<h2 className="uppercase text-[22px] leading-7 font-bold mb-1 dark:text-[#fff] text-[#0C1932]">
					EXPERT
				</h2>
				<p className="text-sm leading-[18px] dark:text-[#BFC6D2] text-[#0C1932]">
					Congratulations
				</p>
				<div className="absolute w-full flex items-center justify-center h-10 left-[0px] bottom-4 ">
					<Button
						buttonText="Claim Achievement"
						linkTo="#"
						customValue="calc(100% - 20px)"
						className="px-0"
					/>
				</div>
			</div>
			<div className="expert-grid relative dark-dark-light-card p-6 h-[280px]">
				<span className="absolute text-[#fff] top-5 right-5 cursor-pointer">
					<img src={Toggle} alt="dot" className="dot-toggle" />
				</span>
				<CircleProgressBar
					progress={75}
					strokeWidth={8}
					size={120}
					color="#2E6FAA"
				/>
				<h2 className="leading-5 text-[15px] mb-1 dark:text-[#fff] text-[#0C1932] mt-2">
					Available Vacations
				</h2>
				<p className="text-sm dark:text-[#BFC6D2] text-[#0C1932] leading-[18px] mt-2">
					12 days of 20 days
				</p>
				<div className="md:absolute relative w-full flex items-center justify-center h-10 left-[0px] bottom-4 ">
					<Button
						buttonText="Request Vacation"
						linkTo="#"
						customValue="calc(100% - 20px)"
						className="px-0"
					/>
				</div>
			</div>
		</div>
	);
}
