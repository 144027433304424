import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	notificationSuccess,
	notificationFail,
} from '../slices/notificationSlice';
import { setLoading } from '../slices/dataLoadingSlice';
import {
	setJobsData,
	setFetching,
	setInterviewData,
	setAccessTokenData,
} from '../slices/jobsSlice';
import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';
import { vsmJobs, vsmRecruitment } from '../../utils/validations';

export const getJobsList = createAsyncThunk(
	'getJobsList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageNo, pageSize, searchText, sortBy } = _request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';

				if (
					sortField !== 'day' &&
					sortField !== 'active' &&
					sortField !== 'action'
				) {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';

			const response = await apiClient(false).get(
				`/accounts/jobs/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setJobsData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addJob = createAsyncThunk(
	'addJob',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().post(
				`/accounts/jobs/`,
				_request
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getJobsList());
				dispatch(notificationSuccess(vsmJobs?.addSucces));
				toast.success(vsmJobs?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const editJob = createAsyncThunk(
	'editJob',
	async (_request, { dispatch }) => {
		try {
			let { is_status_update } = _request;
			delete _request.is_status_update;
			dispatch(setLoading(true));
			const response = await apiClient().patch(
				`/accounts/jobs/${_request.id}/`,
				_request
			);

			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getJobsList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmJobs?.updateSuccess);
				if (!is_status_update) {
					_request.callbackFunction();
				}
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeJob = createAsyncThunk(
	'removeJob',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(`/accounts/jobs/${_request.id}/`);
			dispatch(setLoading(false));
			dispatch(getJobsList());
			dispatch(notificationSuccess(vsmJobs?.deleteSuccess));
			toast.success(vsmJobs?.deleteSuccess);
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const getInterviewList = createAsyncThunk(
	'getInterviewList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageNo, pageSize, searchText, sortBy } = _request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';

				if (
					sortField !== 'day' &&
					sortField !== 'active' &&
					sortField !== 'action'
				) {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			pageNo = pageNo || 1;
			pageSize = pageSize || 100;
			searchText = searchText ? `&search=${searchText}` : '';

			const response = await apiClient(false).get(
				`/accounts/jobs/recruitment/lists/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setInterviewData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addRecruitment = createAsyncThunk(
	'addRecruitment',
	async (_request, { dispatch }) => {
		try {
			const formData = new FormData();

			Object.keys(_request).forEach((key) => {
				if (key !== 'resume' && _request[key] !== null) {
					formData.append(key, _request[key]);
				}
			});

			// Append file data if provided
			if (_request.resume) {
				formData.append('resume', _request.resume);
			}

			dispatch(setLoading(true));
			const response = await apiClient(true).post(
				`/accounts/jobs/recruitment/`,
				formData
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getInterviewList());
				dispatch(notificationSuccess(vsmRecruitment?.addSucces));
				toast.success(vsmRecruitment?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const editRecruitment = createAsyncThunk(
	'editRecruitment',
	async (_request, { dispatch }) => {
		try {
			const formData = new FormData();

			Object.keys(_request).forEach((key) => {
				if (key !== 'resume' && _request[key] !== null) {
					formData.append(key, _request[key]);
				}
			});

			// Append file data if provided
			if (_request.resume) {
				formData.append('resume', _request.resume);
			}

			dispatch(setLoading(true));
			const response = await apiClient(true).patch(
				`/accounts/jobs/recruitment/${_request.id}/`,
				formData
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getInterviewList());
				dispatch(notificationSuccess(vsmRecruitment?.addSucces));
				toast.success(vsmRecruitment?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeInterview = createAsyncThunk(
	'removeInterview',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(
				`/accounts/jobs/recruitment/${_request.id}/`
			);
			dispatch(setLoading(false));
			dispatch(getInterviewList());
			dispatch(notificationSuccess(vsmRecruitment?.deleteSuccess));
			toast.success(vsmRecruitment?.deleteSuccess);
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const updateRecruitmentOrder = createAsyncThunk(
	'updateRecruitmentOrder',
	async (_request, { dispatch }) => {
		try {
			const { recruitment_order } = _request;
			dispatch(setLoading(true));
			await apiClient().post(`/accounts/jobs/update-recruitment-order/`, {
				recruitment_order,
			});
			dispatch(setLoading(false));
			dispatch(getInterviewList());
			toast.success(vsmRecruitment?.orderSuccess);
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const getLinkedinConnectAccessToken = createAsyncThunk(
	'getLinkedinConnectAccessToken',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			const response = await apiClient().post(
				`/accounts/linkedin/accesstoken/`,
				_request
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setAccessTokenData(response?.data?.data));
				toast.success(response?.data?.message);
			} else {
				dispatch(setFetching(false));
				toast.error(response?.data?.message);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);
