import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute(props) {
	const { component } = props;
	const userAuthData = useSelector((state) => state.auth);

	return userAuthData?.userDetails ? component : <Navigate to="/login" />;
}

export default PrivateRoute;
