import React from 'react';
import AvailableVacations from './AvailableVacations';
import UpcomingEvents from './UpcomingEvents';

export default function EventsBlock() {
	return (
		<div className="grid-cols-12  lg:grid block">
			<div className="col-span-5 text-white">
				<AvailableVacations />
			</div>
			<div className="col-span-7 text-white px-[20px]">
				<UpcomingEvents />
			</div>
		</div>
	);
}
