import React from 'react';
import WelcomeMassage from './welcomeMassage';
import DashboardProfile from './dashboardProfile';
import EventsBlock from './eventsBlock';

const Dashboard = () => {
	return (
		<>
			<WelcomeMassage />
			<DashboardProfile />
			<EventsBlock />
		</>
	);
};
export default Dashboard;
