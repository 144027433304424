import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import dataLoadingReducer from './slices/dataLoadingSlice';
import authSlice from './slices/authSlice';
import notificationReducer from './slices/notificationSlice';
import customerReducer from './slices/customerSlice';
import companyReducer from './slices/companySlice';
import userReducer from './slices/userSlice';
import countryReducer from './slices/countrySlice';
import commonReducer from './slices/commonSlice';
import departmentReducer from './slices/departmentSlice';
import rolesReducer from './slices/rolesSlice';
import skillsReducer from './slices/skillsSlice';
import tasksReducer from './slices/tasksSlice';
import leaveReducer from './slices/leavesSlice';
import meetingReducer from './slices/meetingSlice';
import projectReducer from './slices/projectsSlice';
import holidayReducer from './slices/holidaysSlice';
import jobsReducer from './slices/jobsSlice';

const rootReducer = combineReducers({
	auth: authSlice,
	customers: customerReducer,
	notification: notificationReducer,
	dataLoading: dataLoadingReducer,
	company: companyReducer,
	user: userReducer,
	countries: countryReducer,
	common: commonReducer,
	department: departmentReducer,
	skills: skillsReducer,
	roles: rolesReducer,
	tasks: tasksReducer,
	leaves: leaveReducer,
	meetings: meetingReducer,
	projects: projectReducer,
	holiday: holidayReducer,
	jobs: jobsReducer,
});

export const store = configureStore({
	reducer: rootReducer,
});
