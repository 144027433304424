import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	notificationSuccess,
	notificationFail,
} from '../slices/notificationSlice';
import { setLoading } from '../slices/dataLoadingSlice';
import {
	setProjectData,
	setFetching,
	setProjectViewData,
	setClientData,
	setClientViewData,
} from '../slices/projectsSlice';

import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';
import { vsmProjects } from '../../utils/validations';

export const getProjectList = createAsyncThunk(
	'getProjectList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageNo, pageSize, searchText, sortBy, clientID } = _request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';

				if (sortField !== 'active' && sortField !== 'action') {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';
			const clientSearch = clientID ? `&client_id=${clientID}` : '';

			const response = await apiClient(false).get(
				`/accounts/projects/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}${clientSearch}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setProjectData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addProject = createAsyncThunk(
	'addProject',
	async (_request, { dispatch }) => {
		try {
			const formData = new FormData();
			Object.keys(_request).forEach((key) => {
				if (
					key !== 'attachments' &&
					key !== 'team_members' &&
					_request[key] !== null
				) {
					formData.append(key, _request[key]);
				}
			});

			// Append file data if provided
			if (_request.attachments) {
				_request.attachments.forEach((file, index) => {
					formData.append('attachments', file);
				});
			}

			if (_request.team_members) {
				_request.team_members.map((item) =>
					formData.append('team_members', item)
				);
			}

			dispatch(setLoading(true));
			const response = await apiClient(true).post(
				`/accounts/projects/`,
				formData
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getProjectList());
				dispatch(notificationSuccess(vsmProjects?.addSucces));
				toast.success(vsmProjects?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const editProject = createAsyncThunk(
	'editProject',
	async (_request, { dispatch }) => {
		try {
			const formData = new FormData();
			const { is_from_view_page } = _request;
			delete _request.is_from_view_page;

			Object.keys(_request).forEach((key) => {
				if (
					key !== 'attachments' &&
					key !== 'team_members' &&
					_request[key] !== null
				) {
					formData.append(key, _request[key]);
				}
			});

			// Append file data if provided
			if (_request.attachments) {
				_request.attachments.forEach((file, index) => {
					formData.append('attachments', file);
				});
			}

			if (_request.team_members) {
				_request.team_members.map((item) =>
					formData.append('team_members', item)
				);
			}

			dispatch(setLoading(true));
			const response = await apiClient(true).patch(
				`/accounts/projects/${_request.id}/`,
				formData
			);

			dispatch(setLoading(false));
			if (response?.data) {
				if (is_from_view_page) {
					dispatch(getProjectViewData(_request));
				} else {
					dispatch(getProjectList());
				}
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmProjects?.updateSuccess);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeProject = createAsyncThunk(
	'removeProject',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(`/accounts/projects/${_request.id}/`);
			dispatch(setLoading(false));
			dispatch(getProjectList());
			dispatch(notificationSuccess(vsmProjects?.deleteSuccess));
			toast.success(vsmProjects?.deleteSuccess);
			// _request.callbackFunction();
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const getProjectViewData = createAsyncThunk(
	'getProjectViewData',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().get(
				`/accounts/project/view/${_request?.id}`
			);
			dispatch(setLoading(false));
			dispatch(setProjectViewData(response?.data));
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeAttachment = createAsyncThunk(
	'removeAttachment',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient().get(
				`/accounts/project/delete_attachment/${_request.id}/${_request.attachment_id}/`
			);
			dispatch(setLoading(false));
			dispatch(getProjectViewData(_request));
			dispatch(notificationSuccess(vsmProjects?.deleteSuccess));
			toast.success(vsmProjects?.deleteSuccess);
			// _request.callbackFunction();
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const getClientList = createAsyncThunk(
	'getClientList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageNo, pageSize, searchText, sortBy } = _request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';

				if (sortField !== 'active' && sortField !== 'action') {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';

			const response = await apiClient(false).get(
				`/accounts/clients/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setClientData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addClient = createAsyncThunk(
	'addClient',
	async (_request, { dispatch }) => {
		try {
			const formData = new FormData();
			Object.keys(_request).forEach((key) => {
				if (key !== 'profile_pic' && _request[key] !== null) {
					formData.append(key, _request[key]);
				}
			});

			// Append file data if provided
			if (_request.profile_pic) {
				formData.append('profile_pic', _request.profile_pic);
			}

			dispatch(setLoading(true));
			const response = await apiClient(true).post(
				`/accounts/clients/`,
				formData
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getClientList());
				dispatch(notificationSuccess(vsmProjects?.addSucces));
				toast.success(vsmProjects?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const editClient = createAsyncThunk(
	'editClient',
	async (_request, { dispatch }) => {
		try {
			const formData = new FormData();
			const { is_from_view_page } = _request;
			delete _request.is_from_view_page;

			Object.keys(_request).forEach((key) => {
				if (key !== 'profile_pic' && _request[key] !== null) {
					formData.append(key, _request[key]);
				}
			});

			// Append file data if provided
			if (_request.profile_pic) {
				formData.append('profile_pic', _request.profile_pic);
			}

			dispatch(setLoading(true));
			const response = await apiClient(true).patch(
				`/accounts/clients/${_request.id}/`,
				formData
			);

			dispatch(setLoading(false));
			if (response?.data) {
				if (is_from_view_page) {
					dispatch(getClientViewData(_request));
				} else {
					dispatch(getClientList());
				}
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmProjects?.updateSuccess);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeClient = createAsyncThunk(
	'removeClient',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(`/accounts/clients/${_request.id}/`);
			dispatch(setLoading(false));
			dispatch(getClientList());
			dispatch(notificationSuccess(vsmProjects?.deleteSuccess));
			toast.success(vsmProjects?.deleteSuccess);
			// _request.callbackFunction();
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const getClientViewData = createAsyncThunk(
	'getClientViewData',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().get(
				`/accounts/clients/view/${_request?.id}`
			);
			dispatch(setLoading(false));
			dispatch(setClientViewData(response?.data));
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);
