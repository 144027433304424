import React, { useState, useEffect } from 'react';
import { getClientViewData } from '../../store/thunk/projectsThunk';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import BreadcrumbsComponent from '../../component/ui-component/BreadcrumbsComponent';
import UserPic from '../../assets/images/user.png';
import EditClientComponent from '../../pages/Clients/EditClientComponent';
import EditTaskComponent from './Tasks/EditTaskComponent';
import Dialogpopup from '../../component/ui-component/Dailogpopup';
import { removeTask } from '../../store/thunk/tasksThunk';
import { removeProject } from '../../store/thunk/projectsThunk';
import Workflow from './Workflow';
import EditProjectComponent from './Projects/EditProjectComponent';
import { getUserPermissions } from '../../store/thunk/userThunk';

export default function ViewClientComponent() {
	const { id } = useParams();
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const client_data = useSelector((state) => state.projects.clientViewData);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [editProjectDrawerVisible, setProjectDrawerVisible] = useState(false);
	const [editTaskDrawerVisible, setTaskDrawerVisible] = useState(false);
	const [deleteDailogVisible, setDeleteDailogVisible] = useState(false);
	const [deleteTaskDailogVisible, setDeleteTaskDailogVisible] =
		useState(false);

	const [editValues, setEditValues] = useState(null);
	const [editTaskValues, setEditTaskValues] = useState(null);
	const [editProjectValues, setEditProjectValues] = useState(null);

	const authUser = useSelector((state) => state.auth);
	const user_permissions = useSelector((state) => state.user.userPermissions);

	const handleSetEditValues = (item) => {
		setEditValues(item);
		setEditDrawerVisible(true);
	};

	const handleDeleteClick = (item) => {
		setEditValues(item);
		setDeleteDailogVisible(true);
	};

	const handleSetProjectEditValues = (item) => {
		setEditProjectValues(item);
		setProjectDrawerVisible(true);
	};

	const handleTaskEditValues = (item) => {
		setEditTaskValues(item);
		setTaskDrawerVisible(true);
	};

	const handleTaskDeleteClick = (item) => {
		setEditTaskValues(item);
		setDeleteTaskDailogVisible(true);
	};

	useEffect(() => {
		let data = { id: id };
		dispatch(getClientViewData(data));
	}, [dispatch, id]);

	useEffect(() => {
		dispatch(getUserPermissions());
	}, [dispatch]);

	return (
		<>
			{client_data && (
				<>
					<div className="p-[20px] lg:mb-10 mb-24">
						<div className="float-left mt-2">
							<BreadcrumbsComponent
								showBreadCrumbs={true}
								pageTitle={'Projects'}
								showMoreOptions={true}
								pageTitles={{
									page01_link: '/clients',
									page01_title: 'Client',
									page02_title: 'View',
								}}
							/>
						</div>
						<div className="float-right lg:mt-[0px] mt-[20px]">
							<button
								className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 lg:py-3 py-2 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]"
								onClick={() =>
									handleSetProjectEditValues(client_data)
								}
							>
								<i
									className="fa fa-pencil"
									aria-hidden="true"
								></i>{' '}
								Edit Client
							</button>
							<button
								className="expert-btn text-sm leading-[18px] font-semibold text-white rounded-full px-6 lg:py-3 py-2 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)] ml-4"
								onClick={() => navigate(-1)}
							>
								<i
									className="fa fa-angle-double-left"
									aria-hidden="true"
								></i>{' '}
								Back
							</button>
						</div>
					</div>

					<div className="my-[38px] mx-[20px] relative">
						<div className="grid-cols-12 md:grid block gap-[30px]">
							<div className="col-span-12">
								<div className="relative h-full overflow-hidden rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
									<div className="flex flex-wrap relative p-5 pt-10">
										<div className="relative lg:w-[48%] w-[100%] z-[99] pr-[35px] lg:border-r-2 border-dashed border-sky-800">
											<div className="user-profile relative flex items-center mb-[29px]">
												<span className="img lg:h-[150px] lg:w-[150px] h-[100px] w-[100px] inline-block rounded-full bg-white border border-slate-300">
													<img
														src={
															client_data?.profile_pic ||
															UserPic
														}
														className="h-full w-full object-cover rounded-full bg-white border border-slate-300 p-1.5"
														alt="userpic"
													/>
												</span>
												<div className="content text-left dark:text-white text-[#0C1932] ml-[22px]">
													<h2 className="lg:text-[22px] text-[18px] not-italic font-bold leading-7 lg:mb-[10px] mb-[2px]">
														{`${client_data?.company_name}`}
													</h2>
													<h4 className="text-[16px] not-italic font-bold leading-7">
														{`${client_data?.first_name} ${client_data?.middle_name} ${client_data?.last_name}`}
													</h4>
													<p className="text-sm font-normal leading-[18px] mb-[5px] text-slate-400">
														{client_data?.position}
													</p>
												</div>
											</div>
										</div>
										<div className="contact lg:w-[48%] w-[100%] text-left mt-[10px] ml-[20px]">
											<div className="mb-[10px]">
												<p className="text-xs dark:text-white text-[#0C1932] font-normal leading-4 tracking-[5px]">
													Phone
												</p>
												<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px]">
													{client_data?.phone_number}
												</h3>
											</div>
											<div className="mb-[10px]">
												<p className="text-xs dark:text-white text-[#0C1932] font-normal leading-4 tracking-[5px]">
													Email
												</p>
												<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px]">
													{client_data?.email}
												</h3>
											</div>
											<div className="mb-[10px]">
												<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
													Address
												</p>
												<h3 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
													{`${
														client_data?.address ||
														''
													}`}
												</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="grid-cols-12 grid gap-[30px] mb-[38px]">
							<div className="col-span-12">
								<Workflow
									handleSetEditValues={handleSetEditValues}
									handleDeleteClick={handleDeleteClick}
									handleTaskEditValues={handleTaskEditValues}
									handleTaskDeleteClick={
										handleTaskDeleteClick
									}
									clientID={id}
									userData={authUser?.userDetails}
									userPermissions={user_permissions}
								/>
							</div>
						</div>
					</div>

					{deleteDailogVisible && (
						<>
							<Dialogpopup
								onCancel={() => setDeleteDailogVisible(false)}
								deleteItem={editValues}
								removeThunkCall={removeProject}
								message="Are you sure you want to delete this projects ?"
							/>
						</>
					)}

					{editProjectDrawerVisible && editProjectValues && (
						<EditClientComponent
							visible={editProjectDrawerVisible}
							onClose={() => setProjectDrawerVisible(false)}
							editValues={editProjectValues}
							isFromViewPage={true}
						/>
					)}
					{editDrawerVisible && (
						<EditProjectComponent
							visible={editDrawerVisible}
							onClose={() => setEditDrawerVisible(false)}
							editValues={editValues}
						/>
					)}

					{editTaskDrawerVisible && editTaskValues && (
						<EditTaskComponent
							visible={editTaskDrawerVisible}
							onClose={() => setTaskDrawerVisible(false)}
							editValues={editTaskValues}
						/>
					)}

					{deleteTaskDailogVisible && (
						<>
							<Dialogpopup
								onCancel={() =>
									setDeleteTaskDailogVisible(false)
								}
								deleteItem={editTaskValues}
								removeThunkCall={removeTask}
								message="Are you sure you want to delete this task ?"
							/>
						</>
					)}
				</>
			)}
		</>
	);
}
