import user_male_icon from '../assets/images/user_male.jpg';
import user_female_icon from '../assets/images/user_female.jpg';
import pdf_icon from '../assets/images/pdf_icon.png';
import doc_icon from '../assets/images/doc.png';
import xls_icon from '../assets/images/xls.png';

import { Link } from 'react-router-dom';
const moment = require('moment');

const getLeaveStatusInfo = (leaveStatus) => {
	let statusInfo = { leaveStatusName: '', leaveTextcolor: '' };

	switch (leaveStatus) {
		case 1:
			statusInfo.leaveStatusName = 'Pending';
			statusInfo.leaveTextcolor = 'text-sky-500';
			break;
		case 2:
			statusInfo.leaveStatusName = 'Approved';
			statusInfo.leaveTextcolor = 'text-green-500';
			break;
		case 3:
			statusInfo.leaveStatusName = 'Rejected';
			statusInfo.leaveTextcolor = 'text-red-500';
			break;
		case 4:
			statusInfo.leaveStatusName = 'Processing';
			statusInfo.leaveTextcolor = 'text-orange-500';
			break;
		default:
			statusInfo.leaveStatusName = 'Withdrawn';
			statusInfo.leaveTextcolor = 'text-yellow-600';
			break;
	}

	return statusInfo;
};

const getPriorityStatus = (status) => {
	let statusInfo = { priorityName: '', priorityTextcolor: '' };

	switch (status) {
		case 1:
			statusInfo.priorityName = 'Low';
			statusInfo.priorityTextcolor = 'text-green-500';
			break;
		case 2:
			statusInfo.priorityName = 'Medium';
			statusInfo.priorityTextcolor = 'text-gray-500';
			break;
		case 3:
			statusInfo.priorityName = 'High';
			statusInfo.priorityTextcolor = 'text-orange-500';
			break;
		default:
			statusInfo.priorityName = 'Medium';
			statusInfo.priorityTextcolor = 'text-gray-600';
			break;
	}
	return statusInfo;
};

const getStatus = (status) => {
	let statusInfo = { statusName: '', textcolor: '' };

	switch (status) {
		case 1:
			statusInfo.statusName = 'In Progress';
			statusInfo.textcolor = 'text-sky-500';
			break;
		case 2:
			statusInfo.statusName = 'Completed';
			statusInfo.textcolor = 'text-green-500';
			break;
		case 3:
			statusInfo.statusName = 'On Hold';
			statusInfo.textcolor = 'text-orange-500';
			break;
		default:
			statusInfo.statusName = 'In Progress';
			statusInfo.textcolor = 'text-sky-600';
			break;
	}
	return statusInfo;
};

const getFormatMonthRange = (startDate, endDate) => {
	const monthRanges = [];
	while (startDate.isBefore(endDate)) {
		const currentEnd = moment(startDate).add(6, 'days');
		const formattedRange = `${startDate.format(
			'MMM DD'
		)} - ${currentEnd.format('MMM DD')}`;

		monthRanges.push({
			id: `${startDate.format('YYYY-MM-DD')}/${currentEnd.format(
				'YYYY-MM-DD'
			)}`,
			name: formattedRange,
		});

		// sourcery skip: dont-reassign-parameters
		startDate = moment(currentEnd).add(1, 'days');
	}
	return monthRanges;
};

const formatDate = (date, format) => {
	return moment(date, 'YYYY-MM-DD').format(format);
};

const getDefaultProfile = (gender) => {
	let default_profile_icon = '';
	if (gender === 'female') {
		default_profile_icon = user_female_icon;
	} else {
		default_profile_icon = user_male_icon;
	}
	return default_profile_icon;
};

const getFileExtension = (fileUrl) => {
	const parts = fileUrl.split('/');
	const fileNameWithExtension = parts.pop();
	const lastDotIndex = fileNameWithExtension.lastIndexOf('.');

	// If a dot is found in the file name, extract the file name and extension
	if (lastDotIndex !== -1) {
		const extension = fileNameWithExtension.slice(lastDotIndex + 1);
		return { fileName: fileNameWithExtension, extension };
	}

	// If no dot is found, return an object with the file name and an empty string for the extension
	return { fileName: fileNameWithExtension, extension: '' };
};

const readFileWithExtension = (fileURL, folderName) => {
	const { fileName, extension } = getFileExtension(fileURL);
	if (['jpg', 'jpeg', 'png'].includes(extension)) {
		return (
			<img
				src={fileURL}
				width="80px"
				alt="doc"
				className="float-left mr-3"
			/>
		);
	} else {
		let extension_icon = '';
		if (['pdf'].includes(extension)) {
			extension_icon = pdf_icon;
		} else if (['doc', 'docm', 'docx', 'odt', 'rtf'].includes(extension)) {
			extension_icon = doc_icon;
		} else if (['csv', 'ods', 'xls', 'xlsx'].includes(extension)) {
			extension_icon = xls_icon;
		}
		return (
			<Link className="p-2.5 block w-full" to={fileURL} target="__blank">
				<div className="flex items-center dark:text-white text-[#4b5563] hover:text-blue-600">
					<img
						src={extension_icon}
						width="30px"
						alt={fileName}
						className="float-left mr-3"
					/>
					<span>{fileURL.split(folderName)[1]}</span>
				</div>
			</Link>
		);
	}
};

const hasPermission = (
	user,
	available_permissions,
	permission1 = '',
	permission2 = ''
) => {
	return (
		user?.is_companyadmin ||
		available_permissions?.includes(permission1, permission2)
	);
};

const getCurrentMonthDates = (custom_month, custom_year) => {
	const today = new Date();
	let month;
	let year;
	if (custom_month !== null && !isNaN(custom_month)) {
		month = custom_month;
	} else {
		month = today.getMonth() + 1;
	}
	if (custom_year !== null && !isNaN(custom_year)) {
		year = custom_year;
	} else {
		year = today.getFullYear();
	}
	const lastDay = new Date(year, month, 0);
	const allDates = Array.from({ length: lastDay.getDate() }, (_, index) => {
		const date = new Date(year, month - 1, index + 1);
		const formattedDate = formatDate(date, 'YYYY-MM-DD'); // Format as 'YYYY-MM-DD'
		return formattedDate;
	});
	return allDates.reverse();
};

const getYearList = (previous_year_cnt) => {
	const currentYear = new Date().getFullYear();
	const yearList = ['-'];

	for (let i = 0; i < previous_year_cnt; i++) {
		yearList.push(currentYear - i);
	}

	return yearList;
};

const getInterviewListStatusWise = (response) => {
	const status_name = [
		'Scheduled',
		'Interviewed',
		'Cancelled',
		'Made Offer',
		'Hired',
		'Offer Declined',
		'Not Selected',
	];

	const interviewsByStatus = status_name.map((statusName) => {
		// Find interviews with the current statusName
		const interviewsWithStatus = response.filter(
			(interview) => interview.status_name === statusName
		);

		return {
			status: statusName,
			interviews: interviewsWithStatus || [],
		};
	});

	return interviewsByStatus;
};

const calculateWorkedHours = (punches) => {
	let totalWorkedSeconds = 0;
	const sortedPunches = punches.slice().sort((a, b) => a.id - b.id);
	if (sortedPunches.length % 2 !== 0) {
		sortedPunches.pop();
	}
	for (let i = 0; i < sortedPunches.length; i += 2) {
		const punchIn = moment(sortedPunches[i].timestamp);
		const punchOut = moment(sortedPunches[i + 1].timestamp);
		const workedSeconds = punchOut.diff(punchIn, 'seconds');
		totalWorkedSeconds += workedSeconds;
	}

	return totalWorkedSeconds;
};

const formatTime = (totalseconds, format) => {
	// const duration = moment.duration(totalseconds, 'seconds');
	// const hours = Math.floor(duration.asHours());
	// const minutes = duration.minutes();
	// const seconds = duration.seconds();

	// return moment({ hours, minutes, seconds }).format(format);
	const hours = Math.floor(totalseconds / 3600);
	const minutes = Math.floor((totalseconds % 3600) / 60);
	const seconds = Math.floor(totalseconds % 60);

	const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
	const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
	const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

	return `${formattedHours} : ${formattedMinutes} : ${formattedSeconds}`;
};

const getMonthList = () => {
	const monthsLists = [
		{ id: '-', name: '-' },
		{ id: 1, name: 'January' },
		{ id: 2, name: 'February' },
		{ id: 3, name: 'March' },
		{ id: 4, name: 'April' },
		{ id: 5, name: 'May' },
		{ id: 6, name: 'June' },
		{ id: 7, name: 'July' },
		{ id: 8, name: 'August' },
		{ id: 9, name: 'September' },
		{ id: 10, name: 'Octomber' },
		{ id: 11, name: 'November' },
		{ id: 12, name: 'December' },
	];
	return monthsLists;
};

const getLastRecord = (data) => {
	let lastRecord = null;
	for (const date in data) {
		if (data.hasOwnProperty(date)) {
			const records = data[date];
			const latestRecord = records[records.length - 1];
			if (!lastRecord || latestRecord.timestamp > lastRecord.timestamp) {
				lastRecord = latestRecord;
			}
		}
	}
	return lastRecord;
};

export {
	getLeaveStatusInfo,
	getPriorityStatus,
	getStatus,
	getFormatMonthRange,
	formatDate,
	getDefaultProfile,
	hasPermission,
	getFileExtension,
	readFileWithExtension,
	getCurrentMonthDates,
	getYearList,
	getMonthList,
	getInterviewListStatusWise,
	calculateWorkedHours,
	formatTime,
	getLastRecord,
};
