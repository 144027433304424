import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { editRecruitment } from '../../../store/thunk/jobsThunk';
import FormComponent from './FormComponent';

const EditRecruitmentComponent = ({ visible, onClose, editValues }) => {
	const [uploadedFile, setUploadedFile] = useState();
	const loading = useSelector((state) => state.dataLoading.loading);
	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		setValue,
	} = useForm({
		defaultValues: {
			full_name: editValues?.candidate?.full_name,
			email: editValues?.candidate?.email,
			phone_number: editValues?.candidate?.phone_number,
			address: editValues?.candidate?.address,
			resume: editValues?.candidate?.resume,
			linkedin_profile: editValues?.candidate?.linkedin_profile,
			portfolio_url: editValues?.candidate?.portfolio_url,
			education: editValues?.candidate?.education,
			work_experience: editValues?.candidate?.work_experience,
			skills: editValues?.candidate?.skills,
			references: editValues?.candidate?.references,
			interview_date: editValues?.interview_date,
			notes: editValues?.notes,
			job: editValues?.job?.id,
			position: editValues?.position,
			status: editValues?.status,
			from_time: editValues?.from_time,
			to_time: editValues?.to_time,
			interview_format: editValues?.interview_format,
		},
	});

	const onSuccessCallback = () => {
		reset();
		onClose();
	};

	const onSubmit = (data) => {
		data.uid = editValues?.uid;
		data.callbackFunction = onSuccessCallback;
		data.setError = setError;
		data.id = editValues?.id;
		if (uploadedFile !== undefined) {
			data.resume = uploadedFile;
		} else {
			delete data.resume;
		}
		if (data.assigned_interviewers === undefined) {
			let assigned_interviewers_ids = [];
			editValues?.assigned_interviewers.map((item) =>
				assigned_interviewers_ids.push(parseInt(item.id))
			);
			data.assigned_interviewers = assigned_interviewers_ids;
		}
		dispatch(editRecruitment(data));
	};

	return (
		<>
			<div
				className={` togglesidebar bg-[#ffffff57] fixed h-full w-full top-0 left-0 p-6 z-[99999]  ${
					visible ? 'active' : ''
				}`}
			>
				<div
					onClick={onClose}
					className="w-full bg-[#ffffff57] overlay bg-[#0f1a32] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
				/>
				<div className="dark:bg-[#12243d] bg-[#fafafa] border-gray-300 border-l w-[85%] lg:w-[50%] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]">
					<button
						className="close h-8 w-8 rounded-full absolute top-5 right-5 bg-[#fff]"
						onClick={onClose}
					>
						X
					</button>
					<h1 className="p-6 dark:text-white text-[#4b5563] fontI-bold text-[20px] text-left pb-[20px] border-solid border-b dark:border-[#333333] border-gray-300">
						Update Recruitment Information
					</h1>
					<div className="rounded-lg">
						<FormComponent
							loading={loading}
							register={register}
							handleSubmit={handleSubmit}
							onSubmit={onSubmit}
							errors={errors}
							isUpdate={true}
							setValue={setValue}
							setUploadedFile={setUploadedFile}
							selectedJob={editValues?.job?.id}
							notes={editValues?.notes}
							feedback={editValues?.feedback}
							fileURL={editValues?.candidate?.resume}
							selectedAssignedInterviewers={
								editValues?.assigned_interviewers || []
							}
							selectedInterviewStatus={
								editValues?.status_interview
							}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditRecruitmentComponent;
