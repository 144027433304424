import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	projectList: [],
	clientList: [],
	isFetching: false,
	projectViewData: {},
	clientViewData: {},
	totalPages: 0,
	totalRecords: 0,
};

const projectsSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		setProjectData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			projectList: action.payload?.results,
		}),
		setProjectViewData: (state, action) => ({
			...state,
			isFetching: false,
			projectViewData: action.payload,
		}),
		setFetching: (state, action) => ({
			...state,
			isFetching: action.payload,
			projectList: [],
		}),
		setClientData: (state, action) => ({
			...state,
			isFetching: false,
			totalRecords: action.payload?.count,
			clientList: action.payload?.results,
		}),
		setClientViewData: (state, action) => ({
			...state,
			isFetching: false,
			clientViewData: action.payload,
		}),
	},
});

export const {
	setProjectData,
	setProjectViewData,
	setFetching,
	setClientData,
	setClientViewData,
} = projectsSlice.actions;
export default projectsSlice.reducer;
