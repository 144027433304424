import React, { useEffect, useState } from 'react';
import { Select, Option } from '@material-tailwind/react';
import { getDefaultProfile } from '../../component/Common';
import UserProfile from './UserProfile';

export default function SelectComponent({
	selectOptionLists,
	onChangeSelectValue,
	selectedValue,
	title,
	id,
	register,
	errors,
	fieldvalidation,
}) {
	const [optionsReady, setOptionsReady] = useState(false);

	useEffect(() => {
		if (selectOptionLists.length > 0) {
			setOptionsReady(true);
		}
	}, [selectOptionLists]);

	const handleSelectChange = (e) => {
		onChangeSelectValue(id, e);
	};

	return optionsReady ? (
		<div className="relative h-12 min-w-[200px] my-[10px] mr-[12px] select-options-list">
			<div className="selectbox">
				<Select
					id={id}
					value={selectedValue ? selectedValue.toString() : ''}
					variant="outlined"
					label={title}
					style={{ height: '3rem' }}
					{...register(id, fieldvalidation.validation[id])}
					onChange={handleSelectChange}
					className="dark:text-white text-[#4b5563] text-base"
				>
					{selectOptionLists.map((item) => (
						<Option
							key={item.id}
							value={item.id.toString()}
							className="flex items-center gap-2"
						>
							{item.first_name && (
								<UserProfile
									imageUrl={
										item.profile_image_url ||
										item.profile_pic
									}
									defaultImageUrl={getDefaultProfile(
										item?.gender
									)}
									altName={item?.first_name}
									className={
										'rounded-full h-[30px] w-[30px] inline-block mr-[10px]'
									}
								/>
							)}
							{item.title ||
								item.name ||
								item.project_name ||
								`${item.first_name} ${item.last_name}`}
						</Option>
					))}
				</Select>
				{/* <select
					className="dark:text-white text-[#4b5563] border-t-0 peer h-full w-full rounded-[7px] border bg-transparent px-3 pt-4 pb-3 font-sans text-sm font-normal outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-1 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
					id={id}
					variant="outlined"
					label={title}
					style={{ height: '3rem' }}
					{...register(id, fieldvalidation.validation[id])}
					onChange={handleSelectChange}
				>
					{selectOptionLists.map((item) => (
						<option key={item.id} value={item.id.toString()}>
							{item.first_name && (
								<img
									src={
										item.profile_image_url ||
										user_profile_image
									}
									alt={item.first_name}
									className="rounded-full h-[30px] w-[30px] inline-block mr-[10px]"
								/>
							)}
							{item.title ||
								item.name ||
								item.project_name ||
								`${item.first_name} ${item.last_name}`}
						</option>
					))}
				</select>
				<label className="dark:text-white text-[#4b5563] before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[14px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.80] dark:peer-placeholder-shown:text-white peer-placeholder-shown:text-[#4b5563]peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[14px] peer-focus:leading-tight dark:peer-focus:text-white peer-focus:text-[#4b5563] peer-focus:before:border-t-2 peer-focus:before:border-l-2 dark:peer-focus:before:border-white peer-focus:before:border-[#e5e7eb] peer-focus:after:border-t-2 peer-focus:after:border-r-2 dark:peer-focus:after:border-white peer-focus:after:border-[#e5e7eb] peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent dark:peer-disabled:peer-placeholder-shown:text-white peer-disabled:peer-placeholder-shown:text-[#4b5563]">
					{title}
				</label> */}
				{errors?.[id] && (
					<p className="error-message select-error-message">
						{errors?.[id]?.message}
					</p>
				)}
			</div>
		</div>
	) : null;
}
