import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from '../slices/dataLoadingSlice';
import { setUserData } from '../slices/authSlice';
import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';

export const loginUser = createAsyncThunk(
	'loginUser',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const { email, password } = _request;
			const response = await apiClient().post(`/accounts/login/`, {
				email,
				password,
			});
			dispatch(setLoading(false));
			if (response?.data && response?.data?.tokens) {
				localStorage.setItem(
					'user_data',
					JSON.stringify(response?.data?.user)
				);
				localStorage.setItem(
					'auth_tokens',
					JSON.stringify(response?.data?.tokens)
				);
				localStorage.setItem(
					'profile_image',
					response?.data?.user?.profile_image
				);
				dispatch(
					setUserData({
						tokens: response?.data?.tokens,
						user: response?.data?.user,
					})
				);
				toast.success(Messages.SUCCESS.LOGIN);
			} else {
				toast.error(
					'Failed to login using this credentials' ||
						Messages.ERROR.LOGIN
				);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.message || err?.message);
		}
	}
);

export const forgotpassword = createAsyncThunk(
	'forgotpassword',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const { email, navigate } = _request;
			const response = await apiClient().post(
				`/accounts/forgot-password-link/`,
				{ email }
			);
			dispatch(setLoading(false));
			toast.success(response?.data?.message);
			navigate(
				response?.data?.data?.email ? '/login' : '/forgot-password'
			);
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(
				err?.response?.data?.errors?.non_field_errors[0] || err?.message
			);
		}
	}
);

export const resetpassword = createAsyncThunk(
	'resetpassword',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const { uid, token, password, confirm_password, navigate } =
				_request;
			const response = await apiClient().post(
				`/accounts/reset-password/${uid}/${token}/`,
				{ password, confirm_password }
			);
			dispatch(setLoading(false));
			toast.success(response?.data?.message);
			navigate(response?.data?.message ? '/login' : '/reset-password');
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(
				err?.response?.data?.errors?.non_field_errors[0] || err?.message
			);
		}
	}
);

export const changepassword = createAsyncThunk(
	'changepassword',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().post(
				`/accounts/change-password/`,
				_request
			);
			dispatch(setLoading(false));
			toast.success(response?.data?.message);
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(
				err?.response?.data?.errors?.non_field_errors[0] || err?.message
			);
		}
	}
);
