import React from 'react';
import PastPreferenceReview from './PastPreferenceReview';
import PresentPreferenceReview from './PresentPreferenceReview';
import LineChart from './LineChart';
import SelfReviewChart from './SelfReviewChart';
import RatingCircleChart from './RatingCircleChart';

export default function ReviewHistory() {
	return (
		<div className="p-5">
			<div className="md:grid block grid-cols-2 gap-5">
				<PastPreferenceReview />
				<PresentPreferenceReview />
			</div>
			<LineChart />
			<div className="md:grid block  grid-cols-2 gap-5">
				<SelfReviewChart />
				<RatingCircleChart />
			</div>
		</div>
	);
}
