import React from 'react';
import skillbg from '../../assets/images/skill-bg-image.png';
import skillman from '../../assets/images/man-working-on-laptop.png';
import Ellipse from '../../assets/images/Ellipse-25.png';
import ProgressBar from '../../component/ui-component/ProgressBar';
import DocumentationIcon from '../../assets/images/documentation-icon.png';
import DModeling from '../../assets/images/DModeling.png';
import Communication from '../../assets/images/Communication.png';
import Toggle from '../../assets/images/dots.png';
import Heart from '../../assets/images/heart.png';
import FillHeart from '../../assets/images/fill-heart.png';
import { useSelector } from 'react-redux';

const SkillComponent = () => {
	const authUser = useSelector((state) => state.auth);

	const skillprogress = [
		{
			title: 'C++ Programming',
			level: 'Level 10',
			images: Heart,
			percantage: '55%',
			mainColor: '#2f4b0f',
			shadow1: '#00ab58',
			shadow2: '#00761b',
			shadow3: '#000',
			color2: '#3aaa35',
			color1: '#9de800',
			bgColor: '#3aaa35',
			bgColor2: '#c4e6c3',
			bgColor3: '#fff',
		},
		{
			title: 'Communication',
			level: 'Level 9',
			images: Heart,
			percantage: '55%',
			mainColor: '#073a56',
			shadow1: '#0070a7',
			shadow2: '#004773',
			shadow3: '#000',
			color2: '#3f576e',
			color1: '#99d1f2',
			bgColor: '#4d5e6e',
			bgColor2: '#c3c9ce',
			bgColor3: '#fff',
		},
		{
			title: 'Problem Solving',
			level: 'Level 8',
			images: Heart,

			percantage: '95%',
			mainColor: '#674306',
			shadow1: '#AA622E',
			shadow2: '#753507',
			shadow3: '#cc925a',
			bgColor: '#cc925a',
			bgColor2: '#ecd7c3',
			bgColor3: '#fff',
			color1: '#ffc800',
			color2: '#ff8c00',
		},
		{
			title: 'Leadership',
			level: 'Level 7',
			images: FillHeart,

			percantage: '55%',
			mainColor: '#412d71',
			shadow1: '#8456f9',
			shadow2: '#482c90',
			shadow3: '#8e37e2',
			color2: '#4f309f',
			color1: '#804dff',
			bgColor: '#b37bda',
			bgColor2: '#dcc3ea',
			bgColor3: '#fff',
		},
		{
			title: 'User Research',
			level: 'Level 6',
			images: Heart,

			percantage: '55%',
			mainColor: '#55040c',
			shadow1: '#be001e',
			shadow2: '#8b0013',
			shadow3: '#CC3300',

			color2: '#8A1F1F',
			color1: '#E30613',

			bgColor: '#CC3300',
			bgColor2: '#D5572D',
			bgColor3: '#ffffff',
		},

		{
			title: 'Development',
			level: 'Level 5',
			images: Heart,

			percantage: '55%',
			mainColor: '#1F4466',
			shadow1: '#2E6FAA',
			shadow2: '#074675',
			shadow3: '#3471c0',
			color2: '#009eef',
			color1: '#1ffbff',
			bgColor: '#3471c0',
			bgColor2: '#c3d5ec',
			bgColor3: '#ffffff',
		},

		{
			title: 'Identification',
			level: 'Level 4',
			images: Heart,
			percantage: '55%',
			mainColor: '#2f4b0f',
			shadow1: '#00ab58',
			shadow2: '#00761b',
			shadow3: '#000',
			color2: '#3aaa35',
			color1: '#9de800',
			bgColor: '#3aaa35',
			bgColor2: '#c4e6c3',
			bgColor3: '#fff',
		},

		{
			title: 'Graphics Design',
			level: 'Level 3',
			images: Heart,
			percantage: '55%',
			mainColor: '#073a56',
			shadow1: '#0070a7',
			shadow2: '#004773',
			shadow3: '#000',
			color2: '#3f576e',
			color1: '#99d1f2',
			bgColor: '#4d5e6e',
			bgColor2: '#c3c9ce',
			bgColor3: '#fff',
		},
	];

	const skilllist = [
		{ number: '01', name: 'Graphics Design' },
		{ number: '02', name: 'Lead Design' },
		{ number: '03', name: 'Animation Design' },
		{ number: '04', name: 'UI Design' },
	];

	return (
		<>
			<div className="mt-6 px-[20px]">
				<div className="relative skill-banner bg-[#6C38FF] rounded-[15px] mb-[20px] md:mb-[38px] flex items-center">
					<img
						src={skillbg}
						className="absolute top-0 left-0 h-full w-full object-cover rounded-[15px]"
						alt="skill banner"
					/>
					<img src={skillman} alt="skillman" className="man-img" />

					<div className="px-[31px] relative text-left content">
						<h1 className="lg:text-[32px] text-[20px] text-[#fff] font-extrabold leading-[42px] mb-[10px]">
							Hey,
							{` ${authUser?.userDetails?.first_name} ${authUser?.userDetails?.last_name}`}
						</h1>
						<p className="lg:text-lg text-[13px] font-normal lg:leading-7 landing-[20px] text-[#fff]">
							It's impressive how fast you've grown in Game Design
							skills, over the last two performance reviews. As
							Level Design and Communication are strong as well, I
							suggest putting some time into the Leadership skill
							as this could help you move towards being a Lead
							Designer in Gamecan.
						</p>
					</div>
				</div>
			</div>

			<div className="px-[20px] md:grid block grid-cols-2 gap-[30px]">
				<div className="left">
					<div className="md:p-5 p-3 relative h-full dark-dark-light-card">
						<h2 className="card-title dark:text-white text-[#4b5563]">
							Skill Progress
						</h2>
						<span className="absolute text-[#fff] top-5 right-5 cursor-pointer">
							<img
								src={Toggle}
								alt="dot"
								className="dot-toggle"
							/>
						</span>
						<div className="progressbar-row-lists custom-scroll">
							{skillprogress.map((data, index) => (
								<div
									key={index}
									className="progressbar-row mb-2"
								>
									<div className="progressbar-title flex flex-wrap justify-between dark:text-white text-[#0C1932] mb-[5px]">
										<p className="text-sm font-normal leading-[18px]">
											{data.title}
										</p>
										<span className=" text-sm not-italic font-semibold leading-[18px] flex">
											{data.level}
											<img
												src={data.images}
												alt="heart"
												className="ml-2"
											/>
										</span>
									</div>
									<div className="progressbar p-[7px]">
										<ProgressBar
											progress={data.percantage}
											mainColor={data.mainColor}
											shadow1={data.shadow1}
											shadow2={data.shadow2}
											shadow3={data.shadow3}
											bgColor={data.bgColor}
											bgColor2={data.bgColor2}
											bgColor3={data.bgColor3}
											color1={data.color1}
											color2={data.color2}
										/>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="right md:mt-0 mt-5">
					<div className="changes-form md:mb-10 mb-5 md:p-5 p-3 relative dark-dark-light-card">
						<h2 className="card-title pr-20 dark:text-white text-[#4b5563]">
							Changes From Last Quarterly Review
						</h2>
						<button className="absolute top-5 right-5 expert-btn  text-sm leading-[18px] font-semibold text-white rounded-full p-[10px] px-5 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)]">
							View All
						</button>

						<div className="circleboxes md:grid block grid-cols-3 gap-[30px] mt-12">
							<div className="single-circle content-center">
								<div className="circle lg:h-[120px] h-[100px] lg:w-[120px] w-[100px] rounded-full mb-[22px] flex items-center justify-center bg-[url(../images/circle-bg.png)]">
									<img
										src={Communication}
										alt="communication"
									/>
								</div>
								<p className="md:mb-0 mb-[10px] mt-[10px] lg:text-base text-[13px] dark:text-white text-[#0C1932] text-center font-semibold leading-[21px]">
									Communication
								</p>
							</div>
							<div className="single-circle content-center">
								<div className="circle lg:h-[120px] h-[100px] lg:w-[120px] w-[100px] rounded-full mb-[22px] flex items-center justify-center bg-[url(../images/circle-bg.png)]">
									<img src={DModeling} alt="DModeling" />
								</div>
								<p className="md:mb-0 mb-[10px] mt-[10px] lg:text-base text-[13px] dark:text-white text-[#0C1932] text-center font-semibold leading-[21px]">
									3D Modeling
								</p>
							</div>
							<div className="single-circle content-center">
								<div className="circle lg:h-[120px] h-[100px] lg:w-[120px] w-[100px] rounded-full mb-[22px] flex items-center justify-center bg-[url(../images/circle-bg.png)]">
									<img
										src={DocumentationIcon}
										alt="DocumentationIcon"
									/>
								</div>
								<p className="md:mb-0 mb-[10px] mt-[10px] lg:text-base text-[13px] dark:text-white text-[#0C1932] text-center font-semibold leading-[21px]">
									Documentation
								</p>
							</div>
						</div>
					</div>

					<div className="skill-advancement p-5 relative dark-dark-light-card">
						<h2 className="card-title pr-3 dark:text-white text-[#4b5563]">
							Skill Advancement
						</h2>
						<span className="absolute text-[#fff] top-5 right-5 cursor-pointer">
							<img
								src={Toggle}
								alt="dot"
								className="dot-toggle"
							/>
						</span>

						<div className="skill-lists dark:bg-[#2B3C5E] bg-[#f7f7f7] rounded-[5px] p-[15px] flex flex-wrap justify-between dark:text-white text-[#0C1932]">
							<p className="text-sm font-semibold leading-[18px]">
								Skill Lists
							</p>
							<p className="text-sm font-semibold leading-[18px]">
								Mentors
							</p>
						</div>

						<div className="design-lists">
							{skilllist.map((data, index) => (
								<div
									key={index}
									className="design-item flex flex-wrap justify-between items-center py-4 dark:border-b-[#293650] border-b border-solid border-b-[#f7f7f7]"
								>
									<h4 className="md:text-base text-[13px] font-bold leading-[21px] dark:text-white text-[#0C1932]">
										<strong className="dark:text-[#667799] text-[#4b5563] text-xs leading-4 mr-2">
											{data.number}
										</strong>
										{data.name}
									</h4>
									<ul>
										<li className="h-[40px] w-[40px] inline-block rounded-full overflow-hidden">
											<img src={Ellipse} alt="img" />
										</li>
										<li className="h-[40px] w-[40px] inline-block rounded-full overflow-hidden">
											<img src={Ellipse} alt="img" />
										</li>
										<li className="h-[40px] w-[40px] inline-block rounded-full overflow-hidden">
											<img src={Ellipse} alt="img" />
										</li>
									</ul>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SkillComponent;
