import React, { useEffect } from 'react';
import BgImg2 from '../../assets/images/Maskgroup4.png';
import Xp2Image from '../../assets/images/xp2.png';
import Xp3Image from '../../assets/images/b1.png';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleUserList } from '../../store/thunk/userThunk';
import { getDefaultProfile } from '../../component/Common';
import UserProfile from '../../component/ui-component/UserProfile';

export default function UserRoles() {
	const dispatch = useDispatch();
	const userRoleList = useSelector((state) => state.user.userRoleList);
	useEffect(() => {
		dispatch(getRoleUserList({ pageSize: 10 }));
	}, [dispatch]);

	return (
		<>
			<div className="mb-[20px] md:p-8 p-3 mt-6 lg:mt-0 rounded-[15px]  dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
				<div className="overflow-x-auto top-scroll">
					<div className="flex items w-[max-content] mb-[25px]">
						{userRoleList.map((row) => (
							<div
								key={row.id}
								className=" relative item w-[230px] md:mr-[22px] mr-[10px] text-left rounded-[10px] "
							>
								<div>
									<div className="flex z-[1] relative p-[14px]">
										<div className="mr-[12.38px]">
											<UserProfile
												imageUrl={
													row?.profile_image_url
												}
												defaultImageUrl={getDefaultProfile(
													row?.gender
												)}
												altName={row?.fullname}
												className={
													'w-[45px] h-[45px] rounded-full bg-white border border-slate-300 p-0.5'
												}
											/>
										</div>
										<div className="mt-[5px]">
											<h2 className="text-base font-bold leading-[12px] mb-3 dark:text-[#fff] text-black">
												{row.first_name} {row.last_name}
											</h2>
											<p className="dark:text-[#fff] text-black text-xs leading-4">
												{row.role.title}
											</p>
										</div>
									</div>
								</div>{' '}
								<div className="flex items-center justify-between z-[1] relative p-[14px]">
									<div className="flex items-center">
										<img
											src={Xp2Image}
											alt="img2"
											className="w-[27px] h-[27px] mr-1.5"
										/>
										<p className="dark:text-[#fff] text-black text-base leading-[21px]">
											XP - 9
										</p>
									</div>
									<div className="flex items-center">
										<img
											src={Xp3Image}
											alt="img3"
											className="w-[27px] h-[27px] mr-1.5"
										/>
										<p className="dark:text-[#fff] text-black text-base leading-[21px]">
											Level - 5
										</p>
									</div>
								</div>
								<div className="absolute z-0 w-full left-0 top-0">
									<img
										src={BgImg2}
										alt="bgImg"
										className="user-role-block h-full w-full"
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
}
