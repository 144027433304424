import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { addCompany } from '../../store/thunk/companyThunk';

import FormComponent from './FormComponent';

const AddCompanyComponent = ({ visible, onClose }) => {
	const loading = useSelector((state) => state.dataLoading.loading);
	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		setValue,
	} = useForm({
		defaultValues: {
			company_name: '',
			company_address: '',
			company_country: '',
			company_contact_no: '',
			company_postal_code: '',
			company_website: '',
			first_name: '',
			middle_name: '',
			last_name: '',
			email: '',
			phone_number: '',
			password: '',
			gender: '',
		},
	});

	const onSuccessCallback = () => {
		reset();
		onClose();
	};

	const onSubmit = (data) => {
		data.callbackFunction = onSuccessCallback;
		data.setError = setError;
		dispatch(addCompany(data));
	};

	return (
		<>
			<div
				className={` togglesidebar bg-[#ffffff57] fixed h-full w-full top-0 left-0 p-6 z-[99999]  ${
					visible ? 'active' : ''
				}`}
			>
				<div
					onClick={onClose}
					className="w-full bg-[#ffffff57] overlay bg-[#0f1a32] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
				/>
				<div className="dark:bg-[#12243d] bg-[#fafafa] border-gray-300 border-l w-[85%] lg:w-[50%] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]">
					<button
						className="close h-8 w-8 rounded-full absolute top-5 right-5 bg-[#fff]"
						onClick={onClose}
					>
						X
					</button>
					<h1 className="p-6 dark:text-white text-[#4b5563] font-bold text-[20px] text-left pb-[20px] border-solid border-b dark:border-[#333333] border-gray-300">
						Add Company
					</h1>
					<div className="rounded-lg">
						<FormComponent
							loading={loading}
							register={register}
							handleSubmit={handleSubmit}
							onSubmit={onSubmit}
							errors={errors}
							setValue={setValue}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddCompanyComponent;
