import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	notificationSuccess,
	notificationFail,
} from '../slices/notificationSlice';
import { setLoading } from '../slices/dataLoadingSlice';
import { setSkillsData, setFetching } from '../slices/skillsSlice';

import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';
import { vsmSkills } from '../../utils/validations';

export const getSkillsList = createAsyncThunk(
	'getSkillsList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageNo, pageSize, searchText, sortBy, roles } = _request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';
				if (sortField !== 'active' && sortField !== 'action') {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			if (roles !== null && typeof roles !== 'undefined') {
				roles = `&roles=${roles}`;
				pageSize = 500;
			} else {
				roles = '';
			}

			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';

			const response = await apiClient(false).get(
				`/accounts/skills/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}${roles}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setSkillsData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const addSkill = createAsyncThunk(
	'addSkill',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().post(
				`/accounts/skills/`,
				_request
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getSkillsList());
				dispatch(notificationSuccess(vsmSkills?.addSucces));
				toast.success(vsmSkills?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const editSkill = createAsyncThunk(
	'editSkill',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiClient().patch(
				`/accounts/skills/${_request.id}/`,
				_request
			);

			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getSkillsList());
				dispatch(notificationSuccess(response?.data?.message));
				toast.success(vsmSkills?.updateSuccess);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeSkill = createAsyncThunk(
	'removeSkill',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(`/accounts/skills/${_request.id}/`);
			dispatch(setLoading(false));
			dispatch(getSkillsList());
			dispatch(notificationSuccess(vsmSkills?.deleteSuccess));
			toast.success(vsmSkills?.deleteSuccess);
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);
