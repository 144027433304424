import React, { useEffect, useState } from 'react';
import AchievementCard from '../../assets/images/Achievement-Card.png';
import LinkedIn from '../../assets/images/inkedin.png';
import Website from '../../assets/images/website.png';
import Edit from '../../assets/images/Edit.png';
import { getCompanyData } from '../../store/thunk/companyThunk';
import { useDispatch, useSelector } from 'react-redux';
import EditProfile from './EditProfile';
import { getDefaultProfile } from '../../component/Common';
import UserProfile from '../../component/ui-component/UserProfile';

export default function CompanyProfile() {
	const dispatch = useDispatch();
	const company_data = useSelector((state) => state.company.companyData);
	const authUser = useSelector((state) => state.auth);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [isCompanyInformation, setIsCompanyInformation] = useState(false);
	const [editValues, setEditValues] = useState(null);
	const handleSetEditValues = (item, is_company_information) => {
		setEditValues(item);
		setEditDrawerVisible(true);
		setIsCompanyInformation(is_company_information);
	};

	useEffect(() => {
		dispatch(getCompanyData(authUser?.userDetails));
	}, [dispatch, authUser?.userDetails]);

	return (
		<>
			<div className="md:my-[38px] my-[20px] mx-[20px] relative">
				<div className="grid-cols-12 md:grid block gap-[30px] md:mb-[38px] mb-[15px]">
					<div className="col-span-8">
						<div className="relative h-full overflow-hidden rounded-[15px] p-5 dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
							<img
								src={AchievementCard}
								alt="AchievementCard"
								className="absolute w-full h-full object-cover top-0 left-0"
							/>

							<div className="flex flex-wrap relative">
								<div className="relative z-[99] w-full">
									<div className="user-profile relative flex items-center mb-[29px]">
										<span>
											<UserProfile
												imageUrl={
													company_data?.user_info
														?.profile_image_url
												}
												defaultImageUrl={getDefaultProfile(
													company_data?.user_info
														?.gender
												)}
												altName={
													company_data?.user_info
														?.fullname
												}
												className={
													'img h-[120px] w-[120px] inline-block rounded-full bg-white border border-slate-300 p-1'
												}
											/>
										</span>
										<div className="content text-left dark:text-[#ffffff] text-[#0C1932] ml-[22px]">
											<h2 className="text-[22px] not-italic font-bold leading-7 mb-[10px]">
												{`${company_data?.user_info?.first_name} ${company_data?.user_info?.last_name}`}
											</h2>
											<p className="dark:text-white text-[#0C1932] text-sm not-italic font-normal leading-[18px]">
												Company Admin
											</p>
										</div>
										<span className="toggle absolute top-0 right-0">
											<div
												className="cursor-pointer flex items-center justify-center w-[39.56px] h-[39.56px] rounded-full mr-[8px]"
												onClick={() =>
													handleSetEditValues(
														company_data,
														false
													)
												}
											>
												<img src={Edit} alt="edit" />
											</div>
										</span>
									</div>

									<div className="relative z-[99] flex items-end">
										<div className="information-boxes md:grid block grid-cols-3 gap-[10px] w-full">
											<div className="single-box md:mb-0 mb-2 text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[15px] p-[15px]">
												<p className="text-xs dark:text-[#BFC6D2] text-[#54565C] font-normal leading-4 tracking-[5px]">
													Email
												</p>
												<h3 className="font-semibold text-[14px] dark:text-[#ffffff] text-[#0C1932] mt-[7px]  leading-[21px] text-[15px]">
													{
														company_data?.user_info
															?.email
													}
												</h3>
											</div>
											<div className="single-box  md:mb-0 mb-2 text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[15px] p-[15px]">
												<p className="text-xs dark:text-[#BFC6D2] text-[#54565C] font-normal leading-4 tracking-[5px]">
													Phone Number
												</p>
												<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px] tracking-[1px]">
													{
														company_data?.user_info
															?.phone_number
													}
												</h3>
											</div>
											<div className="single-box  md:mb-0 mb-2 text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[15px] p-[15px]">
												<p className="text-xs dark:text-[#BFC6D2] text-[#54565C] font-normal leading-4 tracking-[5px]">
													DOB
												</p>
												<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px] tracking-[1px]">
													{
														company_data?.user_info
															?.dob
													}
												</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-4 md:mt-0 mt-3">
						<div className="upcommingsection relative dark-dark-light-card p-5">
							<h2 className="card-title">Company Policies</h2>
						</div>
					</div>
				</div>

				<div className="grid-cols-12 md:grid block gap-[30px] mb-[38px]">
					<div className="col-span-8">
						<div className="basic-info-box relative h-full p-5 dark-dark-light-card">
							<h2 className="card-title">Company Information</h2>
							<span className="toggle absolute top-5 right-5">
								<div
									className="cursor-pointer flex items-center justify-center w-[39.56px] h-[39.56px] rounded-full mr-[8px]"
									onClick={() =>
										handleSetEditValues(company_data, true)
									}
								>
									<img src={Edit} alt="edit" />
								</div>
							</span>
							<div className="information-boxes md:grid block grid-cols-3 gap-[34px]">
								<div className="single-box  md:mb-0 mb-2  text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[15px] p-[15px]">
									<p className="text-xs dark:text-[#BFC6D2] text-[#0C1932] font-normal leading-4 tracking-[5px]">
										Company Name
									</p>
									<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px] tracking-[1px]">
										{company_data?.company_name}
									</h3>
								</div>
								<div className="single-box  md:mb-0 mb-2  text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[15px] p-[15px]">
									<p className="text-xs dark:text-[#BFC6D2] text-[#0C1932] font-normal leading-4 tracking-[5px]">
										Company Email
									</p>
									<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px] tracking-[1px]">
										{company_data?.company_email}
									</h3>
								</div>
								<div className="single-box  md:mb-0 mb-2  text-left dark:bg-[#223457] bg-[#E6E6E6] rounded-[8px] py-[15px] p-[15px]">
									<p className="text-xs dark:text-[#BFC6D2] text-[#0C1932] font-normal leading-4 tracking-[5px]">
										Company Contact
									</p>
									<h3 className="text-base dark:text-[#ffffff] text-[#0C1932] mt-[7px] font-semibold leading-[21px] tracking-[1px]">
										{company_data?.company_contact_no}
									</h3>
								</div>
							</div>

							<div className="info-address pt-[25px] pb-[20px] md:flex block">
								<div className="add-item md:w-[40%] w-full">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Company Address
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
										{company_data?.company_address}
									</h4>
								</div>
								<div className="add-item md:w-[30%] w-full">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Company City
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
										{company_data?.company_city}
									</h4>
								</div>
								<div className="add-item md:w-[30%] w-full">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Company Postal Code
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
										{company_data?.company_postal_code}
									</h4>
								</div>
							</div>
							<div className="info-address pt-[25px] pb-[20px] md:flex block">
								<div className="add-item md:w-[40%] w-full">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Registration No
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
										{company_data?.registration_number}
									</h4>
								</div>
								<div className="add-item md:w-[30%]  w-full">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Company Tax ID Number
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
										{company_data?.company_taxid_number}
									</h4>
								</div>
								<div className="add-item md:w-[30%]  w-full">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Company Filling Date
									</p>
									<h4 className="text-base dark:text-white text-[#0C1932] text-left font-semibold leading-[21px] tracking-[1px]">
										{company_data?.company_filling_date}
									</h4>
								</div>
							</div>
							<div className="info-address pt-[25px] pb-[20px] flex">
								<div className="add-item w-full">
									<p className="text-xs mb-3 dark:text-white text-[#0C1932] text-left font-normal leading-4 tracking-[5px]">
										Company Description
									</p>
									<p
										className="text-base dark:text-white text-[#0C1932] text-left text-justify text-black text-[15px] leading-[25px]"
										dangerouslySetInnerHTML={{
											__html: `${company_data?.company_descriptions}`,
										}}
									></p>
								</div>
							</div>

							<ul className="social-icon pt-[25px] flex">
								<li key="website" className="mr-[22px]">
									<a
										href={company_data?.company_website}
										target="_blank"
										rel="noopener noreferrer"
										className="h-[35px] w-[35px] rounded-full  bg-[#223457] hover:bg-[linear-gradient(137.02deg,#bd00ff_0%,#216cff_103.79%)] flex items-center justify-center"
									>
										<img
											src={Website}
											alt={company_data?.company_name}
										/>
									</a>
								</li>
								<li key="linkedin" className="mr-[22px]">
									<a
										href={company_data?.company_linkedin}
										target="_blank"
										rel="noopener noreferrer"
										className="h-[35px] w-[35px] rounded-full  bg-[#223457] hover:bg-[linear-gradient(137.02deg,#bd00ff_0%,#216cff_103.79%)] flex items-center justify-center"
									>
										<img
											src={LinkedIn}
											alt={company_data?.company_name}
										/>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-span-4 md:mt-0 mt-3">
						<div className="relative p-5 dark-dark-light-card">
							<div className="relative">
								<h2 className="card-title">Company Benefits</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
			{editDrawerVisible && editValues && (
				<EditProfile
					visible={editDrawerVisible}
					onClose={() => setEditDrawerVisible(false)}
					editValues={editValues}
					isCompanyInformation={isCompanyInformation}
				/>
			)}
		</>
	);
}
