import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { UpdateUserProfile } from '../../store/thunk/userThunk';

import FormComponent from './FormComponent';

const EditProfile = ({ visible, onClose, editValues, showPortion }) => {
	const [uploadedFile, setUploadedFile] = useState();
	const loading = useSelector((state) => state.dataLoading.loading);
	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		setValue,
	} = useForm({
		defaultValues: {
			first_name: editValues?.first_name,
			middle_name: editValues?.middle_name,
			last_name: editValues?.last_name,
			email: editValues?.email,
			phone_number: editValues?.phone_number,
			address: editValues?.address,
			dob: editValues?.dob,
			joining_date: editValues?.joining_date,
			profile_image: editValues?.profile_image,
			education: editValues?.education,
			city: editValues?.city,
			postal_code: editValues?.postal_code,
			country: editValues?.country?.id,
			about: editValues?.about,
			facebook_link: editValues?.facebook_link,
			instagram_link: editValues?.instagram_link,
			twitter_link: editValues?.twitter_link,
			linkedin_link: editValues?.linkedin_link,
			passport_number: editValues?.passport_number,
			passport_expiry_date: editValues?.passport_expiry_date,
			department: editValues?.department?.id,
			role: editValues?.role?.id,
			gender: editValues?.gender,
			bank_name: editValues?.bank_name,
			bank_account_number: editValues?.bank_account_number,
			bank_ifsc_number: editValues?.bank_ifsc_number,
			bank_pan_number: editValues?.bank_pan_number,
			marital_status: editValues?.marital_status,
		},
	});

	const onSuccessCallback = () => {
		reset();
		onClose();
	};

	const onSubmit = (data) => {
		data.id = editValues?.id;
		data.callbackFunction = onSuccessCallback;
		data.setError = setError;
		if (uploadedFile !== undefined) {
			data.profile_image = uploadedFile;
		} else {
			delete data.profile_image;
		}
		if (data.skills === undefined) {
			let selected_skill_ids = [];
			editValues?.skills.map((item) =>
				selected_skill_ids.push(parseInt(item.id))
			);
			data.skills = selected_skill_ids;
		}
		if (data.skills.length === 0) {
			delete data.skills;
		}
		dispatch(UpdateUserProfile(data));
	};

	return (
		<>
			<div
				className={` togglesidebar bg-[#ffffff57] fixed h-full w-full top-0 left-0 p-6 z-[99999]  ${
					visible ? 'active' : ''
				}`}
			>
				<div
					onClick={onClose}
					className="w-full bg-[#ffffff57] overlay bg-[#0f1a32] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]"
				/>
				<div className="dark:bg-[#12243d] bg-[#fafafa] border-gray-300 border-l w-[85%] lg:w-[50%] absolute top-0 right-0 h-full z-[999999] transition-[0.5s]">
					<button
						className="close h-8 w-8 rounded-full absolute top-5 right-5 bg-[#fff]"
						onClick={onClose}
					>
						X
					</button>
					<h1 className="p-6 dark:text-white text-[#4b5563] font-bold text-[20px] text-left pb-[20px] border-solid border-b dark:border-[#333333] border-gray-300">
						Update Profile
					</h1>
					<div className="rounded-lg">
						<FormComponent
							loading={loading}
							register={register}
							handleSubmit={handleSubmit}
							onSubmit={onSubmit}
							errors={errors}
							isUpdate={true}
							setValue={setValue}
							setUploadedFile={setUploadedFile}
							selectedDepartment={
								editValues?.department?.id || ''
							}
							selectedRoles={editValues?.role?.id || ''}
							existingProfileImage={editValues?.profile_image_url}
							selectedCountry={editValues?.country?.id || ''}
							selectedGender={editValues?.gender || ''}
							selectedMaritalStatus={
								editValues?.marital_status || ''
							}
							about_me={editValues?.about}
							showPortion={showPortion}
							selectedUserSkills={editValues?.skills}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditProfile;
