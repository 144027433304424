import React from 'react';
import { useDispatch } from 'react-redux';
import ListAllTaskComponent from './ListAllTaskComponent';
import ListPendingTaskComponent from './ListPendingTaskComponent';
import ListCompleteTaskComponent from './ListCompleteTaskComponent';
import { editTask } from '../../../store/thunk/tasksThunk';

export default function Workflow({
	handleSetEditValues,
	handleDeleteClick,
	projectID,
	userData,
	userPermissions,
}) {
	const dispatch = useDispatch();
	const [openTab, setOpenTab] = React.useState(1);
	const handleTaskStatusChange = (record, status, show_all_project_task) => {
		let payload = {
			status: status,
			id: record?.id,
			assigned_to: record?.assigned_to?.id,
			is_status_update: true,
			project_id: parseInt(projectID),
			show_all_project_task: show_all_project_task,
		};
		dispatch(editTask(payload));
	};

	return (
		<div className="flex flex-wrap">
			<div className="w-full">
				<ul
					className="flex mb-0 list-none flex-wrap pt-3 flex-row mb-5 border-b-[#2C3E63] border-b border-solid"
					role="tablist"
				>
					<li className="-mb-px mr-2 last:mr-0">
						<a
							className={
								'border-b border-solid flex lg:text-base text-sm font-bold py-3 md:mr-[56.99px] lg:mr-[30px] mr-[18px] leading-normal ' +
								(openTab === 1
									? 'border-b-[#00FFF0] text-[#00FFF0]'
									: 'border-b-transparent text-[#BFC6D2]')
							}
							onClick={(e) => {
								e.preventDefault();
								setOpenTab(1);
							}}
							data-toggle="tab"
							href="#link1"
							role="tablist"
						>
							All Tasks
						</a>
					</li>
					<li className="-mb-px mr-2 last:mr-0">
						<a
							className={
								'border-b border-solid lg:text-base text-sm font-bold py-3 md:mr-[56.99px] lg:mr-[30px] mr-[18px] block leading-normal ' +
								(openTab === 2
									? 'border-b-[#00FFF0] text-[#00FFF0]'
									: 'border-b-transparent text-[#BFC6D2]')
							}
							onClick={(e) => {
								e.preventDefault();
								setOpenTab(2);
							}}
							data-toggle="tab"
							href="#link2"
							role="tablist"
						>
							Pending Tasks
						</a>
					</li>
					<li className="-mb-px mr-2 last:mr-0">
						<a
							className={
								'border-b border-solid lg:text-base text-sm font-bold py-3 md:mr-[56.99px] lg:mr-[30px] mr-[18px] block leading-normal ' +
								(openTab === 3
									? 'border-b-[#00FFF0] text-[#00FFF0]'
									: 'border-b-transparent text-[#BFC6D2]')
							}
							onClick={(e) => {
								e.preventDefault();
								setOpenTab(3);
							}}
							data-toggle="tab"
							href="#link3"
							role="tablist"
						>
							Completed Tasks
						</a>
					</li>
				</ul>
				<div className="relative min-w-0 w-full mb-6 md:p-8 p-3 mt-6 lg:mt-0 rounded-[15px] dark:bg-[#17243E] bg-[#fff] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
					<div className="tab-content tab-space dark:text-white text-black">
						<div
							className={openTab === 1 ? 'block' : 'hidden'}
							id="link1"
						>
							<ListAllTaskComponent
								handleTaskStatusChange={handleTaskStatusChange}
								projectID={projectID}
								handleSetEditValues={handleSetEditValues}
								handleDeleteClick={handleDeleteClick}
								userData={userData}
								userPermissions={userPermissions}
							/>
						</div>
						<div
							className={openTab === 2 ? 'block' : 'hidden'}
							id="link2"
						>
							<ListPendingTaskComponent
								handleTaskStatusChange={handleTaskStatusChange}
								projectID={projectID}
								handleSetEditValues={handleSetEditValues}
								handleDeleteClick={handleDeleteClick}
								userData={userData}
								userPermissions={userPermissions}
							/>
						</div>
						<div
							className={openTab === 3 ? 'block' : 'hidden'}
							id="link3"
						>
							<ListCompleteTaskComponent
								handleTaskStatusChange={handleTaskStatusChange}
								projectID={projectID}
								handleSetEditValues={handleSetEditValues}
								handleDeleteClick={handleDeleteClick}
								userData={userData}
								userPermissions={userPermissions}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
