import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	userDetails: localStorage.getItem('user_data')
		? JSON.parse(localStorage.getItem('user_data'))
		: null,
	tokens: localStorage.getItem('auth_tokens')
		? JSON.parse(localStorage.getItem('auth_tokens'))
		: null,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUserData: (state, action) => {
			return {
				...state,
				tokens: action?.payload?.tokens,
				userDetails: action.payload?.user,
			};
		},
	},
});

export const { setUserData } = authSlice.actions;

export default authSlice.reducer;
