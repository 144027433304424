import axios from 'axios';
const baseURL = process.env.REACT_APP_API_URL;

const apiClient = (isFormData = false) => {
	let headers = {};

	let auth_tokens = localStorage.getItem('auth_tokens');

	if (auth_tokens) {
		auth_tokens = JSON.parse(auth_tokens);
		headers = {
			...headers,
			Authorization: `Bearer ${auth_tokens.access}`,
		};
	}

	if (isFormData) {
		Object.assign(headers, { 'content-type': 'multipart/form-data' });
	}

	let userLoginDetail = localStorage.getItem('user_data');
	if (userLoginDetail) {
		userLoginDetail = JSON.parse(userLoginDetail);
		if (!userLoginDetail.is_superuser && userLoginDetail?.company_id) {
			let companyID = userLoginDetail?.company_id;
			Object.assign(headers, { uid: companyID });
		}
	}

	return axios.create({
		baseURL,
		withCredentials: false,
		headers,
	});
};
export default apiClient;
