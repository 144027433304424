import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTasksList, updateTaskOrder } from '../../../store/thunk/tasksThunk';
import ReactTablePaginationDragable from '../../../component/ui-component/pagination/ReactTablePaginationDragable';
import { getDefaultProfile } from '../../../component/Common';
import UserProfile from '../../../component/ui-component/UserProfile';
import Delete from '../../../assets/images/Delete.png';
import { hasPermission } from '../../../component/Common';

const moment = require('moment');

const ListTaskComponent = ({
	handleSetEditValues,
	handleDeleteClick,
	handleTaskStatusChange,
	clientID,
	userData,
	userPermissions,
}) => {
	const [recordsPerPage, setRecordsPerPage] = useState(10);

	const dispatch = useDispatch();

	const tasksData = useSelector((state) => state.tasks);

	const [initialData, setinitialData] = useState([]);

	useEffect(() => {
		if (tasksData?.tasksLists.length > 0) {
			setinitialData(
				tasksData?.tasksLists.map((row) => ({
					...row,
					isSelected: !(row?.status === 1), // Initially, no row is selected
				}))
			);
		}
	}, [tasksData]);

	const onChangeStatus = (
		task,
		newStatus,
		show_all_project_task,
		projectId
	) => {
		const updatedData = initialData.map((row) =>
			row.id === task.id
				? { ...row, isSelected: !row.isSelected, status: newStatus }
				: row
		);
		handleTaskStatusChange(
			task,
			newStatus,
			show_all_project_task,
			projectId
		);
		setinitialData(updatedData);
	};

	const updateTaskOrders = (task_orders) => {
		dispatch(updateTaskOrder({ task_order: task_orders }));
	};

	const columns = [
		{
			Header: '',
			accessor: 'dragable',
			width: 30,
			Cell: ({ row, value }) => (
				<div className="flex">
					<i className="fas fa-grip-vertical text-lg"></i>
				</div>
			),
		},
		{
			Header: '',
			id: 'checkbox',
			accessor: 'isSelected',
			width: 50,
			Cell: ({ row }) => (
				<label
					className="relative flex cursor-pointer items-center rounded-full p-3"
					htmlFor="checkbox"
					data-ripple-dark="true"
				>
					<input
						type="checkbox"
						className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none border border-2 dark:border-[#fffff] border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-2 checked:border-red-600 checked:bg-red-600 checked:before:bg-red-600 "
						id="checkbox"
						checked={row?.original?.isSelected}
						onChange={() =>
							onChangeStatus(
								row?.original,
								row?.original?.isSelected ? 1 : 3,
								true,
								row?.original?.project?.id
							)
						}
					/>
					<div className=" pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-3.5 w-3.5"
							viewBox="0 0 20 20"
							fill="#FFF"
							stroke="#FFF"
							strokeWidth="1"
						>
							<path
								fillRule="evenodd"
								d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
								clipRule="evenodd"
							></path>
						</svg>
					</div>
				</label>
			),
		},
		{
			Header: 'Task',
			accessor: 'title',
			width: '50%',
			resize: true,
			Cell: ({ row, value }) => (
				<div className="flex">
					<div
						className="cursor-pointer hover:text-blue-500"
						onClick={
							hasPermission(
								userData,
								userPermissions,
								'change_tasks'
							)
								? () => handleSetEditValues(row?.original)
								: undefined
						}
					>
						{row?.original?.title}
					</div>
				</div>
			),
		},
		{
			Header: 'Project',
			accessor: 'project.project_name',
			width: '20%',
			resize: true,
			Cell: ({ row, value }) => {
				return (
					<div className="flex">
						{row?.original?.project?.project_name}
					</div>
				);
			},
		},
		{
			Header: 'Priority',
			accessor: 'priority_name',
			width: '15%',
			resize: true,
			Cell: ({ row, value }) => {
				let icon = 'fa-minus';
				let textColor = 'text-gray-600';
				if (row?.original?.priority === 1) {
					icon = 'fas fa-arrow-down';
					textColor = 'text-green-600';
				} else if (row?.original?.priority === 2) {
					icon = 'fas fa-minus ';
					textColor = 'text-gray-500';
				} else if (row?.original?.priority === 3) {
					icon = 'fas fa-arrow-up text-orange-600';
					textColor = 'text-orange-600';
				}

				return (
					<div className={textColor + ' flex font-semibold'}>
						<i
							className={icon + ' text-lg mr-[10px] ' + textColor}
						></i>
						<span className="mt-[2px] tracking-wide">
							{row?.original?.priority_name}
						</span>
					</div>
				);
			},
		},
		{
			Header: 'Assigned To',
			accessor: 'assigned_to.fullname',
			width: '22%',
			resize: true,
			Cell: ({ row, value }) => (
				<div className="flex">
					<div>
						<UserProfile
							imageUrl={row?.original?.assigned_to?.profile_image}
							defaultImageUrl={getDefaultProfile(
								row?.original?.assigned_to?.gender
							)}
							altName={row?.original?.assigned_to?.fullname}
							className={
								'mr-[13px] h-[40px] w-[40px] rounded-full bg-white border border-slate-300 p-[2px]'
							}
						/>
					</div>
					<span className="mt-[5px]">
						{row?.original?.assigned_to?.fullname}
					</span>
				</div>
			),
		},
		{
			Header: 'Due Date',
			accessor: 'due_date',
			width: '18%',
			resize: true,
			Cell: ({ row, value }) => {
				return (
					<>{moment(value, 'YYYY-MM-DD').format('DD MMM, YYYY')}</>
				);
			},
		},
		...(hasPermission(userData, userPermissions, 'delete_tasks')
			? [
					{
						Header: 'Action',
						accessor: 'action',
						width: '10%',
						disableSortBy: true,
						Cell: ({ row, value }) => (
							<div className="flex">
								<div
									className="cursor-pointer flex items-center justify-center dark:bg-[#243555] bg-[#fff] w-[35px] h-[35px] rounded-full border border-solid dark:border-[#2e4062] border-[#bfc6d2]"
									onClick={() =>
										handleDeleteClick(row?.original)
									}
									title="Delete"
								>
									<img src={Delete} alt="Delete" />
								</div>
							</div>
						),
					},
			  ]
			: []),
	];

	const fetchData = useCallback(
		(pageSize, pageIndex, searchText, sortBy) => {
			dispatch(
				getTasksList({
					pageSize: pageSize,
					pageNo: pageIndex,
					searchText: searchText,
					sortBy: sortBy,
					clientID: clientID,
				})
			);
		},
		[dispatch, clientID]
	);

	return (
		<>
			<ReactTablePaginationDragable
				columns={columns}
				data={initialData}
				fetchData={fetchData}
				pageCount={Math.ceil(tasksData?.totalRecords / recordsPerPage)}
				totalRecords={tasksData?.totalRecords}
				recordsPerPage={recordsPerPage}
				setRecordsPerPage={setRecordsPerPage}
				showContentClass={false}
				updateRowOrders={updateTaskOrders}
				csvFileName="task_lists.csv"
			/>
		</>
	);
};

export default ListTaskComponent;
