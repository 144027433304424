import React from 'react';
import { useFieldArray } from 'react-hook-form';
import InputComponent from '../../component/ui-component/InputComponent';
import Button from '../../component/ui-component/Button';
import { vsmUserEmergency } from '../../utils/validations';

const EmergencyForm = ({
	handleSubmit,
	onSubmit,
	errors,
	register,
	loading,
	control,
}) => {
	const { fields } = useFieldArray({
		control,
		name: 'emergencyc', // name of your field array
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="px-[20px] infobox custom-scroll overflow-y-auto max-h-[calc(100vh_-_160px)]">
				{fields.map((emergencyc, index) => (
					<div
						key={index}
						className="hover:cursor-pointer dark:bg-[#12243d] bg-[#fafafa] rounded py-6 p-4 shadow-md dark:shadow-[#0c4a6e] shadow-[#cbd5e1] my-4"
					>
						<div>
							<h3 className="text-left dark:text-white text-[#4b5563] font-bold">
								{index === 0
									? 'Primary Contact'
									: 'Secondary Contact'}
							</h3>
						</div>
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
							{emergencyc?.ecid && emergencyc?.ecid > 0 && (
								<InputComponent
									id={`emergencyc.${index}.ecid`}
									title="Id"
									errors={errors}
									register={register}
									fieldvalidation={vsmUserEmergency}
									inputType="hidden"
									index={index}
									isMultipleField={true}
								/>
							)}
							<InputComponent
								id={`emergencyc.${index}.name`}
								title="Name"
								errors={errors}
								register={register}
								fieldvalidation={vsmUserEmergency}
								index={index}
								isMultipleField={true}
							/>
							<InputComponent
								id={`emergencyc.${index}.relationship`}
								title="Relationship"
								errors={errors}
								register={register}
								fieldvalidation={vsmUserEmergency}
								index={index}
								isMultipleField={true}
							/>
							<InputComponent
								id={`emergencyc.${index}.phone1`}
								title="Phone 1"
								errors={errors}
								register={register}
								fieldvalidation={vsmUserEmergency}
								index={index}
								isMultipleField={true}
							/>
							<InputComponent
								id={`emergencyc.${index}.phone2`}
								title="Phone 2"
								errors={errors}
								register={register}
								fieldvalidation={vsmUserEmergency}
							/>
						</div>
					</div>
				))}
			</div>
			<div className="pt-[20px] flex p-6 border-solid border-t dark:border-[#333333] border-gray-300 fixed w-[calc(100%_-_50%)] bottom-0">
				<Button
					buttonText="Submit"
					customValue="175px"
					inputType="submit"
					loading={loading}
				/>
			</div>
		</form>
	);
};
export default EmergencyForm;
