import React from 'react';

const Pagination = ({
	totalRecords,
	canPreviousPage,
	gotoPage,
	previousPage,
	pageIndex,
	pageOptions,
	canNextPage,
	nextPage,
	pageCount,
	pageSize,
	setPageSize,
	setRecordsPerPage,
}) => {
	const incrementalPageIndex = pageIndex + 1;

	return (
		<>
			<div className="overflow-auto pagination-row flex items-center justify-end p-2.5 rounded-[10px] dark:bg-[#202D47] bg-[#f2f2f2]">
				<ul className="pagination-one flex mr-5 dark:text-[#fff] text-[#000]">
					<li>
						<strong>
							{pageIndex > 0 ? pageIndex * pageSize + 1 : 1}
						</strong>
					</li>
					<li>to</li>
					<li>
						<strong>
							{pageCount === pageIndex + 1
								? totalRecords
								: incrementalPageIndex * pageSize}
						</strong>
					</li>
					<li>of</li>
					<li>
						<strong>{totalRecords}</strong>
					</li>
				</ul>

				<ul className="items-center pagination-two flex dark:text-[#fff] text-[#000] md:w-auto w-full">
					<li
						className="prev"
						onClick={() => canPreviousPage && gotoPage(0)}
					>
						<i
							className={`fas fa-step-backward ${
								!canPreviousPage ? 'disable' : ''
							}`}
						></i>
					</li>
					<li
						className="md:mx-4 mx-2"
						onClick={() => canPreviousPage && previousPage()}
					>
						<i
							className={`fas fa-chevron-left lg:w-[30px] w-[25px] lg:h-[30px] h-[25px] border p-1.5 lg:text-[16px] text-[12px] rounded-sm border-solid border-[#2e4062] dark:bg-[#17243e] bg-[#ddd]  ${
								canPreviousPage ? '' : 'disable'
							}`}
						></i>
					</li>
					<li style={{ display: 'flex' }}>
						Page<strong> {pageIndex + 1}</strong>
					</li>
					<li className="mr-2 ml-2">of</li>
					<li>
						<strong>{pageOptions.length}</strong>
					</li>
					<li
						className="md:mx-4 mx-2"
						onClick={() => canNextPage && nextPage()}
					>
						<i
							className={`fas fa-chevron-right lg:w-[30px] w-[25px] lg:h-[30px] h-[25px] border p-1.5 lg:text-[16px] text-[12px] rounded-sm border-solid border-[#2e4062] dark:bg-[#17243e] bg-[#ddd] ${
								canNextPage ? '' : 'disable'
							}`}
						></i>
					</li>
					<li
						className="next"
						onClick={() => canNextPage && gotoPage(pageCount - 1)}
					>
						<i
							className={`fas fa-step-forward ${
								canNextPage ? '' : 'disable'
							}`}
						></i>
					</li>
					{/* {<li className="mr-4 ml-4">
						<span className="relative">
							Go to page :
							<input type="number" className="ml-[20px] border-[#2e4062] pagination-input appearance-none  border  rounded focus:outline-none px-4 py-1 pr-0"
								defaultValue={pageIndex + 1}
								onChange={(e) => {
									if (e.target.value <= pageCount) {
										const page = e.target.value
											? Number(e.target.value) - 1
											: 0;
										gotoPage(page);
									}
								}}
								max={pageCount}
								min={1}
								style={{
									background: "#17243e"
								}} />
							<div className="absolute right-0 flex items-center pr-2 pointer-events-none  w-5 h-full top-0">
								<i className="fas fa-chevron-down absolute text-xs right-2 top-[8px]"></i>
								<i className="fas fa-chevron-up  absolute text-xs right-2 bottom-[8px]"></i>

							</div>
						</span>
					</li>} */}
					<li className="relative inline-block pagination-selection">
						<select
							className="dark:bg-[#17243e] bg-[#ddd] appearance-none border border-solid border-[#2e4062] rounded focus:outline-none dark:text-[#fff] text-[#2e4062] focus-hidden shadow-none  lg:w-16 w-14 focus-hidden lg:ml-[20px] ml-[10px] px-[10px] py-0 focus:shadow-none outline-none md:mr-0 mr-[10px]"
							style={{
								height: '30px',
								fontWeight: 'bold',
							}}
							value={pageSize}
							onChange={(e) => {
								setPageSize(Number(e.target.value));
								setRecordsPerPage(Number(e.target.value));
							}}
						>
							{[2, 5, 10, 20, 50, 100].map((pageSize) => (
								<option
									key={pageSize}
									value={pageSize}
									className="text-left"
								>
									{pageSize}
								</option>
							))}
						</select>
						<div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
							<svg
								className="w-4 h-4 dark:text-[#fff] text-[#2e4062]"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								x="0"
								clipRule="evenodd"
							>
								<path
									fillRule="evenodd"
									d="M5 7l5 5 5-5H5z"
									clipRule="evenodd"
								/>
							</svg>
						</div>
					</li>
				</ul>
			</div>
		</>
	);
};

export default Pagination;
