import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardData } from '../../store/thunk/companyThunk';

export default function AdminBoxLayout() {
	const dispatch = useDispatch();
	const dashboard_count = useSelector(
		(state) => state.company.dashboardCounts
	);
	const authUser = useSelector((state) => state.auth);
	useEffect(() => {
		dispatch(getDashboardData());
	}, [dispatch]);

	return (
		<>
			{authUser?.userDetails?.is_companyadmin && (
				<div className="grid md:grid-cols-4 grid-cols-2 gap-5 py-[20px] single-grid-wrapper">
					<div key="1" className="single-grid">
						<p className="text-[#fff] font-semibold leading-[21px]">
							Full Time / Part Time
						</p>
						<h1 className="text-white text-[40px] font-bold leading-[48px] font-family-Montserrat-bold mt-[11.87px]">
							{dashboard_count?.full_time_user_count}/
							{dashboard_count?.part_time_user_count}
						</h1>
					</div>
					<div key="2" className="single-grid">
						<p className="text-[#fff] font-semibold leading-[21px]">
							On Leave
						</p>
						<h1 className="text-white text-[40px] font-bold leading-[48px] font-family-Montserrat-bold mt-[11.87px]">
							{dashboard_count?.user_on_leave_count}/
							{dashboard_count?.total_user_count}
						</h1>
					</div>
					<div key="3" className="single-grid">
						<p className="text-[#fff] font-semibold leading-[21px]">
							New Join
						</p>
						<h1 className="text-white text-[40px] font-bold leading-[48px] font-family-Montserrat-bold mt-[11.87px]">
							{dashboard_count?.current_month_users_count}/
							{dashboard_count?.total_user_count}
						</h1>
					</div>
					<div key="4" className="single-grid">
						<p className="text-[#fff] font-semibold leading-[21px]">
							Happiness Rate
						</p>
						<h1 className="text-white text-[40px] font-bold leading-[48px] font-family-Montserrat-bold mt-[11.87px]">
							80%
						</h1>
					</div>
				</div>
			)}
			{authUser?.userDetails?.is_superuser && (
				<div className="md:grid block grid-cols-4 gap-5 py-[20px] single-grid-wrapper">
					<div key="1" className="single-grid">
						<p className="text-[#fff] font-semibold leading-[21px]">
							Total Company
						</p>
						<h1 className="text-white text-[40px] font-bold leading-[48px] font-family-Montserrat-bold mt-[11.87px]">
							{dashboard_count?.total_company_counts}
						</h1>
					</div>
				</div>
			)}
		</>
	);
}
