import React, { useCallback, useState } from 'react';
import { getBase64 } from './utils';
import { customValidations } from '../../utils/validations';

const InputComponent = ({
	id,
	inputType,
	title,
	placeholder,
	errors,
	register,
	readOnly,
	fieldvalidation,
	onChange,
	existingProfileImage,
	isMultipleField,
	index,
}) => {
	const [image, setImage] = useState(existingProfileImage);
	const handleInputChange = useCallback(
		async (event) => {
			if (inputType === 'file') {
				const base64 = await getBase64(event.target.files[0]);
				setImage(base64);
				onChange(event);
			}
		},
		[inputType, onChange]
	);

	let fieldPrefix = '';
	let fieldName = '';
	let registrationProps = {};

	if (isMultipleField) {
		fieldPrefix = id.split('.').shift();
		fieldName = id.split('.').pop();
		registrationProps = {
			...fieldvalidation.validation[id],
			validate: (value) => customValidations(id, value, fieldvalidation),
		};
	} else {
		registrationProps = fieldvalidation.validation[id];
	}

	return (
		<div
			className={`input-component my-[10px] mr-[12px] ${inputType || ''}`}
		>
			<div className="relative h-12 w-full min-w-[200px]">
				<input
					id={id}
					type={inputType || 'text'}
					className="dark:text-white text-[#4b5563] border-t-0 peer h-full w-full rounded-[7px] border bg-transparent px-3 pt-4 pb-3 font-sans text-base font-normal outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-1 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
					placeholder={placeholder || ' '}
					readOnly={readOnly}
					{...register(id, registrationProps)}
					onChange={handleInputChange}
					autoComplete="off"
				/>
				{errors?.[id] && (
					<p className="error-message">{errors?.[id]?.message}</p>
				)}
				{errors[fieldPrefix] &&
					errors[fieldPrefix][index] &&
					errors[fieldPrefix][index][fieldName] && (
						<p className="error-message">
							{errors[fieldPrefix][index][fieldName]?.message}
						</p>
					)}
				<label className="dark:text-white text-[#4b5563] before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[14px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-base peer-placeholder-shown:leading-[3.80] dark:peer-placeholder-shown:text-white peer-placeholder-shown:text-[#4b5563] peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[14px] peer-focus:leading-tight dark:peer-focus:text-white peer-focus:text-[#4b5563] peer-focus:before:border-t-2 peer-focus:before:border-l-2 dark:peer-focus:before:border-white peer-focus:before:border-[#e5e7eb] peer-focus:after:border-t-2 peer-focus:after:border-r-2 dark:peer-focus:after:border-white peer-focus:after:border-[#e5e7eb] peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent dark:peer-disabled:peer-placeholder-shown:text-white peer-disabled:peer-placeholder-shown:text-[#4b5563]">
					{title}
				</label>
			</div>
			{inputType === 'file' && image && (
				<div className="mt-[15px]">
					<img
						src={image}
						width="150px"
						alt=""
						className="rounded-full"
					/>
				</div>
			)}
		</div>
	);
};
export default InputComponent;
