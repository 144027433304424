import React, { useEffect, useState } from 'react';
import ProfileImage from '../../assets/images/setting-profile.png';
import SaveImage from '../../assets/images/save.png';
import EditImage from '../../assets/images/Edit.png';
import { UpdateUserProfile, getUserData } from '../../store/thunk/userThunk';
import { useDispatch, useSelector } from 'react-redux';

function ProfilePicture({ selectedFile, profileImageUrl }) {
	const imageUrl = selectedFile
		? URL.createObjectURL(selectedFile)
		: profileImageUrl;

	return (
		<img
			src={imageUrl}
			alt="Profile"
			style={{ width: '100px' }}
			className="h-full w-full object-cover rounded-full bg-white border border-slate-300 p-1"
		/>
	);
}

function TextInputField({
	label,
	value,
	onEditView,
	isEditMode,
	fieldName,
	onSave,
}) {
	const [editedValue, setEditedValue] = useState(value);

	useEffect(() => {
		setEditedValue(value);
	}, [value]);

	return (
		<div className="text-left relative">
			<label className="block mb-[15px] text-[#0C1932] dark:text-white text-sm not-italic font-normal leading-[18px]">
				{label}
			</label>
			{isEditMode ? (
				<div className="flex items-center justify-between">
					{fieldName !== 'about' ? (
						<input
							type="text"
							defaultValue={editedValue}
							onChange={(e) => setEditedValue(e.target.value)}
							className="text-base text-[#0C1932] dark:text-white pl-[19.34px] leading-[21px] w-full bg-[#f1f5f9] dark:bg-[#0F1A32] px-2 py-1 h-[47.983px] outline-none rounded text-[#000]"
						/>
					) : (
						<textarea
							defaultValue={editedValue}
							onChange={(e) => setEditedValue(e.target.value)}
							className="h-full text-base text-[#0C1932] dark:text-white  leading-[21px] w-full bg-[#f1f5f9] dark:bg-[#0F1A32] p-4 h-[47.983px] outline-none rounded text-[#000]"
						/>
					)}
					<img
						src={SaveImage}
						alt="Save"
						title="Save"
						className="cursor-pointer absolute right-[14.98px] bottom-[12px] h-[26px]"
						onClick={() => {
							onSave(fieldName, editedValue);
							onEditView();
						}}
					/>
				</div>
			) : (
				<div className="flex items-center justify-between">
					{fieldName !== 'about' ? (
						<input
							type="text"
							value={editedValue}
							readOnly
							className="text-base text-[#0C1932] dark:text-white pl-[19.34px] leading-[21px] w-full bg-[#f1f5f9] dark:bg-[#0F1A32] px-2 py-1 h-[47.983px] outline-none rounded text-[#000]"
						/>
					) : (
						<textarea
							value={editedValue}
							readOnly
							className="h-full text-base text-[#0C1932] dark:text-white  leading-[21px] w-full bg-[#f1f5f9] dark:bg-[#0F1A32] p-4 h-[47.983px] outline-none rounded text-[#000]"
						/>
					)}
					<img
						src={EditImage}
						alt="Edit"
						title="Edit"
						className="cursor-pointer absolute right-[14.98px] bottom-[15px]"
						onClick={onEditView}
					/>
				</div>
			)}
		</div>
	);
}

export default function Profileblock() {
	const dispatch = useDispatch();
	const [selectedFile, setSelectedFile] = useState(null);
	const user_data = useSelector((state) => state.user.userData);
	const authUser = useSelector((state) => state.auth);

	const [isEditMode, setIsEditMode] = useState(false);

	useEffect(() => {
		dispatch(getUserData(authUser?.userDetails));
	}, [dispatch, authUser?.userDetails]);

	const onEditView = () => {
		setIsEditMode(true);
	};

	const handleSaveField = async (fieldName, value) => {
		let data = {
			id: user_data?.id,
			...(fieldName === 'fullname' && {
				first_name: value.split(' ')[0],
				middle_name: value.split(' ')[1],
				last_name: value.split(' ')[2],
			}),
			...(fieldName !== 'fullname' && { [fieldName]: value }),
		};

		try {
			await dispatch(UpdateUserProfile(data));
			setIsEditMode(false);
		} catch (error) {
			console.error('Error updating user profile:', error);
		}
	};

	useEffect(() => {
		return () => {
			if (selectedFile) {
				URL.revokeObjectURL(URL.createObjectURL(selectedFile));
			}
		};
	}, [selectedFile]);

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
		const data = {
			id: user_data?.id,
			profile_image: event.target.files[0],
		};
		dispatch(UpdateUserProfile(data));
	};

	return (
		<>
			<h1 className="mb-[28.58px] dark:text-white text-[#000] text-[22px] not-italic font-bold leading-7  text-left">
				Recent Settings
			</h1>
			<div className="shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)] dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] rounded-[15px] bg-[#fff] dark:bg-[#17243e] p-6">
				<div className="flex items-center h-full">
					<ProfilePicture
						selectedFile={selectedFile}
						profileImageUrl={
							user_data?.profile_image_url || ProfileImage
						}
					/>
					<div className="text-left pl-5">
						<p className="dark:text-white text-[#4b5563] text-[19px] font-bold leading-[25px] pb-[21px]">
							Change Profile Picture
						</p>
						<button className="cursor-pointer expert-btn text-sm leading-[18px] font-semibold text-white rounded-lg p-[10px] relative">
							<input
								type="file"
								onChange={handleFileChange}
								style={{
									opacity: 0,
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: '100%',
									cursor: 'pointer',
								}}
							/>
							Upload
						</button>
					</div>
				</div>
				<div className="grid grid-cols-1 lg:grid-cols-3 gap-[17.84px] mt-[38.14px]">
					<TextInputField
						label="Employee Full Name"
						value={`${user_data?.first_name} ${user_data?.middle_name} ${user_data?.last_name}`}
						onEditView={onEditView}
						isEditMode={isEditMode}
						fieldName="fullname"
						onSave={handleSaveField}
					/>
					<TextInputField
						label="Address"
						value={user_data?.address || ''}
						onEditView={onEditView}
						isEditMode={isEditMode}
						fieldName="address"
						onSave={handleSaveField}
					/>
					<TextInputField
						label="Phone"
						value={user_data?.phone_number || ''}
						onEditView={onEditView}
						isEditMode={isEditMode}
						fieldName="phone_number"
						onSave={handleSaveField}
					/>
				</div>
				<div className="mt-[28.87px]">
					<div className="relative">
						<TextInputField
							label="About Me"
							value={user_data?.about || ''}
							onEditView={onEditView}
							isEditMode={isEditMode}
							fieldName="about"
							onSave={handleSaveField}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
